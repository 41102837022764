import { Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { useGlobalContext } from 'contexts/GlobalContext';
import { ValueHover } from './StatisticTable';
interface CellTextProps {
	column?: {
		accessor?: string;
	};
	row?: {
		dataTest?: string;
	};
	cell: number | string | ValueHover;
	showDecimals?: boolean;
	applyLocaleString?: boolean;
}
export const CellText = ({
	cell,
	showDecimals = true,
	applyLocaleString = false,
	column,
	row,
}: CellTextProps) => {
	const dataTest = row?.dataTest + '-' + column?.accessor;
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const { configsCompany } = useGlobalContext();

	function render() {
		if (typeof cell === 'number') {
			if (showDecimals) {
				if (applyLocaleString) {
					return Number(
						cell.toFixed(configsCompany.precisao_decimal),
					)?.toLocaleString?.();
				}

				return Number(cell.toFixed(configsCompany.precisao_decimal));
			}
			return Number(cell.toFixed())?.toLocaleString?.();
		}

		return cell || '-';
	}

	return (
		<Text
			paddingX='8px'
			w={'100%'}
			h='100%'
			display={'flex'}
			alignItems='center'
			marginRight={0}
			color={textColor}
			fontSize='sm'
			fontWeight='700'
			data-test={dataTest}
		>
			{render()}
		</Text>
	);
};
