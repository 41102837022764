import { useCallback, useEffect, useState, useMemo } from 'react';
import { Flex, Spinner, Tooltip } from '@chakra-ui/react';
import { IconEye, IconEyeOff, IconHistory } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import StatisticTable, {
	Row,
} from 'components/dataTables/statisticTable/StatisticTable';
import { useSalesHistory } from 'services/queryClient/wrapperHooks/useSalesHistory';
import { useContextColaboracaoConsenso } from '../context';
import {
	columnFirtDataColaboracaoCadastrar,
	columnsEndCadastrar,
} from '../variables/columnsDataCadastrar';
import { monthsUpperCase } from '../../../../../utils/consts/months';
import LineChart from '../../../../../components/chart/LineChart';
import { AlertComponent } from '../../../../../components/alerts/AlertComponent';
import MultiSelect, {
	Option,
} from '../../../../../components/multiSelect/Multiselect';

import './SectionHistorico.css';
import moment from 'moment';
import { numberToLocaleString } from '../../../../../utils/functions/number';
import { renderChartCustomTooltip } from 'utils/functions/charts';
import { generateRowsMonths } from 'components/dataTables/statisticTable/generateRowsMonths';

export default function SectionHistorico() {
	const {
		familyId,
		salesChannelId,
		cycleId,
		valuesFilter,
		salesZoneId,
		cycle,
	} = useContextColaboracaoConsenso();
	const { ano: year } = cycle;

	const clientId = valuesFilter.cliente?.value || '';
	const sizeHistoricalPeriod = 10;
	const historicalBasis = String((sizeHistoricalPeriod - 1) * 12);

	const { data, isLoading } = useSalesHistory({
		salesChannelId,
		clientId: String(clientId) || '',
		cycleId,
		familyId,
		historicalBasis,
		salesZoneId,
	});

	const [historico, setHistorico] = useState<Row[]>([]);
	const [apexChartData, setApexChartData] = useState<any>({});
	const [exibirGrafico, setExibirGrafico] = useState<boolean>(true);

	const yearCiclo = Number(year);

	const [valuesFilterChart, setValuesFilterChart] = useState<number[]>([
		yearCiclo,
	]);
	const [filterOptions, setFilterOptions] = useState<Option[]>([
		{ label: yearCiclo, value: yearCiclo },
	]);

	const [maxHistoricYear, setMaxHistoricYear] = useState<number>(yearCiclo);

	// vermelho - verde - laranja - azul - roxo
	// amarelo - rosa - marrom - verde escuro - laranja escuro
	const apexChartColors = useMemo(
		() => [
			'#B01A2E',
			'#01B574',
			'#FDAB4F',
			'#567EB3',
			'#B400FF',
			'#F5FF44',
			'#21D6ED',
			'#FF53F9',
			'#850000',
			'#004514',
			'#FF4500',
		],
		[],
	);

	const getMaxYear = useCallback(() => {
		return data?.length
			? Math.max(...data.map((d) => Number(d?.ano || 0)))
			: new Date().getUTCFullYear();
	}, [data]);

	const getMinYear = useCallback(() => {
		return data?.length
			? Math.min(...data.map((d) => Number(d?.ano || 0)))
			: new Date().getUTCFullYear();
	}, [data]);

	const getYearDataSizeByLastMonth = useCallback(
		(year) => {
			const maxMonth = Math.abs(
				Math.max(
					...(data
						?.filter((d: any) => Number(d?.ano) === Number(year))
						?.map((d) => Number(d?.mes)) || []),
				),
			);

			return maxMonth === Infinity ? 0 : maxMonth;
		},
		[data],
	);

	const getApexChartOptions = useCallback(() => {
		return {
			chart: {
				stacked: false,
				toolbar: {
					offsetY: 0,
				},
			},
			stroke: {
				width: [2, 2, 2, 2, 2],
			},
			grid: {
				show: true,
				borderColor: '#90a4ae6b',
				strokeDashArray: 0,
				position: 'front',
			},
			xaxis: {
				categories: [...monthsUpperCase],
			},
			yaxis: {
				labels: {
					formatter: (v) =>
						v ? `${numberToLocaleString({ value: v })}` : '0',
				},
			},
			tooltip: {
				theme: 'dark',
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					return `<div className="arrow_box">${renderChartCustomTooltip(
						{
							series,
							seriesIndex,
							dataPointIndex,
							w,
							colors: apexChartColors,
							hideUndefinedValues: false,
						},
					)}</div>`;
				},
			},
		};
	}, [apexChartColors]);

	const renderApexChartSeries = useCallback(({ ...props }) => {
		const { allSeries, year, historic } = props;

		const index = historic?.mes - 1;
		if (index > -1 && allSeries[year].data.length > index)
			allSeries[year].data[index] = Number(historic?.soma) || 0;

		return allSeries;
	}, []);

	const createInitialApexChartSeries = useCallback(() => {
		const maxYear = getMaxYear();

		const series = {};

		for (let i = 0; i < sizeHistoricalPeriod; i++) {
			const year = maxYear - i;

			series[year] = {
				name: year,
				type: 'line',
				data: Array(getYearDataSizeByLastMonth(year)).fill(0),
				color: apexChartColors[i],
			};
		}

		return series;
	}, [apexChartColors, getYearDataSizeByLastMonth, getMaxYear]);

	const updateHistoricData = useCallback(({ ...props }) => {
		const { year, historyData, historic } = props;
		const currentDate = moment().utc();

		const sum = Number(historic?.soma) || 0;

		historyData[year][historic?.mes] = sum;

		if (Number(year) === currentDate.year()) {
			if (Number(historic?.mes) < currentDate.month() + 1) {
				let previousMonth = Number(currentDate.format('M')) - 1;

				if (previousMonth === 0) previousMonth = 1;

				historyData[year]['total'] += sum;
				historyData[year]['media'] =
					historyData[year]['total'] / previousMonth;
			}
		} else {
			historyData[year]['total'] += sum;
			historyData[year]['media'] = historyData[year]['total'] / 12;
		}
	}, []);

	const createInitialHistoricData = useCallback(() => {
		const statisticTableData = {};

		const maxYear = getMaxYear();
		const minYear = getMinYear();

		const rangeYears = maxYear - minYear + 1;

		for (let i = 0; i < rangeYears; i++) {
			const year = maxYear - i;
			const maxMonth = getYearDataSizeByLastMonth(year);

			const monthsKeys = Array(maxMonth)
				.fill(null)
				.reduce((acc, _, index) => {
					acc[index + 1] = '';
					return acc;
				}, {});

			statisticTableData[year] = {
				...monthsKeys,
				media: 0,
				total: 0,
				year: String(year),
				type: 'text',
			};
		}

		return statisticTableData;
	}, [getMaxYear, getMinYear, getYearDataSizeByLastMonth]);

	const getSeriesToRender = useCallback(
		(series) => {
			const allSeries = Object.values(series || []) as object[];
			return allSeries.filter((s) =>
				valuesFilterChart?.includes?.(s['name']),
			);
		},
		[valuesFilterChart],
	);

	const sortObjectArrayDesc = (key, a, b) => {
		if (!key || !a || !b) return 0;

		if (a[key] < b[key]) return 1;
		if (a[key] > b[key]) return -1;
		return 0;
	};

	const createFilterOptions = useCallback(() => {
		const options = [] as Array<Option>;

		const maxYear = getMaxYear();
		const minYear = getMinYear();

		const rangeYears = maxYear - minYear + 1;

		for (let i = 0; i < rangeYears; i++) {
			const year = maxYear - i;

			options.push({ label: year, value: year });
		}

		setFilterOptions(options);
	}, [getMaxYear, getMinYear]);

	const renderStatisticTableBody = useMemo(
		() =>
			generateRowsMonths({
				startMonth: 0,
				qtdMounth: 12,
				abbreviatedMonth: false,
			}),
		[],
	);

	const showHideApexChart = useCallback(() => {
		return (
			<Tooltip
				label={exibirGrafico ? 'Ocultar gráfico' : 'Exibir gráfico'}
				placeContent='left'
			>
				<span>
					<ButtonComponent
						onClick={() => setExibirGrafico(!exibirGrafico)}
						iconType={exibirGrafico ? <IconEyeOff /> : <IconEye />}
						type='icon'
					/>
				</span>
			</Tooltip>
		);
	}, [exibirGrafico]);

	const checkRowVisible = useCallback(
		({ row }) => {
			return valuesFilterChart?.includes(Number(row?.year));
		},
		[valuesFilterChart],
	);

	const filterOptionsDefaultValue = useCallback(() => {
		return filterOptions?.length ? filterOptions[0] : null;
	}, [filterOptions]);

	const filteOptionsValuesSelected = useCallback(() => {
		return valuesFilterChart.map((value) => {
			return {
				label: value,
				value,
			};
		});
	}, [valuesFilterChart]);

	const headerHelp = useMemo(
		() => [
			{
				accessor: 'total',
				tooltip: `Para o ano atual, o cálculo do total considera o acumulado até ${moment()
					.utc()
					.subtract({ month: 1 })
					.format(
						'MMM/YY',
					)}. Para os anos anteriores, o cálculo do total considera o acumulado dos 12 meses.`,
			},
			{
				accessor: 'media',
				tooltip: `Para o ano atual, o cálculo da média considera o acumulado até ${moment()
					.utc()
					.subtract({ month: 1 })
					.format(
						'MMM/YY',
					)}. Para os anos anteriores, o cálculo da média considera o acumulado dos 12 meses.`,
			},
		],
		[],
	);

	useEffect(() => {
		if (data?.length) {
			const years =
				data
					?.map((d) => Number(d?.ano))
					?.filter((el, index, arr) => arr.indexOf(el) === index) ||
				[];
			const maxYear = Math.max(...years);

			setMaxHistoricYear(maxYear);

			const existsYearsInData = valuesFilterChart?.filter((v) =>
				years.includes(v),
			);

			if (!existsYearsInData?.length) {
				setValuesFilterChart([maxYear]);
			} else {
				setValuesFilterChart(existsYearsInData);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.length, getMaxYear]);

	useEffect(() => {
		if (!valuesFilterChart?.length) setValuesFilterChart([maxHistoricYear]);
	}, [maxHistoricYear, valuesFilterChart?.length]);

	useEffect(() => {
		if (data?.length) {
			const historyData = createInitialHistoricData();
			const currentYear = new Date().getUTCFullYear();

			createFilterOptions();

			const series = data.reduce((allSeries, historic) => {
				const year = historic?.ano || currentYear;

				updateHistoricData({
					year,
					historyData,
					historic,
				});

				return renderApexChartSeries({
					allSeries,
					year,
					historic,
				});
			}, createInitialApexChartSeries());

			setApexChartData({
				options: getApexChartOptions(),
				series: getSeriesToRender(series),
			});
			const hitoricSortedByYearDesc = Object.values(
				historyData || {},
			).sort((a, b) => sortObjectArrayDesc('year', a, b)) as Row[];

			setHistorico(hitoricSortedByYearDesc);
		} else {
			setApexChartData({
				options: {},
				series: [],
			});
			setHistorico([]);
		}
	}, [
		yearCiclo,
		data,
		getApexChartOptions,
		getYearDataSizeByLastMonth,
		updateHistoricData,
		renderApexChartSeries,
		valuesFilterChart,
		createInitialHistoricData,
		createInitialApexChartSeries,
		getSeriesToRender,
		createFilterOptions,
	]);

	useEffect(() => {
		setExibirGrafico(false);
		setTimeout(() => {
			setExibirGrafico(true);
		}, 750);
	}, [familyId]);

	const renderChartContent = useCallback(() => {
		function showChart() {
			return !!apexChartData?.series?.length;
		}

		if (!isLoading) {
			if (showChart()) {
				return (
					<StatisticTable
						title={{
							icon: IconHistory,
							name: 'Histórico',
						}}
						SectionButtons={
							<>
								<Flex
									flexGrow={1}
									justifyContent={'space-between'}
								>
									<Flex gap={'10px'}>
										{showHideApexChart()}
									</Flex>
									{exibirGrafico && (
										<Flex gap={'20px'}>
											<MultiSelect
												defaultValue={filterOptionsDefaultValue()}
												onChange={(newValue) => {
													setValuesFilterChart(
														newValue.map((v) =>
															Number(v.label),
														),
													);
												}}
												options={filterOptions}
												size={'medium'}
												placeholder='Filtrar por ano'
												notHideSelectedOptions
												value={filteOptionsValuesSelected()}
												selectAllTitle='Todos os anos'
											/>
										</Flex>
									)}
								</Flex>
								<Flex
									w={'100%'}
									flexDirection='column'
								>
									{exibirGrafico && (
										<LineChart
											chartOptions={
												apexChartData?.options
											}
											chartData={apexChartData?.series}
										/>
									)}
								</Flex>
							</>
						}
						conditionalFilter={checkRowVisible}
						columnsData={{
							header: [columnFirtDataColaboracaoCadastrar],
							body: renderStatisticTableBody,
							footer: columnsEndCadastrar,
						}}
						tableData={historico}
						applyLocaleString
						headerHelp={headerHelp}
					/>
				);
			}

			return (
				<AlertComponent
					title='Observação!'
					description={
						'Não existem dados suficientes para gerar o gráfico histórico!'
					}
					status='info'
					hasCloseButton
				/>
			);
		}

		return (
			<Flex
				w={'100%'}
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
			>
				<Spinner
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='blue.500'
					size='lg'
				/>
				<p>Carregando dados para gráfico histórico...</p>
			</Flex>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		apexChartData,
		checkRowVisible,
		exibirGrafico,
		filteOptionsValuesSelected,
		filterOptions,
		filterOptionsDefaultValue,
		historico,
		isLoading,
		renderStatisticTableBody,
		showHideApexChart,
	]);

	return <>{renderChartContent()}</>;
}
