import { Flex, Text } from '@chakra-ui/react';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useGetAccuracyProductData } from 'services/queryClient/wrapperHooks/useGetAccuracyData';
import { LoadingSpinner } from 'components/loading/Loading';
import { useAcuracidade } from '../context';
import { renderColumns } from '../variables/utils';

const MAX_RESULTS_PAGE = 10;

const ProductInfiniteListComponent = (props) => {
	const { keysHeaders, familiaId } = props;

	const { filters, tableFilters } = useAcuracidade();

	const currentPage = useRef<number>(0);

	const observer = useRef<IntersectionObserver>();

	const { data, error, isFetching, isLoading, fetchNextPage, hasNextPage } =
		useGetAccuracyProductData(currentPage, {
			additional_filters: tableFilters,
			interval: filters?.intervalo,
			demand_type: filters?.tipo_previsao,
			sales_channel: filters?.canal_venda,
			indicator: filters?.tipo_indicador,
			family_id: familiaId,
			limit: MAX_RESULTS_PAGE,
		});

	const lastElementRef = useCallback(
		(node: HTMLDivElement) => {
			if (isLoading) return;

			/**
			 * Se já existir um observer, desconecta ele, afim de evitar a criação desnecessário
			 * de múltiplos observers.
			 */
			if (observer.current) {
				observer.current.disconnect();
			}

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasNextPage && !isFetching) {
					fetchNextPage();
				}
			});

			if (node) {
				observer.current.observe(node);
			}
		},
		[fetchNextPage, hasNextPage, isFetching, isLoading],
	);

	const produtos = useMemo(() => {
		const productData = data?.pages?.reduce?.(
			(acc, page) => [
				...acc,
				...(page?.product_list?.map((el) => ({
					...el,
					mape: el?.ape,
				})) || []),
			],
			[],
		);

		if (!!data?.pages)
			currentPage.current = Math.ceil(
				(productData?.length || 0) / MAX_RESULTS_PAGE,
			);

		return productData || [];
	}, [data?.pages]);

	const renderRows = useCallback(
		(items, level = 0) => {
			return items?.map((item, index) => {
				const columns = renderColumns(keysHeaders, item, level);

				return (
					<Flex
						key={`${item?.descricao}-${index}`}
						_hover={{
							backgroundColor: '#f4f7fe !important',
							color: '#42adef !important',
						}}
						pt='5px'
						pb='5px'
						ref={lastElementRef}
					>
						<Flex
							pl={`${(30 * level) / 2}px`}
							w='40px !important'
						/>
						{columns}
					</Flex>
				);
			});
		},
		[keysHeaders, lastElementRef],
	);

	return (
		<Flex
			display='column'
			maxH='250px'
			overflowY='auto'
		>
			{isLoading && (
				<LoadingSpinner
					pt='5px'
					pb='5px'
					color='easyBLUE.300'
				/>
			)}

			{error && (
				<Flex
					w='100%'
					justifyContent='center'
					alignItems='center'
				>
					<Text
						color='easyRED.300'
						fontSize='sm'
						pt='5px'
						pb='5px'
					>
						Ocorreu um erro ao buscar os produtos.
					</Text>
				</Flex>
			)}
			{renderRows(produtos, 1)}

			{!isLoading && isFetching && (
				<LoadingSpinner
					message='carregando mais produtos'
					pt='5px'
					pb='5px'
					color='easyBLUE.300'
				/>
			)}

			{!isLoading && !isFetching && !produtos?.length && (
				<Flex
					w='100%'
					justifyContent='center'
					alignItems='center'
				>
					<Text
						color='easyBLUE.300'
						fontSize='sm'
						pt='5px'
						pb='5px'
					>
						Não há produtos para carregar.
					</Text>
				</Flex>
			)}
		</Flex>
	);
};

const ProductInfiniteList = memo(ProductInfiniteListComponent);

export default ProductInfiniteList;
