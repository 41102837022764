import { QueryFunctionContext } from '@tanstack/react-query';
import alb from '../alb';
import api from '../api';

export const checkExistsRestrictedPlan = async ({
	queryKey,
}): Promise<boolean> => {
	const [, cycleId] = queryKey;

	const response = await api.get(
		'/planoRestrito/existsRestrictedPlanByCycle',
		{
			params: { ciclo_id: cycleId },
		},
	);
	return response.data;
};
export interface BodyRestrictedPlan {
	ciclo_id: number;
	quantidade_produtos: number;
	produto_id: number;
	canal_venda_id: number;
	excecao_consenso_id?: number;
	oportunidade_id?: number;
	excecao: boolean;
	mes_ano: string;
}
export interface BodyUpdateRestrictedPlan {
	ciclo_id: number;
	plano_restrito: BodyRestrictedPlan[];
}
export const updateRestrictedPlanByCycle = async (
	body: BodyUpdateRestrictedPlan,
) => {
	const url = `/planoRestrito`;
	const response = await api.patch(url, body);
	return response.data;
};

export const recalculateRestrictedPlan = async (cycleId: number) => {
	const url = `/planoRestrito/recalcular`;
	const response = await alb.post(url, { ciclo_id: cycleId });
	return response.data;
};

export const exportRestrictedPlan = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, ciclo_id] = queryKey;
	const response = await api.get(`/planoRestrito/exportCSV`, {
		params: { ciclo_id: ciclo_id },
	});
	return response.data;
};

export const getFamiliesRestrictedPlanByCycle = async (payload) => {
	const response = await api.get(`/planoRestrito/familias`, {
		params: { ...payload },
	});
	return response.data;
};

export const getProductsRestrictedPlanByCycle = async (payload) => {
	const response = await api.get(`/planoRestrito/produtos`, {
		params: { ...payload },
	});
	return response.data;
};
