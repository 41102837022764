import { Input } from '@chakra-ui/react';
import moment from 'moment';
import InputTimeMask from 'components/inputs/time/InputTimeMask';

export function getRoteiroInputValue({ row, cell, tableData }) {
	if (
		cell.column.id === 'quantidade_produto' ||
		cell.column.id === 'ordem_roteiro'
	)
		return tableData[row.id][cell.column.id];

	if (cell.column.id === 'tempo_maquina') {
		if (typeof tableData[row.id][cell.column.id] !== 'string')
			return moment
				.utc(tableData[row.id][cell.column.id] * 1000)
				.format('HH:mm:ss');

		return tableData[row.id][cell.column.id];
	}

	if (cell.column.id === 'tempo_mao_obra') {
		if (typeof tableData[row.id][cell.column.id] !== 'string')
			return moment
				.utc(tableData[row.id][cell.column.id] * 1000)
				.format('HH:mm:ss');

		return tableData[row.id][cell.column.id];
	}

	return null;
}

export const getRoteiroInput = ({ ...props }) => {
	const { tableData, row, cell, valueInput, setRoteiro, setDataToBody } =
		props;

	const handleOnChange = (e) => {
		const newRoteiro = [...tableData];
		newRoteiro[row.id][cell.column.id] = e.target.value;
		setRoteiro([...newRoteiro]);
		setDataToBody(tableData);
	};

	if (
		cell.column.id === 'quantidade_produto' ||
		cell.column.id === 'ordem_roteiro'
	)
		return (
			<Input
				value={valueInput}
				type={'number'}
				name={cell.column.id}
				required
				onChange={handleOnChange}
				h='44px'
				w='150px'
				borderRadius={13}
				min={100}
			/>
		);
	else if (
		cell.column.id === 'tempo_maquina' ||
		cell.column.id === 'tempo_mao_obra'
	)
		return (
			<InputTimeMask
				name={cell?.column?.id}
				value={valueInput}
				required
				onChange={handleOnChange}
				h='44px'
				w='150px'
				borderRadius={13}
			/>
		);

	return null;
};
