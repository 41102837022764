/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import {
	ButtonGroup,
	Flex,
	FormLabel,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverFooter,
	PopoverTrigger,
	useBoolean,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { InputNumber } from './components/InputNumber';
import { InputCurrency } from './components/InputCurrency';
import { InputDate } from './components/InputDate';
import { InputMonth } from './components/InputMonth';
import { InputDateTime } from './components/InputDateTime';
import { InputTime } from './components/InputTime';
import { InputBoolean } from './components/InputBoolean';
import { InputText } from './components/InputText';
import {
	addOrUpdateItemInArray,
	findIndexByOperation,
	handleInputChange,
	operationMap,
	removeItemAtIndex,
	validateEndValue,
} from './components/constants/InputsValidations';
import {
	ColumnType,
	FilterBody,
	ModulesType,
	SelectedFilterBody,
} from 'components/filter/models/TableFilterModels';
import { getLocalStorageFilter } from 'components/filter/utils/localStorageFilterManager';
import { InputNames, InputValues } from './components/models/InputsModel';

interface PopoverFilterProps {
	children: React.ReactNode;
	applyFilters: (close: any) => void;
	filter: FilterBody;
	form: SelectedFilterBody[];
	module: ModulesType;
	selectedItems: string[];
	setForm: React.Dispatch<React.SetStateAction<any>>;
	setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
	getFromExternalAPI?: boolean;
	searchParams?: Object;
}

export const PopoverFilter = ({ children, ...rest }: PopoverFilterProps) => {
	const {
		applyFilters,
		filter,
		form,
		module,
		selectedItems,
		setForm,
		setSelectedItems,
		getFromExternalAPI = false,
		searchParams,
	} = rest;

	const { nome_coluna, tipo_coluna, tipo_valor, transformar } = filter;

	const [inputType, setInputType] = useState<ColumnType>(tipo_coluna);
	// Verifique se há uma configuração salva no localStorage para este Popover
	const filtersLocalStorage = getLocalStorageFilter(module);
	const applyFilter = filtersLocalStorage.some(
		(option) => option.nome_coluna === nome_coluna,
	);

	// Inicialize o estado do Popover com base na configuração
	const [isOpenPopover, setIsOpenPopover] = useBoolean(!applyFilter);

	const [inputValue, setInputValue] = useState<InputValues>({
		valorInicial: '',
		valorFinal: '',
		textSearch: '',
		boolean: null,
	});
	const [selectedOptions, setSelectedOptions] = useState<any>([]);
	const [validation, setValidation] = useState<boolean>(false);

	const array = {
		date: 'Data',
		month: 'Competência',
		quantity: 'Valor',
		currency: 'Valor',
		datetime: 'Data e Hora',
		time: 'Tempo',
	};

	const renderColumn = (
		dataTest: string,
		label: string,
		type: ColumnType,
		name: InputNames,
	) => {
		return (
			<Flex
				display={'column'}
				w={'100%'}
			>
				<FormLabel
					m={0}
					fontSize={'14px'}
				>
					{label}
				</FormLabel>
				{renderInputs(dataTest, type, name)}
			</Flex>
		);
	};

	const handleOnChange = (
		event: React.ChangeEvent<HTMLInputElement> | any,
	) => {
		const name = event?.target.name;
		const value = event?.target.value;

		// Atualizar o estado inputValue com o valor correspondente
		handleInputChange({ name, value, setInputValue });

		// Validar o valor inserido nos inputs de valor Final, e setar o estado validation
		const isValidationRequired = validateEndValue({
			name,
			value,
			tipo_coluna,
			inputValue,
		});
		setValidation(isValidationRequired);

		let updatedForm;

		if (form.length > 0 && form[0].nome_coluna === 'empty') {
			updatedForm = [];
		} else {
			updatedForm = [...form]; // Crie uma cópia do array form para não mutá-lo diretamente
		}

		const operation = operationMap[name];

		if (operation !== 'in') {
			if (value || value === false) {
				if (operation) {
					updatedForm = addOrUpdateItemInArray({
						updatedForm,
						nome_coluna,
						tipo_valor,
						value,
						operation,
						transformar,
					});
				}
			} else {
				if (operation) {
					const existingIndex = findIndexByOperation({
						updatedForm,
						nome_coluna,
						operation,
					});

					updatedForm = removeItemAtIndex({
						updatedForm,
						existingIndex,
					});
				}
			}
			if (updatedForm.length === 0) {
				setForm([
					{
						nome_coluna: 'empty',
					},
				]);
			} else {
				setForm(updatedForm);
			}
		}
	};

	const handleCheck = (selected: string[] | number[], name: string) => {
		let updatedForm = !!form ? [...form] : []; // Crie uma cópia do array form para não mutá-lo diretamente
		const operation = operationMap[name];

		const existingIndex = findIndexByOperation({
			updatedForm,
			nome_coluna,
			operation,
		});

		if (selected.length > 0) {
			updatedForm = addOrUpdateItemInArray({
				updatedForm,
				nome_coluna,
				tipo_valor,
				value: selected,
				operation,
			});
		} else {
			updatedForm = removeItemAtIndex({ updatedForm, existingIndex });
		}

		// Atualize o estado com o array atualizado
		setForm(updatedForm);
	};

	const renderInputs = useCallback(
		(dataTest: string, type: ColumnType, name: InputNames) => {
			switch (type) {
				case 'quantity':
					return (
						<InputNumber
							dataTest={dataTest}
							name={name}
							column={nome_coluna}
							handleOnChange={handleOnChange}
							inputValue={inputValue}
							setInputValue={setInputValue}
							module={module}
							validation={validation}
						/>
					);
				case 'currency':
					return (
						<InputCurrency
							dataTest={dataTest}
							name={name}
							column={nome_coluna}
							handleOnChange={handleOnChange}
							inputValue={inputValue}
							setInputValue={setInputValue}
							module={module}
							validation={validation}
						/>
					);
				case 'date':
					return (
						<InputDate
							dataTest={dataTest}
							name={name}
							column={nome_coluna}
							handleOnChange={handleOnChange}
							inputValue={inputValue}
							setInputValue={setInputValue}
							module={module}
							validation={validation}
						/>
					);
				case 'month':
					return (
						<InputMonth
							dataTest={dataTest}
							name={name}
							column={nome_coluna}
							handleOnChange={handleOnChange}
							inputValue={inputValue}
							setInputValue={setInputValue}
							module={module}
							validation={validation}
						/>
					);
				case 'datetime':
					return (
						<InputDateTime
							dataTest={dataTest}
							name={name}
							column={nome_coluna}
							handleOnChange={handleOnChange}
							inputValue={inputValue}
							setInputValue={setInputValue}
							module={module}
							validation={validation}
						/>
					);
				case 'time':
					return (
						<InputTime
							dataTest={dataTest}
							name={name}
							column={nome_coluna}
							handleOnChange={handleOnChange}
							inputValue={inputValue}
							setInputValue={setInputValue}
							module={module}
							validation={validation}
						/>
					);
				case 'boolean':
					return (
						<InputBoolean
							name={name}
							column={nome_coluna}
							handleOnChange={handleOnChange}
							inputValue={inputValue}
							setInputValue={setInputValue}
							module={module}
						/>
					);
				default:
					return (
						<InputText
							name={name}
							column={nome_coluna}
							handleOnChange={handleOnChange}
							inputValue={inputValue}
							setInputValue={setInputValue}
							module={module}
							handleCheck={handleCheck}
							setSelectedOptions={setSelectedOptions}
							selectedOptions={selectedOptions}
							getFromExternalAPI={getFromExternalAPI}
							searchParams={searchParams}
						/>
					);
			}
		},
		[form, inputValue, validation, selectedOptions],
	);

	const renderFilter = (type: ColumnType) => {
		// eslint-disable-next-line eqeqeq
		if (tipo_coluna == 'string') {
			return <>{renderColumn('', '', type, 'textSearch')}</>;
		} else if (tipo_coluna === 'boolean') {
			return <>{renderColumn(``, ``, type, 'boolean')}</>;
		} else {
			return (
				<Flex flexDirection={'column'}>
					<Flex>
						{renderColumn(
							'input_inicial',
							`${array[type]} Inicial`,
							type,
							'valorInicial',
						)}
						<p
							style={{
								fontSize: '12px',
								margin: '10px',
								alignSelf: 'flex-end',
							}}
						>
							até
						</p>
						{renderColumn(
							'input_final',
							`${array[type]} Final`,
							type,
							'valorFinal',
						)}
					</Flex>
					{validation && (
						<Flex
							alignItems={'center'}
							color='red'
							marginTop={2}
							fontSize='12px'
						>
							O valor final deve ser igual ou superior ao inicial.
						</Flex>
					)}
				</Flex>
			);
		}
	};

	useEffect(() => {
		if (!isOpenPopover) {
			if (!applyFilter) {
				const filterSelected = selectedItems.filter(
					(option) => option !== nome_coluna,
				);
				setSelectedItems(filterSelected);
			}
		}
	}, [isOpenPopover]);

	const disabledApplyValue = validation
		? true
		: !inputValue.valorFinal && !inputValue.valorInicial
		? true
		: false;

	const disabledApplyText = selectedOptions.length === 0 ? true : false;

	const disableApplyBoolean = inputValue.boolean === null ? true : false;

	const disbaledApply =
		// eslint-disable-next-line eqeqeq
		tipo_coluna == 'string'
			? disabledApplyText
			: tipo_coluna === 'boolean'
			? disableApplyBoolean
			: disabledApplyValue;

	return (
		<Popover
			returnFocusOnClose={false}
			isOpen={isOpenPopover}
			onOpen={setIsOpenPopover.on}
			onClose={setIsOpenPopover.off}
		>
			{({ isOpen, onClose }) => (
				<form
					onSubmit={(e) => {
						e.preventDefault();
						applyFilters(onClose);
					}}
				>
					<PopoverTrigger>{children}</PopoverTrigger>
					<PopoverContent
						bg='white'
						borderColor='#e2e8f0'
						color='blue.800'
						_focus={{ outline: 'none' }}
						w={'auto'}
					>
						<PopoverArrow bg='white' />
						<PopoverBody p={'20px 20px 15px 20px'}>
							<Flex
								alignItems={'end'}
								justifyContent={'space-around'}
							>
								{renderFilter(inputType)}
							</Flex>
						</PopoverBody>
						<PopoverFooter
							display='flex'
							alignItems='center'
							justifyContent='space-between'
							border='1'
						>
							<ButtonGroup
								display={'flex'}
								justifyContent={'space-between'}
								size='sm'
								width={'100%'}
								p={'5px 10px 10px 10px'}
							>
								<ButtonComponent
									type='ghost'
									px={'24px'}
									fontSize={'12px'}
									title='Cancelar'
									onClick={() => {
										onClose();
									}}
								/>
								<ButtonComponent
									type='primary'
									px={'24px'}
									fontSize={'12px'}
									title='Aplicar filtro'
									action='submit'
									isDisabled={disbaledApply}
								/>
							</ButtonGroup>
						</PopoverFooter>
					</PopoverContent>
				</form>
			)}
		</Popover>
	);
};
