import {
	Box,
	ButtonGroup,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { IconClipboardCheck } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import ButtonExportGenericCSV from 'components/buttons/ButtonExportGenericCSV';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { useExportRestrictedPlan } from 'services/queryClient/wrapperHooks/capacidade/restrictedPlan/useExportRestrictedPlan';
import { useExistsRestrictedPlan } from 'services/queryClient/wrapperHooks/capacidade/restrictedPlan/useExistsRestrictedPlan';
import { useExistsUnrestrictedPlan } from 'services/queryClient/wrapperHooks/demanda/unrestrictedPlan/useExistsUnrestrictedPlan';
import { useRecalculateRestrictedPlan } from 'services/queryClient/wrapperHooks/capacidade/restrictedPlan/useRecalculateRestrictedPlan';
import { Cycle } from 'models/cycle.model';
import { RestrictedPlanMessages } from './variables/restrictedPlanMessages';
import { PlanStatusAlert } from './PlanStatusAlert';
import { GridRestrictedPlan } from './GridRestrictedPlan';
import { RecalculateRestrictedPlanModal } from 'components/modal/RecalculateRestrictedPlanModal';

interface IEditRestrictedPlanModalProps {
	isOpen: boolean;
	onClose: () => void;
	cycleId: number | string;
	cycle: Cycle;
}

const EditRestrictedPlanModal = (props: IEditRestrictedPlanModalProps) => {
	const { isOpen, cycleId, cycle, onClose } = props;

	const {
		data: existsRestrictedPlanData,
		isLoading: isLoadingRestrictedPlan,
	} = useExistsRestrictedPlan(cycleId);
	const {
		data: existsUnRestrictedPlanData,
		isLoading: isLoadingUnrestrictedPlan,
	} = useExistsUnrestrictedPlan(cycleId);
	const {
		mutate: recalculateRestrictedPlan,
		isLoading: isLoadingRecalculateRestrictedPlan,
	} = useRecalculateRestrictedPlan();

	const {
		isOpen: isOpenModal,
		onOpen: onOpenModal,
		onClose: onCloseModal,
	} = useDisclosure();

	const handleOnClose = useCallback(() => {
		onClose();
	}, []);
	const [isSubmit, setIsSubmit] = useState(false);

	function renderButtons() {
		return (
			<Flex
				justifyContent='end'
				mt='10px'
				mb='20px'
			>
				<ButtonGroup spacing={'10px'}>
					<ButtonComponent
						data-test='button-cancelar-editar_plano-modal-dashboard_capacidade'
						type={'ghost'}
						title={'Cancelar'}
						onClick={handleOnClose}
						isDisabled={isSubmit}
					/>
					<ButtonComponent
						data-test='button-recalcular-editar_plano-modal-dashboard_capacidade'
						type={'secondary'}
						title={'Recalcular'}
						onClick={onOpenModal}
						isDisabled={!existsRestrictedPlanData || isSubmit}
					/>

					{!existsRestrictedPlanData &&
						existsUnRestrictedPlanData && (
							<ButtonComponent
								data-test='button-gerar_plano-editar_plano-modal-dashboard_capacidade'
								type='primary'
								action='submit'
								title='Gerar Plano'
								isDisabled={!existsUnRestrictedPlanData}
								onClick={() =>
									recalculateRestrictedPlan(cycleId as any)
								}
							/>
						)}
					{existsRestrictedPlanData && (
						<ButtonComponent
							data-test='button-gerar_plano-editar_plano-modal-dashboard_capacidade'
							type='primary'
							action='submit'
							title='Atualizar Plano'
							onClick={() => setIsSubmit(true)}
						/>
					)}
				</ButtonGroup>
				<RecalculateRestrictedPlanModal
					isOpen={isOpenModal}
					onClose={onCloseModal}
					cycleId={cycleId as any}
				/>
			</Flex>
		);
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleOnClose}
			onEsc={handleOnClose}
			isCentered={!existsRestrictedPlanData}
			size={existsRestrictedPlanData ? 'full' : '4xl'}
		>
			<ModalOverlay />
			<ModalContent borderRadius={20}>
				<ModalHeader
					display='flex'
					alignItems='end'
					justifyContent='space-between'
					tabIndex={-1}
				>
					<Box>
						<Icon
							as={IconClipboardCheck}
							boxSize={12}
							color={'#25364D'}
						/>
						<Flex
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Text
								fontSize='2xl'
								fontWeight={700}
								color={'#25364D'}
							>
								Plano Restrito
							</Text>
						</Flex>
					</Box>
					<Box
						display={'flex'}
						gap={2}
					>
						<TooltipStandard
							label={
								RestrictedPlanMessages.MSG_EXPORT_RESTRICTED_PLAN_TO_CSV
							}
							shouldWrapChildren={true}
						>
							<ButtonExportGenericCSV
								errorDescription={
									RestrictedPlanMessages.MSG_WHITOUT_RESTRICTED_PLAN
								}
								module='plano-restrito'
								useExport={useExportRestrictedPlan}
								cycleId={cycleId}
								filters={{
									ano: cycle?.ano?.toString(),
									ciclo: cycle?.ciclo?.toString(),
								}}
							/>
						</TooltipStandard>
					</Box>
				</ModalHeader>
				<ModalCloseButton data-test='button-fechar-modal' />
				<ModalBody
					display={'flex'}
					flexDirection='column'
					height='100vh'
					gap={'2px'}
					paddingY={0}
				>
					<PlanStatusAlert
						restrictedPlanExists={existsRestrictedPlanData}
						unrestrictedPlanExists={existsUnRestrictedPlanData}
						isLoading={
							isLoadingRestrictedPlan ||
							isLoadingUnrestrictedPlan ||
							isLoadingRecalculateRestrictedPlan
						}
					/>
					{existsRestrictedPlanData && cycle && (
						<GridRestrictedPlan
							cycle={cycle}
							isSubmit={isSubmit}
							setIsSubmit={setIsSubmit}
						/>
					)}
					{renderButtons()}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default EditRestrictedPlanModal;
