import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { signUpAdmin } from 'services/api/requests/authentication';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useSignUpAdmin = (cleanFields) => {
	const toast = useToast();
	return useMutation(signUpAdmin, {
		onSuccess: () => {
			toast({
				title: 'Usuário e Empresa criados com sucesso',
				description:
					'Usuário e empresa cadastrados com sucesso e um e-mail foi enviado para o usuário com as informações de acesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
			cleanFields();
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao cadastrar empresa!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
