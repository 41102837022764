import { useToast } from '@chakra-ui/react';
import { IconDownload } from '@tabler/icons';
import { UseQueryResult } from '@tanstack/react-query';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

type Module =
	| 'colaboracao'
	| 'rateio'
	| 'plano-restrito'
	| 'plano-irrestrito'
	| 'hierarquia-comercial'
	| 'produtos'
	| 'cliente-relacionado';

interface ComponentProps {
	onClick: () => void;
	isDisabled: boolean;
}
interface ButtonExportGenericCSVProps {
	useExport: (cycleId: string, ...arg: any[]) => UseQueryResult<any, any>;
	cycleId: string | number | undefined;
	module: Module;
	errorDescription: string;
	filters?: {
		ciclo: string;
		ano: string;
	};
	isDisabled?: boolean;
	Component?: (props: ComponentProps) => JSX.Element;
	args?: any[];
}
const ButtonComponentGeneric = ({ onClick, isDisabled }: ComponentProps) => {
	return (
		<ButtonComponent
			data-test='button-exportar'
			iconType={<IconDownload />}
			type='icon'
			onClick={onClick}
			disabled={isDisabled}
		/>
	);
};

export default function ButtonExportGenericCSV(
	props: ButtonExportGenericCSVProps,
) {
	const {
		useExport,
		cycleId,
		module,
		errorDescription,
		filters,
		isDisabled = false,
		Component = ButtonComponentGeneric,
		args,
	} = props;

	const { pathCompanyName } = useGlobalContext();

	const [dataCSV, setDataCSV] = useState([]);
	const csvInstance = useRef<any | null>(null);

	const { data: fetchDataCSV, refetch } = useExport(
		String(cycleId || ''),
		...(args || []),
	);
	const toast = useToast();

	const getDataCsv = () => {
		return dataCSV;
	};

	useEffect(() => {
		if (fetchDataCSV) {
			setDataCSV(fetchDataCSV);
		}
	}, [fetchDataCSV]);
	const transformInCamelCase = (data: string) => {
		return data
			.split('-')
			.map((word, i) =>
				i === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1),
			)
			.join('');
	};

	const CSVFileName = (): string => {
		const fileNameDefault = `easy360_${pathCompanyName}`;
		const { ciclo, ano } = filters || { ciclo: '', ano: '' };
		const fileNameSuffix = transformInCamelCase(module);

		return `${fileNameDefault}_${fileNameSuffix}_${ciclo}_${ano}.csv`;
	};

	const notFoundData = () => {
		toast({
			title: 'Atenção! ',
			description: errorDescription,
			status: 'warning',
			position: 'bottom-left',
			duration: 8000,
			isClosable: true,
		});
	};
	return (
		<>
			<Component
				onClick={() => {
					// simular click no botão de download
					if (csvInstance.current && csvInstance.current.link) {
						refetch()
							.then((data) => {
								if (!!data['data']?.length) {
									toast({
										title: 'Sucesso!',
										description:
											'Exportação do arquivo realizada com sucesso!',
										status: 'success',
										duration: 8000,
										isClosable: true,
										position: 'bottom-left',
									});
									setDataCSV(data['data']);
									csvInstance.current.link.click();
								} else {
									notFoundData();
								}
							})
							.catch((error) => {
								if (error.status === 404) {
									notFoundData();
								} else {
									const toastErrors: Array<any> =
										mapErrorToToastProps(
											error,
											'Erro ao exportar!',
										);
									toastErrors?.forEach((element) =>
										toast(element),
									);
								}
							});
					}
				}}
				isDisabled={isDisabled}
			/>
			<CSVLink
				data={getDataCsv()}
				style={{ display: 'none' }}
				ref={csvInstance}
				filename={CSVFileName()}
			>
				<ButtonComponent
					iconType={<IconDownload />}
					type='icon'
				/>
			</CSVLink>
		</>
	);
}
