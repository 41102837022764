import { useQuery } from '@tanstack/react-query';
import { exportRelatedCustomer } from 'services/api/requests/customer';

export const useExportRelatedCustomer = (cicloId: string) => {
	return useQuery(
		['export-clienteRelacionado-list', cicloId],
		exportRelatedCustomer,
		{
			refetchOnWindowFocus: false,
			retry: 1,
			enabled: false,
		},
	);
};
