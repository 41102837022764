import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';
import { SidebarHelp } from 'models/sidebar-help.model';

export const helpRateio: SidebarHelp = {
	title: 'Rateio',
	firstParagraph:
		'O rateio representa a porcentagem em vendas de cada produto em sua respectiva família e canal de venda. O rateio é calculado com base no histórico de vendas. Certifique-se de ter incluído os produtos desejados na participação do rateio, isso pode ser feito em Cadastros > Produto.',
	secondParagraph:
		'Para alterar o valor de rateio de algum produto, filtre o Ciclo e o Canal desejados, no campo do mês e produto desejados entre com o valor em porcentagem. Clique em “Salvar”. Atenção: o total do rateio sempre deverá totalizar 100%, caso contrário os valores de entrada estarão errados e deverão ser ajustados!',
	thirdParagraph: `O botão Recalcular irá calcular o rateio automaticamente com base no histórico de vendas.`,
	firstLink: URL_CT360,
	secondLink: URL_FALE_CONOSCO,
};
