import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ModulesType } from 'components/filter/models/TableFilterModels';
import { recalculateRestrictedPlan } from 'services/api/requests/restrictedPlan';

export const useRecalculateRestrictedPlan = (onSuccess?: () => void) => {
	const queryClient = useQueryClient();
	const toast = useToast();
	return useMutation(recalculateRestrictedPlan, {
		onSuccess: () => {
			toast({
				title: 'Plano restrito recalculado com sucesso!',
				description:
					'O plano restrito foi retornado ao padrão mais recente do plano irrestrito deste ciclo!',

				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
			const module: ModulesType = 'planoRestrito';
			queryClient.invalidateQueries([`${module}-list`]);
			queryClient.invalidateQueries([`restrictedPlan-exists-byId`]);
			onSuccess?.();
		},
		onError: (error: any) => {
			toast({
				title: 'Erro ao recalcular plano restrito',
				description: error.response.data.message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
