import { Flex, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { ColumnData } from 'components/dataTables/Table';
import { TooltipHelper } from 'components/tooltip/TooltipHelper';
import { MessageHover, RowHover } from '../../StatisticTable';
interface CellMessageHoverProps {
	cell: MessageHover;
	row: RowHover;
	column: ColumnData;
}
export const CellMessageHover = ({
	cell,
	row,
	column,
}: CellMessageHoverProps): JSX.Element => {
	const dataTest = row.dataTest + '-' + column.accessor;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	return (
		<Flex alignItems={'center'}>
			<Text
				paddingX='8px'
				w={'100%'}
				h='100%'
				display={'flex'}
				alignItems='center'
				marginRight={0}
				color={textColor}
				fontSize='sm'
				fontWeight='700'
				data-test={dataTest}
			>
				{cell.value}
			</Text>
			{TooltipHelper({
				message: cell.message,
			})}
		</Flex>
	);
};
