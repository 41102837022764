import { useEffect, useRef, useState } from 'react';
import Select, { ActionMeta, OnChangeValue, components } from 'react-select';
import { getStylesConfig } from './stylesConfig';
export interface Option {
	label: string | number;
	value?: string | number;
}
export type Size = 'medium' | 'large';

export type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

export interface Styles {
	container: {
		maxWidth?: string;
	};
	valueContainer: {
		flexWrap?: FlexWrap;
	};
	multiValue: {
		minWidth?: string;
	};
}

interface PropsMultiSelect {
	options: Option[];
	placeholder?: string;
	size: Size;
	value?: Option[];
	defaultValue?: any;
	notHideSelectedOptions?: boolean;
	selectAllTitle?: string;
	isLoading?: boolean;
	styles?: Partial<Styles>;
	triggerReset?: any;
	closeMenuOnScroll?: boolean;
	blurInputOnSelect?: boolean;
	onChange: (
		newValue: OnChangeValue<Option, true>,
		actionMeta: ActionMeta<Option>,
	) => void;
	id?: string;
	'data-test'?: string;
}
export default function MultiSelect({
	options,
	placeholder,
	size,
	value,
	defaultValue,
	notHideSelectedOptions,
	selectAllTitle,
	isLoading,
	styles,
	triggerReset,
	onChange,
	id,
	'data-test': dataTest,
}: PropsMultiSelect) {
	const formatedOptions = options.map(({ label, value }) => ({
		value: value || label,
		label,
	}));

	const valueRef = useRef(value);
	valueRef.current = value;

	const inputRef = useRef(null);

	const selectAllOption = {
		value: 'all',
		label: selectAllTitle,
	};

	const [menuIsOpen, setMenuIsOpen] = useState(false);

	useEffect(() => {
		if (triggerReset) {
			if (inputRef.current != null) {
				const selectInput = inputRef.current;
				// @ts-ignore
				selectInput.clearValue();
			}
		}
	}, [triggerReset]);

	const isSelectAllSelected = () =>
		valueRef?.current?.length !== 0 &&
		valueRef?.current?.length === formatedOptions.length;

	const isOptionSelected = (option) =>
		valueRef?.current?.some(({ value }) => value === option.value) ||
		isSelectAllSelected();

	const getOptions = () =>
		!isLoading
			? !!selectAllTitle && options.length
				? [selectAllOption, ...formatedOptions]
				: formatedOptions
			: [];

	const getValue = () => (isSelectAllSelected() ? [selectAllOption] : value);

	const handleChange = (newValue, actionMeta) => {
		const { action, option, removedValue } = actionMeta;

		if (
			action === 'select-option' &&
			option.value === selectAllOption.value
		) {
			onChange(formatedOptions, actionMeta);
		} else if (
			(action === 'deselect-option' &&
				option.value === selectAllOption.value) ||
			(action === 'remove-value' &&
				removedValue.value === selectAllOption.value)
		) {
			onChange([], actionMeta);
		} else if (
			actionMeta.action === 'deselect-option' &&
			isSelectAllSelected()
		) {
			onChange(
				formatedOptions.filter(({ value }) => value !== option.value),
				actionMeta,
			);
		} else {
			onChange(newValue || [], actionMeta);
		}
	};

	const Option = (props) => {
		const index = props?.data.label;
		const newInnerProps = {
			...props.innerProps,
			'data-test': `option-${dataTest}-${index}`,
		};
		return (
			<components.Option
				{...props}
				innerProps={newInnerProps}
			/>
		);
	};

	return (
		<Select
			components={{
				Option,
				Input: (inputProps) => (
					<components.Input
						{...inputProps}
						data-test={dataTest}
					/>
				),
			}}
			isMulti
			isLoading={isLoading}
			hideSelectedOptions={!notHideSelectedOptions}
			placeholder={placeholder}
			defaultValue={defaultValue}
			closeMenuOnSelect={false}
			closeMenuOnScroll={true}
			blurInputOnSelect={true}
			value={getValue()}
			styles={getStylesConfig(size, styles)}
			options={getOptions()}
			onChange={handleChange}
			isOptionSelected={selectAllTitle ? isOptionSelected : undefined}
			onMenuOpen={() => setMenuIsOpen(true)}
			onMenuClose={() => setMenuIsOpen(false)}
			onBlur={() => setMenuIsOpen(false)}
			menuIsOpen={menuIsOpen}
			ref={inputRef}
			id={id}
		/>
	);
}
