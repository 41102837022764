import moment from 'moment';
import { numberToLocaleString } from './number';
import { numberToFormattedString } from './numberToFormateString';
import '../../pages/admin/rotina/operacoes/dashboardProducao/variables/chartsGrouped.css';

export function renderChartCustomTooltip({
	series,
	seriesIndex,
	dataPointIndex,
	w,
	colors,
	hideUndefinedValues = true,
	decimalPrecision = 0,
	typeLine = '',
}) {
	let render;

	const rowStyleProps = {
		display: 'flex',
		'flex-direction': 'row',
		'justify-content': 'start',
		'align-items': 'center',
		padding: '10px',
	};

	const lineStyleProps = {
		'padding-bottom': '10px',
	};

	const containerStyleProps = {
		display: 'flex',
		'flex-direction': 'column',
	};

	const infoStyleProps = {
		'padding-left': '10px',
	};

	const headerContainerStyleProps = {
		background: '#000000',
	};

	const headerStyleProps = {
		padding: '10px',
	};

	const dataChart = w?.globals?.initialSeries;

	function renderStyleAttribute(key, value) {
		return ` ${key}: ${value};`;
	}

	function renderStyle(style) {
		let result = '';

		for (const [key, value] of Object.entries(style)) {
			result = result.concat(renderStyleAttribute(key, value));
		}

		return result;
	}

	function getDotStyle(color, index) {
		return {
			'border-radius': '50%',
			'background-color': color || colors[index],
			width: '12px',
			height: '12px',
			'margin-right': '5px',
		};
	}

	function defaultRenderLine(line, index) {
		const info = series[index][dataPointIndex];

		if (
			hideUndefinedValues &&
			(info === null || info === undefined || info === '')
		)
			return;

		return `
		<div style="${renderStyle(rowStyleProps)}">
		    <div style="
				${renderStyle(getDotStyle(line?.color, index))}
			"></div>
            <div>
				${line?.name}: 
				<b style="
				${renderStyle(infoStyleProps)}">
				${numberToLocaleString({
					value: info,
					maximumFractionDigits: decimalPrecision,
				})}
			</b>
			</div>
		</div>
		`;
	}

	function renderLineByStock(line, index) {
		const info = series[index][dataPointIndex];

		if (
			hideUndefinedValues &&
			(info === null || info === undefined || info === '')
		)
			return;

		const data = dataChart[index]?.data[dataPointIndex];
		const goal = !!data?.goals?.length ? data?.goals[0] : undefined;

		const dateTime = !!data?.data
			? `
			<div style="${renderStyle(rowStyleProps)}">
				Data: 
				<b style="${renderStyle(infoStyleProps)}">
					${moment(data?.data).format('DD/MM/YYYY')}
				</b>
			</div>
		`
			: '';

		const renderedGoal = goal
			? `
			<div style="${renderStyle(rowStyleProps)}">
				<div style="
					${renderStyle(getDotStyle(goal?.strokeColor, index))}
				"></div>
				<div>
					${goal?.name}: 
					<b style="
						${renderStyle(infoStyleProps)}">
						${numberToLocaleString({
							value: goal?.value,
							maximumFractionDigits: decimalPrecision,
						})}
					</b>
				</div>
			</div>
		`
			: '';

		return `
		<div style="${renderStyle(headerContainerStyleProps)}">
			<div style="${renderStyle(headerStyleProps)}">
				${data?.tooltipHeader || ''}
			</div>
		</div>
		<div style="${renderStyle(rowStyleProps)}">
		    <div style="
				${renderStyle(getDotStyle(line?.color, index))}
			"></div>
            <div>
				${line?.name}: 
				<b style="
					${renderStyle(infoStyleProps)}">
					${numberToLocaleString({
						value: info,
						maximumFractionDigits: decimalPrecision,
					})}
				</b>
			</div>
		</div>
		${dateTime}
		${renderedGoal}
		`;
	}

	switch (typeLine) {
		case 'stock':
			render = renderLineByStock;
			break;
		default:
			render = defaultRenderLine;
			break;
	}

	const div = `
		<div style="${renderStyle(containerStyleProps)}">
            ${
				dataChart
					?.map((d, index) => {
						return `
							<div style="${renderStyle(lineStyleProps)}">
                    			${render?.(d, index)}
                			</div>
						`;
					})
					?.filter((d) => !d?.includes('undefined'))
					?.reduce(
						(element, data) => (data = data.concat(element)),
						'',
					) || []
			}
        </div>
		`;

	return div;
}

interface IConfigMultipleChartData {
	colors: string[];
	xAxisFormatter?: (v: any) => any;
	series: Array<{
		show?: boolean;
		seriesName: string;
		formatter: (v: any) => any;
		showTitle?: boolean;
		[key: string]: any;
	}>;
}

export function generateInitialConfigToMultipleChartData({
	colors,
	xAxisFormatter = (v: any) => v,
	series = [],
}: IConfigMultipleChartData): any {
	function renderYaxis() {
		return series.map((s) => {
			const {
				seriesName,
				formatter = (v: any) => v,
				show = true,
				showTitle = true,
				...others
			} = s;

			return {
				show,
				seriesName,
				labels: {
					formatter,
					style: {
						colors: '#A3AED0',
					},
				},
				...(showTitle
					? {
							title: {
								text: seriesName,
								style: {
									color: '#A3AED0',
								},
							},
					  }
					: undefined),
				tickAmount: 4,
				min: 0,
				max: 100,
				...others,
			};
		});
	}

	return {
		chart: {
			stacked: false,
			toolbar: {
				offsetY: 0,
			},
		},
		colors,
		stroke: {
			curve: 'smooth',
			show: true,
		},
		grid: {
			show: false,
		},
		tooltip: {
			theme: 'dark',
		},
		xaxis: {
			categories: [] as Array<any>,
			labels: {
				formatter: xAxisFormatter,
				style: {
					colors: '#A3AED0',
					fontSize: '12px',
					fontWeight: 500,
				},
			},
		},
		yaxis: [...renderYaxis()],
		legend: {
			show: true,
			ontSize: '12px',
			fontFamily: undefined,
			fontWeight: 400,
			labels: {
				colors: '#A3AED0',
			},
			itemMargin: {
				horizontal: 15,
				vertical: 10,
			},
			offsetY: 10,
		},
	};
}

interface RenderCustomTooltipForGroupedStackedBar {
	series: number | null[];
	seriesIndex: number;
	dataPointIndex: number;
	w: {
		globals: {
			[key: string]: any;
		};
		config: {
			[key: string]: any;
		};
	};
	decimalPrecision: number;
	dates: any;
	suggestedStock: number[] | null[];
	showDates?: boolean;
	showSuggestedStock?: boolean;
}
export const renderCustomTooltipForGroupedStackedBar = ({
	series,
	seriesIndex,
	dataPointIndex,
	w,
	decimalPrecision,
	dates,
	suggestedStock,
	showDates = true,
	showSuggestedStock = true,
}: RenderCustomTooltipForGroupedStackedBar) => {
	const titleLabel = w.globals.labels[dataPointIndex];

	const getSeriesName = (index) => w.config.series[index].name;
	const getColor = (index) => w.config.colors[index];
	const getDate = (seriesName, dataPointIndex) =>
		dates[seriesName][dataPointIndex] || '-';
	const formattedNumber = (value) => {
		if (typeof value !== 'undefined') {
			return numberToFormattedString(value, decimalPrecision);
		}
		return value;
	};

	const seriesName1 = getSeriesName(0);
	const seriesName2 = getSeriesName(1);
	const seriesName3 = getSeriesName(2);

	const color1 = getColor(0);
	const color2 = getColor(1);
	const color3 = getColor(2);

	const date1 = getDate('estoqueAtual', dataPointIndex);
	const date2 = getDate('seguranca', dataPointIndex);
	const date3 = getDate('restrito', dataPointIndex);

	const value1 =
		series[0][dataPointIndex] === null
			? 'Sem estoque cadastrado'
			: formattedNumber(series[0][dataPointIndex]);
	const value2 =
		series[1][dataPointIndex] === null
			? 'Requer 24 meses seguidos de vendas para ser calculado.'
			: formattedNumber(series[1][dataPointIndex]);
	const value3 =
		series[2][dataPointIndex] === null
			? 'Sem plano restrito.'
			: formattedNumber(series[2][dataPointIndex]);

	const suggestedStockTotal =
		suggestedStock[dataPointIndex] === null
			? '-'
			: formattedNumber(suggestedStock[dataPointIndex]);

	const customTooltipContent = `
	<div class="apexcharts-tooltip-title">${titleLabel}</div>
			
				<div class="apexcharts-custom-tooltip">
					<div class="info-group">
						<div class="bullet-container">
							<p>
								<span class="bullet" style="background-color: ${color1};"></span>	
								${seriesName1}: <span class="break-word-span">${value1}</span>
							</p>
							${
								showDates
									? `<p class="date-container">Data Estoque Atual: <span>${date1}</span></p>`
									: ''
							}
						</div>
						<div class="bullet-container">
							<p>
								<span class="bullet" style="background-color: ${color3};"></span>
								${seriesName3}: <span class="break-word-span">${value3}</span>
							</p>
							${
								showDates
									? `<p class="date-container">Data Plano Restrito: <span>${date3}</span></p>`
									: ''
							}
						</div>
						<div class="bullet-container">
							<p>
								<span class="bullet" style="background-color: ${color2};"></span>
								${seriesName2}: <span class="break-word-span">${value2}</span>
							</p>
							${
								showDates
									? `<p class="date-container">Data Segurança: <span>${date2}</span></p>`
									: ''
							}
						</div>
						${
							showSuggestedStock
								? `
						<div class="suggested-stock-total-container">
							<p>
								Estoque Sugerido: <span class="break-word-span">${suggestedStockTotal}</span>
							</p>
						</div>
						`
								: ''
						}
					</div>
				</div>
		
			`;

	return customTooltipContent;
};
