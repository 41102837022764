import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { updateCitySalesZone } from 'services/api/requests/commercial';

export const useUpdateCitySalesZone = (body, id) => {
	const queryClient = useQueryClient();
	const toast = useToast();
	const history = useHistory();

	return useMutation(() => updateCitySalesZone(body, id), {
		onSuccess: () => {
			history.goBack();
			queryClient.invalidateQueries(['city-list']);
			queryClient.invalidateQueries(['citySalesZoneById', id]);
			toast({
				title: 'Atualizado com sucesso!',
				description: 'Cidade atualizada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			toast({
				title: 'Erro ao criar',
				description: error.response.data[0].message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
