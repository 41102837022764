import { CustomCellRendererProps } from 'ag-grid-react';
import { QueryTypeModule } from 'services/queryClient/wrapperHooks/genericTables/useGetCheckTableDataWithFilters';
import { ModulesType } from 'components/filter/models/TableFilterModels';
import { CellStyleFunc, CellStyle, ColDef } from 'ag-grid-community';

export interface OnSortChangeI {
	colId: string;
	sort: 'asc' | 'desc';
}

export type TypeCells = 'number' | 'string' | 'total';
export interface Header<Type> {
	field: string;
	headerName: string;
	sortable?: boolean;
	editable?: boolean;
	width?: number;
	cellRenderer?: (p: CustomCellRendererProps<Type>) => JSX.Element;
	type?: TypeCells;
	cellStyle?: CellStyle | CellStyleFunc<any, any>;
}

export interface CellEdit<Type> {
	oldValue: number;
	id: string;
	value: number;
	data: Type;
	colName: keyof Type;
}

export interface GridExampleProps<Type> {
	module: QueryTypeModule & ModulesType;
	otherParameters?: Object;
	headers: Header<Type>[];
	editable: boolean;
	getIdCellEdit?: (data: Type, col: keyof Type) => string;
	mutate?: (cells: Record<string, CellEdit<Type>>) => void;
	isSubmit?: boolean;
	keyDefinedRow?: Array<keyof Type>;
	model?: JSX.Element;
	disabledEditCell?: (colDef: ColDef, data: Type) => boolean;
	convertToBody?: <BodyType>(cells: Record<string, CellEdit<Type>>) => {
		insert: BodyType[];
		[key: string]: any;
	};
	cellEdit: CellsEdit<Type>;
	setCellEdit: (cellEdit: CellsEdit<Type>) => void;
}
export type CellsEdit<Type> = Record<string, CellEdit<Type>>;
export const totalItemsPageList = [10, 50, 100, 500, 1000];
