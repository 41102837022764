import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editOpportunityConsensusException } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useEditOpportunityConsensusException = (
	dataEditAll: any,
	cycleId: number | string,
	date: string,
	familyId: number | string,
) => {
	const {
		toastSuccessTitle,
		toastSuccessDescription,
		toastErrorTitle,
		invalidate,
		filters,
	} = dataEditAll;
	const queryClient = useQueryClient();

	const toast = useToast();

	const body = {
		ciclo_id: cycleId,
		mes_ano: date,
		familia_id: familyId,
		ids: filters.ids,
		oportunidade: filters.action,
		todos: filters.todos,
	};

	return useMutation(() => editOpportunityConsensusException(body), {
		onSuccess: () => {
			queryClient.invalidateQueries([invalidate]);
			queryClient.invalidateQueries(['oportunidade']);
			toast({
				title: toastSuccessTitle,
				description: toastSuccessDescription,
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				toastErrorTitle,
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
