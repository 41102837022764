import {
	Box,
	ButtonGroup,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { IconRefreshAlert } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useGetRecalculateIROG } from 'services/queryClient/wrapperHooks/useGetRecalculateIROG';

export const RecalculateIROG = (props) => {
	const { isOpen, onClose } = props;

	const { refetch } = useGetRecalculateIROG();

	const handleRecalculate = () => {
		refetch();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'2xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent borderRadius={'3xl'}>
				<ModalHeader>
					<Icon
						as={IconRefreshAlert}
						color={'easyRED.300'}
						boxSize={14}
					/>
					<Text
						fontWeight={700}
						color={'easyBLUE.300'}
						fontSize={'2xl'}
					>
						Recalcular IROG
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box mb={4}>
						<Text
							color={'secondaryGray.500'}
							fontWeight={400}
							fontSize={'md'}
							pb={4}
						>
							Deseja realmente recalcular o IROG, bem como a
							disponibilidade, performance e qualidade de todos os
							dados já cadastrados no lançamento diário? Caso
							deseje prosseguir, clique no botão Confirmar.
						</Text>
					</Box>
				</ModalBody>

				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							type={'ghost'}
							title={'Cancelar'}
							onClick={onClose}
						/>
						<ButtonComponent
							type={'secondary'}
							title={'Confirmar'}
							onClick={handleRecalculate}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
