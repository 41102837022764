import {
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { IconFilter } from '@tabler/icons';
import { FilterBody } from 'components/filter/models/TableFilterModels';
import { sxMenuFilter } from './constants/StylesMenuFilter';

interface MenuFilterProps {
	dataTest?: string;
	filtersMap: FilterBody[];
	selectedItems: string[];
	handleFilterToggle: (itemId: string) => void;
}

export const MenuFilter = ({
	dataTest,
	filtersMap,
	handleFilterToggle,
	selectedItems,
}: MenuFilterProps) => {
	// Filtra as opções de filtro com base nos itens selecionados
	const filterOptions: JSX.Element[] = filtersMap
		.filter(
			(column: FilterBody) => !selectedItems.includes(column.nome_coluna),
		)
		.map((column: FilterBody, index: number) => (
			<MenuItem
				data-test={column.nome_coluna_dicionario}
				key={`${column.nome_coluna}_${index}`}
				onClick={() => handleFilterToggle(column.nome_coluna)}
				_hover={{ bg: 'easyBrandMenu.200' }}
				_focus={{ bg: 'easyBrandMenu.200' }}
			>
				{column.nome_coluna_dicionario}
			</MenuItem>
		));

	return (
		<Menu>
			<MenuButton
				data-test={dataTest}
				as={IconButton}
				aria-label='Opções de filtro'
				icon={<IconFilter />}
				color={'easyBLUE.300'}
				border={'1px solid'}
				borderColor='easyBrandMenu.200'
				variant='ghost'
			/>
			<MenuList
				data-test={'menu_list-options-filter'}
				color={'easyBLUE.300'}
				boxShadow={'14px 17px 40px 4px rgba(112, 144, 176, 0.08)'}
				maxHeight={'xs'}
				overflowY='auto'
				sx={sxMenuFilter}
			>
				{filterOptions.length > 0 ? (
					filterOptions
				) : (
					<Text
						p={2}
						color={'easyBLUE.300'}
					>
						Não existem filtros disponíveis para seleção.
					</Text>
				)}
			</MenuList>
		</Menu>
	);
};
