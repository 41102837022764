import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { editProduct } from 'services/api/requests/product';

export const useEditProduct = (body, id: number, module?: string) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => editProduct(body, id), {
		onSuccess: () => {
			queryClient.invalidateQueries(['produto-list']);

			queryClient.removeQueries({
				predicate: (query: any) =>
					query.queryKey[0].startsWith('apportionment-family'),
			});

			if (!module) {
				history.goBack();
			}

			toast({
				title: 'Atualizado com sucesso!',
				description: 'Produto atualizado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			error?.response?.data?.forEach((element: any) => {
				if (element.path !== 'empresa_id') {
					toast({
						title: 'Erro ao atualizar produto!',
						description: element.message,
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				}
			});
		},
	});
};
