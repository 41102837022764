import { CustomCellRendererProps } from 'ag-grid-react';
import { AgGridTable } from 'components/dataTables/agGridTable/AgGridTable';
import TagTypeException from 'components/modal/unrestrictedPlanModal/TagTypeException';
import { Cycle } from 'models/cycle.model';
import { months } from 'moment';
import { monthAbbrToIndex } from 'utils/consts/months';
import { useState } from 'react';
import { BodyRestrictedPlan } from 'services/api/requests/restrictedPlan';
import { useUpdateRestrictedPlan } from 'services/queryClient/wrapperHooks/capacidade/restrictedPlan/useUpdateRestrictedPlan';
import EditOpportunityList from './EditOpportunityListModal';
import { ColDef } from 'ag-grid-community';
import {
	CellEdit,
	CellsEdit,
	Header,
} from 'components/dataTables/agGridTable/agGrid.type';
import { Flex } from '@chakra-ui/react';

type RestrictedPlan = {
	nome_canal_venda: string;
	canal_venda_id: number;
	codigo_produto: string;
	nome_produto: string;
	oportunidade_id: number;
	excecao: boolean;
	excecao_type: string;
	excecao_consenso_id: number;
	produto_id: number;
	nome_familia: string;
	ciclo_id: number;
	jan: number;
	fev: number;
	mar: number;
	abr: number;
	mai: number;
	jun: number;
	jul: number;
	ago: number;
	set: number;
	out: number;
	nov: number;
	dez: number;
	total: number;
};

const generateHeaderRestrictedPlan = (cycle: Cycle) => {
	const cols: Header<RestrictedPlan>[] = [
		{
			field: 'nome_familia',
			headerName: 'FAMÍLIA',
			sortable: true,
			type: 'string',
			width: 150,
			editable: false,
		},
		{
			field: 'nome_canal_venda',
			headerName: 'CANAL DE VENDA',
			sortable: true,
			type: 'string',
			width: 150,
			editable: false,
		},
		{
			field: 'nome_produto',
			headerName: 'NOME DO PRODUTO',
			type: 'string',
			sortable: true,
			width: 150,
			editable: false,
		},
		{
			field: 'codigo_produto',
			headerName: 'CÓDIGO DO PRODUTO',
			sortable: true,
			width: 150,
			editable: false,
		},
		{
			field: 'excecao_type',
			headerName: 'EXCEÇÃO',
			width: 150,
			editable: false,
			sortable: true,
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingY: 0,
				overflow: 'hidden',
			},
			cellRenderer: ({
				data,
			}: CustomCellRendererProps<RestrictedPlan>) => {
				let tag =
					data?.oportunidade_id || data?.excecao
						? data.oportunidade_id
							? 'opportunity_exception'
							: 'common_exception'
						: '';
				return (
					<Flex alignItems={'center'}>
						<TagTypeException tag={tag} />
					</Flex>
				);
			},
		},
	];
	const monthsCols: Header<RestrictedPlan>[] = [];
	for (let i = 0; i < cycle.duracao; i++) {
		const monthIndex = (cycle.ciclo + i) % 12;
		const currentMonth = months(monthIndex);
		const field = currentMonth.substring(0, 3).toLowerCase();
		const headerName =
			field.toUpperCase() +
			'/' +
			(monthIndex <= cycle.ciclo - 1 ? cycle.ano + 1 : cycle.ano);
		monthsCols.push({
			field: field as any,
			headerName,
			sortable: true,
			editable: true,
			width: 100,
			type: 'number',
		});
	}

	cols.push(...monthsCols, {
		field: 'total',
		headerName: 'TOTAL',
		sortable: true,
		type: 'total',
		width: 100,
		editable: false,
	});
	return {
		cols,
		firstMonth: monthsCols[0],
	};
};
const getMonthMMyyyy = (shortMonth: string, cycle: Cycle) => {
	const index = monthAbbrToIndex[shortMonth.toLowerCase()] + 1;
	const month = index === -1 ? index : index < 10 ? `0${index}` : `${index}`;
	const year = cycle.ano + (index < cycle.ciclo - 1 ? 1 : 0);
	return month + '/' + year;
};
interface IGridRestrictedPlanProps {
	cycle: Cycle;
	setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
	isSubmit: boolean;
}

export const GridRestrictedPlan = ({
	cycle,
	isSubmit,
	setIsSubmit,
}: IGridRestrictedPlanProps) => {
	const getIdCellEdit = (
		{
			canal_venda_id,
			produto_id,
			oportunidade_id,
			excecao_consenso_id,
		}: RestrictedPlan,
		field: string,
	) =>
		[
			'canal_venda_id',
			canal_venda_id,
			'produto_id',
			produto_id,
			'oportunidade_id',
			oportunidade_id || '',
			'excecao_consenso_id',
			excecao_consenso_id || '',
			field,
		].join('-');

	const [opportunity, setOpportunity] = useState<{
		insert: BodyRestrictedPlan[];
		opportunityIds: number[];
	}>();

	const [cellEdit, setCellEdit] = useState<CellsEdit<RestrictedPlan>>({});

	const onSuccess = () => {
		setCellEdit({});
	};
	const onFinallySubmit = () => {
		setIsSubmit(false);
		setOpportunity(undefined);
	};
	const { mutate } = useUpdateRestrictedPlan(onFinallySubmit, onSuccess);
	const convertToBodyRestrictedPlan = (
		cells: Record<string, CellEdit<RestrictedPlan>>,
	) => {
		const opportunityIds: number[] = [];
		const insert: BodyRestrictedPlan[] = Object.values(cells).map(
			(cell) => {
				if (cell.data.oportunidade_id) {
					opportunityIds.push(cell.data.oportunidade_id);
				}
				return {
					canal_venda_id: cell.data.canal_venda_id,
					ciclo_id: cell.data.ciclo_id,
					produto_id: cell.data.produto_id,
					quantidade_produtos: cell.value,
					excecao_consenso_id: cell.data.excecao_consenso_id,
					oportunidade_id: cell.data.oportunidade_id,
					excecao: cell.data.excecao,
					mes_ano: getMonthMMyyyy(cell.colName, cycle),
				};
			},
		);
		return {
			insert,
			opportunityIds,
		};
	};

	const submit = (cells: Record<string, CellEdit<RestrictedPlan>>) => {
		const { insert, opportunityIds } = convertToBodyRestrictedPlan(cells);
		if (opportunityIds.length) {
			setOpportunity({
				insert,
				opportunityIds,
			});
		} else {
			mutate({
				ciclo_id: cycle.id,
				plano_restrito: insert,
			});
		}
	};
	const { cols, firstMonth } = generateHeaderRestrictedPlan(cycle);
	const disabledEditCell = (colDef: ColDef, data: RestrictedPlan) => {
		const isOpportunity = data.oportunidade_id;
		const isFirstMonth = colDef.field === firstMonth.field;
		if (data.codigo_produto === '-') {
			return false;
		}
		return isOpportunity ? isFirstMonth : true;
	};
	return (
		<AgGridTable<RestrictedPlan, BodyRestrictedPlan>
			disabledEditCell={disabledEditCell}
			module='planoRestrito'
			editable={true}
			getIdCellEdit={getIdCellEdit}
			otherParameters={{
				ciclo_id: cycle.id,
			}}
			headers={cols}
			isSubmit={isSubmit}
			mutate={submit}
			model={
				opportunity && (
					<EditOpportunityList
						opportunityIds={opportunity?.opportunityIds}
						cleanOpportunity={() => {
							setOpportunity(undefined);
							setIsSubmit(false);
						}}
						cycleId={cycle.id}
						mutate={mutate}
						bodyRestrictedPlan={opportunity.insert}
					/>
				)
			}
			cellEdit={cellEdit}
			setCellEdit={setCellEdit}
			keyDefinedRow={[
				'canal_venda_id',
				'produto_id',
				'excecao_consenso_id',
				'ciclo_id',
				'oportunidade_id',
				'excecao',
			]}
			convertToBody={convertToBodyRestrictedPlan as any}
		/>
	);
};
