import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateRestrictedPlanByCycle } from 'services/api/requests/restrictedPlan';

export const useUpdateRestrictedPlan = (
	onFinally: VoidFunction,
	onSuccess: VoidFunction,
) => {
	const toast = useToast();
	const queryClient = useQueryClient();

	return useMutation(updateRestrictedPlanByCycle, {
		onSuccess: (_, bodyRequest) => {
			if (bodyRequest.plano_restrito?.length > 0) {
				onFinally();
				onSuccess();
				queryClient.invalidateQueries([`planoRestrito-list`]);
				queryClient.invalidateQueries([`planoRestrito-total`]);
				toast({
					title: 'Atualizado com sucesso!',
					description: 'Plano restrito atualizado com sucesso!',
					position: 'bottom-left',
					status: 'success',
					duration: 8000,
					isClosable: true,
				});
			} else {
				toast({
					title: 'Atenção',
					description: 'Não há alterações no plano restrito!',
					position: 'bottom-left',
					status: 'warning',
				});
				onFinally();
			}
		},
		onError: (error: any) => {
			toast({
				title: 'Erro ao recalcular',
				description: error.response.data.message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
			onFinally();
		},
	});
};
