import { createContext, useContext, useState } from 'react';
import { QuantityValues } from '../templateCadastrarColabCons/hooks/useSelectByStatistic';

const ContextColaboracao = createContext<IContext>({} as IContext);
interface IContext {
	colaboracoes: QuantityValues;
	setColaboracoes: React.Dispatch<React.SetStateAction<QuantityValues>>;
}

export default function ProviderColaboracao({ children }) {
	const [colaboracoes, setColaboracoes] = useState<QuantityValues>({});

	return (
		<ContextColaboracao.Provider
			value={{
				colaboracoes,
				setColaboracoes,
			}}
		>
			{children}
		</ContextColaboracao.Provider>
	);
}

export const useContextColaboracao = () => {
	return useContext(ContextColaboracao);
};
