import { Flex } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo, useState } from 'react';
import FormCadastrarEditarEstoqueAtual from 'components/forms/capacity/stock/FormCadastrarEditarEstoqueAtual';
import { useGetActualStockWithId } from 'services/queryClient/wrapperHooks/useGetActualStockById';
import { useParams } from 'react-router-dom';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function EditarEstoqueAtual() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const { id: actualStockId } = useParams<any>();

	const { data: ActualStockData } = useGetActualStockWithId(actualStockId);

	const [initialState, setInitialState] = useState<any>();
	const [autocompleteAdditionalData, setAutocompleteAditionalData] =
		useState<any>();

	const helpEditarEstoque: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Estoque Atual',
			firstParagraph: `O estoque atual aponta a quantidade de produtos em estoque para cada família em uma data específica. É importante atualizar o estoque atual diariamente para mantê-lo sempre atualizado.`,
			secondParagraph: `No campo Estoque é possível editar a quantidade de estoque para o produto selecionado. Você também pode editar a data do estoque, no campo Data, bem como o produto no campo Produto. Clique em Atualizar.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarEstoque);
	}, [helpEditarEstoque, setDataSidebar]);

	// TO-DO: ajustar as propriedades
	useEffect(() => {
		if (ActualStockData) {
			setAutocompleteAditionalData({
				product: ActualStockData?.produto,
				family: ActualStockData?.produto?.familia,
			});

			// TO-DO: ajustar as propriedades
			setInitialState({
				data: ActualStockData?.data,
				familia_id: ActualStockData?.produto?.familia?.id,
				produto_id: ActualStockData?.produto?.id,
				quantidade: ActualStockData?.quantidade,
			});
		}
	}, [ActualStockData]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<FormCadastrarEditarEstoqueAtual
				initialState={initialState}
				actualStockId={actualStockId}
				autocompleteAdditionalData={autocompleteAdditionalData}
			/>
		</Flex>
	);
}
