import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { createBudget } from 'services/api/requests/requests';

export const useCreateBudget = (body) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();
	return useMutation(() => createBudget(body), {
		onSuccess: (success) => {
			queryClient.invalidateQueries(['orcamento-list']);
			history.goBack();
			toast({
				title: 'Criado com sucesso!',
				description: 'Orçamento criado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			// console.log("ERRO", error.response.data[0].message)
			toast({
				title: 'Erro ao criar orçamento!',
				description: error.response.data[0].message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
