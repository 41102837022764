/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

/*
 * TODO: Separar os arquivos de tipo: https://easy360-tech.atlassian.net/browse/EASY-4456
 **/

import {
	Flex,
	Grid,
	Heading,
	Icon,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { TablerIcon } from '@tabler/icons';
import { ScrollBar } from 'components/scroolbar/Scrollbar';
import { TooltipHelper } from 'components/tooltip/TooltipHelper';
import { CellInput } from './CellInput';
import { CellInputHover } from './cell/hover/CellInputHover';
import { CellText } from './CellText';
import { backgroundColors } from './styles';

interface ComponetsRowsTextProps {
	column: ColumnData;
	row: Row | RowNew;
	cell: any;
}

export type ColumnDataJSX = (
	props: ComponetsRowsTextProps,
	component: JSX.Element,
) => JSX.Element;

export type ColumnDataInputType = 'exception' | 'exceptionEdit' | 'default';
export type ColumnData = {
	Header: string | JSX.Element;
	accessor: string;
	type: string;
	inputType?: ColumnDataInputType;
	JSX?: ColumnDataJSX;
	disableSortBy?: boolean;
	startMonth?: number;
	qtdMounth?: number;
	abbreviatedMonth?: boolean;
};

export type Row = {
	type: 'text';
	dataTest?: string;
	media: number | string;
	total: number | string;
	[key: string]: string | number | undefined | any;
};

export type ValueHover = ClientHover | MessageHover;
export type RowNew = {
	type: 'text-hover';
	dataTest?: string;
	[key: string]: string | number | ValueHover | undefined;
};

export type RowInput = {
	type: 'input';
	dataTest?: string;
	media: number | string;
	total: number | string;
	errorConditional: (value: number | '') => boolean;
	preenchimento: {
		options: Array<{
			value: string;
			label: string;
		}>;
		onChange: (key: string, value: string) => void;
		onChangeSelect: (value: any) => Promise<void>;
		value: string;
		isLoading: boolean;
	};
	[key: string]: any;
};
export interface BaseHover {
	value: number | string;
	typeHover: 'client' | 'message';
	message?: string;
}

export interface ClientHover extends BaseHover {
	typeHover: 'client';
	clientes: any;
	hasValue?: boolean;
	isLoading?: boolean;
	onHoverCallback?: any;
	month: string;
}

export interface MessageHover extends BaseHover {
	typeHover: 'message';
	message: string;
}

export type RowHover = {
	type: 'text-hover';
	media: string | number;
	total: string | number;
	dataTest?: string;
	[key: string]: string | number | ValueHover | undefined;
};
interface PropsStatisticTable {
	columnsData: {
		header: ColumnData[];
		body: ColumnData[];
		footer: ColumnData[];
	};
	tableData: Array<Row | RowInput | RowHover | RowNew>;
	title: {
		name?: string;
		icon?: TablerIcon;
		red?: boolean;
		marginLeft?: '0px' | '22px';
	};
	SectionButtons?: JSX.Element;
	conditionalFilter?: (props: ComponetsRowsTextProps) => boolean;
	onClickTable?: () => void;
	size?: number | string;
	applyLocaleString?: boolean;
	useInputMoney?: boolean;
	dataTest?: string;
	headerHelp?: any[];
	showColumnBorders?: boolean;
}
const StatisticTable = (props: PropsStatisticTable) => {
	const {
		columnsData,
		tableData,
		title,
		SectionButtons,
		conditionalFilter,
		onClickTable,
		size = 'auto',
		applyLocaleString = false,
		useInputMoney = false,
		dataTest,
		headerHelp = [],
		showColumnBorders = false,
	} = props;

	const rows = (column: ColumnData, type: any) => {
		return (
			<Grid
				bgColor={backgroundColors[column.accessor]}
				templateColumns={'auto'}
				alignContent={'center'}
				width={'100%'}
				flexGrow={1}
			>
				{tableData.map((row: any, index) => {
					const cell = row[column.accessor];
					if (
						index === tableData.length - 1 &&
						(tableData[index]?.canalVenda === 'Total' ||
							tableData[index]?.nomeFamilia === 'Total')
					) {
						if (type === 'footer') {
							return (
								<Flex
									h={showColumnBorders ? '64px' : '60px'}
									paddingY={showColumnBorders ? '4px' : '0px'}
									backgroundColor={'#E0E5F2'}
									display={
										!conditionalFilter ||
										conditionalFilter({ row, column, cell })
											? undefined
											: 'none'
									}
									width={'100%'}
									key={index}
									borderBottom={
										showColumnBorders
											? `1px solid #E2E8F0`
											: undefined
									}
								>
									<Text
										color='secondaryGray.900'
										fontSize='sm'
										fontWeight='700'
										lineHeight='100%'
										w='100%'
									>
										{componetsRows[row.type]({
											cell,
											column,
											row,
										})}
									</Text>
								</Flex>
							);
						}
						return (
							<Flex
								h={showColumnBorders ? '64px' : '60px'}
								paddingY={showColumnBorders ? '4px' : '0px'}
								display={
									!conditionalFilter ||
									conditionalFilter({ row, column, cell })
										? undefined
										: 'none'
								}
								backgroundColor={'#F4F7FE'}
								width={'100%'}
								key={index}
								borderBottom={
									showColumnBorders
										? `1px solid #E2E8F0`
										: undefined
								}
							>
								<Text
									color='secondaryGray.900'
									fontSize='sm'
									fontWeight='700'
									lineHeight='100%'
									textAlign='center'
									w='100%'
								>
									a
									{componetsRows[row.type]({
										cell,
										column,
										row,
									})}
								</Text>
							</Flex>
						);
					}
					return (
						<Flex
							h={showColumnBorders ? '64px' : '60px'}
							paddingY={showColumnBorders ? '4px' : '0px'}
							w={size}
							key={index}
							display={
								!conditionalFilter ||
								conditionalFilter({ row, column, cell })
									? undefined
									: 'none'
							}
							data-test={`statistic_table-${column.accessor}`}
							borderBottom={
								showColumnBorders
									? `1px solid #E2E8F0`
									: undefined
							}
						>
							{column.JSX
								? column.JSX(
										{ cell, column, row },
										componetsRows[row.type]({
											cell,
											column,
											row,
										}),
								  )
								: componetsRows[row.type]({
										cell,
										column,
										row,
										applyLocaleString,
										useInputMoney,
										headerHelp,
								  })}
						</Flex>
					);
				})}
			</Grid>
		);
	};

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const componetsRows = {
		text: CellText,
		input: CellInput,
		'text-hover': CellInputHover,
	};

	function getHelper(column) {
		if (!headerHelp?.length) return null;

		const help = headerHelp?.find(
			(help) => help.accessor === column.accessor,
		);

		if (!help) return null;

		return TooltipHelper(help?.tooltip || 'Não informado!');
	}

	return (
		<Flex
			direction='column'
			w='100%'
			px='0px'
			className='outer'
		>
			<Flex
				align={{ sm: 'flex-start', lg: 'center' }}
				w='100%'
				gap={'16px'}
				flexWrap={'wrap'}
				mt='20px'
				px={title?.marginLeft || '22px'}
			>
				{title?.icon && (
					<Icon
						color='secondaryGray.500'
						as={title?.icon}
						w='20px'
						h='20px'
					/>
				)}
				<Heading
					color={title?.red ? 'red' : textColor}
					fontSize='xl'
					fontWeight='700'
					lineHeight='100%'
				>
					{title?.name}
				</Heading>
				{SectionButtons}
			</Flex>
			<Grid
				w='100$'
				templateColumns={'auto 1fr auto'}
				color='gray.500'
				onClick={onClickTable}
				mb='5px'
				data-test={dataTest}
			>
				<Flex>
					{columnsData.header.map((column, index) => {
						return (
							<Flex key={index}>
								<Flex
									flexDirection={'column'}
									alignItems='start'
									justifyContent={'start'}
									key={index}
									borderColor={borderColor}
									padding={0}
									bgColor={backgroundColors[column.accessor]}
								>
									<Flex
										alignItems={'center'}
										paddingX={'8px'}
										h='40px'
										justify='space-between'
										padding='0'
										width={'100%'}
										fontSize={{
											sm: '10px',
											lg: '12px',
										}}
										color='gray.400'
										marginRight={'20px'}
										borderBottom={
											showColumnBorders
												? `1px solid #E2E8F0`
												: undefined
										}
									>
										{column.Header}
									</Flex>
									{rows(column, 'header')}
								</Flex>
							</Flex>
						);
					})}
				</Flex>
				<ScrollBar color='secondary'>
					<Flex
						position={'relative'}
						justifyContent={'space-around'}
						flexGrow={1}
					>
						{columnsData.body.map((column, index) => {
							return (
								<Flex
									flexGrow={1}
									key={index}
								>
									<Flex
										flexGrow={1}
										flexDirection={'column'}
										alignItems='start'
										justifyContent={'start'}
										key={index}
										borderColor={borderColor}
										padding={0}
										bgColor={
											backgroundColors[column.accessor]
										}
										data-test={`table-column-${column.Header}`}
									>
										<Flex
											paddingX={'8px'}
											alignItems={'center'}
											justify='space-between'
											padding='0'
											h='40px'
											width={'100%'}
											fontSize={{
												sm: '10px',
												lg: '12px',
											}}
											color='gray.400'
											borderBottom={
												showColumnBorders
													? `1px solid #E2E8F0`
													: undefined
											}
										>
											{column.Header}
										</Flex>
										{rows(column, 'body')}
									</Flex>
								</Flex>
							);
						})}
					</Flex>
				</ScrollBar>
				<Flex>
					{columnsData.footer.map((column, index) => {
						return (
							<Flex key={index}>
								<Flex
									flexDirection={'column'}
									alignItems='start'
									justifyContent={'start'}
									key={index}
									borderColor={borderColor}
									padding={0}
									bgColor={backgroundColors[column.accessor]}
								>
									<Flex
										h='40px'
										justify='space-between'
										padding='0'
										alignItems={'center'}
										width={'100%'}
										fontSize={{
											sm: '10px',
											lg: '12px',
										}}
										paddingX={'8px'}
										color='gray.400'
										borderBottom={
											showColumnBorders
												? `1px solid #E2E8F0`
												: undefined
										}
									>
										<Flex
											width='100%'
											gap={1}
											direction='row'
										>
											{column.Header}
											{getHelper(column)}
										</Flex>
									</Flex>
									{rows(column, 'footer')}
								</Flex>
							</Flex>
						);
					})}
				</Flex>
			</Grid>
		</Flex>
	);
};

export default StatisticTable;
