import {
	Box,
	ButtonGroup,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { IconRefreshAlert } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import {
	RateioTypeCalculation,
	useCalculateApportionment,
} from 'services/queryClient/wrapperHooks/apportionment/useCalculateApportionment';

interface RecalculateApportionmentModalProps {
	cycleData: any[];
	currentCycleId: number;
	isOpen: boolean;
	onClose: () => void;
	onClosePrevious?: () => void;
	onSuccessCallback?: any;
}

export const RecalculateApportionmentPreviousCycleModal = (
	props: RecalculateApportionmentModalProps,
) => {
	const {
		currentCycleId,
		isOpen,
		onClose,
		onClosePrevious = () => {},
		onSuccessCallback = () => {},
	} = props;

	const { refetch, isFetching } = useCalculateApportionment(
		currentCycleId,
		'',
		RateioTypeCalculation.RATEIO_ANTERIOR,
	);

	const handleRecalculate = async (): Promise<void> => {
		await refetch();
		onSuccessCallback();
		onClose();
		onClosePrevious();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'2xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent borderRadius={'3xl'}>
				<ModalHeader>
					<Icon
						as={IconRefreshAlert}
						color={'easyRED.300'}
						boxSize={14}
					/>
					<Text
						fontWeight={700}
						color={'easyBLUE.300'}
						fontSize={'2xl'}
					>
						Atualizar Rateio conforme Ciclo Anterior
					</Text>
					<br />
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box mb={6}>
						<Text
							color={'secondaryGray.500'}
							fontWeight={400}
							fontSize={'md'}
						>
							Deseja realmente atualizar o rateio deste ciclo
							seguindo o ciclo anterior? Note que ao atualizar
							você perderá as edições de rateio realizadas neste
							ciclo.
							{/* Caso deseja prosseguir, selecione o ano e
							ciclo que deseja seguir no rateio: */}
						</Text>
						<br />
						<br />
					</Box>
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							type='ghost'
							title='Cancelar'
							onClick={onClose}
						/>
						<ButtonComponent
							type={'secondary'}
							isLoading={isFetching}
							loadingText='Recalculando'
							title='Confirmar'
							onClick={isFetching ? undefined : handleRecalculate}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
