import { FilterBody } from 'components/filter/models/TableFilterModels';
import alb from 'services/api/alb';
import api from 'services/api/api';
import {
	QueryTypeModule,
	RequestCheckTableBody,
} from 'services/queryClient/wrapperHooks/genericTables/useGetCheckTableDataWithFilters';
interface FetchDataByFiltersOutput<T> {
	dados: Array<T>;
	filterMap: FilterBody[];
	dependencias: string[];
	total_linhas: number;
}
export const fetchDataByFilters = async <T>(
	module: QueryTypeModule,
	body: RequestCheckTableBody,
): Promise<FetchDataByFiltersOutput<T>> => {
	const url = `/${module}/view`;
	const response = await api.post(url, body);
	return response.data;
};

export const fetchDataByFiltersAiPrediction = async ({ page, page_size }) => {
	const response = await alb.get('/core/ai-prediction/view', {
		params: {
			page,
			page_size,
		},
	});

	return {
		dados: response?.data?.data,
		dependencias: [],
		filterMap: [],
		total_linhas: response?.data.count_lines,
	};
};
