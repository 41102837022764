import { Flex, Text } from '@chakra-ui/react';
import {
	CellValueChangedEvent,
	ColDef,
	RowNumbersOptions,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { SelectedFilterBody } from 'components/filter/models/TableFilterModels';
import { TableFilter } from 'components/filter/TableFilter';
import { StrictMode, useCallback, useEffect, useState } from 'react';
import { useGetDataGridTableWithFilters } from 'services/queryClient/wrapperHooks/genericTables/useGetDataGridTableWithFilters';
import ManualPaging, { IManualPaging } from '../ManualPaging';
import {
	CellsEdit,
	GridExampleProps,
	OnSortChangeI,
	totalItemsPageList,
} from './agGrid.type';
import { generateHeader } from './generateHeader';
import {
	ClientSideRowModelModule,
	CustomFilterModule,
	ModuleRegistry,
	ValidationModule,
} from 'ag-grid-community';
import { useGlobalContext } from 'contexts/GlobalContext';
import { globalStyles } from 'theme/styles';
import { LoadingSpinner } from 'components/loading/Loading';
ModuleRegistry.registerModules([
	CustomFilterModule,
	ClientSideRowModelModule,
	ValidationModule,
]);

export const AgGridTable = <Type, BodyType>({
	module,
	editable = false,
	getIdCellEdit = () => '',
	otherParameters = {},
	headers,
	isSubmit = false,
	mutate,
	keyDefinedRow = [],
	model,
	disabledEditCell,
	convertToBody,
	cellEdit,
	setCellEdit,
}: GridExampleProps<Type>) => {
	const [cellEditCurrent, setCellEditCurrent] = useState<CellsEdit<Type>>({});
	const [cols, setCols] = useState<ColDef[]>([]);
	const [rowData, setRowData] = useState<Type[]>([]);
	const [rowTotal, setRowTotal] = useState<Type & { isHighlighted: true }>();
	const [order, setOrder] = useState<Array<[string, 'desc' | 'asc']>>([]);
	const [filters, setFilters] = useState<SelectedFilterBody[] | []>([]);
	const {
		configsCompany: { precisao_decimal },
	} = useGlobalContext();
	const onSuccess = () => {
		setCellEditCurrent({});
	};
	useEffect(() => {
		if (headers && cols.length === 0) {
			setCols(
				generateHeader<Type>(
					headers,
					onSortChange,
					precisao_decimal,
					disabledEditCell,
				),
			);
		}
	}, [headers]);
	const [pagination, setPagination] = useState<IManualPaging>({
		page: 0,
		size: 100,
		totalPages: 0,
	});
	const responseConvertToBody = convertToBody?.<BodyType>(cellEdit);
	const edited = responseConvertToBody?.insert || [];
	const { data, isLoading } = useGetDataGridTableWithFilters<Type, BodyType>(
		{
			module,
			filters,
			page: pagination.page,
			size: pagination.size,
			order,
			edited,
			...otherParameters,
		},
		onSuccess,
	);

	const onSortChange = ({ colId, sort }: OnSortChangeI) => {
		const newOrder = order.filter(([key]) => key !== colId);
		if (sort) {
			newOrder.unshift([colId.toString(), sort]);
		}
		setOrder(newOrder);
	};
	const transformRows = (dados: Type[]): Type[] => {
		return dados;
	};
	useEffect(() => {
		if (data?.dados) {
			setRowData(transformRows(data.dados));
			const totalPages = Math.ceil(
				+data?.total_linhas / +pagination.size,
			);
			setPagination((prev) => ({
				...prev,
				page:
					data.dados.length === 0 && prev.page !== 0
						? totalPages - 1
						: prev.page,
				totalPages,
			}));
		}
	}, [data]);
	const updateTotalEditable = useCallback(
		(isEditing: boolean) => {
			const dataTotal = data?.total;
			if (editable && dataTotal && cols.length && cellEditCurrent) {
				const keyInitial = cols[0].field;
				const newRowTotal: Type = {
					[keyInitial || '']: 'TOTAL',
					jan: 0,
					fev: 0,
					mar: 0,
					abr: 0,
					mai: 0,
					jun: 0,
					jul: 0,
					ago: 0,
					set: 0,
					out: 0,
					nov: 0,
					dez: 0,
					total: 0,
					...(dataTotal as Type),
					isHighlighted: true,
				};
				cols.forEach((col) => {
					if (col.field && newRowTotal[col.field] === undefined) {
						newRowTotal[col.field] = '-';
					}
				});
				if (isEditing && Object.keys(cellEditCurrent).length > 0) {
					Object.values(cellEditCurrent).forEach((cell) => {
						const key = cell.colName;
						newRowTotal[key] += (+cell.value -
							cell.oldValue) as any;
						newRowTotal['total'] += (+cell.value -
							cell.oldValue) as any;
					});
				}
				setRowTotal(newRowTotal as any);
			}
		},
		[cellEditCurrent, cols, editable],
	);

	useEffect(() => {
		updateTotalEditable(true);
	}, [cols, cellEditCurrent, updateTotalEditable]);
	const onChangeValue = (a: CellValueChangedEvent<Type, any>) => {
		const { colDef, value, data, oldValue } = a;
		if (editable && data[keyDefinedRow[0]]) {
			const colName = colDef.field as unknown as keyof Type;
			const id = getIdCellEdit(data, colName);
			const oldValueSaved = cellEditCurrent[id]?.oldValue;
			const newValueSaved = {
				id,
				value,
				data,
				colName,
				oldValue:
					oldValueSaved !== undefined ? oldValueSaved : oldValue,
			};

			setCellEdit({
				...cellEdit,
				[id]: newValueSaved,
			});
			setCellEditCurrent({
				...cellEditCurrent,
				[id]: newValueSaved,
			});
		} else {
			updateTotalEditable(false);
		}
	};

	useEffect(() => {
		if (isSubmit && mutate) {
			mutate(cellEdit);
		}
	}, [isSubmit]);
	const rowNumbersOptions: RowNumbersOptions = {};
	return (
		<Flex
			style={{
				flexGrow: 1,
				width: '100%',
				flexDirection: 'column',
				gap: '5x',
			}}
		>
			{model}
			<Flex
				paddingY={'10px'}
				marginLeft={'-10px'}
				flexDirection={'row'}
				zIndex={4}
			>
				<TableFilter
					filtersMap={data?.filterMap || []}
					module={module}
					setFilters={setFilters}
					filters={filters}
					searchParams={otherParameters}
				/>
			</Flex>
			<Flex
				grow={1}
				flexDirection={'column'}
			>
				{(data?.dados?.length ||
					(!data?.dados.length && isLoading)) && (
					<>
						<Flex flexGrow={1}>
							<Flex
								w={'100%'}
								minH={'calc(100vh - 200px)'}
								p={0}
								flexDirection={'column'}
							>
								<StrictMode>
									<div
										style={{
											width: '100%',
											height: '100%',
										}}
									>
										<AgGridReact<Type>
											rowData={rowData}
											columnDefs={cols}
											rowNumbers={rowNumbersOptions}
											onCellValueChanged={onChangeValue}
											domLayout='normal'
											pinnedTopRowData={
												rowTotal ? [rowTotal] : []
											}
											getRowStyle={(params) => {
												const data =
													params.data as Type & {
														isHighlighted: true;
													};
												if (
													params.node.rowIndex ===
														0 &&
													data?.isHighlighted === true
												) {
													return {
														fontWeight: 'bold',
														backgroundColor:
															globalStyles.colors
																.secondaryGray[300],
													};
												}
												return undefined;
											}}
											loading={isLoading}
											loadingOverlayComponent={
												LoadingSpinner
											}
											stopEditingWhenCellsLoseFocus={true}
											singleClickEdit={true}
										/>
									</div>
								</StrictMode>
								<ManualPaging
									pagination={pagination}
									setPagination={setPagination}
									totalItemsPageList={totalItemsPageList}
								/>
							</Flex>
						</Flex>
					</>
				)}
				{data?.dados?.length === 0 && !isLoading && (
					<Flex
						w={'100%'}
						justifyContent={'center'}
					>
						<Text>Nenhum registro encontrado</Text>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};
