import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { editMember } from 'services/api/requests/commercial';

export const useEditMember = (body, id: number) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => editMember(body, id), {
		onSuccess: () => {
			queryClient.invalidateQueries(['membro-list']);
			history.goBack();
			toast({
				title: 'Atualizado com sucesso!',
				description: 'Membro atualizado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			if (error.response.data.length > 0) {
				toast({
					title: 'Erro ao criar membro!',
					description: error.response.data[0].message,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else {
				toast({
					title: 'Erro ao atualizar Membro!',
					description:
						'Verifique se os campos estão preenchidos corretamente. O campo Membro é obrigatório.',
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			}
		},
	});
};
