import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { registerTime } from 'services/api/requests/lancamentoHoraHora/register-time';

export const useRegisterTime = (body, clearSomeFieldsAfterSubmit) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(() => registerTime(body), {
		onSuccess: () => {
			queryClient.cancelQueries(['time-list']);
			queryClient.invalidateQueries(['hourly-list']);

			clearSomeFieldsAfterSubmit();
			toast({
				title: 'Lançamento Diário cadastrado com sucesso!',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			if (Array.isArray(error?.response?.data)) {
				if (
					error?.response?.data[0].message.includes(
						'O cadastro de HoraHora já existe!',
					)
				) {
					toast({
						title: 'Apontamento de produção já existe!',
						position: 'bottom-left',
						description:
							'Já existe apontamento de produção para este produto no recurso, data e intervalo de tempo informados! Verifique os campos e tente novamente!',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				} else {
					error?.response?.data?.forEach?.((e) => {
						toast({
							title: 'Erro ao salvar o lançamento Diário',
							position: 'bottom-left',
							description: e?.message || '',
							status: 'error',
							duration: 8000,
							isClosable: true,
						});
					});
				}
			}
			toast({
				title: 'Erro ao salvar o lançamento Diário',
				position: 'bottom-left',
				description: 'Ocorreu um erro ao salvar o lançamento diário!',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
