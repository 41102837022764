export const nameMonths: string[] = [
	'Janeiro',
	'Fevereiro',
	'Março',
	'Abril',
	'Maio',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezembro',
];
export const monthsUpperCase = [
	'JANEIRO',
	'FEVEREIRO',
	'MARÇO',
	'ABRIL',
	'MAIO',
	'JUNHO',
	'JULHO',
	'AGOSTO',
	'SETEMBRO',
	'OUTUBRO',
	'NOVEMBRO',
	'DEZEMBRO',
];

export const monthNumberToName = {
	1: 'Janeiro',
	2: 'Fevereiro',
	3: 'Março',
	4: 'Abril',
	5: 'Maio',
	6: 'Junho',
	7: 'Julho',
	8: 'Agosto',
	9: 'Setembro',
	10: 'Outubro',
	11: 'Novembro',
	12: 'Dezembro',
};

export const monthAbbrToIndex = {
	jan: 0,
	fev: 1,
	mar: 2,
	abr: 3,
	mai: 4,
	jun: 5,
	jul: 6,
	ago: 7,
	set: 8,
	out: 9,
	nov: 10,
	dez: 11,
};

export const getNameMonthByDate = (date: Date) => {
	return nameMonths[date.getMonth()];
};
