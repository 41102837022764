import { Flex, Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import { IconHandStop, IconNotebook, IconTargetArrow } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import StatisticTable, {
	Row,
	RowInput,
} from 'components/dataTables/statisticTable/StatisticTable';
import { useEffect, useState } from 'react';
import { useCreateCollaboration } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useCreateCollaboration';
import ExportCSVButton from '../../templateCadastrarColabCons/components/exportCSV/ExportCSVButton';
import {
	ErrorComment,
	ModalComment,
} from '../../templateCadastrarColabCons/components/ComentarioJustificando';
import { useContextColaboracaoConsenso } from '../../templateCadastrarColabCons/context';
import { useContextColaboracao } from '../context';
import { columnsEndCadastrar } from '../variables/columnsDataCadastrar';
import { useCreateAtaColab } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useCreateAtaColab';
import { useCollaborationConsensuFinalize } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useCollaborationConsensuFinalize';
import { currencyToNumber } from '../../../../../utils/functions/currencyToNumber';
import { useHistory } from 'react-router-dom';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { numberToFormattedString } from 'utils/functions/numberToFormateString';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useSumForSalesChannel } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useSumForSalesChannel';
import { useGetAtaCollaboration } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useGetAtaCollaboration';
import { useCollaborationConsensusByFamilyList } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/filters/useCollaborationConsensusByFamilyList';
import { useSelectByStatistic } from '../../templateCadastrarColabCons/hooks/useSelectByStatistic';
import { numberToLocaleString } from 'utils/functions/number';
import ModalAiPredictionRequest from '../../consenso/components/ModalAiPredictionRequest';
import SectionExceptions from '../../templateCadastrarColabCons/components/exception/SectionExceptions';
import { useExceptionsByFamilyList } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/exception/useExceptionsByFamilyList';
import { generateRowsMonths } from 'components/dataTables/statisticTable/generateRowsMonths';
import { FinishButton } from '../../templateCadastrarColabCons/components/finishButton/FinishButton';

export default function CollaborationSection() {
	const { colaboracoes, setColaboracoes } = useContextColaboracao();
	const [exceptions, setExceptions] = useState<{
		[key: string]: {
			id?: string;
		};
	}>({});
	const { companyAreaId, companyArea } = useContextColaboracaoConsenso();
	const {
		familyId,
		salesChannelId,
		cycleId,
		cycle: cycleData,
		valuesFilter,
		averageTicket,
		keysPage,
		confidenceInterval,
		status,
		isLoadingFilters,
		salesZoneId,
		collaborationConfig,
	} = useContextColaboracaoConsenso();
	const { ciclo: cycle, duracao: cycleDuration, ano: yearCycle } = cycleData;

	const {
		data: dataCollaborationByFamily,
		isLoading: isLoadingCollaborationData,
	} = useCollaborationConsensusByFamilyList({
		page: keysPage.page,
		cycleId,
		salesChannelId,
		familyId,
		companyAreaId,
		salesZoneId,
	});

	const { data: dataAtaColab } = useGetAtaCollaboration({
		cycleId,
		salesChannelId,
		familyId,
		companyAreaId,
		salesZoneId,
	});

	const { data: dataCollaborationExceptionByFamily } =
		useExceptionsByFamilyList({
			page: keysPage.page,
			cycleId,
			familyId,
			salesChannelId,
			companyAreaId,
		});

	const { data: sumForSalesChannel } = useSumForSalesChannel(
		cycleId,
		companyAreaId,
		familyId,
		salesChannelId,
		salesZoneId,
	);

	const { mutate: finish } = useCollaborationConsensuFinalize('colaboracao');

	const { mutate: createAta } = useCreateAtaColab();

	const { mutateAsync } = useCreateCollaboration();

	const history = useHistory();
	const { pathCompanyName } = useGlobalContext();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const [ata, setAta] = useState('');
	const columnFirtDataColaboracaoCadastrar = {
		Header: 'PREENCHIMENTO',
		accessor: 'estatitica',
		type: 'text',
	};
	const updateCollaborationByDatabase = () => {
		if (dataCollaborationByFamily) {
			const newColaboracao = {};
			let total = 0;
			for (const collaboration of dataCollaborationByFamily.colaboracoes) {
				const key = +collaboration.mes_ano.split('-')[1];
				newColaboracao[key] = collaboration.colaboracao;
				total += collaboration.colaboracao;
			}
			for (let i = 0; i < cycleDuration; i++) {
				const key = new Date(1, Number(cycle) + i).getMonth() + 1;
				if (newColaboracao[key] === undefined) {
					newColaboracao[key] = '';
				}
			}
			setColaboracoes({
				...newColaboracao,
			});
			setMedia(total / cycleDuration);
			setTotal(total);
		}
	};
	const updateCollaborationExceptionByDatabase = () => {
		if (dataCollaborationExceptionByFamily) {
			const newExceptions = {};
			for (const { id } of dataCollaborationExceptionByFamily) {
				newExceptions[id] = { id };
			}
			setExceptions(newExceptions);
		}
	};
	useEffect(() => {
		updateCollaborationExceptionByDatabase();
	}, [dataCollaborationExceptionByFamily]);

	useEffect(() => {
		updateCollaborationByDatabase();
	}, [dataCollaborationByFamily]);

	useEffect(() => {
		setAta(dataAtaColab?.anotacao || '');
	}, [dataAtaColab]);

	useEffect(() => {
		if (colaboracoes) {
			const { max, min } = confidenceInterval;
			for (const key in colaboracoes) {
				const colaboracao = colaboracoes[key];
				if (Number(colaboracao) < min || Number(colaboracao) > max) {
					setShowErrorComment(true);
					return;
				}
			}
			setShowErrorComment(false);
		}
	}, [colaboracoes, confidenceInterval]);

	const onChange = (key: string, value: string) => {
		let total = 0;
		const newColaboracao = {
			...colaboracoes,
			[key]: value,
		};

		for (const key in newColaboracao) {
			total += currencyToNumber(String(newColaboracao[key])) || 0;
		}
		setColaboracoes({
			...newColaboracao,
		});
		setMedia(total / cycleDuration);
		setTotal(total);
	};
	const [media, setMedia] = useState(0);
	const [total, setTotal] = useState(0);

	const errorConditional = (value: number | string) => {
		return (
			Number(confidenceInterval.min) < Number(value) &&
			Number(confidenceInterval.max) > Number(value)
		);
	};
	const {
		configsCompany: { precisao_decimal },
	} = useGlobalContext();

	const { onChangeSelect, options, valueSelect, isLoading, extraResult } =
		useSelectByStatistic({
			cycleId,
			familyId,
			page: keysPage.page,
			salesChannelId,
			salesZoneId,
			companyAreaId,
			cycleData,
			setTotal,
			setValues: setColaboracoes,
			setAverage: setMedia,
		});

	const colaboracao: RowInput = {
		type: 'input',
		...colaboracoes,
		media: numberToFormattedString(media, precisao_decimal),
		total: numberToFormattedString(total, precisao_decimal),
		preenchimento: {
			options,
			onChange,
			onChangeSelect,
			value: valueSelect,
			isLoading,
		},
		errorConditional: errorConditional,
		dataTest: `input-quantidade-colaboracao`,
	};

	const previsaoReceita: Row = {
		type: 'text',
		media: Math.trunc(media * averageTicket)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		}),
		total: Math.trunc(total * averageTicket)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		}),
		estatitica: 'Previsão de Receita',
	};

	for (const key in colaboracoes) {
		const value = currencyToNumber(String(colaboracoes[key]));
		const previsao = value ? value * averageTicket : 0;

		previsaoReceita[key] = Math.trunc(previsao)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		});
	}

	// Iniciativa de implementação de linha de totais abaixo de previsão de receita
	const formatCurrency = (value) =>
		Math.trunc(value)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		});

	const [channelTotalQuantity, setChannelTotalQuantity] = useState<Row>({
		type: 'text',
		media: '',
		total: '',
		estatitica: 'Total do Canal (QTD)',
	});
	const [channelTotalMoney, setChannelTotalMoney] = useState<Row>({
		type: 'text',
		media: '',
		total: '',
		estatitica: 'Total do Canal (R$)',
	});

	useEffect(() => {
		if (sumForSalesChannel) {
			const newSumForSalesChannel = {};
			const newSumForSalesChannelMoney = {};
			let totalSumSalesChannel = 0;
			let totalSumSalesChannelMoney = 0;

			for (const sum of sumForSalesChannel) {
				const key = +sum.mes_ano.split('-')[1];
				newSumForSalesChannel[key] = sum.colaboracao;
				newSumForSalesChannelMoney[key] = formatCurrency(
					sum.receita_bruta,
				);
				totalSumSalesChannel += sum.colaboracao;
				totalSumSalesChannelMoney += sum.receita_bruta;
			}

			for (let i = 0; i < cycleDuration; i++) {
				const key = new Date(1, Number(cycle) + i).getMonth() + 1;
				newSumForSalesChannel[key] = numberToLocaleString({
					value: newSumForSalesChannel[key],
					maximumFractionDigits: precisao_decimal,
				});
				newSumForSalesChannelMoney[key] =
					newSumForSalesChannelMoney[key] || '';
			}
			setChannelTotalQuantity((prev) => ({
				...prev,
				...newSumForSalesChannel,
				total: numberToLocaleString({
					value: totalSumSalesChannel,
					maximumFractionDigits: precisao_decimal,
				}),
				media: numberToLocaleString({
					value: totalSumSalesChannel / cycleDuration,
					maximumFractionDigits: precisao_decimal,
				}),
			}));
			setChannelTotalMoney((prev) => ({
				...prev,
				...newSumForSalesChannelMoney,
				total: formatCurrency(totalSumSalesChannelMoney),
				media: formatCurrency(
					totalSumSalesChannelMoney / cycleDuration,
				),
			}));
		}
	}, [sumForSalesChannel, cycleDuration, cycle, precisao_decimal]);

	const collumnsMounths = generateRowsMonths({
		startMonth: cycle,
		qtdMounth: cycleDuration,
		abbreviatedMonth: false,
	});

	const addException = () => {
		const key: string = Date.now().toString();
		setExceptions({
			...exceptions,
			[key]: {},
		});
		setTimeout(() => {
			const element = document.getElementById('buttonNewException');
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 200);
	};
	const toast = useToast();
	const onDeleteCard = (key: string) => {
		const newExceptions = { ...exceptions };
		delete newExceptions[key];

		setExceptions(newExceptions);
		toast({
			title: 'Exceção removida com sucesso!',
			status: 'success',
			duration: 8000,
			isClosable: true,
			position: 'bottom-left',
		});
	};
	const [showErrorComment, setShowErrorComment] = useState<boolean>(false);
	const [showModalColaboracao, setShowModalColaboracao] =
		useState<boolean>(false);

	const onSaveAta = (newAta: string) => {
		let bodyAta = {
			anotacao: newAta,
			ciclo_id: cycleId,
			familia_id: familyId,
			canal_venda_id: salesChannelId,
			area_empresa_id: companyAreaId,
			zona_venda_id: salesZoneId,
		};
		setAta(newAta);
		createAta(bodyAta);
		setShowModalColaboracao(false);
	};

	const createCollab = async () => {
		const collabsBody: Array<{
			mes_ano: string;
			quantidade: number;
		}> = Array.from({
			length: cycleDuration,
		}).map((_, i) => {
			const month = ((cycle + i) % 12) + 1;
			const ano = (i + cycle + 1) / 12 > 1 ? yearCycle + 1 : yearCycle;
			return {
				mes_ano: `${month < 10 ? '0' : ''}${month}/${ano}`,
				quantidade: currencyToNumber(colaboracao[month]),
			};
		});

		const body = {
			area_empresa_id: companyAreaId,
			ciclo_id: valuesFilter.ciclo?.value,
			familia_id: familyId,
			canal_venda_id: valuesFilter.canal_venda?.value,
			zona_venda_id: valuesFilter.zona_venda?.value,
			exception: false,
			colaboracoes: collabsBody,
			ata: ata,
		};

		await mutateAsync(body);
	};

	const clickCancel = () => {
		updateCollaborationByDatabase();
		updateCollaborationExceptionByDatabase();
	};

	const goToaccuracy = () => {
		history.push(
			`/${pathCompanyName}/demanda/acuracidade?areaColaboracao=${companyArea?.toUpperCase?.()}`,
		);
	};

	useEffect(() => {
		if (extraResult?.hasData && extraResult?.isInvalidData) onOpen();
	}, [extraResult, extraResult?.hasData, extraResult?.isInvalidData, onOpen]);

	return isLoadingCollaborationData || isLoadingFilters ? (
		<Flex
			w='100%'
			h='50vh'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
		>
			<Spinner
				thickness='4px'
				speed='0.65s'
				emptyColor='gray.200'
				color='blue.500'
				size='lg'
			/>
			<p>Carregando dados...</p>
		</Flex>
	) : (
		<Flex
			flexDirection={'column'}
			className={`duracao-${cycleDuration}`}
		>
			<StatisticTable
				title={{
					icon: IconHandStop,
					name: 'Colaboração',
					red: showErrorComment,
				}}
				SectionButtons={
					<>
						<Flex
							cursor={'pointer'}
							gap={'10px'}
							marginBottom='10px'
							flexGrow={1}
							justifyContent={'flex-end'}
						>
							<TooltipStandard
								label='Exportar dados de Colaboração para o CSV'
								shouldWrapChildren={true}
							>
								<ExportCSVButton />
							</TooltipStandard>

							<TooltipStandard
								label='ATA de Colaboração'
								shouldWrapChildren={true}
							>
								<ButtonComponent
									onClick={() =>
										setShowModalColaboracao(true)
									}
									iconType={<IconNotebook />}
									type='icon'
								/>
							</TooltipStandard>

							<TooltipStandard
								label='Acuracidade'
								shouldWrapChildren={true}
							>
								<ButtonComponent
									type='icon'
									iconType={<IconTargetArrow />}
									onClick={goToaccuracy}
								/>
							</TooltipStandard>
						</Flex>
					</>
				}
				columnsData={{
					body: collumnsMounths,
					footer: columnsEndCadastrar,
					header: [columnFirtDataColaboracaoCadastrar],
				}}
				tableData={
					collaborationConfig?.agrupar_zona_venda
						? [colaboracao, previsaoReceita]
						: [
								colaboracao,
								previsaoReceita,
								channelTotalQuantity,
								channelTotalMoney,
						  ]
				}
				useInputMoney
			/>
			{showModalColaboracao && (
				<ModalComment
					header='Ata de Colaboração'
					description='Escreva aqui suas observações sobre esta coloboração'
					onClose={() => setShowModalColaboracao(false)}
					onSave={onSaveAta}
					ata={ata}
					showModal={showModalColaboracao}
					valuesFilter={valuesFilter}
				/>
			)}
			{showErrorComment && !ata && (
				<ErrorComment
					onClose={() => setShowErrorComment(false)}
					onSave={onSaveAta}
					ata={ata}
					showModal={showErrorComment}
				/>
			)}
			<Flex
				justifyContent={'flex-end'}
				gap='10px'
			>
				<ButtonComponent
					data-test='button-cancelar-page-colaboracoes'
					title='Cancelar'
					onClick={clickCancel}
				/>
				<ButtonComponent
					data-test='button-salvar-page-colaboracoes'
					title='Salvar'
					onClick={createCollab}
					type='primary'
					isDisabled={!ata && showErrorComment}
				/>
			</Flex>
			{status?.allowedFinish && (
				<FinishButton
					companyAreaId={companyAreaId}
					cycleId={cycleId}
					finish={finish}
					keysPage={keysPage}
				/>
			)}
			{Object.keys(exceptions).map((key, indexException) => {
				return (
					<SectionExceptions
						key={key}
						exceptionId={exceptions[key]?.id}
						exceptionKey={key}
						onDeleteCard={onDeleteCard}
						initiIsEditing={!exceptions[key]?.id} // Em colaboração quando é uma exceção ela não tem id, o card já inicia em modo de edição
						indexException={indexException}
					/>
				);
			})}
			<Flex marginTop={'30px'}>
				<ButtonComponent
					title='Nova exceção'
					onClick={() => addException()}
					type='secondary'
					size='lg'
					id='buttonNewException'
				/>
			</Flex>
			<ModalAiPredictionRequest
				isOpen={isOpen}
				onClose={onClose}
				message={extraResult?.message}
			/>
		</Flex>
	);
}
