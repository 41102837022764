import { useQuery } from '@tanstack/react-query';
import { getExceptionsById } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
interface IUseExceptionsById {
	page: string;
	id?: string;
	salesChannelId?: string;
	productId?: string;
	cycleId?: string;
}
export const useExceptionsById = ({
	page,
	id = '',
	salesChannelId = '',
	productId = '',
	cycleId = '',
}: IUseExceptionsById) => {
	return useQuery(
		[
			'collaboration-consensus-exception-by-family',
			page,
			id,
			salesChannelId,
			productId,
			cycleId,
		],
		getExceptionsById,
		{
			refetchOnWindowFocus: false,
		},
	);
};
