import { monthsUpperCase } from 'utils/consts/months';
import { ColumnData } from '../Table';
import { ColumnDataInputType } from './StatisticTable';

interface GenerareInputRowsProps {
	startMonth: number;
	qtdMounth: number;
	abbreviatedMonth?: boolean;
	addYear?: boolean;
	startYear?: number;
	acessorIncludeYaer?: boolean;
	inputType?: ColumnDataInputType;
}
export const generateRowsMonths = ({
	startMonth,
	qtdMounth,
	abbreviatedMonth = true,
	addYear = false,
	startYear = 0,
	acessorIncludeYaer,
	inputType,
}: GenerareInputRowsProps): ColumnData[] => {
	const indexSlice = abbreviatedMonth ? 3 : undefined;
	return Array.from({ length: qtdMounth }, (_, i) => {
		const date = new Date(startYear, startMonth + i);
		const mounth = date.getMonth();
		let year = '';
		if (addYear) year = '/' + date.getFullYear().toString().slice(-2);
		const Header = monthsUpperCase[mounth].slice(0, indexSlice) + year;
		return {
			Header,
			accessor: acessorIncludeYaer
				? `${('0' + (mounth + 1)).slice(-2)}${year}`
				: (mounth + 1).toString(),
			type: 'text',
			disableSortBy: true,
			inputType,
		};
	});
};
