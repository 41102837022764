import api from '../../api';

export const getDemandDashboardData = async ({ queryKey }) => {
	const [_key, params] = queryKey;
	const { cicloId, canalVendaId, familiaId } = params;
	let url = '/dashboardDemanda/dadosDashboard';
	const response = await api.get(url, {
		params: {
			ciclo_id: cicloId || '',
			canal_venda_id: canalVendaId || '',
			familia_id: familiaId || '',
		},
	});
	return response.data;
};
