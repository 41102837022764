import { EditIcon } from '@chakra-ui/icons';
import {
	Box,
	Text,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	ResponsiveValue,
	Tag,
} from '@chakra-ui/react';
import { Produto } from 'services/api/requests/requests';
import { globalStyles } from 'theme/styles';

const styles = {
	box: {
		mt: 4,
		overflowX: 'auto' as ResponsiveValue<'auto'>,
	},
	text: {
		fontWeight: 700,
		color: 'easyBLUE.300',
		fontSize: 'lg',
		mb: 2,
	},
	tableContainer: {
		maxHeight: '400px',
		overflowY: 'auto' as ResponsiveValue<'auto'>,
	},
};
const columns = [
	{
		label: 'Código',
		acessor: 'codigo_produto',
	},
	{
		label: 'Nome',
		acessor: 'codigo_produto',
	},
];
type LineType = 'edit' | 'unallocated';
const tagStyles: Record<
	LineType,
	{
		color: string;
		label: string;
	}
> = {
	edit: {
		color: globalStyles.colors.green[200],
		label: 'Troca de família',
	},
	unallocated: {
		color: globalStyles.colors.easyRED[300],
		label: 'Removido do rateio',
	},
};
interface LineProps {
	products: any[];
	type: LineType;
	pathCompanyName?: string;
}
const Lines = ({ products, type, pathCompanyName }: LineProps) => {
	const tagStyle = tagStyles[type];
	return (
		<>
			{products.map((product, index) => (
				<Tr key={index}>
					{columns.map((column) => (
						<Td key={column.acessor}>{product[column.acessor]}</Td>
					))}
					<Td>
						<Tag
							size='sm'
							color={tagStyle.color}
							backgroundColor={tagStyle.color + '1A'}
						>
							{tagStyle.label}
						</Tag>
					</Td>
					<Td key={'edit'}>
						<a
							href={`/${pathCompanyName}/cadastros/produto/editar/${product.id}`}
						>
							<EditIcon />
						</a>
					</Td>
				</Tr>
			))}
		</>
	);
};
interface ModalInconsistencyUpdateTableProps {
	productsChangedFamilies: Produto[];
	productsUnallocated: Produto[];
	pathCompanyName?: string;
}
const ModalInconsistencyUpdateTable = ({
	productsChangedFamilies,
	productsUnallocated,
	pathCompanyName,
}: ModalInconsistencyUpdateTableProps) => {
	return (
		<Box {...styles.box}>
			<Text {...styles.text}>Produtos inconsistentes:</Text>
			<Box
				as='hr'
				my={4}
			/>
			<Box {...styles.tableContainer}>
				<Table variant='simple'>
					<Thead>
						<Tr>
							{columns.map((column) => (
								<Th key={column.acessor}>{column.label}</Th>
							))}
							<Th key={'status'}>{'Alteração'}</Th>
							<Th key={'edit'}>{'Editar'}</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Lines
							products={productsChangedFamilies}
							type='edit'
							pathCompanyName={pathCompanyName}
						/>
						<Lines
							products={productsUnallocated}
							type='unallocated'
							pathCompanyName={pathCompanyName}
						/>
					</Tbody>
				</Table>
			</Box>
		</Box>
	);
};

export default ModalInconsistencyUpdateTable;
