import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { createSale } from 'services/api/requests/requests';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useCreateSale = (body) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();
	return useMutation(() => createSale(body), {
		onSuccess: () => {
			queryClient.invalidateQueries(['venda-list']);
			history.goBack();
			toast({
				title: 'Criado com sucesso!',
				description: 'Venda criada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao cadastrar Venda!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
