import { Alert, AlertIcon, Box, Flex, useToast } from '@chakra-ui/react';
import { IconNewSection } from '@tabler/icons';
import StatisticTable, {
	ColumnData,
	Row,
	RowHover,
	RowInput,
} from 'components/dataTables/statisticTable/StatisticTable';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { generateRowsMonths } from 'components/dataTables/statisticTable/generateRowsMonths';
import { LoadingSpinner } from 'components/loading/Loading';
import SectionButtons from './SectionButtons';

import { globalStyles } from 'theme/styles';

import { useEffect, useState } from 'react';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useContextColaboracaoConsenso } from '../../context';
import { useSelectByStatistic } from '../../hooks/useSelectByStatistic';
import { useAverageTicketByProduct } from 'services/queryClient/wrapperHooks/demanda/averageTicket/useAverageTicketByProduct';
import { useExceptionsById } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/exception/useExceptionsById';
import { useCreateException } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/exception/useCreateException';
import { useEditException } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/exception/useEditException';
import { useDeleteException } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/exception/useDeleteException';
import {
	ICollaborationExceptions,
	ICreateOrEditException,
} from 'services/api/requests/colabAndConsensus/collaborationConsensus';

import { currencyToNumber } from 'utils/functions/currencyToNumber';
import { numberToLocaleString } from 'utils/functions/number';
import { numberToFormattedString } from 'utils/functions/numberToFormateString';

import { ExceptionProprietes } from 'pages/admin/demanda/consenso/context';

export interface QuantityValuesException {
	[key: string]: number | string | undefined | boolean | '';
}
const getColumnHeader = (isEditing: boolean): ColumnData[] => [
	{
		Header: 'PREENCHIMENTO',
		accessor: 'preechimentoText',
		disableSortBy: true,
		type: 'text',
		inputType: isEditing ? 'exceptionEdit' : 'exception',
	},
];
const columnsEndCadastrar: ColumnData[] = [
	{
		Header: 'MÉDIA',
		accessor: 'media',
		type: 'text',
	},
	{
		Header: 'TOTAL',
		accessor: 'total',
		type: 'text',
	},
];

interface ExceptionsObject {
	colaboracao?: number;
	consenso?: number;
	mes_ano: string;
	familia_id: number;
}
interface PropsSectionExceptions {
	exceptionId?: string;
	fixedProprities?: {
		salesChannelId: string;
		productId: string;
	};
	associatedCollaboration?: boolean;
	exceptionKey: string;
	onDeleteCard: (key: string, amountCollaborationAssociated?: number) => void;
	initiIsEditing: boolean;
	indexException: number;
}
function convertExceptionToNumber(
	exceptionText: string | number | boolean | undefined,
) {
	// Verifica se é um número, caso contrário converte para string e ajusta o formato
	const exceptionCell =
		typeof exceptionText === 'number'
			? exceptionText
			: String(exceptionText)
					.replaceAll('.', '') // Remove pontos
					.replace(',', '.'); // Substitui vírgulas por pontos

	// Retorna o valor convertido ou 0 caso seja um valor inválido
	const value = exceptionCell ? +exceptionCell : 0;

	return value;
}
export default function SectionExceptions({
	exceptionId: exceptionIdInit,
	exceptionKey,
	onDeleteCard,
	fixedProprities,
	initiIsEditing,
	associatedCollaboration = false,
	indexException,
}: Readonly<PropsSectionExceptions>) {
	const {
		familyId,
		cycle: cycleData,
		companyAreaId,
		salesZoneId,
		salesChannelId,
		keysPage,
		filters,
	} = useContextColaboracaoConsenso();
	const [exceptionId, setExceptionId] = useState<string | undefined>(
		exceptionIdInit,
	);
	const [isEditing, setIsEditing] = useState(initiIsEditing);
	const { ciclo: cycle, duracao: cycleDuration, ano: yearCycle } = cycleData;
	const [ticketMedio, setTicketMedio] = useState<number>(0);
	const [revenueForecast, setRevenueForecast] = useState<RowHover>({
		media: 0,
		total: 0,
		type: 'text-hover',
	});
	const [exceptionsRow, setExceptionsRow] = useState<RowInput>();
	const { data: exceptionsDatabase, isLoading: isLoadingExceptionsDatabase } =
		useExceptionsById({
			page: keysPage.page,
			id: exceptionId,
			productId: fixedProprities?.productId,
			salesChannelId: fixedProprities?.salesChannelId,
			cycleId: String(cycleData.id),
		});
	const [exception, setException] = useState<QuantityValuesException>({});
	const [exceptionProprietes, setExceptionProprietes] =
		useState<ExceptionProprietes>({
			salesChannel: salesChannelId
				? {
						id: salesChannelId,
						nome_canal_venda: filters.canal_venda?.label || '',
				  }
				: undefined,
		});
	const [collaborationRows, setCollaborationRows] = useState<Row[] | null>(
		null,
	);

	const updateExceptionByDatabase = () => {
		if (exceptionsDatabase) {
			const exceptionsForPage = exceptionsDatabase[keysPage.page];
			const calculatedExceptions = {};

			let total = 0;
			let numberMonths = 0;

			if (exceptionsForPage) {
				for (const exceptionDatabase of exceptionsForPage) {
					const qtd = exceptionDatabase[keysPage.page];
					const key = +exceptionDatabase.mes_ano.split('-')[1];

					calculatedExceptions[key] = qtd;
					total += qtd;
					numberMonths += qtd ? 1 : 0;
				}

				const newException: QuantityValuesException = {
					...calculatedExceptions,
					total: numberToLocaleString({
						value: total,
						maximumFractionDigits: precisao_decimal,
					}),
					media: numberToLocaleString({
						value: numberMonths ? total / numberMonths : 0,
						maximumFractionDigits: precisao_decimal,
					}),
				};
				setException(newException);
			} else {
				setException({});
			}

			setExceptionProprietes({
				product: exceptionsDatabase.produto,
				salesChannel: exceptionsDatabase.canal_venda,
			});

			setExceptionId(String(exceptionsDatabase?.id || '') || undefined);

			if (associatedCollaboration) {
				updateExceptionCollaborationByDatabase(
					exceptionsDatabase.colaboracoes,
				);
			}
		}
	};

	const updateExceptionCollaborationByDatabase = (
		collaborations?: ICollaborationExceptions[],
	) => {
		const newCollaborationRows: Row[] = [];
		if (collaborations) {
			for (const colaboracao of collaborations) {
				const newCollaboration: Row = {
					type: 'text',
					media: 0,
					total: 0,
					preechimentoText:
						colaboracao.area_empresa.nome_area_empresa,
				};
				let total = 0,
					numberMonths = 0;
				for (const quantity of colaboracao.colaboracao) {
					const value = convertExceptionToNumber(
						quantity.colaboracao,
					);
					const key = +quantity.mes_ano.split('-')[1];
					newCollaboration[key] = numberToLocaleString({
						value,
						maximumFractionDigits: precisao_decimal,
					});
					total += value;
					numberMonths += value ? 1 : 0;
				}
				newCollaborationRows.push({
					...newCollaboration,
					total: numberToLocaleString({
						value: total,
						maximumFractionDigits: precisao_decimal,
					}),
					media: numberToLocaleString({
						value: numberMonths ? total / numberMonths : 0,
						maximumFractionDigits: precisao_decimal,
					}),
				});
			}
		}
		setCollaborationRows(newCollaborationRows);
	};

	const { mutateAsync: deleteException } = useDeleteException();
	useEffect(() => {
		if (exceptionsDatabase) updateExceptionByDatabase();
	}, [exceptionsDatabase]);
	const handleDeleteSuccess = () => {
		if (associatedCollaboration) {
			setIsEditing(false);
			setException({});
			setExceptionId(undefined);
		}
		onDeleteCard(exceptionKey, collaborationRows?.length);
	};
	const onDelete = async () => {
		if (exceptionId) {
			deleteException({
				page: keysPage.page,
				id: exceptionId,
			}).then(() => {
				handleDeleteSuccess();
			});
		} else {
			handleDeleteSuccess();
		}
	};
	useEffect(() => {
		const revenueForecast: RowHover = {
			type: 'text-hover',
			media: 0,
			total: 0,
			preechimentoText: 'Previsão de Receita',
			dataTest: `text-exception-${indexException}-previsao`,
		};

		Object.entries(exception).forEach(([key, exceptionText]) => {
			const value = convertExceptionToNumber(exceptionText);
			const previsao = value * ticketMedio;

			if (!ticketMedio) {
				revenueForecast[key] = {
					message:
						'Produto não possui preço médio no canal de venda informado.',
					typeHover: 'message',
					value: 'R$ 0',
				};
				return;
			}

			revenueForecast[key] = exceptionText
				? Math.trunc(previsao).toLocaleString('pt-BR', {
						style: 'currency',
						currency: 'BRL',
						maximumFractionDigits: 0,
				  })
				: '';
		});

		setRevenueForecast(revenueForecast);
	}, [exception, ticketMedio]);

	const { data: ticketMedioData } = useAverageTicketByProduct({
		productId: exceptionProprietes?.product?.id,
		salesChannelId: exceptionProprietes?.salesChannel?.id,
	});
	useEffect(() => {
		if (ticketMedioData) {
			setTicketMedio(
				(ticketMedioData && ticketMedioData.ticket_medio) || 0,
			);
		}
	}, [ticketMedioData]);

	const {
		configsCompany: { precisao_decimal },
	} = useGlobalContext();

	const calculateRowStatsOnChange = (key: string, value: string) => {
		let total = 0;
		let numberMonths = 0;
		const newExcecao = {
			...exception,
			[key]: value,
		};

		for (const key in newExcecao) {
			if (!isNaN(+key)) {
				const qtd = currencyToNumber(String(newExcecao[key])) || 0;
				total += qtd;
				numberMonths += qtd ? 1 : 0;
			}
		}

		newExcecao.total = numberToFormattedString(total, precisao_decimal);
		newExcecao.media = numberToFormattedString(
			numberMonths ? total / numberMonths : 0,
			precisao_decimal,
		);
		setException(newExcecao);
	};
	const { onChangeSelectByProduct, valueSelect, options } =
		useSelectByStatistic({
			salesChannelId: exceptionProprietes?.salesChannel
				? String(exceptionProprietes.salesChannel?.id)
				: '',
			companyAreaId,
			cycleData,
			familyId,
			page: keysPage.page,
			salesZoneId,
			productId: exceptionProprietes?.product
				? String(exceptionProprietes?.product?.id)
				: '',
			setValues: (values) => setException(values),
			cycleId: String(cycleData.id),
			exception: true,
		});
	const updateExceptionsRows = () => {
		const errorConditional = (value: number | string) => {
			return value !== 0 && value !== '';
		};

		const excecao: RowInput = {
			type: 'input',
			...exception,
			preenchimento: {
				options,
				onChange: calculateRowStatsOnChange,
				onChangeSelect: (valueSelect) =>
					onChangeSelectByProduct(exceptionProprietes, valueSelect),
				value: valueSelect,
			},
			errorConditional,
			dataTest: `input-exception-${indexException}-${keysPage.page}`,
		} as RowInput;
		setExceptionsRow(excecao);
	};

	useEffect(() => {
		updateExceptionsRows();
	}, [exception, options, valueSelect, exceptionProprietes]);

	const background = globalStyles.colors.secondaryGray[250];
	const border = globalStyles.colors.secondaryGray[150];

	const toast = useToast();
	const { mutateAsync: createExceptionAsync } = useCreateException(
		keysPage.page,
	);
	const { mutateAsync: editExceptionAsync } = useEditException(keysPage.page);
	const onSave = async () => {
		const produto_id = exceptionProprietes?.product?.id as any;
		const canal_venda_id = exceptionProprietes?.salesChannel?.id as any;
		const collabsExceptionBody: Array<ExceptionsObject> = [];
		for (let i = 0; i < cycleDuration; i++) {
			const month = ((cycle + i) % 12) + 1;

			const year = (i + cycle + 1) / 12 > 1 ? yearCycle + 1 : yearCycle;
			if (currencyToNumber(String(exception[month]))) {
				collabsExceptionBody.push({
					mes_ano: `${year}-${month.toString().padStart(2, '0')}`,
					[keysPage.page]: currencyToNumber(String(exception[month])),
					familia_id: +familyId,
				});
			}
		}

		if (!exceptionProprietes.product?.id) {
			erroInputBlank('do Produto');
			return;
		}
		if (!exceptionProprietes.salesChannel?.id) {
			erroInputBlank('do Canal de Venda');
			return;
		}
		if (!collabsExceptionBody.length) {
			erroInputBlank('de pelo menos um mês');
			return;
		}

		const exceptionsBody: ICreateOrEditException = {
			excecoes: collabsExceptionBody,
			area_empresa_id: companyAreaId ? +companyAreaId : 0,
			canal_venda_id,
			id: exceptionId ? +exceptionId : undefined,
			produto_id,
			ciclo_id: cycleData.id,
			colaboracao_associada: associatedCollaboration,
		};
		if (exceptionId) {
			await editExceptionAsync(exceptionsBody);
		} else {
			const result = await createExceptionAsync(exceptionsBody);
			setExceptionId(result.id);
		}
		setIsEditing(false);
	};
	const erroInputBlank = (atributo: number | string) => {
		toast({
			title: `Exceção da ${keysPage.singular} não cadastrada!`,
			description: `Preenchimento ${atributo} é obrigatório na exceção`,
			status: 'error',
			duration: 8000,
			isClosable: true,
			position: 'bottom-left',
		});
	};

	const onCancel = () => {
		if (exceptionId) {
			updateExceptionByDatabase();
			setIsEditing(false);
		} else {
			onDeleteCard(exceptionKey, collaborationRows?.length);
		}
	};
	//Maior que 2 para desconsiderar os campos de total e média
	const exceptionExists = Object.keys(exception).length > 2;
	const showInputRow = Boolean(
		exceptionsRow &&
			(associatedCollaboration ? isEditing || exceptionExists : true),
	);

	return (
		<Box
			backgroundColor={background}
			border={`1px solid ${border}`}
			borderRadius={'24px'}
			marginY={'40px'}
			padding={'30px'}
			gap='20px'
			display={'flex'}
			flexDirection={'column'}
		>
			<StatisticTable
				title={{
					icon: IconNewSection,
					name: 'Exceção',
					red: true,
					marginLeft: '0px',
				}}
				showColumnBorders={true}
				SectionButtons={
					<Flex
						flexDirection={'column'}
						w='100%'
					>
						<Flex gap='20px'>
							<SectionButtons
								onChange={(exception) =>
									setExceptionProprietes(exception)
								}
								exception={exceptionProprietes}
								onDelete={onDelete}
								onEdit={() => setIsEditing(true)}
								isEditing={isEditing}
								familyId={familyId}
								disabled={fixedProprities !== undefined}
								exceptionExists={exceptionExists}
								indexException={indexException}
							/>
							{isLoadingExceptionsDatabase && <LoadingSpinner />}
						</Flex>
						{!isLoadingExceptionsDatabase &&
							associatedCollaboration &&
							exceptionsDatabase?.colaboracoes?.length === 0 && (
								<Alert status='warning'>
									<AlertIcon />
									Colaborações que definiram esta exceção
									foram excluídas
								</Alert>
							)}
					</Flex>
				}
				columnsData={{
					header: getColumnHeader(isEditing),
					body: generateRowsMonths({
						startMonth: Number(cycle),
						qtdMounth: cycleDuration,
						abbreviatedMonth: false,
						inputType: isEditing ? 'exceptionEdit' : 'exception',
					}),
					footer: columnsEndCadastrar,
				}}
				tableData={[
					...(collaborationRows ? collaborationRows : []),
					...(showInputRow
						? ([exceptionsRow, revenueForecast] as any)
						: []),
				]}
				useInputMoney
			/>
			{isEditing && (
				<Flex
					justifyContent={'flex-end'}
					id='buttonSave'
					gap='10px'
					alignItems={'start'}
				>
					<ButtonComponent
						data-test={`button-cancelar-exception-${indexException}-${keysPage.page}`}
						title='Cancelar'
						onClick={onCancel}
					/>
					<ButtonComponent
						data-test={`button-salvar-exception-${indexException}-${keysPage.page}`}
						title='Adicionar exceção'
						onClick={onSave}
						type='primary'
					/>
				</Flex>
			)}
		</Box>
	);
}
