import type { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';
import { numberToLocaleString } from 'utils/functions/number';

export class NumberEditor implements ICellEditorComp<any> {
	gui!: HTMLInputElement;
	params!: ICellEditorParams<any, any, any>;
	private convertToCurrency(value: number) {
		const quantityDigits = +this.params['quantityDigits'] || 0;
		return numberToLocaleString({
			value: +value,
			maximumFractionDigits: quantityDigits,
		});
	}
	private formatValue(input: string): string {
		const lastChar = input[input.length - 1];
		const quantityDigits = +this.params['quantityDigits'] || 0;

		const cleaned = input.replace(/[^0-9,]/g, '');

		const [, afterComma] = cleaned.split(',');

		const [integer, decimal = ''] = cleaned.replace(',', '.').split('.');
		const quantityAffterComma = afterComma?.length || 0;
		const number = parseFloat(
			integer + '.' + decimal.substring(0, quantityDigits),
		);
		if (isNaN(number)) return '';
		const numberRoundedDown =
			Math.floor(number * Math.pow(10, quantityDigits)) /
			Math.pow(10, quantityDigits);

		const numberFormatted = numberToLocaleString({
			value: numberRoundedDown,
			minimumFractionDigits: Math.min(
				quantityDigits,
				quantityAffterComma,
			),
			maximumFractionDigits: quantityDigits,
		});

		if (lastChar === ',' && quantityAffterComma === 0) {
			return numberFormatted + ','; // Retorna com a vírgula no final se for o último caractere
		}
		return numberFormatted;
	}
	init(params: ICellEditorParams) {
		this.gui = document.createElement('input');
		this.gui.type = 'text';
		this.gui.style.width = '100%';
		this.params = params;

		const startValue = this.convertToCurrency(params.value);
		if (startValue !== null && startValue !== undefined) {
			this.gui.value = startValue;
		}
		this.gui.addEventListener('input', () => {
			const formattedValue = this.formatValue(this.gui.value);
			this.gui.value = formattedValue;
		});
	}

	getGui() {
		return this.gui;
	}

	getValue() {
		return this.gui.value;
	}

	afterGuiAttached() {
		this.gui.focus();
		this.gui.select();
	}
}
