import {
	Flex,
	Text,
	Icon,
	TabList,
	Tab,
	Tabs,
	TabPanels,
	TabPanel,
	Switch,
} from '@chakra-ui/react';
import { IconAlertTriangle, IconCheck } from '@tabler/icons';
import Card from 'components/card/Card';
import { useMemo } from 'react';
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community';

ModuleRegistry.registerModules([AllCommunityModule]);
interface Props {
	generalResult?: any;
	ignorarCapacidadeOciosa?: any;
	setIgnorarCapacidadeOciosa?: any;
}

export const GeneralResult: React.FC<Props> = (props) => {
	const {
		generalResult,
		ignorarCapacidadeOciosa,
		setIgnorarCapacidadeOciosa,
	} = props;

	let geral = generalResult?.geral;
	let gargalo = generalResult?.gargalo;
	let ociosidade = generalResult?.ociosidade;

	let hasData = !!geral?.value;
	let levelled = geral?.nivelado;

	function messageGeral() {
		if (geral?.value === 0) {
			return {
				console: 'Sem Dados',
				title: '-',
				message: 'Não há dados de resultado geral.',
			};
		} else {
			return {
				console: '',
				title: '',
				message: '',
			};
		}
	}

	function messageGargalo() {
		if (gargalo?.nivelado === false) {
			return {
				console: 'Com Dados | Gargalo Desnivelado',
				title: 'Atenção!',
				message:
					'Os recursos indicados estão no limite de suas operações! Recomendamos verificar o seu plano.',
			};
		}
		return {
			console: 'Com Dados | Gargalo Nivelado',
			title: 'Atenção!',
			message:
				'Os recursos indicados estão com pouco gargalo. Veja se está impactando sua demanda.',
		};
	}

	function messageOcioso() {
		if (ignorarCapacidadeOciosa === false) {
			if (ociosidade?.nivelado === false) {
				return {
					console: 'Com Dados | Ocioso Desnivelado',
					title: 'Atenção!',
					message:
						'Capacidade ociosa! Os recursos estão com capacidade ociosa em relação a demanda.',
				};
			}
			return {
				console: 'Com Dados | Ocioso Nivelado',
				title: 'Atenção!',
				message:
					'Os recursos indicados estão com pouca capacidade ociosa.',
			};
		}
		return {
			console: 'Com Dados | Ocioso Ignorado',
			title: 'Atenção!',
			message: 'Capacidade ociosa não está sendo considerada!',
		};
	}

	const defaultTabStyleProps = useMemo(
		() => ({
			_selected: {
				color: '#55bcdf',
				fontWeight: 'bold',
				borderBottom: '1px solid #55bcdf',
			},
			_hover: {
				color: '#55bcdf',
				borderBottom: '1px solid #55bcdf',
			},
			_focus: {
				outline: '0 !important',
			},
			color: 'white',
			fontSize: '0.8rem',
			width: '50%',
		}),
		[],
	);

	const color = hasData && levelled ? 'green.500' : 'yellow.300';
	const icon = levelled ? IconCheck : IconAlertTriangle;

	function renderGeneralResult() {
		return (
			<Text
				ml={'2'}
				color={'#fff'}
			>
				Resultado geral
				<Flex
					mt={2}
					direction={'column'}
				>
					<Text>{messageGeral()?.title}</Text>
					<Text>{messageGeral()?.message}</Text>
				</Flex>
			</Text>
		);
	}

	function renderGargaloContent() {
		return (
			<>
				<Flex
					flexDirection={'row'}
					gap={2}
					alignItems='center'
					mt={2}
					fontSize={'1.5rem'}
					color={color}
				>
					<Icon
						as={icon}
						w='30px'
						h='30px'
						color={color}
						m={1}
					/>
					{gargalo?.value.toLocaleString('pt-BR', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}
					<Text fontSize={'1rem'}>h/mês</Text>
				</Flex>
				<Flex
					flexDirection={'column'}
					mt={4}
				>
					<Text>{messageGargalo()?.title}</Text>
					<Text>{messageGargalo()?.message}</Text>
				</Flex>
			</>
		);
	}

	function renderOciosoContent() {
		return (
			<>
				{ignorarCapacidadeOciosa === false && (
					<Flex
						flexDirection={'row'}
						gap={2}
						alignItems='center'
						mt={2}
						color={color}
						fontSize={'1.5rem'}
					>
						<Icon
							as={icon}
							w='30px'
							h='30px'
							color={color}
							m={1}
						/>
						{ociosidade?.value.toLocaleString('pt-BR', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
						<Text fontSize={'1rem'}>h/mês</Text>
					</Flex>
				)}
				<Flex
					color={'yellow.300'}
					flexDirection={'column'}
					mt={4}
				>
					<Text>{messageOcioso()?.title}</Text>
					<Text>{messageOcioso()?.message}</Text>
				</Flex>
			</>
		);
	}

	function renderSwitch() {
		if (hasData)
			return (
				<Flex
					alignItems={'center'}
					mt={6}
					color={'#fff'}
				>
					<Switch
						mt={-2}
						ml={2}
						textAlign={'center'}
						colorScheme='green'
						onChange={(e) => {
							localStorage.setItem(
								'dashboardCapacidade',
								String(e?.target?.checked),
							);
							setTimeout(() => {
								setIgnorarCapacidadeOciosa?.(
									Boolean(e?.target?.checked),
								);
							}, 500);
						}}
						defaultChecked={ignorarCapacidadeOciosa}
					></Switch>
					<Text
						mt={-2}
						ml={2}
					>
						Ignorar capacidade ociosa
					</Text>
				</Flex>
			);

		return null;
	}

	return (
		<Card
			bgColor={'rgba(0, 0, 0, 0.3)'}
			width={'350px'}
			py='5'
		>
			{renderGeneralResult()}
			{hasData && (
				<Tabs
					mt={'4'}
					px='2'
					pt={1}
					borderRadius={'20px'}
					bgColor={'rgba(0, 0, 0, 0.5)'}
					size='md'
					variant='enclosed'
					height={'240px'}
					defaultIndex={
						localStorage.getItem('dashboardCapacidadeAba') ===
						'Gargalo'
							? 0
							: 1
					}
				>
					<TabList>
						<Tab
							{...defaultTabStyleProps}
							onClick={() =>
								localStorage.setItem(
									'dashboardCapacidadeAba',
									'Gargalo',
								)
							}
							tabIndex={0}
						>
							Gargalo
						</Tab>
						<Tab
							{...defaultTabStyleProps}
							onClick={() =>
								localStorage.setItem(
									'dashboardCapacidadeAba',
									'Ocioso',
								)
							}
							tabIndex={1}
						>
							Ocioso
						</Tab>
					</TabList>

					<TabPanels>
						<TabPanel color={'yellow.300'}>
							{renderGargaloContent()}
						</TabPanel>
						<TabPanel>{renderOciosoContent()}</TabPanel>
					</TabPanels>
				</Tabs>
			)}
			{hasData && renderSwitch()}
		</Card>
	);
};
