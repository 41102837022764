import { IRequestSearchPagination } from 'pages/admin/demanda/templateCadastrarColabCons/components/MainFilter/InputSearch';
import api from '../api';

export const getSalesChannelById = async ({ queryKey }) => {
	const [, id] = queryKey;

	const response = await api.get('canalVenda/canalVendaById', {
		params: { id },
	});
	return response.data;
};

export const updateSalesChannel = async (id: number, body) => {
	const response = await api.patch('canalVenda', body, {
		params: { id },
	});
	return response.data;
};

export const deleteSalesChannelByFilters = async (filters: any) => {
	const response = await api.post('canalVenda/deletarPorFiltros', filters);
	return response.data;
};

export const deleteSalesChannel = async (id: number) => {
	const response = await api.delete('canalVenda', { params: { id } });
	return response.data;
};
type IGetSalesChannelByFiltersAndLimit = Promise<{
	rows: Array<{
		id: number;
		canal_venda: string;
	}>;
	count: number;
}>;
export const getSalesChannelByFiltersAndLimit = async ({
	limit,
	search,
}: IRequestSearchPagination): Promise<IGetSalesChannelByFiltersAndLimit> => {
	const response = await api.get('/canalVenda/filter', {
		params: {
			limit,
			search,
		},
	});

	return response.data;
};
