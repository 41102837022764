import { useQuery } from '@tanstack/react-query';
import { checkExistsRestrictedPlan } from 'services/api/requests/restrictedPlan';

export const useExistsRestrictedPlan = (cycleId?: number | string) => {
	return useQuery(
		['restrictedPlan-exists-byId', cycleId],
		checkExistsRestrictedPlan,
		{
			refetchOnWindowFocus: true,
			enabled: !!cycleId,
		},
	);
};
