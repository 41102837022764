import { useQuery } from '@tanstack/react-query';
import { exportRestrictedPlan } from 'services/api/requests/restrictedPlan';

export const useExportRestrictedPlan = (cicloId: string) => {
	return useQuery(['export-restrictedPlan', cicloId], exportRestrictedPlan, {
		refetchOnWindowFocus: false,
		retry: 1,
		enabled: false,
	});
};
