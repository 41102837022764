import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { createRelatedCustomer } from 'services/api/requests/requests';

export const useCreateRelatedCustomer = (body, id) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => createRelatedCustomer(body, id), {
		onSuccess: () => {
			queryClient.invalidateQueries(['clienteRelacionado-list']);
			history.goBack();
			toast({
				title: 'Criado com sucesso!',
				description: 'Cliente Relacionamento criado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			console.log(error);
			toast({
				title: 'Erro ao criar Cliente Relacionamento!',
				description:
					'Verifique se os campos estão preenchidos corretamente. Os campos Razão Social, CNPJ/CPF e Relacionamento são obrigatórios.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
