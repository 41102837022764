import {
	ButtonGroup,
	Heading,
	Icon,
	Link,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { IconAlertTriangle } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import {
	BodyRestrictedPlan,
	BodyUpdateRestrictedPlan,
} from 'services/api/requests/restrictedPlan';
import { useGetCheckTableDataWithFilters } from 'services/queryClient/wrapperHooks/genericTables/useGetCheckTableDataWithFilters';
interface IEditOpportunityListModal {
	mutate: (b: BodyUpdateRestrictedPlan) => void;
	opportunityIds: number[];
	bodyRestrictedPlan: BodyRestrictedPlan[];
	cleanOpportunity: VoidFunction;
	cycleId: number;
}
interface IOpportunityListModal {
	title: string;
	info: string[];
	list: { name: string; url: string }[];
}
function renderOpportunityListModal(deleteMessage: IOpportunityListModal) {
	const { title, info, list } = deleteMessage;

	return (
		<>
			<Icon
				as={IconAlertTriangle}
				color='easyRED.300'
				w={14}
				h={14}
			></Icon>
			<Heading
				color='gray.700'
				mb={2}
				size='md'
			>
				{title}
			</Heading>
			{info?.length > 0 &&
				info.map((el, index) => (
					<Text
						key={index}
						color='gray.500'
						fontSize='sm'
					>
						<p style={{ textAlign: 'justify' }}>{el}</p>
						<br />
					</Text>
				))}
			{list?.length > 0 && (
				<ul>
					{list?.map((el, index) => (
						<Text
							color='easyBLUE.300'
							fontSize='sm'
							pl='25'
						>
							<li key={index}>
								<Link
									target={'_blank'}
									href={el.url}
								>
									{el.name}
								</Link>
							</li>
						</Text>
					))}
				</ul>
			)}
		</>
	);
}
interface IOpportunityView {
	nome_cliente: string;
	id: string;
}
const EditOpportunityListModal = ({
	opportunityIds,
	mutate,
	bodyRestrictedPlan,
	cleanOpportunity,
	cycleId,
}: IEditOpportunityListModal) => {
	const { isOpen } = useDisclosure({
		isOpen: true,
	});
	const { data } = useGetCheckTableDataWithFilters<IOpportunityView>({
		module: 'oportunidade',
		filters: [
			{
				nome_coluna: 'id',
				operacao: 'in',
				tipo_valor: 'number',
				valores: opportunityIds,
			},
		],
	});

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => cleanOpportunity()}
			size={'4xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalBody>
					{renderOpportunityListModal({
						title: 'Alteração da Oportunidade',
						info: [
							'Notamos alteração na quantidade de exceção de oportunidade. Essa alteração irá refletir em todo sistema (exemplo, plano irrestrito e consenso). A receita bruta da oportunidade permanece intacta, caso deseje alterar, acesse a tela de oportunidade.',
							'Oportunidades alteradas (clique na oportunidade para ser direcionado):',
						],
						list: data?.dados?.map((o: IOpportunityView) => ({
							name: o.nome_cliente,
							url: `../rotina/oportunidades/editar/${o.id}`,
						})),
					})}
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							data-test={'button-cancelar-modal_exclusao'}
							type={'ghost'}
							title={'Cancelar'}
							onClick={cleanOpportunity}
						/>
						<ButtonComponent
							data-test={'button-excluir-modal_exclusao'}
							type={'secondary'}
							title={'Estou ciente e desejo continuar'}
							onClick={() => {
								mutate({
									ciclo_id: cycleId,
									plano_restrito: bodyRestrictedPlan,
								});
							}}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default EditOpportunityListModal;
