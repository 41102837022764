import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteBudget } from 'services/api/requests/budget';
import { deleteForecast } from 'services/api/requests/forecast';
import { deletePortfolio } from 'services/api/requests/portfolio';
import { deleteCitySalesZone } from 'services/api/requests/city';
import { deleteHierarchy, deleteMarket } from 'services/api/requests/market';
import { deleteMember } from 'services/api/requests/member';
import { deleteProduct } from 'services/api/requests/product';
import { deleteStopTopology } from 'services/api/requests/production';
import { deleteRelatedCustomer } from 'services/api/requests/relatedCustomer';
import { deleteResource } from 'services/api/requests/resources';
import { deleteActivity } from 'services/api/requests/roadmap';
import { deleteSale } from 'services/api/requests/sale';
import {
	deleteActualStock,
	deleteDesiredStock,
} from 'services/api/requests/stock';
import {
	deleteBreak,
	deleteTime,
} from 'services/api/requests/lancamentoHoraHora/delete-time';
import { deleteOpportunity } from 'services/api/requests/opportunity/opportunity';
import { deleteUser } from 'services/api/requests/authentication';
import { deleteSalesForce } from 'services/api/requests/salesForce';
import { deleteSalesZone } from 'services/api/requests/salesZone';
import { deleteSalesChannel } from 'services/api/requests/salesChannel';

export const useDeleteModal = (
	context,
	onClose,
	toastSuccessDescription,
	toastSuccessTitle,
	toastErrorTitle,
	idDelete,
	invalidate,
	queriesToInvalidadeOnRemove,
	resetQuery,
) => {
	const returnContext = {
		product: deleteProduct,
		member: deleteMember,
		hierarchy: deleteHierarchy,
		city: deleteCitySalesZone,
		sale: deleteSale,
		relatedCustomer: deleteRelatedCustomer,
		topology: deleteStopTopology,
		activity: deleteActivity,
		resource: deleteResource,
		actualStock: deleteActualStock,
		time: deleteTime,
		break: deleteBreak,
		budget: deleteBudget,
		opportunity: deleteOpportunity,
		forecast: deleteForecast,
		portfolio: deletePortfolio,
		desiredStock: deleteDesiredStock,
		user: deleteUser,
		market: deleteMarket,
		salesForce: deleteSalesForce,
		salesZone: deleteSalesZone,
		salesChannel: deleteSalesChannel,
	};

	const queryClient = useQueryClient();
	const callFunctionContext = returnContext[context];

	const toast = useToast();

	return useMutation(() => callFunctionContext(idDelete), {
		onSuccess: (success) => {
			onClose();
			if (!!queriesToInvalidadeOnRemove?.length) {
				queriesToInvalidadeOnRemove?.forEach((item) => {
					if (Array.isArray(item)) {
						resetQuery
							? queryClient.resetQueries(item)
							: queryClient.invalidateQueries(item);
					} else {
						resetQuery
							? queryClient.resetQueries([item])
							: queryClient.invalidateQueries([item]);
					}
				});
			}

			queryClient.invalidateQueries([invalidate]);

			toast({
				title: toastSuccessTitle,
				description: toastSuccessDescription,
				position: 'bottom-left',
				status: 'success',
				duration: 9000,
				isClosable: true,
			});

			if (context === 'product') {
				queryClient.removeQueries({
					predicate: (query: any) =>
						query.queryKey[0].startsWith('apportionment-family'),
				});
			}
		},
		onError: () => {
			onClose();
			toast({
				title: toastErrorTitle,
				description: `Não foi possível remover o(s) Canal(is) de
				Venda(s), pois o(s) mesmo(s) está(ão)
				relacionado(s) com Rateio, Consenso, Colaborações, Plano irrestrito ou Oportunidades da Exceção da Demanda`,
				position: 'bottom-left',
				status: 'error',
				duration: 12000,
				isClosable: true,
			});
		},
	});
};
