import { AlertStatus } from '@chakra-ui/react';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { LoadingSpinner } from 'components/loading/Loading';

interface IWarning {
	message: string | JSX.Element;
	title: string;
	status: AlertStatus;
}
type WarningTypes =
	| 'not_exist_unrestricted_plan'
	| 'not_exist_restricted_plan'
	| 'exist_restricted_plan';

const warnings: Record<WarningTypes, IWarning> = {
	not_exist_unrestricted_plan: {
		title: 'Atenção.',
		message:
			'Não há plano irrestrito e restrito para o ciclo selecionado. Por favor, primeiro realize o consenso.',
		status: 'warning',
	},
	not_exist_restricted_plan: {
		title: 'Atenção.',
		message:
			'Não há plano restrito para o ciclo selecionado. Por favor, clique em “Gerar Plano”.',
		status: 'warning',
	},
	exist_restricted_plan: {
		title: 'Plano restrito gerado.',
		message:
			'Caso deseja atualizar o plano, edite as quantidades diretamente na tabela.',
		status: 'success',
	},
};

const getWarningType = (
	restrictedPlanExists?: boolean,
	unrestrictedPlanExists?: boolean,
): WarningTypes | undefined => {
	if (
		restrictedPlanExists === undefined ||
		unrestrictedPlanExists === undefined
	) {
		return;
	}
	if (!restrictedPlanExists && !unrestrictedPlanExists) {
		return 'not_exist_unrestricted_plan';
	}
	if (!restrictedPlanExists) {
		return 'not_exist_restricted_plan';
	}
	return 'exist_restricted_plan';
};

interface IPlanStatusAlertProps {
	restrictedPlanExists?: boolean;
	unrestrictedPlanExists?: boolean;
	isLoading?: boolean;
}
export const PlanStatusAlert = ({
	restrictedPlanExists,
	unrestrictedPlanExists,
	isLoading,
}: IPlanStatusAlertProps) => {
	const warningType = getWarningType(
		restrictedPlanExists,
		unrestrictedPlanExists,
	);
	if (isLoading || !warningType) {
		return <LoadingSpinner />;
	}
	const warning = warnings[warningType];
	const { message, status, title } = warning;
	return (
		<AlertComponent
			status={status}
			title={title}
			description={message}
			hasCloseButton
			mb={'0px'}
		/>
	);
};
