import { useQuery } from '@tanstack/react-query';
import { exportCommercialHierarchy } from 'services/api/requests/commercial';

export const useExportCommercialHierarchy = (cicloId: string) => {
	return useQuery(['export-hierarchy', cicloId], exportCommercialHierarchy, {
		refetchOnWindowFocus: false,
		retry: 1,
		enabled: false,
	});
};
