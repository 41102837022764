import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Text,
} from '@chakra-ui/react';
import { numberToLocaleString } from 'utils/functions/number';
import { generateInitialConfigToMultipleChartData } from 'utils/functions/charts';

import { LineBarChart } from 'components/chart/LineBarChart';

import { useAcuracidade } from '../context';
import { AccuracyMessages } from '../variables/accuracyMessages';

const AbaAnaliseGrafica = () => {
	const {
		lastSelectedDate,
		accuracyDataGraphicalAnalysis: data,
		filters,
	} = useAcuracidade();

	const keyAumentoEstoque = 'aumento_estoque';
	const keyReducaoEstoque = 'reducao_estoque';
	const keyAcuracidadeOperacional = 'percentual_indicador';

	const [apexChart, setApexChart] = useState<any>({});

	const getIndicatorLegend = useCallback(() => {
		switch (data?.tipo_indicador) {
			case 'mape':
				return {
					key: 'mape',
					label: 'MAPE',
				};
			case 'acuracidade_comercial':
				return {
					key: 'acuracidade_comercial',
					label: 'Acuracidade Comercial',
				};
			default:
				return {
					key: 'acuracidade_operacional',
					label: 'Acuracidade Operacional',
				};
		}
	}, [data?.tipo_indicador]);

	const getApexChartOptions = useCallback(() => {
		const formatterCurrency = (v) =>
			v
				? `${numberToLocaleString({
						value: v,
						maximumFractionDigits: 0,
						style: 'currency',
						currency: 'BRL',
				  })}`
				: '0';

		const formatterPercentage = (v) =>
			v
				? `${numberToLocaleString({
						value: v,
						maximumFractionDigits: 1,
				  })}%`
				: '0%';

		const axisBorder = {
			show: true,
			color: '#A3AED0',
		};

		const config = generateInitialConfigToMultipleChartData({
			colors: ['#f9465e', '#4e637b', '#B01A2E'],
			xAxisFormatter: (v) => v?.format?.('MMM/YYYY')?.toUpperCase?.(),
			series: [
				{
					show: true,
					seriesName: 'Aumento de Estoque',
					formatter: formatterCurrency,
					showTitle: false,
					axisBorder,
				},
				{
					show: false,
					seriesName: 'Redução de Estoque',
					formatter: formatterCurrency,
				},
				{
					show: true,
					seriesName: getIndicatorLegend()?.label,
					formatter: formatterPercentage,
					axisBorder,
					opposite: true,
				},
			],
		});

		config.stroke = {
			...config.stroke,
			width: [null, null, 2],
			dashArray: [null, null, 10],
		};

		config.legend = {
			...config.legend,
			markers: {
				width: [12, 12, 15],
				height: [12, 12, 2],
			},
		};

		const finalDate = moment(lastSelectedDate, 'YYYY-MM').startOf('month');
		const initialDate = finalDate.clone().subtract(11, 'months');

		const noDataText = filters?.tipo_previsao?.includes('colab')
			? AccuracyMessages.MSG_NO_STOCK_REDUCTION_OR_INCREASE_FOR_COLLABORATION
			: `	Não há dados de Acuracidade para o período de ${initialDate.format(
					'MMM/YYYY',
			  )} a ${finalDate.format('MMM/YYYY')}.`;

		config.noData = {
			text: noDataText,
			align: 'center',
			verticalAlign: 'middle',
			offsetX: 0,
			offsetY: 0,
			style: {
				color: undefined,
				fontSize: '20px',
				fontFamily: undefined,
			},
		};

		return config;
	}, [getIndicatorLegend, lastSelectedDate, filters?.tipo_previsao]);

	const createInitialApexChartSeries = useCallback(() => {
		return {
			[keyAumentoEstoque]: {
				name: 'Aumento de Estoque',
				type: 'column',
				data: [] as Array<number>,
			},
			[keyReducaoEstoque]: {
				name: 'Redução de Estoque',
				type: 'column',
				data: [] as Array<number>,
			},
			[getIndicatorLegend()?.key]: {
				name: getIndicatorLegend()?.label,
				type: 'line',
				data: [] as Array<number>,
			},
		};
	}, [getIndicatorLegend]);

	const buildSeries = useCallback(({ ...props }) => {
		const { key, chart, serie, el } = props;

		serie[keyAumentoEstoque]?.data?.push(el[keyAumentoEstoque] || 0);

		serie[keyReducaoEstoque]?.data?.push(el[keyReducaoEstoque] || 0);

		serie[key]?.data?.push(el[keyAcuracidadeOperacional] || 0);

		chart.options?.xaxis?.categories?.push(el?.data);

		return serie;
	}, []);

	useEffect(() => {
		const hasValidData =
			!!data?.values?.length &&
			data?.values?.some(
				(d) =>
					!!d[keyAumentoEstoque] ||
					!!d[keyReducaoEstoque] ||
					!!d[keyAcuracidadeOperacional],
			);

		if (hasValidData) {
			const chart = {
				options: { ...getApexChartOptions() },
				series: [] as Array<any>,
			};

			const indicatorLegend = getIndicatorLegend();

			const series = data?.values
				?.map((d) => ({
					...d,
					data: moment(d?.data),
				}))
				.reduce(
					(serie, el) =>
						buildSeries({
							key: indicatorLegend?.key,
							chart,
							serie,
							el,
						}),
					createInitialApexChartSeries(),
				);

			chart.series = Object.values(series || {});

			const maxYaxisValue = Math.max(
				...chart.series[0]?.data,
				...chart.series[1]?.data,
			);

			chart.options.yaxis[0].max = maxYaxisValue;
			chart.options.yaxis[1].max = maxYaxisValue;

			setApexChart(chart);
		} else {
			setApexChart({
				options: { ...getApexChartOptions() },
				series: [],
			});
		}
	}, [
		buildSeries,
		createInitialApexChartSeries,
		getApexChartOptions,
		data,
		getIndicatorLegend,
	]);

	const renderLegendHelp = useCallback(() => {
		return (
			<Accordion allowMultiple>
				<AccordionItem>
					<AccordionButton
						_focus={{
							outline: '0 !important',
						}}
						bg='#f5f5f5'
					>
						<Box
							as='span'
							flex='1'
							textAlign='left'
							color='easyBLUE.300'
						>
							Informações adicionais
						</Box>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel
						pb={4}
						color='easyBLUE.300'
					>
						<Flex
							direction='column'
							gap={2}
							fontSize='smaller'
						>
							<Flex
								direction='row'
								alignItems='center'
								gap={2}
							>
								<Flex
									w='12px'
									h='12px'
									style={{
										borderRadius: '50px',
										backgroundColor: '#f9465e',
									}}
								/>
								<Text>
									{AccuracyMessages.MSG_STOCK_REDUCTION}
								</Text>
							</Flex>
							<Flex
								direction='row'
								alignItems='center'
								gap={2}
							>
								<Flex
									w='12px'
									h='12px'
									style={{
										borderRadius: '50px',
										backgroundColor: '#4e637b',
									}}
								/>
								<Text>
									{AccuracyMessages.MSG_STOCK_INCREASE}
								</Text>
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		);
	}, []);

	return (
		<>
			<LineBarChart
				chartOptions={apexChart?.options}
				chartData={apexChart?.series}
			/>
			{renderLegendHelp()}
		</>
	);
};

export default AbaAnaliseGrafica;
