import { Flex, FormControl, InputGroup } from '@chakra-ui/react';
import { IconUser, IconUsers } from '@tabler/icons';
import { useCallback } from 'react';
import { InputFormLabel } from 'components/fields/InputFormLabel';
import { FilterOption, useContextColaboracaoConsenso } from '../../context';
import InputSearch from './InputSearch';
import { useGetCustomerToCollaborationConsensusByNameOrCnpjAsMutate } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useGetCustomerToCollaborationConsensusByNameOrCnpjAsMutate';
import { IAutocomplete } from 'utils/forms/autocomplete';

const InputClients = (page): JSX.Element => {
	const {
		valuesFilter,
		setValuesFilter,
		salesChannelId,
		companyAreaId,
		cycleId,
	} = useContextColaboracaoConsenso();
	const handleChangeCliente = (name: string, data: any) => {
		if (data) {
			setValuesFilter((prevFilters) => {
				return {
					...prevFilters,
					[name]: {
						...prevFilters[name],
						label: data?.label || '',
						value: data?.value,
						total: data?.total,
					},
				};
			});
		}
	};

	const getAutocompleteIcon = useCallback((data: any) => {
		const { total } = data;
		if (total > 1)
			return (
				<IconUsers
					color='green'
					width='16'
					height='16'
				/>
			);

		if (total === 1)
			return (
				<IconUser
					color='green'
					width='16'
					height='16'
				/>
			);

		return null;
	}, []);

	const name = 'cliente';

	const extraRequestData = {
		ciclo_id: cycleId,
		area_empresa_id: companyAreaId ? Number(companyAreaId) : '',
		canal_venda_id: salesChannelId,
		apenas_clientes_que_possuem_relacionamento: true,
	};

	return (
		<Flex>
			<FormControl
				mr={5}
				minW={'500px'}
				maxW={'500px'}
			>
				<InputFormLabel
					fontSize='0.875rem'
					label={'Clientes'}
					labelTooltip='O filtro altera os valores exibidos nos gráficos de histórico e estatísticas.'
					pb='6px'
				/>
				<InputGroup h='50px'>
					<InputSearch<FilterOption, IAutocomplete>
						inputProps={{
							getIcon: getAutocompleteIcon,
							name,
							onChange: handleChangeCliente,
							value: valuesFilter.cliente,
							renderInputLabel: (value) => value?.label ?? '',
							disabled: false,
							dataTest: `autocomplete-filtro_cliente-main-page-${page.page}`,
						}}
						searchType={name}
						extraRequestData={extraRequestData}
						useGetRequest={
							useGetCustomerToCollaborationConsensusByNameOrCnpjAsMutate
						}
						onClean={() =>
							setValuesFilter((prevFilters) => {
								return {
									...prevFilters,
									[name]: undefined,
								};
							})
						}
					/>
				</InputGroup>
			</FormControl>
		</Flex>
	);
};

export default InputClients;
