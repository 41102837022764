import { Flex, Icon, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { OnSortChangeI } from './agGrid.type';

interface CustomHeaderProps {
	onSortChange: (p: OnSortChangeI) => void;
	colId: string;
	headerName: string;
}

export const CustomHeader = ({
	colId,
	headerName,
	onSortChange,
}: CustomHeaderProps) => {
	const [sort, setSort] = useState<'asc' | 'desc' | null>(null);
	const handleSortNext = {
		asc: 'desc',
		desc: 'none',
		none: 'asc',
	};
	const handleSortValue = {
		asc: 'asc',
		desc: 'desc',
		none: null,
	};
	const handleSort = () => {
		const newSortKey = handleSortNext[sort || 'none'];
		const newSort = handleSortValue[newSortKey || 'none'];
		setSort(newSort);
		onSortChange({
			colId,
			sort: newSort,
		});
	};

	return (
		<Flex
			onClick={handleSort}
			style={{ cursor: 'pointer' }}
			display={'flex'}
			alignItems={'center'}
			justifyContent={'space-between'}
			flexDirection={'row'}
			position={'relative'}
		>
			<Text
				ml={0}
				fontSize={'sm'}
			>
				{headerName}
			</Text>
			<Flex position={'relative'}>
				<>
					<Icon
						position={'absolute'}
						as={FaSort}
						color={'gray.400'}
					/>
					<Icon
						color={sort ? 'black' : 'transparent'}
						as={sort === 'asc' ? FaSortUp : FaSortDown}
					/>
				</>
			</Flex>
		</Flex>
	);
};
