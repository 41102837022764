
import { Flex, FormControl, FormLabel, Tooltip } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { IconFilter } from '@tabler/icons';

import {
	defaultInputStyle,
	defaultTooltipProps,
} from 'utils/forms/defaultsProps';
import { useQuery } from 'services/hooks/useQuery';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

import Card from 'components/card/Card';
import GenericInput from 'components/inputs/GenericInput';
import { LoadingSpinner } from 'components/loading/Loading';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { InputFormLabel } from 'components/fields/InputFormLabel';
import { ScrollBar } from 'components/scroolbar/Scrollbar';
import useSidebarHelp from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import AbasAcuracidade from './components/abasAcuracidade';
import { renderHelpContent } from './components/helpContent';
import './components/Acuracidade.css';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';

import AcuracidadeProvider, { useAcuracidade } from './context';
import { AccuracyMessages } from './variables/accuracyMessages';
import { DISABLED_FORECAST_TYPES } from './variables/accuracyVariables';

const AcuracidadeComponent = () => {
	const { setters } = useSidebarHelp();

	const { setDataSidebar } = setters;

	const {
		filters,
		setFilters,
		selectedTab,
		setLastSelectedDate,
		setCanShowAddtionalInfo,
		tableFilters,
		setFilteredDates,
		salesChannelData,
		isLoadingSalesChannelData,
		accuracyFilters,
		isLoadingAccuracyFilters,
		mutateAccuracyFilters,
		isLoadingAccuracyDataControl,
		mutateAccuracyDataControl,
		isLoadingAccuracyDataGraphicalAnalysis,
		mutateAccuracyDataGraphicalAnalysis,
		isLoadingAccuracyDataTable,
		mutateAccuracyDataTable,
		accuracyDataTable,
		getInitialPrevisionType,
	} = useAcuracidade();

	const query = useQuery();
	const areaColaboracao = query.get('areaColaboracao');

	const [hasAccuracy, setHasAccuracy] = useState<boolean>(false);
	const [isFirstRender, setFirstRender] = useState<boolean>(true);

	const forecastType = useMemo(
		() => [
			{
				label: 'Colab. Marketing',
				value: 'colab_marketing',
			},
			{
				label: 'Colab. Planejamento',
				value: 'colab_planejamento',
			},
			{
				label: 'Colab. Comercial',
				value: 'colab_comercial',
			},
			{
				label: 'Plano Irrestrito',
				value: 'consenso',
			},
			{
				label: 'Plano Restrito',
				value: 'restrito',
			},
		],
		[],
	);

	const indicatorType = useMemo(
		() => [
			{
				label: 'Acuracidade Operacional',
				value: 'acuracidade_operacional',
			},
			{
				label: 'Acuracidade Comercial',
				value: 'acuracidade_comercial',
			},
			{
				label: 'MAPE',
				value: 'mape',
			},
		],
		[],
	);

	const selectPrevisionTypes = useMemo(() => {
		return forecastType
			.filter(
				(type) =>
					!(
						selectedTab === 'analise_grafica' &&
						DISABLED_FORECAST_TYPES.includes(type.value)
					),
			)
			.map((type) => {
				return (
					<option
						key={type.value}
						value={type.value}
					>
						{type.label}
					</option>
				);
			});
	}, [forecastType, selectedTab]);

	const selectIndicatorTypes = useMemo(() => {
		return indicatorType?.map((type) => {
			return (
				<option
					value={type?.value}
					key={type?.value}
				>
					{type?.label}
				</option>
			);
		});
	}, [indicatorType]);

	const selectSalesChannel = useMemo(() => {
		return (
			salesChannelData?.map((channel) => (
				<option
					value={channel?.id}
					key={channel?.id}
				>
					{channel?.nome_canal_venda}
				</option>
			)) || []
		);
	}, [salesChannelData]);

	const filterPriority = useMemo(
		() => [
			'consenso',
			'restrito',
			'colab_comercial',
			'colab_planejamento',
			'colab_marketing',
		],
		[],
	);

	const showWarningExistsChannel = useMemo(
		() =>
			accuracyDataTable &&
			!accuracyDataTable?.exists_channel &&
			filters.canal_venda,
		[accuracyDataTable],
	);

	const helpSales: SidebarHelp = useMemo(
		() => ({
			title: 'Acuracidade',
			firstParagraph: '',
			renderContentAsJSX: true,
			helpContentJSX: renderHelpContent(),
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	function handleOnChange(name: string, event) {
		let value = event?.target?.value;

		const newFilters = {
			...filters,
			[name]: value,
		};
		setFilters(newFilters);
	}

	function renderSelectInput(
		name: string,
		selectOptions: any[],
		label: string,
		labelTooltip: string,
		dataTestKey: string,
	) {
		return (
			<FormControl mr={5}>
				{!!labelTooltip ? (
					<InputFormLabel
						label={label}
						labelTooltip={labelTooltip}
						fontSize='sm'
						fontWeight={500}
						color='easyBLUE.300'
					/>
				) : (
					<FormLabel
						fontSize='sm'
						fontWeight={500}
						color='easyBLUE.300'
					>
						{label}
					</FormLabel>
				)}

				<GenericInput
					type='select'
					inputProps={{
						...(name === 'canal_venda'
							? { placeholder: 'Selecione' }
							: undefined),
						mt: '8px',
						name,
						selectOptions,
						value: filters[name],
						onChange: (e) => handleOnChange(name, e),
						disabled: !hasAccuracy,
						...defaultInputStyle,
						'data-test': dataTestKey,
					}}
				/>
			</FormControl>
		);
	}

	function renderDateInput(name: string, label: string, dataTestKey: string) {
		return (
			<FormControl mr={5}>
				<FormLabel
					fontSize={'sm'}
					fontWeight={500}
					color={'easyBLUE.300'}
				>
					{label}
				</FormLabel>
				<GenericInput
					type='month'
					inputProps={{
						name,
						value: filters[name],
						onChange: (e) => handleOnChange(name, e),
						max: moment()
							.startOf('day')
							.add({ year: 1 })
							.format('YYYY-MM'),
						...defaultInputStyle,
						'data-test': dataTestKey,
					}}
				/>
			</FormControl>
		);
	}

	function applyFilter() {
		setLastSelectedDate(filters?.intervalo || '');
		setCanShowAddtionalInfo(
			!filters?.tipo_previsao?.includes('colab') && !filters?.canal_venda,
		);

		const start = moment(filters?.intervalo, 'YYYY-MM')?.startOf('month');
		const end = moment(filters?.intervalo, 'YYYY-MM')?.endOf('month');

		setFilteredDates({
			data_inicio: start?.format('YYYY-MM-DD'),
			data_fim: end?.format('YYYY-MM-DD'),
		});

		mutateAccuracyFilters?.();
	}

	function renderButtons() {
		return (
			<TooltipStandard
				label='Filtrar'
				{...defaultTooltipProps}
			>
				<span>
					<ButtonComponent
						type='icon'
						icon={<IconFilter />}
						onClick={applyFilter}
						mt='30px'
						data-test='button-filtrar_acuracidade'
					/>
				</span>
			</TooltipStandard>
		);
	}

	function renderFilter() {
		return (
			<Flex direction={'column'}>
				<Flex
					direction='row'
					gap='3'
					mb='35px'
				>
					{renderDateInput(
						'intervalo',
						'Mês/Ano',
						'input-date_mes-ano_acuracidade',
					)}
					{renderSelectInput(
						'tipo_previsao',
						selectPrevisionTypes,
						'Tipo de Previsão',
						selectedTab === 'analise_grafica'
							? AccuracyMessages.MSG_NO_STOCK_REDUCTION_OR_INCREASE_FOR_COLLABORATION
							: '',
						'select_tipo-previsao_acuracidade',
					)}
					{selectedTab === 'controle_acuracidade'
						? renderSelectInput(
								'canal_venda',
								selectSalesChannel,
								'Canal de Venda',
								'',
								'select_canal-venda_acuracidade',
						  )
						: null}
					{selectedTab === 'analise_grafica'
						? renderSelectInput(
								'tipo_indicador',
								selectIndicatorTypes,
								'Indicador',
								'',
								'select_analise-grafica_acuracidade',
						  )
						: null}
					{renderButtons()}
				</Flex>
				{showWarningExistsChannel && (
					<AlertComponent
						title={'Atenção'}
						description={
							AccuracyMessages.MSG_SALES_CHANNEL_NOT_INCLUDED_IN_ALLOCATION
						}
						status={'warning'}
						hasCloseButton
					/>
				)}
			</Flex>
		);
	}

	function renderCardFilter() {
		if (isLoadingSalesChannelData) return null;

		return (
			<Card
				px='20px'
				py='20px'
			>
				{renderFilter()}
			</Card>
		);
	}

	function renderCardTable() {
		if (
			isLoadingAccuracyFilters ||
			isLoadingAccuracyDataTable ||
			isLoadingAccuracyDataControl ||
			isLoadingAccuracyDataGraphicalAnalysis ||
			isLoadingSalesChannelData
		)
			return <LoadingSpinner h='50vh' />;

		if (!hasAccuracy)
			return (
				<AlertComponent
					title='Atenção'
					status='info'
					description={
						AccuracyMessages.MSG_ERROR_NO_DATA_FOR_SELECTED_FORECAST
					}
					hasCloseButton
				/>
			);

		return (
			<Card
				px='20px'
				py='20px'
			>
				<ScrollBar color='secondary'>
					<AbasAcuracidade />
				</ScrollBar>
			</Card>
		);
	}

	useEffect(() => {
		setDataSidebar(helpSales);
	}, [helpSales, setDataSidebar]);

	useEffect(() => {
		setTimeout(() => mutateAccuracyDataTable?.(), 350);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilters]);

	useEffect(() => {
		if (accuracyFilters?.demand_types?.length) {
			setHasAccuracy(true);

			if (isFirstRender && areaColaboracao) {
				setFilters((prev) => ({
					...prev,
					tipo_previsao: getInitialPrevisionType(areaColaboracao),
				}));

				setTimeout(() => mutateAccuracyDataTable?.(), 350);
				setTimeout(() => mutateAccuracyDataControl?.(), 350);
				setTimeout(() => mutateAccuracyDataGraphicalAnalysis?.(), 350);
			} else if (isFirstRender) {
				const getDemandType = async () => {
					for (const type of filterPriority) {
						const demand = accuracyFilters?.demand_types?.find(
							(el) => el?.key === type,
						);

						if (demand) return demand;
					}
					return { key: 'consenso' };
				};

				getDemandType()
					?.then((demandType) => {
						setFilters((prev) => ({
							...prev,
							tipo_previsao: demandType?.key,
						}));

						setTimeout(() => mutateAccuracyDataTable?.(), 350);
						setTimeout(() => mutateAccuracyDataControl?.(), 350);
						setTimeout(
							() => mutateAccuracyDataGraphicalAnalysis?.(),
							350,
						);
					})
					?.catch(() => {
						setHasAccuracy(false);
					});
			} else {
				setTimeout(() => mutateAccuracyDataTable?.(), 350);
				setTimeout(() => mutateAccuracyDataControl?.(), 350);
				setTimeout(() => mutateAccuracyDataGraphicalAnalysis?.(), 350);
			}
			setFirstRender(false);
		} else {
			setHasAccuracy(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		accuracyFilters?.demand_types,
		areaColaboracao,
		filterPriority,
		isFirstRender,
	]);

	useEffect(() => {
		mutateAccuracyFilters?.();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
			gap='5'
		>
			{renderCardFilter()}
			{renderCardTable()}
		</Flex>
	);
};

const Acuracidade = () => {
	return (
		<AcuracidadeProvider>
			<AcuracidadeComponent />
		</AcuracidadeProvider>
	);
};

export default Acuracidade;
