import ColumsEditOrColab from '../components/RowEditOrColab';
// ■ SelectColumnFilter
import { SelectColumnFilter } from 'components/dataTables/FilterTypes/SelectColumnFilter';

export const columnsDataColaboracao = [
	{
		Header: 'Ano',
		accessor: 'ano',
		type: 'text',
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: 'Ciclo',
		accessor: 'ciclo',
		disableSortBy: true,
		type: 'text',
		Filter: SelectColumnFilter,
		filter: 'equals',
	},

	{
		Header: 'Colaborador',
		accessor: 'colaborador',
		type: 'relation',
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: 'Status',
		accessor: 'status',
		type: 'badge',
		badgeTrueText: 'Completa',
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: '',
		accessor: 'button',
		type: 'jsx',
		jsx: ColumsEditOrColab,
		filter: false,
	},
];
