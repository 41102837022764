import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createForecast, ForecastBody } from 'services/api/requests/forecast';
import { useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

export const useCreateForecast = (body: ForecastBody) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();
	return useMutation(() => createForecast(body), {
		onSuccess: () => {
			queryClient.invalidateQueries(['forecastDemanda-list']);
			history.goBack();
			toast({
				title: 'Criada com sucesso!',
				description: 'Forecast criada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			if (error.response.data.length > 0) {
				for (let i = 0; i < error.response.data.length; i++) {
					if (error.response.data[i].path === 'sequencia_codigo') {
						toast({
							title: 'Erro ao criar forecast!',
							description: error.response.data[i].message,
							position: 'bottom-left',
							status: 'error',
							duration: 8000,
							isClosable: true,
						});
						return;
					}
				}
				toast({
					title: 'Erro ao criar forecast!',
					description: error.response.data[0].message,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else {
				toast({
					title: 'Erro ao criar forecast!',
					description:
						'Verifique se os campos estão preenchidos corretamente. Todos os campos são de preenchimento obrigatório.',
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			}
		},
	});
};
