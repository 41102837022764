import { QueryFunctionContext } from '@tanstack/react-query';
import api from '../api';

export const exportUnrestrictedPlan = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, ciclo_id] = queryKey;
	const response = await api.get(`/consenso/exportCSV?ciclo_id=${ciclo_id}`);
	return response.data;
};

export const getSalesChannelUnrestrictedPlanByCycle = async (payload) => {
	const response = await api.get('/consenso/canaisPlanoIrrestrito/paginado', {
		params: { ...payload },
	});
	return response.data;
};

export const getFamiliesUnrestrictedPlanByCycle = async (payload) => {
	const response = await api.get(
		'/consenso/familiasPlanoIrrestrito/paginado',
		{
			params: { ...payload },
		},
	);
	return response.data;
};

export const getProductsUnrestrictedPlanByCycle = async (payload) => {
	const response = await api.get(
		'/consenso/produtosPlanoIrrestrito/paginado',
		{
			params: { ...payload },
		},
	);
	return response.data;
};
export const checkExistsUnrestrictedPlan = async ({
	queryKey,
}): Promise<boolean> => {
	const [, cycleId] = queryKey;

	const response = await api.get('/consenso/existsUnrestrictedPlanByCycle', {
		params: { ciclo_id: cycleId },
	});
	return response.data;
};
