import { currencyToNumber } from 'utils/functions/currencyToNumber';
import {
	columnFlexProps,
	defaultFormStyle,
	defaultInputStyle,
} from '../../utils/forms/defaultsProps';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { LoadingSpinner } from '../loading/Loading';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import {
	renderClient,
	renderFamily,
	renderProduct,
} from 'utils/forms/autocomplete';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateOpportunity } from 'services/queryClient/wrapperHooks/opportunity/useCreateOpportunity';
import { useEditOpportunity } from 'services/queryClient/wrapperHooks/opportunity/useEditOpportunity';
import { useHistory } from 'react-router-dom';
import {
	validateByRegex,
	validateMin,
	validateMinDecimal,
	validateRequired,
} from '../../utils/forms/validations';
import { useSalesChannelList } from 'services/queryClient/wrapperHooks/useSalesChannelList';
import { getMembers } from 'services/api/requests/requests';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useGetCheckTableDataWithFilters } from 'services/queryClient/wrapperHooks/genericTables/useGetCheckTableDataWithFilters';
import { requestParseTime } from 'components/filter/utils/requestParseTime';
import { SelectedFilterBody } from 'components/filter/models/TableFilterModels';

interface IFormCadastrarEditarOportunidade {
	initialState: any;
	opportunityId?: number;
	autocompleteAdditionalData?: any;
}

interface Cycle {
	id: number;
	mes_ano: string;
	ciclo: number;
	ano: number;
	data_inicio: string;
}

const FormCadastrarEditarOportunidade = ({
	initialState,
	opportunityId = 0,
	autocompleteAdditionalData,
}: IFormCadastrarEditarOportunidade) => {
	const history = useHistory();

	const isEditing = !!opportunityId;

	const { data: SalesChannelData, isLoading: isLoadingSalesChannelData } =
		useSalesChannelList();

	const [formInitialState, setFormInitialState] = useState<any>();

	const [salesChannelId, setSalesChannelId] = useState<number>();
	const [familyId, setFamilyId] = useState<number>();
	const [payload, setPayload] = useState<any>();
	const [cycleFilter, setCycleFilter] = useState<SelectedFilterBody[] | []>([
		{
			nome_coluna: 'mes_ano',
			tipo_valor: 'string',
			valores: moment().format('MM/YYYY'),
			operacao: 'mes_ano',
			prioritario: true,
		},
	]);

	const filterBySalesChannel = isEditing ? false : true;

	const { data: MembersData, isLoading: isLoadingMemberData } = useQuery(
		['member-list-filteredSales', filterBySalesChannel, salesChannelId],
		getMembers,
		{
			// Propriedade de opção para no caso de true, atualizar a página sempre que o usuário troca de aba e/ou colocar a tela em foco.
			refetchOnWindowFocus: false,
		},
	);

	const { data: dataCheckTableForCycle, isLoading } =
		useGetCheckTableDataWithFilters({
			module: 'ciclo',
			page: 0,
			size: 1000,
			filters: requestParseTime(cycleFilter),
		});

	const { mutate: createOpportunity } = useCreateOpportunity(payload);
	const { mutate: updateOpportunity } = useEditOpportunity(
		payload,
		opportunityId,
	);

	useEffect(() => {
		setSalesChannelId(initialState?.canal_venda_id);
	}, [initialState]);

	useEffect(() => {
		setFamilyId(initialState?.familia_id);
	}, [initialState]);

	useEffect(() => {
		setFormInitialState(initialState);
	}, [initialState]);

	useEffect(() => {
		if (!isEditing) {
			setFormInitialState({
				...initialState,
				ciclo_id: dataCheckTableForCycle?.dados[0]?.id || null,
			});
		}
	}, [initialState, dataCheckTableForCycle?.dados, isEditing]);

	const findMemberById = (membersData, memberId) => {
		return membersData.find((member) => member?.id === memberId);
	};

	useEffect(() => {
		if (MembersData) {
			const matchingMember = findMemberById(
				MembersData?.data?.rows,
				initialState?.membro_id,
			);
			if (matchingMember) {
				setMemberBelongsToChannel(matchingMember.membro_pertence_canal);
			}
		}
	}, [MembersData]);

	const goToOpportunities = useCallback(() => {
		history.goBack();
	}, [history]);

	const salesChannelOptions = useMemo(() => {
		return (
			SalesChannelData?.map((channel) => {
				return (
					<option value={channel?.id}>
						{channel?.nome_canal_venda}
					</option>
				);
			}) || []
		);
	}, [SalesChannelData]);

	const memberOptions = useMemo(() => {
		return (
			MembersData?.data?.rows.map((member) => (
				<option value={member?.id}>{member?.nome_membro}</option>
			)) || []
		);
	}, [MembersData, salesChannelId]);

	useEffect(() => {
		if (isEditing && formInitialState?.ciclo_id) {
			setCycleFilter([
				{
					nome_coluna: 'id',
					tipo_valor: 'number',
					valores: formInitialState?.ciclo_id,
					operacao: 'equal',
					prioritario: true,
				},
				{
					nome_coluna: 'mes_ano',
					tipo_valor: 'string',
					valores: moment().format('MM/YYYY'),
					operacao: 'mes_ano',
					prioritario: true,
				},
			]);
		}
	}, [formInitialState, isEditing]);

	const cycleYearOptions = useMemo(() => {
		if (dataCheckTableForCycle?.dados) {
			return (
				dataCheckTableForCycle?.dados?.map((cycle: Cycle) => {
					return (
						<option
							key={cycle.id}
							value={cycle.id}
						>
							{cycle.mes_ano}
						</option>
					);
				}) || null
			);
		}
	}, [dataCheckTableForCycle, formInitialState, isEditing]);

	const [memberBelongsToChannel, setMemberBelongsToChannel] = useState<
		null | boolean
	>(true);

	const handleOnChangeCode = useCallback((event, form) => {
		form.setValue('sequencia_codigo', '');
	}, []);

	const handleOnChangeSalesChannel = useCallback((event) => {
		const valueSelected = Number(event.target.value);
		setSalesChannelId(valueSelected);
	}, []);

	const handleOnChangeMember = useCallback(
		(event) => {
			const memberId = event?.target.value;

			const matchingMember = findMemberById(
				MembersData?.data?.rows,
				memberId,
			);

			if (matchingMember) {
				setMemberBelongsToChannel(matchingMember.membro_pertence_canal);
			}

			if (!memberId) {
				setMemberBelongsToChannel(true);
			}
		},
		[MembersData],
	);

	const handleOnChangeFamily = useCallback((value) => {
		setFamilyId(value?.id);
	}, []);

	const isDisabledProductAutocomplete = useCallback(() => {
		return !familyId;
	}, [familyId]);

	const isDisabledMemberSelect = useCallback(() => {
		return !salesChannelId;
	}, [salesChannelId]);

	const shouldClearAutocompleteProduct = useCallback(() => {
		if (!familyId) return false;

		return familyId !== autocompleteAdditionalData?.product?.familia?.id;
	}, [familyId, autocompleteAdditionalData?.product?.familia?.id]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				data: values?.data,
				ciclo_id: Number(values?.ciclo_id),
				codigo: values?.codigo,
				sequencia_codigo: values?.sequencia_codigo,
				cliente_id: values?.cliente_id,
				cnpj_cpf: values?.cnpj_cpf,
				familia_id: values?.familia_id,
				produto_id: values?.produto_id,
				codigo_produto: values?.codigo_produto,
				quantidade: currencyToNumber(values?.quantidade),
				canal_venda_id: Number(values?.canal_venda_id),
				membro_id: values.membro_id,
				receita_bruta: currencyToNumber(values?.receita_bruta),
			};

			setPayload(body);

			if (isEditing) updateOpportunity();
			else createOpportunity();
		},
		[createOpportunity, isEditing, updateOpportunity],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps: {
					flexDirection: 'row',
					width: '100%',
				},
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'codigo',
							label: 'Código da Oportunidade',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
							inputProps: {
								'data-test':
									'input-codigo_da_oportunidade-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
								onChange: handleOnChangeCode,
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'number',
							name: 'sequencia_codigo',
							label: 'Sequência do Cód. da Oportunidade',
							validate: {
								...validateMin(
									1,
									'O valor deve ser maior ou igual a 1!',
								),
								...validateByRegex(
									/^[1-9]\d*$/,
									'O valor deve ser um número inteiro válido!',
								),
							},
							inputProps: {
								'data-test':
									'input-sequencia_do_cod_da_oportunidade-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'date',
							name: 'data',
							label: 'Competência',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-competencia-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'ciclo_id',
							label: 'Ciclo/Ano',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'select-ciclo_ano-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
								selectOptions: cycleYearOptions,
								disabled: !isEditing,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'cliente_id',
							label: 'Cliente',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'autocomplete-cliente-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
								searchType: 'cliente',
								renderInputLabel: renderClient,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.client
									? renderClient(
											autocompleteAdditionalData?.client,
									  )
									: '',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'familia_id',
							label: 'Família',
							inputProps: {
								'data-test':
									'autocomplete-familia-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
								searchType: 'familia',
								renderInputLabel: renderFamily,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.family
									? renderFamily(
											autocompleteAdditionalData?.family,
									  )
									: '',
								onChange: handleOnChangeFamily,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'produto_id',
							label: 'Produto',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'autocomplete-produto-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
								searchType: 'produto',
								renderInputLabel: renderProduct,
								autocomplete: 'off',
								initialLabel:
									autocompleteAdditionalData?.product
										? renderProduct(
												autocompleteAdditionalData?.product,
										  )
										: '',
								extraRequestData: {
									familia_id: familyId,
								},
								clearInput: shouldClearAutocompleteProduct,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'product-quantity',
							name: 'quantidade',
							label: 'Quantidade',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								validate: (value) =>
									validateMinDecimal(
										value,
										0,
										'O valor deve ser maior ou igual a 0!',
									),
							},
							inputProps: {
								'data-test':
									'input-quantidade-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'receita_bruta',
							label: 'Receita bruta',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-receita_bruta-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'canal_venda_id',
							label: 'Canal de venda',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'select-canal_de_venda-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
								selectOptions: salesChannelOptions,
								onChange: handleOnChangeSalesChannel,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'membro_id',
							label: 'Membro',
							showRequiredOnLabel: true,
							disabled: isDisabledMemberSelect,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'select-membro-cadastrar_editar-page-oportunidades',
								...defaultInputStyle,
								selectOptions: memberOptions,
								onChange: handleOnChangeMember,
								showWarning: isEditing
									? {
											condition: !memberBelongsToChannel,
											message:
												'Este membro não pertence ao canal de venda selecionado!',
									  }
									: null,
							},
						}),
					},
				],
			},
		];
	}, [
		autocompleteAdditionalData,
		salesChannelId,
		familyId,
		handleOnChangeSalesChannel,
		handleOnChangeFamily,
		isDisabledMemberSelect,
		isDisabledProductAutocomplete,
		salesChannelOptions,
		memberOptions,
		shouldClearAutocompleteProduct,
		memberBelongsToChannel,
		isEditing,
		cycleYearOptions,
	]);

	const renderForm = useCallback(() => {
		if (isLoadingSalesChannelData) return <LoadingSpinner />;

		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-oportunidades',
						save: 'button-salvar-page-oportunidades',
					}}
					initialState={formInitialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToOpportunities}
				/>
			</>
		);
	}, [
		formInitialState,
		renderRows,
		onSubmit,
		goToOpportunities,
		isLoadingSalesChannelData,
		isLoadingMemberData,
	]);

	return renderForm();
};

export default FormCadastrarEditarOportunidade;
