import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useDisclosure,
} from '@chakra-ui/react';
import { IconDashboard } from '@tabler/icons';
import { generateRowsMonths } from 'components/dataTables/statisticTable/generateRowsMonths';
import StatisticTable, {
	ClientHover,
	RowHover,
	ValueHover,
} from 'components/dataTables/statisticTable/StatisticTable';
import { ColumnData } from 'components/dataTables/Table';
import MultiSelect from 'components/multiSelect/Multiselect';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { useGlobalContext } from 'contexts/GlobalContext';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useOpportunityByCycleList } from 'services/queryClient/wrapperHooks/useOpportunityByCycleList';
import { numberToFormattedString } from 'utils/functions/numberToFormateString';
import { useContextColaboracaoConsenso } from '../context';
import { columnsEndCadastrar } from '../variables/columnsDataCadastrar';
import { OpportunityExceptionsTableModal } from './OpportunityExceptionsTableModal';

export default function OpportunitySection() {
	const {
		cycleId,
		salesChannelId,
		familyId,
		cycle: cycleData,
		keysPage,
		familys,
		salesZoneId,
	} = useContextColaboracaoConsenso();
	const { ano: year, ciclo: cycle, duracao: cycleDuration } = cycleData;
	const { data: oportunidades } = useOpportunityByCycleList({
		cycleId,
		familyId,
		salesChannelId,
		salesZoneId,
	});

	const { isOpen, onOpen, onClose } = useDisclosure();

	const columnFirtDataColaboracaoCadastrar = {
		Header: 'CICLO',
		accessor: 'cycle',
		disableSortBy: true,
		type: 'text',
	};
	const [filterCycle, setFilterCycle] = useState<number[]>([]);
	const [cyclesOportunidades, setCyclesOportunidades] = useState<RowHover[]>(
		[],
	);
	const [cycles, setCycles] = useState<number[]>([]);
	const {
		configsCompany: { precisao_decimal },
	} = useGlobalContext();
	useEffect(() => {
		if (oportunidades) {
			let newOpotunidades: RowHover[] = [];
			const newCycles: number[] = [];
			const months = [
				'JANEIRO',
				'FEVEREIRO',
				'MARÇO',
				'ABRIL',
				'MAIO',
				'JUNHO',
				'JULHO',
				'AGOSTO',
				'SETEMBRO',
				'OUTUBRO',
				'NOVEMBRO',
				'DEZEMBRO',
			];
			oportunidades.oportunidades.forEach((oportunidade) => {
				// if (!oportunidade.listByMes) return;
				const newOpotunidade: RowHover = {
					media:
						oportunidade.listByMes?.media?.toLocaleString(
							'pt-BR',
						) || '-',
					total:
						oportunidade.listByMes?.total?.toLocaleString(
							'pt-BR',
						) || '-',
					cycle: oportunidade.ciclo,
					type: 'text-hover',
				};
				for (let i = 0; i < cycleDuration; i++) {
					const date = new Date(
						+oportunidade.ano,
						+oportunidade.ciclo + i,
					);
					const mesKey = ('0' + (date.getMonth() + 1)).slice(-2);
					const yearKey = date.getFullYear().toString().slice(-2);
					const key = mesKey + '/' + yearKey;
					const cell: ValueHover = {
						value:
							oportunidade.listByMes &&
							typeof oportunidade.listByMes[key] === 'number'
								? oportunidade.listByMes[key]?.toLocaleString(
										'pt-BR',
								  )
								: '-',
						clientes: [],
						month:
							months[date.getMonth()] + '/' + date.getFullYear(),
						typeHover: 'client',
					};
					newOpotunidade[key] = cell;
				}

				oportunidade.listByClient?.forEach((oportunidade) => {
					const data = oportunidade.data;
					const partesData = data.split('-');
					const mesKey = partesData[1];
					const yearKey = partesData[0].slice(-2);
					const key2 = mesKey + '/' + yearKey;
					const newOportunidadeHover: ClientHover = newOpotunidade[
						key2
					] as ClientHover;

					if (newOportunidadeHover) {
						newOportunidadeHover.clientes.push({
							nome: oportunidade.cliente.razao_social,
							quantidade: numberToFormattedString(
								oportunidade?.quantidade,
								precisao_decimal,
							),
						});
					}
				});

				newOpotunidades.push(newOpotunidade);
				if (oportunidade.ciclo && +oportunidade.ciclo !== +cycle) {
					newCycles.push(oportunidade.ciclo);
				}
			});
			setCyclesOportunidades([...newOpotunidades]);
			setCycles([...newCycles]);
			setFilterCycle([]);

			const family = familys?.find((family) => family.value === familyId);

			if (family) {
				setFamilyName(family.label);
			}
		}
	}, [oportunidades, oportunidades?.oportunidades]);

	const [familyName, setFamilyName] = useState<string>('');

	useEffect(() => {
		const quantityCycles = 5;
		const startMonth =
			+cycle - quantityCycles < 0
				? +cycle - quantityCycles + 12
				: +cycle - quantityCycles;
		const startYear = +cycle - quantityCycles < 0 ? +year - 1 : +year;
		const collumData = generateRowsMonths({
			startMonth,
			qtdMounth: cycleDuration + quantityCycles,
			abbreviatedMonth: true,
			addYear: true,
			startYear,
			acessorIncludeYaer: true,
		});
		if (cyclesOportunidades && keysPage.page === 'consenso') {
			cyclesOportunidades.forEach((newOpotunidade) => {
				Object.keys(newOpotunidade).forEach((oppotunityKey) => {
					const oportunidadeItem = newOpotunidade[
						oppotunityKey
					] as unknown as ValueHover;

					const index = collumData.findIndex(
						(columnData) => columnData.accessor === oppotunityKey,
					);

					if (index !== -1) {
						if (
							oportunidadeItem.value !== '0' &&
							oportunidadeItem.value !== '-'
						) {
							if (cycle === newOpotunidade.cycle) {
								const Header: ColumnData = {
									Header: renderOpportunityExceptionMenu(
										collumData[index].Header,
										collumData[index].accessor,
									),
									accessor: oppotunityKey,
									type: 'jsx',
									disableSortBy: true,
									filter: false,
								};

								collumData.splice(index, 1, Header);
							}
						}
					}
				});
			});
		}
		setColumnsDataOppotunity(collumData);
	}, [cyclesOportunidades, cycleData.id]);

	const [columnsDataOppotunity, setColumnsDataOppotunity] = useState<any>([]);

	const [dateTableOportunity, setDateTableOportunity] = useState<string>('');

	const renderOpportunityExceptionMenu = useCallback(
		(header, date) => {
			const formattedDate = moment(date, 'MM/YY').format('YYYY-MM');

			const handleOnClick = () => {
				onOpen();
				setDateTableOportunity(formattedDate);
			};

			const hasException = oportunidades?.mesesComExcecao.some(
				(mes) => mes === date,
			);

			return (
				<>
					<Menu>
						<TooltipStandard
							shouldWrapChildren={true}
							label='Permite criar exceções com base nos itens da oportunidade.'
						>
							<MenuButton
								as={Button}
								rightIcon={<ChevronDownIcon />}
								bg={hasException ? '#e6faf5' : 'none'}
								color={hasException ? 'green.500' : 'none'}
								borderRadius='none'
								size='small'
								fontWeight='100'
								onFocus={(e) => e.preventDefault()}
							>
								<Flex
									alignItems='center'
									justifyContent='center'
								>
									{header}
								</Flex>
							</MenuButton>
						</TooltipStandard>
						<MenuList>
							<MenuItem onClick={handleOnClick}>
								Adicionar exceções
							</MenuItem>
						</MenuList>
					</Menu>
				</>
			);
		},
		[isOpen, onOpen, onClose, oportunidades?.mesesComExcecao],
	);

	return (
		<>
			{cyclesOportunidades && (
				<>
					<StatisticTable
						title={{
							icon: IconDashboard,
							name: 'Oportunidades',
						}}
						SectionButtons={
							<>
								<MultiSelect
									id='select-exibir-oportunidades_cycle'
									onChange={(newValue) => {
										setFilterCycle(
											newValue.map((v) =>
												Number(v.label),
											),
										);
									}}
									options={cycles.map((item) => ({
										label: item,
										value: item,
									}))}
									size={'medium'}
									placeholder='Filtrar por ciclo'
									notHideSelectedOptions
								/>
							</>
						}
						conditionalFilter={({ row }) =>
							filterCycle.includes(Number(row.cycle)) ||
							Number(row.cycle) === +cycle
						}
						columnsData={{
							header: [columnFirtDataColaboracaoCadastrar],
							body: columnsDataOppotunity,
							footer: columnsEndCadastrar,
						}}
						tableData={cyclesOportunidades}
						applyLocaleString
						dataTest='table-exibir-oportunidades_cycle'
					/>
					{keysPage.page === 'consenso' && (
						<OpportunityExceptionsTableModal
							isOpenModal={isOpen}
							onCloseModal={onClose}
							cycleId={cycleId}
							date={dateTableOportunity}
							familyId={familyId}
							familyName={familyName}
						/>
					)}
				</>
			)}
		</>
	);
}
