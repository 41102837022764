import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { editOpportunity } from 'services/api/requests/opportunity/opportunity';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useEditOpportunity = (body, id: number) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => editOpportunity(body, id), {
		onSuccess: () => {
			queryClient.invalidateQueries(['oportunidade-list']);
			queryClient.invalidateQueries(['opportunityWithId', id]);
			history.goBack();
			toast({
				title: 'Atualizada com sucesso!',
				description: 'Oportunidade atualizada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao editar Oportunidade!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
