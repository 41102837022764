import { ModulesType } from 'components/filter/models/TableFilterModels';
import api from 'services/api/api';
import apiPython from 'services/api/apiPython';
interface RequestBody {
	nome_coluna: string;
	pesquisa: string;
	size: number;
	page: number;
	[key: string]: any;
}
export const fetchByFiltersDependency = async (
	body: RequestBody,
	module: ModulesType,
) => {
	const url = `/${module}/view/filter`;
	const response = await api.post(url, body);
	return response.data;
};

export const fetchByFiltersDependencyFromExternalAPI = async (body) => {
	const url = '/accuracy/additional/filter';

	const {
		nome_coluna: column,
		page,
		size,
		pesquisa: search,
		...others
	} = body;

	const response = await apiPython.get(url, {
		params: {
			column,
			search,
			page,
			size,
			...others,
		},
	});
	return response.data;
};
