import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
	colors: {
		brand: {
			100: '#E9E3FF',
			200: '#422AFB',
			300: '#422AFB',
			400: '#7551FF',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#11047A',
		},
		easyBrandMenu: {
			100: '#FFFFFF',
			200: '#E9EDF7',
		},
		easyRED: {
			100: '#F0243E1A',
			150: '#ffe9ec',
			200: '#FC6574',
			300: '#F0243E', //easyred-figma
			400: '#B01A2E',
		},
		easyBLUE: {
			100: '#F1F3F9', //bg menu
			150: '#A3AED0',
			200: '#567EB3',
			300: '#314866', //easyblue-figma
			350: '#31486633', //easyblue-figma-alpha-channel
			400: '#25364D',
			500: '#055A7F',
			600: '#0789C2',
		},
		brandScheme: {
			100: '#E9E3FF',
			200: '#7551FF',
			300: '#7551FF',
			400: '#7551FF',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#02044A',
		},
		secondaryGray: {
			100: '#E0E5F2',
			150: '#E2E8F0',
			200: '#E1E9F8',
			250: '#FAFAFA',
			300: '#F4F7FE',
			400: '#E9EDF7',
			500: '#8F9BBA',
			600: '#A3AED0',
			700: '#707EAE',
			800: '#707EAE',
			900: '#1B2559',
		},
		red: {
			100: '#FEEFEE',
			500: '#EE5D50',
			600: '#E31A1A',
		},
		blue: {
			50: '#EFF4FB',
			500: '#3965FF',
		},
		orange: {
			100: '#FFF6DA',
			500: '#FFB547',
			700: '#9C4221',
		},
		green: {
			100: '#E6FAF5',
			200: '#1BA118',
			500: '#01B574',
		},
		navy: {
			50: '#d0dcfb',
			100: '#aac0fe',
			200: '#a3b9f8',
			300: '#728fea',
			400: '#3652ba',
			500: '#1b3bbb',
			600: '#24388a',
			700: '#1B254B',
			800: '#111c44',
			900: '#0b1437',
		},
		gray: {
			100: '#FAFCFE',
			250: '#505050',
		},
		graphic: {
			red: '#F0243E',
			lightRed: '#FC6574',
			darkBlue: '#25364D',
			blue: '#314866',
			lightBlue: '#56CCF2',
			turquoise: '#49A1B2',
			gray: '#A3AED0',
			green: '#00E396',
			pastelGreen: '#B0E57C',
			yellow: '#FFB547',
			purple: '#713C81',
			lightPurple: '#9F79EE',
		},
		tables: {
			color: {
				hover: '#42adef',
			},
			background: {
				level0: '#f6f6f8',
				hover: '#f4f7fe',
			},
		},
	},
	styles: {
		global: (props) => ({
			body: {
				overflowX: 'hidden',
				bg: mode('#fdfeff', 'navy.900')(props),
				fontFamily: 'DM Sans',
				letterSpacing: '-0.5px',
			},
			input: {
				color: 'gray.700',
			},
			html: {
				fontFamily: 'DM Sans',
			},
		}),
	},
};
