import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	editException,
	ICreateOrEditException,
} from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useEditException = (page: string) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation((b: ICreateOrEditException) => editException(page, b), {
		onSuccess: () => {
			queryClient.invalidateQueries([
				'collaboration-consensus-exception-by-family',
			]);
			toast({
				title: 'Exceção editada com sucesso!',
				status: 'success',
				duration: 8000,
				isClosable: true,
				position: 'bottom-left',
			});
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao criar exceção!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
