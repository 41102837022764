import { useCallback, useEffect, useMemo, useState } from 'react';
import GenericForm, { IFormRows } from 'components/forms/GenericForm';
import {
	defaultFormStyle,
	defaultInputStyle,
	formFlexProps,
	rowFlexProps,
} from 'utils/forms/defaultsProps';
import { useHistory } from 'react-router-dom';
import { makeGenericFormInputStructure } from 'utils/forms/defaultInputs';
import { validateRequired } from 'utils/forms/validations';
import moment from 'moment';
import { renderFamily, renderProduct } from 'utils/forms/autocomplete';
import { useCreateActualStock } from 'services/queryClient/wrapperHooks/useCreateActualStock';
import { useEditActualStock } from 'services/queryClient/wrapperHooks/useEditActualStock';
import { currencyToNumber } from 'utils/functions/currencyToNumber';

interface IFormCadastrarEstoqueAtual {
	initialState: any;
	actualStockId?: number;
	autocompleteAdditionalData?: any;
}

const FormCadastrarEditarEstoqueAtual = ({
	initialState,
	actualStockId = 0,
	autocompleteAdditionalData,
}: IFormCadastrarEstoqueAtual) => {
	const history = useHistory();

	const isEditing = !!actualStockId;

	const [familyId, setFamilyId] = useState<number>();
	const [payload, setPayload] = useState<any>();

	const { mutate: createActualStock } = useCreateActualStock(payload);
	const { mutate: updateActualStock } = useEditActualStock(
		payload,
		actualStockId,
	);

	useEffect(() => {
		setFamilyId(initialState?.familia_id);
	}, [initialState]);

	const goToEstoqueAtual = useCallback(() => history.goBack(), [history]);

	const getMaxDate = useMemo(
		() => moment().startOf('day').format('YYYY-MM-DD'),
		[],
	);

	const handleOnChangeFamily = useCallback((value) => {
		setFamilyId(value?.id);
	}, []);

	const isDisabledProductAutocomplete = useCallback(() => {
		return !familyId;
	}, [familyId]);

	const shouldClearAutocompleteProduct = useCallback(() => {
		if (!familyId) return false;

		return familyId !== autocompleteAdditionalData?.product?.familia?.id;
	}, [familyId, autocompleteAdditionalData?.product?.familia?.id]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				// Necessário enviar como datetime pois caso contrário o back devolve a data com
				// 1 dia a menos durante a edição
				data: moment(values?.data, 'YYYY-MM-DD').toISOString() || null,
				familia_id: values?.familia_id || null,
				produto_id: values?.produto_id || null,
				quantidade: values?.quantidade
					? currencyToNumber(values?.quantidade)
					: null,
			};

			setPayload(body);

			if (isEditing) updateActualStock();
			else createActualStock();
		},
		[createActualStock, isEditing, updateActualStock],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'date',
							name: 'data',
							label: 'Data',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-data-cadastrar_editar-page-estoque_atual',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'familia_id',
							label: 'Família',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'autocomplete-familia-cadastrar_editar-page-estoque_atual',
								...defaultInputStyle,
								searchType: 'familia',
								renderInputLabel: renderFamily,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.family
									? renderFamily(
											autocompleteAdditionalData?.family,
									  )
									: '',
								onChange: handleOnChangeFamily,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'produto_id',
							label: 'Produto',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							disabled: isDisabledProductAutocomplete,
							inputProps: {
								'data-test':
									'autocomplete-produto-cadastrar_editar-page-estoque_atual',
								...defaultInputStyle,
								searchType: 'produto',
								renderInputLabel: renderProduct,
								autocomplete: 'off',
								initialLabel:
									autocompleteAdditionalData?.product
										? renderProduct(
												autocompleteAdditionalData?.product,
										  )
										: '',
								extraRequestData: {
									familia_id: familyId,
								},
								clearInput: shouldClearAutocompleteProduct,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'product-quantity',
							name: 'quantidade',
							label: 'Estoque',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'input-estoque-cadastrar_editar-page-estoque_atual',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
		];
	}, [
		familyId,
		getMaxDate,
		handleOnChangeFamily,
		autocompleteAdditionalData,
		isDisabledProductAutocomplete,
		shouldClearAutocompleteProduct,
	]);

	const renderForm = useCallback(() => {
		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-estoque_atual',
						save: 'button-salvar-page-estoque_atual',
					}}
					initialState={initialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToEstoqueAtual}
				/>
			</>
		);
	}, [initialState, onSubmit, renderRows, goToEstoqueAtual]);

	return renderForm();
};

export default FormCadastrarEditarEstoqueAtual;
