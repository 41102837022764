import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { editAverageTicket } from 'services/api/requests/ticketMedio';

export const useEditAverageTicket = (body, id: number) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();
	return useMutation(() => editAverageTicket(body, id), {
		onSuccess: () => {
			queryClient.invalidateQueries(['ticketMedio-list']);
			queryClient.invalidateQueries(['averageTicketWithId', id]);
			history.goBack();
			toast({
				title: 'Atualizado com sucesso!',
				description: 'Preço Médio atualizado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			toast({
				title: 'Erro ao atualizar Preço Médio!',
				description:
					error.response.data.message[0].constraints.isNotEmpty,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
