import { editActualStock } from 'services/api/requests/stock';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

export const useEditActualStock = (body, id: number) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => editActualStock(body, id), {
		onSuccess: () => {
			queryClient.invalidateQueries(['estoque-list']);
			queryClient.invalidateQueries(['actualStockWithId', id]);
			queryClient.resetQueries(['production-dashboard-data']);
			queryClient.invalidateQueries({
				predicate: (query) => {
					return (
						query.queryKey[0] ===
						'production-dashboard-data-by-family-stock'
					);
				},
			});
			queryClient.resetQueries(['production-dashboard-filter']);
			history.goBack();
			toast({
				title: 'Atualizado com sucesso!',
				description: 'Estoque atualizado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			if (
				Array.isArray(error.response.data) &&
				error.response.data.length > 0
			) {
				toast({
					title: 'Erro ao atualizar estoque!',
					description: error.response.data[0].message,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else {
				toast({
					title: 'Erro ao atualizar estoque!',
					description:
						'Verifique se os campos estão preenchidos corretamente. Os campos Data, Produto e Estoque são de preenchimento obrigatório.',
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			}
		},
	});
};
