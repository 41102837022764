import { useQuery } from '@tanstack/react-query';
import { getSelectorsData } from 'services/api/requests/lancamentoHoraHora/register-time';
import { getStopTypology } from 'services/api/requests/production';
import { getWorkingDays } from 'services/api/requests/capacity/workingDays/workingDays';

export const useSelectorsDataList = (
	productId?,
	activityId?,
	recursoId?,
	data?,
) => {
	return useQuery(
		['selectors-data-list', productId, activityId, recursoId, data],
		getSelectorsData,
		{
			refetchOnWindowFocus: false,
		},
	);
};

export const useTypologyList = () => {
	return useQuery(['typology-list'], getStopTypology, {
		refetchOnWindowFocus: false,
	});
};

export const useWorkingDaysByYear = (year: string) => {
	return useQuery(['working-days-by-year', year], getWorkingDays, {
		refetchOnWindowFocus: false,
	});
};
