import { useMemo, useCallback, useState } from 'react';
import { Input } from '@chakra-ui/react';
import InputMask from 'react-input-mask';

interface IInputFlexibleTimeMask {
	name: string;
	value: string;
	required?: boolean;
	maskFormat?: string;
	placeholder?: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	[key: string]: any;
}

const InputFlexibleTimeMask = ({
	name,
	value,
	required = false,
	maskFormat = '00000:00:00', // Agora permite até 100000 horas
	onChange,
	...props
}: IInputFlexibleTimeMask) => {
	const [inputValue, setInputValue] = useState(value || '');

	// Define os caracteres permitidos na máscara
	const formatChars = useMemo(
		() => ({
			'0': '[0-9]', // Qualquer número de 0-9
			'1': '[0-9]', // Permite dois primeiros números livres
			'2': '[0-9]', // Permite três primeiros números livres
			'3': '[0-9]', // Permite quatro primeiros números livres
			'4': '[0-9]', // Permite até 5 dígitos livres
			'5': '[0-5]', // Minutos apenas até 59
			'6': '[0-9]', // Minutos apenas até 59
			'7': '[0-5]', // Segundos apenas até 59
			'8': '[0-9]', // Segundos apenas até 59
		}),
		[],
	);

	const handleChange = useCallback(
		(event) => {
			const newValue = event.target.value;
			setInputValue(newValue);
			onChange(event);
		},
		[onChange],
	);

	return (
		<Input
			as={InputMask}
			//	mask='00000:59:59' // Máscara dinâmica
			maskChar={null}
			name={name}
			value={inputValue}
			required={required}
			formatChars={formatChars}
			onChange={handleChange}
			placeholder='00000:00:00'
			{...props}
		/>
	);
};

export default InputFlexibleTimeMask;
