import { Flex, useDisclosure, useToast } from '@chakra-ui/react';
import {
	IconHandStop,
	IconNotebook,
	IconRefreshAlert,
	IconTargetArrow,
} from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import StatisticTable, {
	Row,
	RowInput,
} from 'components/dataTables/statisticTable/StatisticTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCollaborationConsensusByFamilyList } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/filters/useCollaborationConsensusByFamilyList';
import { useCollaborationsByFamily } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useCollaborationsByFamilyList';
import { useCreateConsensus } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useCreateConsensus';
import { useExceptionsByFamilyList } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/exception/useExceptionsByFamilyList';
import { useContextColaboracaoConsenso } from '../../templateCadastrarColabCons/context';
import { useContextConsenso } from '../context';
import { useCreateAtaConsenso } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useCreateAtaConsenso';
import { useGetAtaConsenso } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useGetAtaConsenso';
import { useCollaborationConsensuFinalize } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useCollaborationConsensuFinalize';
import { useApplyConsensusApportionment } from 'services/queryClient/wrapperHooks/apportionment/useApplyConsensusApportionment';
import { BodyCreateConsensus } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useSelectByStatistic } from '../../templateCadastrarColabCons/hooks/useSelectByStatistic';
import {
	ErrorComment,
	ModalComment,
} from '../../templateCadastrarColabCons/components/ComentarioJustificando';
import { CellArea } from './cells/CellArea';
import { CellCrescimento } from './cells/CellCrescimento';
import { LoadingSpinner } from '../../../../../components/loading/Loading';
import { currencyToNumber } from '../../../../../utils/functions/currencyToNumber';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { numberToFormattedString } from 'utils/functions/numberToFormateString';
import { numberToLocaleString } from 'utils/functions/number';
import ModalAiPredictionRequest from './ModalAiPredictionRequest';
import { generateRowsMonths } from 'components/dataTables/statisticTable/generateRowsMonths';
import SectionExceptions from '../../templateCadastrarColabCons/components/exception/SectionExceptions';
import ExportCSVButton from '../../templateCadastrarColabCons/components/exportCSV/ExportCSVButton';
import { FinishButton } from '../../templateCadastrarColabCons/components/finishButton/FinishButton';

interface ExceptionConsensus {
	id?: string;
	fixedProprities?: {
		salesChannelId: string;
		productId: string;
	};
	associatedCollaboration: boolean;
	initiIsEditing: boolean;
}
const ConsensusSection = () => {
	const { consensus, setConsensus } = useContextConsenso();
	const [exceptions, setExceptions] = useState<
		Record<string, ExceptionConsensus>
	>({});

	const {
		familyId,
		isLoadingFilters,
		salesChannelId,
		cycleId,
		cycle: cycleData,
		valuesFilter,
		averageTicket,
		keysPage,
		status,
		confidenceInterval,
	} = useContextColaboracaoConsenso();

	const { ciclo: cycle, duracao: cycleDuration, ano: yearCycle } = cycleData;

	const { mutate: createAta } = useCreateAtaConsenso();
	const { mutate: finish } = useCollaborationConsensuFinalize('consenso');

	const { data: dataAtaConsenso } = useGetAtaConsenso(cycleId, familyId);

	const [total, setTotal] = useState<number>(0);

	const { data: dataConsensusByFamily, isLoading: isLoadingConsensusData } =
		useCollaborationConsensusByFamilyList({
			page: keysPage.page,
			cycleId,
			salesChannelId,
			familyId,
		});
	const {
		data: dataCollaborationsByFamily,
		isLoading: isLoadingCollaborationByFamily,
	} = useCollaborationsByFamily({ cycleId, familyId });

	const { data: dataCollaborationExceptionByFamily } =
		useExceptionsByFamilyList({
			page: keysPage.page,
			cycleId,
			familyId,
			salesChannelId,
		});

	const { mutate } = useCreateConsensus();

	const history = useHistory();
	const { pathCompanyName } = useGlobalContext();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const [rowsColabs, setRowsColabs] = useState<Row[]>([]);
	const [extremesValues, setExtremeValues] = useState<{
		max: number;
		min: number;
		secondMax;
	}>();

	useEffect(() => {
		setAta(dataAtaConsenso?.anotacao || '');
	}, [dataAtaConsenso]);

	useEffect(() => {
		if (!!dataCollaborationsByFamily?.length) {
			setRowsColabs([]);
			setExtremeValues(undefined);

			const values: number[] = [];

			let max = Number.MIN_VALUE;
			let min = Number.MAX_VALUE;
			let secondMax;

			const newRowsColabs: Row[] = dataCollaborationsByFamily?.map(
				(colabArea) => {
					const newRows: Row = {
						media: 0,
						total: 0,
						type: 'text',
						dataTest: `text-colaboracao-${colabArea.nome_area_empresa}`,
					};

					let total = 0;
					let media = 0;

					for (const colaboracao of colabArea?.colaboracao) {
						const [, mounth] = colaboracao.mes_ano.split('-');
						newRows[+mounth] = Number(
							colaboracao?.colaboracao,
						)?.toLocaleString('pt-BR');

						total += Number(colaboracao?.colaboracao || 0);
					}

					let crescimento;
					if (+colabArea.coloboracaoTotalCicloAnterior) {
						crescimento =
							(total * 100) /
								+colabArea.coloboracaoTotalCicloAnterior -
							100;
					}

					media = total / cycleDuration;

					newRows.preenchimentoText = colabArea.nome_area_empresa;
					newRows.crescimento = crescimento || '-';

					if (colabArea.colaboracao.length >= cycleDuration) {
						total > 0 && values.push(total);
					}

					newRows.atas = colabArea.atas;

					newRows.media = numberToLocaleString({
						value: media || 0,
						maximumFractionDigits: precisao_decimal,
					});
					newRows.total = numberToLocaleString({
						value: total || 0,
						maximumFractionDigits: precisao_decimal,
					});

					return newRows;
				},
			);

			values.sort((a, b) => {
				if (a > b) return 1;
				if (b > a) return -1;
				return 0;
			});

			if (values.length > 0) {
				max = values[values.length - 1];
				min = values[0];
				if (values[0] === values[1]) {
					secondMax = values[0];
				}
				if (values.length > 2) {
					if (values[0] === values[1] || values[0] === values[2]) {
						secondMax = values[0];
					} else if (values[1] === values[2]) {
						secondMax = values[2];
					}
				}
			}

			setRowsColabs(newRowsColabs);
			setExtremeValues({
				max,
				secondMax,
				min,
			});
		}
	}, [dataCollaborationsByFamily, cycleDuration]);

	const columnFirtDataConsensoCadastrar = {
		Header: 'PREENCHIMENTO',
		accessor: 'preenchimentoText',
		type: 'text',
		JSX: (props, ComponentRow) =>
			CellArea(props, ComponentRow, extremesValues),
	};

	const columnsEndCadastrar = [
		{
			Header: 'MÉDIA',
			accessor: 'media',
			type: 'text',
			JSX: (props, ComponentRow) => ComponentRow,
		},
		{
			Header: 'TOTAL',
			accessor: 'total',
			type: 'text',
			JSX: (props, ComponentRow) => ComponentRow,
		},
		{
			Header: 'CRESCIMENTO',
			accessor: 'crescimento',
			type: 'growth',
			JSX: (props) => CellCrescimento(props),
		},
	];

	const updateConsensusByDatabase = () => {
		if (dataConsensusByFamily) {
			const newConsenso = {};
			let total = 0;
			for (const consensus of dataConsensusByFamily.consensus) {
				const key = +consensus.mes_ano.split('-')[1];
				newConsenso[key] = consensus.consenso;
				total += consensus.consenso;
			}

			for (let i = 0; i < cycleDuration; i++) {
				const key = new Date(1, Number(cycle) + i).getMonth() + 1;
				if (newConsenso[key] === undefined) {
					newConsenso[key] = '';
				}
			}

			setConsensus({
				...newConsenso,
			});
			setTotal(total);
		}
	};

	useEffect(() => {
		updateConsensusByDatabase();
	}, [dataConsensusByFamily]);

	const updateConsensusExceptionByDatabase = () => {
		if (dataCollaborationExceptionByFamily) {
			const newExceptions: Record<string, ExceptionConsensus> = {};
			for (
				let index = 0;
				index < dataCollaborationExceptionByFamily.length;
				index++
			) {
				const cosenso = dataCollaborationExceptionByFamily[index];
				const key = new Date(index).getTime();
				newExceptions[key.toString()] = {
					id: cosenso.id ? String(cosenso.id) : undefined,
					fixedProprities: cosenso.colaboracao_associada
						? {
								salesChannelId: String(
									cosenso.canal_venda_id || '',
								),
								productId: String(cosenso.produto_id || ''),
						  }
						: undefined,
					associatedCollaboration: !!cosenso.colaboracao_associada,
					initiIsEditing: false,
				};
			}
			setExceptions(newExceptions);
		}
	};
	useEffect(() => {
		updateConsensusExceptionByDatabase();
	}, [dataCollaborationExceptionByFamily]);

	const onChange = useCallback(
		(key: string, value: string) => {
			let total = 0;
			const newConsenso = {
				...consensus,
				[key]: value,
			};
			for (const key in newConsenso) {
				total += currencyToNumber(String(newConsenso[key])) || 0;
			}
			setConsensus({
				...newConsenso,
			});
			setTotal(total);
		},
		[consensus, setConsensus],
	);

	const errorConditional = useCallback(
		(value: number | string) => {
			return (
				Number(confidenceInterval.min) < Number(value) &&
				Number(confidenceInterval.max) > Number(value)
			);
		},
		[confidenceInterval.max, confidenceInterval.min],
	);
	const {
		configsCompany: { precisao_decimal },
	} = useGlobalContext();

	const { onChangeSelect, options, valueSelect, isLoading, extraResult } =
		useSelectByStatistic({
			cycleId,
			familyId,
			page: keysPage.page,
			cycleData,
			setTotal,
			setValues: setConsensus,
		});

	const consenso: RowInput = useMemo(() => {
		return {
			type: 'input',
			...consensus,
			media: numberToFormattedString(
				total / cycleDuration,
				precisao_decimal,
			),
			total: numberToFormattedString(total, precisao_decimal),
			preenchimento: {
				options,
				onChange: onChange,
				onChangeSelect,
				value: valueSelect,
				isLoading,
			},
			errorConditional,
			dataTest: `input-quantidade-consenso`,
		};
	}, [
		consensus,
		cycleDuration,
		errorConditional,
		isLoading,
		onChange,
		onChangeSelect,
		options,
		precisao_decimal,
		total,
		valueSelect,
	]);

	const previsaoReceita: Row = {
		type: 'text',
		media: Math.trunc(
			(total / cycleDuration) * averageTicket,
		)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		}),
		total: Math.trunc(total * averageTicket)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		}),
		preenchimentoText: 'Previsão de Receita',
	};

	for (const key in consensus) {
		const value = currencyToNumber(String(consensus[key]));
		const previsao = value ? value * averageTicket : 0;

		previsaoReceita[key] = Math.trunc(previsao).toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		});
	}
	const collumnsMounths = generateRowsMonths({
		startMonth: cycle,
		qtdMounth: cycleDuration,
		abbreviatedMonth: cycleDuration > 6,
	});

	const addException = () => {
		const key: string = Date.now().toString();
		setExceptions({
			...exceptions,
			[key]: {
				initiIsEditing: true,
				associatedCollaboration: false,
			},
		});
		setTimeout(() => {
			const element = document.getElementById('buttonNewException');
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 200);
	};
	const toast = useToast();
	const onDeleteCard = (
		key: string,
		amountCollaborationAssociated: number = 0,
	) => {
		const newExceptions = { ...exceptions };
		if (
			newExceptions[key].associatedCollaboration &&
			amountCollaborationAssociated > 0
		) {
			newExceptions[key].initiIsEditing = false;
		} else {
			delete newExceptions[key];
		}

		setExceptions(newExceptions);
		toast({
			title: 'Exceção removida com sucesso!',
			status: 'success',
			duration: 8000,
			isClosable: true,
			position: 'bottom-left',
		});
	};

	const [showErrorComment, setShowErrorComment] = useState<boolean>(false);
	const [showModalConsenso, setShowModalConsenso] = useState<boolean>(false);
	const [ata, setAta] = useState('');

	const onSaveAta = (newAta: string) => {
		let bodyAta = {
			anotacao: newAta,
			ciclo_id: cycleId,
			familia_id: familyId,
		};
		setAta(newAta);
		createAta(bodyAta);
		setShowModalConsenso(false);
	};

	useEffect(() => {
		if (consensus) {
			const { max, min } = confidenceInterval;
			for (const key in consenso) {
				const colaboracao = consensus[key];
				if (Number(colaboracao) < min || Number(colaboracao) > max) {
					setShowErrorComment(true);
					return;
				}
			}
			setShowErrorComment(false);
		}
	}, [consenso, consensus, confidenceInterval]);

	const createConsenso = () => {
		const consensoBody: Array<{
			mes_ano: string;
			quantidade: number;
		}> = Array.from({
			length: cycleDuration,
		}).map((_, i) => {
			const mounth = ((cycle + i) % 12) + 1;
			const ano = (i + cycle + 1) / 12 > 1 ? yearCycle + 1 : yearCycle;
			return {
				mes_ano: `${mounth < 10 ? '0' : ''}${mounth}/${ano}`,
				quantidade: currencyToNumber(consenso[mounth]),
			};
		});

		const body: BodyCreateConsensus = {
			ciclo_id: valuesFilter.ciclo?.value,
			familia_id: familyId,
			consensos: consensoBody,
		};

		mutate(body);
	};

	const clickCancel = () => {
		updateConsensusByDatabase();
	};

	const goToaccuracy = () => {
		history.push(`/${pathCompanyName}/demanda/acuracidade`);
	};

	const { mutate: mutateAppyConsensusApportioment } =
		useApplyConsensusApportionment();

	const onSubmitApplyConsensusApportionment = () => {
		const body = {
			ciclo_id: cycleId,
		};
		mutateAppyConsensusApportioment(body);
	};

	useEffect(() => {
		if (extraResult?.hasData && extraResult?.isInvalidData) onOpen();
	}, [extraResult, extraResult?.hasData, extraResult?.isInvalidData, onOpen]);

	return isLoadingConsensusData ||
		isLoadingCollaborationByFamily ||
		isLoadingFilters ? (
		<LoadingSpinner h='50vh' />
	) : (
		<Flex flexDirection={'column'}>
			{showModalConsenso && (
				<ModalComment
					header='Ata de Consenso'
					description='Escreva aqui suas observações sobre este consenso'
					onClose={() => setShowModalConsenso(false)}
					onSave={onSaveAta}
					ata={ata}
					showModal={showModalConsenso}
					valuesFilter={valuesFilter}
				/>
			)}
			<StatisticTable
				title={{
					icon: IconHandStop,
					name: 'Consenso',
					red: Object.values(consensus).some(
						(number) => number === 0 || number === '',
					),
				}}
				SectionButtons={
					<Flex
						cursor={'pointer'}
						gap={'10px'}
						marginBottom='10px'
						flexGrow={1}
						justifyContent={'flex-end'}
					>
						<TooltipStandard
							label='Recalcular Plano Irrestrito de todas as Famílias'
							shouldWrapChildren={true}
						>
							<ButtonComponent
								type='icon'
								iconType={<IconRefreshAlert />}
								onClick={onSubmitApplyConsensusApportionment}
							/>
						</TooltipStandard>
						<TooltipStandard
							label='Exportar CSV'
							shouldWrapChildren={true}
						>
							<ExportCSVButton />
						</TooltipStandard>
						<TooltipStandard
							label='ATA de Consenso'
							shouldWrapChildren={true}
						>
							<ButtonComponent
								onClick={() => setShowModalConsenso(true)}
								iconType={<IconNotebook />}
								type='icon'
							/>
						</TooltipStandard>
						<TooltipStandard
							label='Acuracidade'
							shouldWrapChildren={true}
						>
							<ButtonComponent
								type='icon'
								iconType={<IconTargetArrow />}
								onClick={goToaccuracy}
							/>
						</TooltipStandard>
					</Flex>
				}
				columnsData={{
					header: [columnFirtDataConsensoCadastrar],
					body: collumnsMounths,
					footer: columnsEndCadastrar,
				}}
				tableData={[...rowsColabs, consenso, previsaoReceita]}
				useInputMoney
			/>
			{showErrorComment && !ata && (
				<ErrorComment
					onClose={() => setShowErrorComment(false)}
					onSave={onSaveAta}
					ata={ata}
					showModal={showErrorComment}
				/>
			)}
			<Flex
				marginTop='20px'
				justifyContent={'end'}
			>
				<Flex gap='10px'>
					<ButtonComponent
						data-test='button-cancelar-page-consensos'
						title='Cancelar'
						onClick={clickCancel}
					/>
					<ButtonComponent
						data-test='button-salvar-page-consensos'
						title='Salvar'
						onClick={createConsenso}
						type='primary'
						isDisabled={!ata && showErrorComment}
					/>
				</Flex>
			</Flex>
			{status?.complete && (
				<Flex
					justifyContent={'end'}
					marginTop='20px'
					flexDirection={'column'}
					alignItems='end'
					gap={'16px'}
				>
					<ButtonComponent
						data-test='button-ir_para_analise_de_capacidade-consensos'
						title='Ir para Análise de Capacidade'
						type='primary'
						onClick={() =>
							history.push('../../analiseCapacidade/dashboard')
						}
					/>
				</Flex>
			)}
			{status?.allowedFinish && (
				<FinishButton
					cycleId={cycleId}
					finish={finish}
					keysPage={keysPage}
				/>
			)}

			{Object.keys(exceptions).map((key, i) => {
				return (
					<SectionExceptions
						key={key}
						exceptionId={exceptions[key]?.id}
						exceptionKey={key}
						onDeleteCard={onDeleteCard}
						fixedProprities={exceptions[key]?.fixedProprities}
						associatedCollaboration={
							exceptions[key]?.associatedCollaboration
						}
						initiIsEditing={exceptions[key]?.initiIsEditing}
						indexException={i}
					/>
				);
			})}
			<Flex marginTop={'30px'}>
				<ButtonComponent
					data-test='button-adicionar_a_excecao-consensos'
					title='Nova exceção'
					onClick={() => addException()}
					type='secondary'
					size='lg'
					id='buttonNewException'
				/>
			</Flex>
			<ModalAiPredictionRequest
				isOpen={isOpen}
				onClose={onClose}
				message={extraResult?.message}
			/>
		</Flex>
	);
};

export default ConsensusSection;
