import { Box, Flex } from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { MenuFilter } from 'components/menu/MenuFilter';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { useCallback, useState } from 'react';
import { SelectedFilters } from './components/SelectedFilters';
import { TableFilterProps } from './models/TableFilterModels';

// Componente responsável por renderizar o filtro da tabela
// Props: filtersMap, setFilters, module
// filtersMap: Array de objetos com as informações dos filtros, como nome, tipo, valores, etc. Esse item é retornado da API;
// setFilters: Função responsável por setar os filtros selecionados na tabela. Essa função é passada como props para o componente TableFilter;
// module: Nome do módulo. Necessário para identificar o módulo e buscar os filtros corretos na API;

export const TableFilter = (props: TableFilterProps) => {
	const {
		dataTest,
		filtersMap,
		filterTooltip,
		filters = [],
		setFilters,
		module,
		getFromExternalAPI = false,
		searchParams,
	} = props;

	const [selectedItems, setSelectedItems] = useState<string[]>([]);
	const [shouldClearFilterForm, setShouldClearFilterForm] =
		useState<boolean>(false);

	// Função responsável por montar o array de itens selecionados ou retirar um item do array
	const handleFilterSelect = (filterId: string, selected = true) => {
		if (selected) {
			setSelectedItems((prevSelected) => [...prevSelected, filterId]);
		} else {
			setSelectedItems((prevSelected) =>
				prevSelected.filter((id) => id !== filterId),
			);
		}
	};

	// Função responsável por selecionar ou deselecionar um item do filtro
	const handleFilterToggle: (filterId: string) => void = useCallback(
		(filterId) => {
			const isSelected = selectedItems.includes(filterId);
			handleFilterSelect(filterId, !isSelected);
		},
		[selectedItems, handleFilterSelect],
	);

	const clearFilters = (): void => {
		setSelectedItems([]);
		setShouldClearFilterForm(true);
	};

	return (
		<Box mx={4}>
			{filtersMap && (
				<>
					<Flex
						alignItems={'center'}
						gap={2}
					>
						<TooltipStandard
							label={filterTooltip || 'Filtrar'}
							shouldWrapChildren={true}
							gutter={20}
						>
							<MenuFilter
								dataTest={dataTest}
								filtersMap={filtersMap}
								handleFilterToggle={handleFilterToggle}
								selectedItems={selectedItems}
							/>
						</TooltipStandard>
						{selectedItems.length > 0 && (
							<ButtonComponent
								onClick={clearFilters}
								title={'Limpar Filtros'}
								type={'clearWithIcon'}
							/>
						)}
					</Flex>
					<SelectedFilters
						filtersMap={filtersMap}
						selectedItems={selectedItems}
						handleFilterToggle={handleFilterToggle}
						setShouldClearFilterForm={setShouldClearFilterForm}
						shouldClearFilterForm={shouldClearFilterForm}
						filters={filters}
						setFilters={setFilters}
						module={module}
						setSelectedItems={setSelectedItems}
						getFromExternalAPI={getFromExternalAPI}
						searchParams={searchParams}
					/>
				</>
			)}
		</Box>
	);
};
