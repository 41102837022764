import {
	ColDef,
	EditableCallbackParams,
	ValueFormatterParams,
} from 'ag-grid-community';
import { Header, OnSortChangeI } from './agGrid.type';
import { CustomHeader } from './CustomHeader';
import { globalStyles } from 'theme/styles';
import { NumberEditor } from './NumberEditor';
type OnSortChange = ({ colId, sort }: OnSortChangeI) => void;

const convertToNumber = (value: any, precision: number) => {
	const cleanValue = value?.replace(/\./g, '')?.replace(',', '.');
	if (isNaN(cleanValue) || cleanValue === null || cleanValue === undefined)
		return 0;
	return parseFloat(parseFloat(cleanValue).toFixed(precision));
};
type TypeCells = 'number' | 'string' | 'total';
const getSpecificColumnsByType = <Type,>(
	quantityDigits: number,
): Record<TypeCells, ColDef> => {
	const valueFormatter = (params: ValueFormatterParams<any, any>) => {
		return (+params.value || 0)?.toLocaleString('pt-BR', {
			minimumFractionDigits: quantityDigits,
			maximumFractionDigits: quantityDigits,
		});
	};
	return {
		total: {
			cellStyle: {
				fontWeight: 'bold',
				backgroundColor: globalStyles.colors.secondaryGray[300],
			},
			valueFormatter,
		},
		number: {
			valueFormatter,
			valueSetter: (params) => {
				const key = params.colDef.field as keyof Type;
				const value = convertToNumber(params.newValue, quantityDigits);
				params.data[key] = value;
				params.data.total = params.data.total - params.oldValue + value;

				return true;
			},
			enableCellChangeFlash: true,
			onCellValueChanged: (params) => {
				console.log('params', params);
			},
			cellEditor: NumberEditor,
			cellEditorParams: {
				quantityDigits: quantityDigits,
			},
		},
		string: {},
	};
};
export const generateHeader = <Type,>(
	headers: Header<Type>[],
	onSortChange: OnSortChange,
	quantityDigits: number,
	disabledEditCell?: (colDef: ColDef, data: Type) => boolean,
): ColDef[] => {
	const formattingTypes = getSpecificColumnsByType<Type>(quantityDigits);
	// -1 because the total column is not editable
	const quantityColumnsNotEditable =
		headers.reduce((acc, header) => acc + (header.editable ? 0 : 1), 0) - 1;
	const firstColumn = headers[0];
	return headers.map(
		({
			field,
			headerName,
			cellRenderer,
			sortable = false,
			editable = false,
			width,
			type = 'string',
			cellStyle,
		}) => {
			return {
				field,
				headerName,
				sortable: false,
				cellStyle,
				...(editable
					? {
							editable: ({
								colDef,
								data,
							}: EditableCallbackParams<Type>) => {
								return disabledEditCell && data
									? disabledEditCell(colDef, data)
									: true;
							},
					  }
					: {}),
				colSpan: (params) => {
					if (
						params.data['isHighlighted'] &&
						firstColumn.field === params.colDef.field
					) {
						return quantityColumnsNotEditable;
					}
					return 0;
				},
				width,
				headerComponent: sortable
					? () => {
							return (
								<CustomHeader
									onSortChange={onSortChange}
									colId={field}
									headerName={headerName}
								/>
							);
					  }
					: undefined,
				cellRenderer,
				...formattingTypes[type],
			};
		},
	);
};
