import { Tooltip } from '@chakra-ui/react';
import GenericInput, { InputType } from '../inputs/GenericInput';
import { defaultTooltipProps } from '../../utils/forms/defaultsProps';
import { Controller } from 'react-hook-form';
import InputTimeMask from '../inputs/time/InputTimeMask';
import MoneyField from '../inputs/MoneyField';
import DecimalField from '../inputs/DecimalField';
import ProductQuantityField from 'components/inputs/ProductQuantityField';
import MultiSelect from 'components/multiSelect/Multiselect';

interface IRenderGenericInputForm {
	name: string;
	type: InputType;
	formProps: any;
	validate?: {
		[key: string]:
			| string
			| {
					[key: string]: string | number;
			  };
	};
	disabled?: (formProps) => boolean;
	inputProps?: {
		[key: string]: any;
	};
	tooltip?: string;
	tooltipProps?: any;
}

const RenderGenericInputForm = ({
	name,
	type,
	formProps,
	validate,
	disabled,
	inputProps,
	tooltip,
	tooltipProps,
}: IRenderGenericInputForm) => {
	const { control } = formProps;

	const isDisabled = disabled ? disabled(formProps) : false;
	const _tooltipProps = tooltipProps || defaultTooltipProps;

	const {
		onChange: onChangeCallback = (e?: any, formProps?: any) => {},
		...otherInputProps
	}: any = inputProps || {};

	function generateInputProps(field) {
		const props = {
			ref: field.ref,
			name,
			value: field.value,
			onChange: (event: any) => {
				field.onChange(event);
				onChangeCallback(event, formProps);
			},
			disabled: isDisabled,
			...otherInputProps,
		};

		if (type === 'select') {
			return {
				...props,
				placeholder: 'Selecione',
			};
		}

		if (type === 'autocomplete') {
			delete props?.onChange;

			return {
				...props,
				// ref: field.ref,
				onChange: (value: any) => {
					field.onChange(value?.id);
					onChangeCallback(value, formProps);
				},
			};
		}

		if (
			type === 'react-select' ||
			type === 'autocomplete-infinite-scroll'
		) {
			delete props?.onChange;

			return {
				...props,
				// ref: field.ref,
				onChange: (value: any) => {
					field.onChange(value);
					onChangeCallback(value, formProps);
				},
			};
		}

		if (type === 'autocomplete-with-data') {
			delete props?.onChange;

			return {
				...props,
				// ref: field.ref,
				onChange: (value: any) => {
					field.onChange(
						typeof value === 'string' ? value : value?.id,
					);
					onChangeCallback(value, formProps);
				},
			};
		}

		return props;
	}

	function renderMultiselect() {
		return (
			<Controller
				name={name}
				control={control}
				rules={validate}
				render={({ field }) => {
					const props = generateInputProps(field);
					return <MultiSelect {...props} />;
				}}
			/>
		);
	}

	function renderTimeMask() {
		return (
			<Controller
				name={name}
				control={control}
				rules={validate}
				render={({ field }) => {
					const props = generateInputProps(field);
					return (
						<InputTimeMask {...props}>
							{(fieldProps) => (
								<input
									{...fieldProps}
									type='text'
								/>
							)}
						</InputTimeMask>
					);
				}}
			/>
		);
	}

	function renderMoneyField() {
		return (
			<Controller
				name={name}
				control={control}
				rules={validate}
				render={({ field }) => {
					const props = generateInputProps(field);
					return <MoneyField {...props} />;
				}}
			/>
		);
	}

	function renderDecimalField() {
		return (
			<Controller
				name={name}
				control={control}
				rules={validate}
				render={({ field }) => {
					const props = generateInputProps(field);
					return <DecimalField {...props} />;
				}}
			/>
		);
	}

	function renderProductQuanityField() {
		return (
			<Controller
				name={name}
				control={control}
				rules={validate}
				render={({ field }) => {
					const props = generateInputProps(field);
					return <ProductQuantityField {...props} />;
				}}
			/>
		);
	}

	function renderDefault() {
		return (
			<Controller
				name={name}
				control={control}
				rules={validate}
				render={({ field }) => {
					const props = generateInputProps(field);
					return (
						<GenericInput
							type={type}
							inputProps={props}
						/>
					);
				}}
			/>
		);
	}

	function getInput() {
		switch (type) {
			case 'multiselect':
				return renderMultiselect();
			case 'timemask':
				return renderTimeMask();
			case 'money':
				return renderMoneyField();
			case 'decimal':
				return renderDecimalField();
			case 'product-quantity':
				return renderProductQuanityField();
			default:
				return renderDefault();
		}
	}

	return tooltip ? (
		<Tooltip
			label={tooltip}
			{..._tooltipProps}
		>
			<span>{getInput()}</span>
		</Tooltip>
	) : (
		getInput()
	);
};

export default RenderGenericInputForm;
