import { Box, ButtonGroup, Flex, useDisclosure } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { helpRateio } from './variables/data';
import Card from 'components/card/Card';
import moment from 'moment';
import { useCycleList } from 'services/queryClient/wrapperHooks/useCycleList';
import { useFamilyListWithoutProducts } from 'services/queryClient/wrapperHooks/useFamilyList';
import { InputFormLabel } from 'components/fields/InputFormLabel';
import GenericInput from 'components/inputs/GenericInput';
import RateioTreeview from './rateioTreeview';
import RateioInconsistencyUpdateModal from 'components/modal/RateioInconsistencyUpdateModal';
import RateioCalculationModal from 'components/modal/RateioCalculationModal';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import { useDownloadTemplateApportionment } from 'services/queryClient/wrapperHooks/uploads/useDownloadTemplateApportionment';
import { useUploadApportionment } from 'services/queryClient/wrapperHooks/uploads/useUploadApportionment';
import { columnsDataRateio } from './variables/columnsDataRateio';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import ButtonExportGenericCSV from 'components/buttons/ButtonExportGenericCSV';
import { useExportApportionment } from 'services/queryClient/wrapperHooks/apportionment/useExportApportionment';
import { renderProduct } from 'utils/forms/autocomplete';
import { defaultInputStyle } from 'utils/forms/defaultsProps';
import { LoadingSpinner } from 'components/loading/Loading';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { useApplyConsensusApportionment } from 'services/queryClient/wrapperHooks/apportionment/useApplyConsensusApportionment';

export interface FilterInputState {
	cycleId: string | number;
	year: string | number;
	family: string | number;
	product: string;
}

const Rateio = () => {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	useEffect(() => {
		setDataSidebar(helpRateio);
	}, [setDataSidebar]);

	const {
		isOpen: isOpenRecalculateModal,
		onOpen: onOpenRecalculateModal,
		onClose: onCloseRecalculateModal,
	} = useDisclosure();

	const {
		isOpen: isOpenImportModal,
		onOpen: onOpenImportModal,
		onClose: onCloseImportModal,
	} = useDisclosure();

	const [filterInput, setFilterInput] = useState<FilterInputState>({
		cycleId: '',
		year: moment().format('YYYY'),
		family: '',
		product: '',
	});

	const [canClearProductInput, setCanClearProductInput] =
		useState<boolean>(false);

	const [currentCycle, setCurrentCycle] = useState<any>();

	const [clearTreeview, setClearTreeview] = useState<boolean>(false);

	const [sharedTreeviewProps, setSharedTreeviewProps] = useState<any>({});

	const lastFilterRef = useRef<string>('');

	const { data: cycleData } = useCycleList();

	const { data: familiesData } = useFamilyListWithoutProducts(true, true);

	const { mutate: applyConsensusApportionment } =
		useApplyConsensusApportionment();

	const yearSelectOptions = useMemo(() => {
		return (
			cycleData
				?.filter(
					(el, index, array) =>
						index === array.findIndex((a) => a?.ano === el?.ano),
				)
				?.map((cycle) => (
					<option
						key={cycle?.id}
						value={cycle?.ano}
						selected={cycle?.ano === filterInput.year}
					>
						{cycle?.ano}
					</option>
				)) || []
		);
	}, [cycleData, filterInput.year]);

	const cycleSelectOptions = useMemo(() => {
		return (
			cycleData
				?.filter((el) => el?.ano === Number(filterInput?.year))
				?.map((cycle) => (
					<option
						key={cycle?.id}
						value={cycle?.id}
						selected={cycle?.id === Number(filterInput.cycleId)}
					>
						{cycle?.ciclo}
					</option>
				)) || []
		);
	}, [cycleData, filterInput.cycleId, filterInput?.year]);

	const filteredYearSelectOptions = useMemo(() => {
		return (
			cycleData
				?.filter((el) => el?.id === Number(filterInput.cycleId))
				?.map((cycle) => (
					<option
						key={cycle?.id}
						value={cycle?.id}
					>
						{cycle?.ciclo}
					</option>
				)) || []
		);
	}, [cycleData, filterInput.cycleId]);

	const familySelectOptions = useMemo(() => {
		return (
			familiesData?.rows?.map((family) => (
				<option
					key={family?.id}
					value={family?.id}
					selected={family?.id === filterInput.family}
				>
					{family?.nome_familia}
				</option>
			)) || []
		);
	}, [familiesData, filterInput.family]);

	const handleClearTreeview = useCallback(() => {
		setClearTreeview(true);
		setTimeout(() => {
			setClearTreeview(false);
		}, 500);
	}, []);

	function renderYearSelect() {
		return (
			<Flex
				display='flex'
				direction='column'
				w='12%'
			>
				<InputFormLabel
					htmlFor='ano'
					label='Ano'
					placement='top'
				/>
				<GenericInput
					type='select'
					inputProps={{
						name: 'ano',
						value: filterInput.year,
						'data-test': 'select-filtrar-ano',
						placeholder: 'Selecione o ano',
						selectOptions: yearSelectOptions,
						onChange: (e) => {
							setFilterInput({
								...filterInput,
								year: e.target.value,
							});
						},
						...defaultInputStyle,
					}}
				/>
			</Flex>
		);
	}

	function renderCycleSelect() {
		return (
			<Flex
				display='flex'
				direction='column'
				w='12%'
			>
				<InputFormLabel
					htmlFor='ciclo'
					label='Ciclo'
					placement='top'
				/>
				<GenericInput
					type='select'
					data-test='select-filtrar-ciclo'
					inputProps={{
						name: 'ciclo',
						value: filterInput.cycleId,
						placeholder: 'Selecione o ciclo',
						'data-test': 'select-filtrar-ciclo',
						selectOptions: cycleSelectOptions,
						onChange: (e) => {
							setFilterInput({
								...filterInput,
								cycleId: e.target.value,
							});
						},
						...defaultInputStyle,
					}}
				/>
			</Flex>
		);
	}

	function renderFamilySelect() {
		return (
			<Flex
				display='flex'
				direction='column'
				w='30%'
			>
				<InputFormLabel
					htmlFor='familia'
					label='Família'
					placement='top'
				/>
				<GenericInput
					type='select'
					inputProps={{
						name: 'familia',
						value: filterInput?.family,
						placeholder: 'Selecione a família',
						selectOptions: familySelectOptions,
						onChange: (e) => {
							setFilterInput((prev) => ({
								...prev,
								family: e.target.value,
							}));
							setCanClearProductInput(true);
							setTimeout(
								() => setCanClearProductInput(false),
								500,
							);
						},
						...defaultInputStyle,
					}}
				/>
			</Flex>
		);
	}

	function renderProductFilter() {
		return (
			<Flex
				display='flex'
				direction='column'
				w='30%'
			>
				<InputFormLabel
					htmlFor='produto'
					label='Produto'
					placement='top'
				/>
				<GenericInput
					type='autocomplete'
					inputProps={{
						searchType: 'produto',
						renderInputLabel: renderProduct,
						onChange: (e) => {
							setFilterInput((prev) => ({
								...prev,
								product: e?.id || '',
							}));
						},
						autocomplete: 'off',
						extraRequestData: {
							familia_id: filterInput?.family,
							participa_rateio: true,
						},
						clearInput: () => canClearProductInput,
						...defaultInputStyle,
					}}
				/>
			</Flex>
		);
	}

	function renderFilters() {
		return (
			<>
				{renderYearSelect()}
				{renderCycleSelect()}
				{renderFamilySelect()}
				{renderProductFilter()}
			</>
		);
	}
	const [sucessImport, setSucessImport] = useState(false);
	const onSuccessImport = () => {
		applyConsensusApportionment({
			ciclo_id: +filterInput.cycleId,
		});
	};
	useEffect(() => {
		if (sucessImport) {
			onSuccessImport();
			setSucessImport(false);
		}
	}, [sucessImport]);

	function renderButtons() {
		return (
			<ButtonGroup
				spacing='20px'
				alignItems='end'
			>
				<ButtonComponent
					data-test='button-importar-rateio'
					type='ghost'
					title='Importar Rateio'
					onClick={onOpenImportModal}
				/>
				<FileUploadModal
					isOpen={isOpenImportModal}
					onClose={onCloseImportModal}
					headerText='Importar Dados de Rateio'
					module='rateio'
					useDownloadTemplate={useDownloadTemplateApportionment}
					stringInvalidQuery='apportionment-listById'
					useUpload={useUploadApportionment}
					columnsData={columnsDataRateio}
					hasBaseData={sharedTreeviewProps?.hasApportionment}
					setSucessImport={setSucessImport}
					handleOnUploadSuccess={handleClearTreeview}
				/>
				<TooltipStandard
					label='Exportar Rateio'
					shouldWrapChildren={true}
				>
					<ButtonExportGenericCSV
						useExport={useExportApportionment}
						cycleId={filterInput?.cycleId.toString()}
						module={'rateio'}
						errorDescription={
							'Por favor, verifique se o rateio foi calculado antes de exportar.'
						}
						filters={{
							ano: filterInput?.year?.toString(),
							ciclo: currentCycle?.ciclo?.toString(),
						}}
					/>
				</TooltipStandard>
			</ButtonGroup>
		);
	}

	function renderTreeview() {
		if (!filterInput?.year || !filterInput?.cycleId)
			return (
				<AlertComponent
					title='Não foi possível carregar os dados.'
					description='Selecione os filtros de ano e ciclo!'
					status='warning'
					hasCloseButton
				/>
			);

		if (!currentCycle || clearTreeview) return <LoadingSpinner />;

		return (
			<Box mb={5}>
				<RateioTreeview
					filters={filterInput}
					cycle={currentCycle}
					recalculateCallback={onOpenRecalculateModal}
					setSharedTreeviewProps={setSharedTreeviewProps}
				/>
			</Box>
		);
	}

	useEffect(() => {
		if (cycleData) {
			const maxYear = Math.max(
				...cycleData.map((cycle) => cycle?.ano || 0),
			);

			const maxMonth = Math.max(
				...cycleData
					.filter((el) => el?.ano === maxYear)
					.map((cycle) => cycle?.ciclo || 0),
			);

			setFilterInput((prev) => ({
				...prev,
				year: maxYear,
				cycleId:
					cycleData.find(
						(cycle) =>
							+cycle?.ano === +maxYear &&
							+cycle?.ciclo === +maxMonth,
					)?.id || 0,
			}));
		}
	}, [cycleData]);

	useEffect(() => {
		if (filterInput?.year && filterInput?.cycleId) {
			const currentCycle = cycleData?.find(
				(cycle) =>
					cycle.id === Number(filterInput.cycleId) &&
					cycle.ano === Number(filterInput.year),
			);
			setCurrentCycle(currentCycle);
		} else {
			setCurrentCycle(null);
		}
	}, [cycleData, filterInput]);

	useEffect(() => {
		const newValues = JSON.stringify(filterInput);
		if (lastFilterRef.current !== newValues) {
			lastFilterRef.current = newValues;

			setClearTreeview(true);

			setTimeout(() => {
				setClearTreeview(false);
			}, 500);
		}
	}, [filterInput]);

	return (
		<Flex
			direction={{ base: 'column', xl: 'row' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
		>
			<Card pt={10}>
				<Box mb={5}>
					<Flex
						gap={2}
						w='100%'
						direction='row'
						justifyContent='space-between'
					>
						<Flex
							w='70%'
							gap={2}
						>
							{renderFilters()}
						</Flex>
						<Flex
							justifyContent='flex-end'
							w='30%'
							gap={2}
						>
							{renderButtons()}
						</Flex>
					</Flex>
				</Box>
				{renderTreeview()}
				<RateioCalculationModal
					isOpen={isOpenRecalculateModal}
					onClose={onCloseRecalculateModal}
					yearFilter={yearSelectOptions}
					filterInput={filterInput}
					setFilterInput={setFilterInput}
					cycleFilter={filteredYearSelectOptions}
					cycleData={cycleData || []}
					onSuccessCallback={handleClearTreeview}
				/>
				<RateioInconsistencyUpdateModal
					cycle={currentCycle}
					onSuccessCallback={handleClearTreeview}
				/>
			</Card>
		</Flex>
	);
};

export default Rateio;
