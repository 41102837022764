import { Tooltip, TooltipProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface TooltipStandardProps extends TooltipProps {
	children: ReactNode;
	[key: string]: any;
}

export const TooltipStandard = ({
	children,
	label,
	...rest
}: TooltipStandardProps) => {
	return (
		<Tooltip
			label={label}
			placement='top'
			bg={'gray.200'}
			color={'blue.800'}
			hasArrow={true}
			arrowSize={15}
			{...rest}
		>
			{children}
		</Tooltip>
	);
};
