import { Text, Tooltip } from '@chakra-ui/react';
import { ClientHover } from '../../StatisticTable';
import { LoadingSpinner } from 'components/loading/Loading';
interface Cell {
	cell: ClientHover;
	textColor: 'secondaryGray.900' | 'white';
}
export const CellClientInputHover = ({ cell, textColor }: Cell) => {
	return (
		<Tooltip
			boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
			padding='20px'
			border={'1px solid #E0E5F2'}
			gap='10px'
			borderRadius={'16px'}
			background='white'
			display={'grid'}
			gridTemplateColumns={'repeat(2, auto)'}
			placement='top'
			label={
				<>
					<Text
						color={'easyBLUE.300'}
						fontWeight={700}
					>
						{cell.month}
					</Text>
					<Text
						color={'easyBLUE.300'}
						fontWeight={700}
					>
						{cell.value}
					</Text>
					{cell?.isLoading ? (
						<LoadingSpinner />
					) : (
						cell?.clientes?.map(({ nome, quantidade }, index) => {
							return (
								<>
									<Text
										color={'secondaryGray.500'}
										key={nome + index}
									>
										{nome}
									</Text>
									<Text
										color={'secondaryGray.500'}
										key={nome}
									>
										{quantidade}
									</Text>
								</>
							);
						})
					)}
				</>
			}
		>
			<Text
				paddingX='8px'
				alignItems={'center'}
				marginRight={0}
				color={textColor}
				fontSize='sm'
				display={'flex'}
				width={'100%'}
				height={'100%'}
				fontWeight='700'
				onMouseOver={cell?.onHoverCallback}
			>
				{cell.value || '-'}
			</Text>
		</Tooltip>
	);
};
