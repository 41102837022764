import {
	CapacityDashboard,
	CapacityDashboardParams,
} from 'models/capacity-dashboard.model';
import api from '../../../api';

export const getCapacityDashboard = async (
	params: CapacityDashboardParams,
): Promise<CapacityDashboard | null> => {
	const { recursos, meses, ...rest } = params;
	if (!rest.ciclo_id) {
		return null;
	}
	const resourceQuery = recursos
		.map((resource) => `recursos=${resource.value}`)
		.join('&');
	const monthQuery = meses.map((month) => `meses=${month.value}`).join('&');

	const query = [] as string[];

	if (resourceQuery) query.push(resourceQuery);
	if (monthQuery) query.push(monthQuery);

	const response = await api.get(`/dashboardCapacidade?${query.join('&')}`, {
		params: {
			...rest,
		},
	});
	return response.data;
};
