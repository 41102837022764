import { useQuery } from '@tanstack/react-query';
import { getAtaConsensoList } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

export const useGetAtaConsenso = (cicloId: string, familiaId: string) => {
	return useQuery(
		['ata-consenso-list', cicloId, familiaId],
		getAtaConsensoList,
		{
			refetchOnWindowFocus: false,
		},
	);
};
