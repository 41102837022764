import { createContext, useContext, useState } from 'react';
import { QuantityValues } from '../templateCadastrarColabCons/hooks/useSelectByStatistic';

const ContextConsenso = createContext<IContext>({} as IContext);
export interface ProductException {
	nome_produto: string;
	id: string | number;
	codigo_produto: string;
}
export interface ChanelException {
	nome_canal_venda: string;
	id: string | number;
}
export interface ExceptionProprietes {
	salesChannel?: ChanelException;
	product?: ProductException;
}

interface IContext {
	consensus: QuantityValues;
	setConsensus: React.Dispatch<React.SetStateAction<QuantityValues>>;
}

export default function ProviderConsenso({ children }) {
	const [consensus, setConsensus] = useState<QuantityValues>({});

	return (
		<ContextConsenso.Provider
			value={{
				consensus,
				setConsensus,
			}}
		>
			{children}
		</ContextConsenso.Provider>
	);
}

export const useContextConsenso = () => {
	return useContext(ContextConsenso);
};
