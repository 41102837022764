import { useToast } from '@chakra-ui/react';
import { isArray } from '@chakra-ui/utils';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFinalizar } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';
import { getKeysValuesSelectedFilterDemandFamily } from './filters/useGetFilterDemandFamily';
import { getKeysValuesSelectedFilters } from './filters/useGetSelectedFilter';
import { getKeysValuesStatusDemand } from './filters/useGetStatusDemand';

export const useCollaborationConsensuFinalize = (
	page: string,
	after?: Function,
) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation((body: any) => postFinalizar(page, body), {
		onSuccess: (sucess, variables) => {
			const { area_empresa_id, ciclo_id } = variables;

			queryClient.invalidateQueries(
				getKeysValuesStatusDemand({
					pageName: page,
					cycleId: ciclo_id,
					companyAreaId: area_empresa_id,
				}),
			);
			queryClient.invalidateQueries(
				getKeysValuesSelectedFilters({
					pageName: page,
					cycleId: ciclo_id,
					companyAreaId: area_empresa_id,
				}),
			);
			queryClient.invalidateQueries(
				getKeysValuesSelectedFilterDemandFamily({
					pageName: page,
					cycleId: ciclo_id,
				}),
			);
			toast({
				title: 'Finalização feita com sucesso',
				status: 'success',
				duration: 9000,
				isClosable: true,
				position: 'bottom-left',
			});
			if (after) {
				after();
			}
		},
		onError: (error: any) => {
			error.response.data = isArray(error.response.data)
				? error.response.data
				: [error.response.data];
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao atualizar Família!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
