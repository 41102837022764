import { Flex } from '@chakra-ui/react';
import { TooltipHelper } from 'components/tooltip/TooltipHelper';
import { TIME_MESSAGE } from './itineraryMessages';

export function ProductionItineraryHeader() {
	return (
		<Flex
			direction='row'
			gap={5}
			mt={10}
			mb={2}
			fontSize={{
				sm: '14px',
				lg: '14px',
			}}
			color='gray.400'
		>
			<Flex
				w='10%'
				alignItems='center'
			>
				<span>Ordem do roteiro</span>
			</Flex>
			<Flex
				w='40%'
				alignItems='center'
			>
				<span>Atividade - Grupo de recurso</span>
			</Flex>
			<Flex
				w='10%'
				alignItems='center'
			>
				<span>Quantidade</span>
			</Flex>
			<Flex
				w='12.5%'
				alignItems='center'
			>
				<TooltipHelper message={TIME_MESSAGE}>
					<span>Tempo de máquina</span>
				</TooltipHelper>
			</Flex>
			<Flex
				w='12.5%'
				alignItems='center'
			>
				<TooltipHelper message={TIME_MESSAGE}>
					<span>Tempo do colaborador</span>
				</TooltipHelper>
			</Flex>
			<Flex
				w='5%'
				alignItems='center'
			>
				<span>Ações</span>
			</Flex>
		</Flex>
	);
}
