import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect } from 'react';
import TemplateCadastrarColabCons from '../templateCadastrarColabCons/cadastrar';
import ProviderColaboracaoConsenso from '../templateCadastrarColabCons/context';
import CollaborationSection from './components/CollaborationSection';
import ProviderColaboracao from './context';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function CadastrarColaboracao() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	useEffect(() => {
		const helpCadastrarColaboracao: SidebarHelp = {
			title: 'Colaboração',
			firstParagraph: `Etapa destinada a colaboração de cada especialista, a qual deve ser realizada de maneira independente, sem sofrer qualquer tipo de influência, considerando as informações de histórico de vendas, valores quantitativos de ciclos anteriores, oportunidades, exceções (ou seja, eventos) e estatísticas.`,
			secondParagraph: `Use o filtro Canal de Venda para navegar entre os canais, sendo que o ícone permanecerá ativo até a colaboração do canal estiver completa. Use o filtro Exibir para visualizar histórico, ciclos, oportunidades e estatísticas.`,
			thirdParagraph: `Para completar a colaboração de cada canal, navegue até o menu lateral esquerdo e clique em uma família por vez. Para cada família selecionada, preencha os campos de cada mês futuro com a demanda em quantidade. Clique em Salvar. Ao salvar com sucesso, aparecerá ao lado da família em que foi realizada a colaboração.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		};

		setDataSidebar(helpCadastrarColaboracao);
	}, []);

	return (
		<>
			<ProviderColaboracao>
				<ProviderColaboracaoConsenso page='colaboracao'>
					<TemplateCadastrarColabCons
						SectionColabCons={CollaborationSection}
						page='colaboracao'
					/>
				</ProviderColaboracaoConsenso>
			</ProviderColaboracao>
		</>
	);
}
