import { IconRotateClockwise2 } from '@tabler/icons';
import StatisticTable, {
	Row,
	RowHover,
	ValueHover,
} from 'components/dataTables/statisticTable/StatisticTable';
import MultiSelect from 'components/multiSelect/Multiselect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCollaborationConsensuHistory } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useCollaborationConsensuHistory';
import { useContextColaboracaoConsenso } from '../context';
import InformationNotFound from './InformationNotFound';
import { LoadingSpinner } from 'components/loading/Loading';
import { numberToLocaleString } from 'utils/functions/number';
import { useGlobalContext } from 'contexts/GlobalContext';
import { generateRowsMonths } from 'components/dataTables/statisticTable/generateRowsMonths';

export interface ConfigCollaboration {
	agrupar_canal_venda: boolean;
	agrupar_zona_venda: boolean;
	[other: string]: boolean | string | number;
}

export default function SectionCycle() {
	const {
		familyId,
		salesChannelId,
		cycleId,
		cycle: cycleData,
		keysPage,
		companyAreaId,
		isLoadingFilters,
		salesZoneId,
		collaborationConfig,
	} = useContextColaboracaoConsenso();
	const { configsCompany } = useGlobalContext();

	const { ano: year, ciclo: cycle, duracao: cycleDuration } = cycleData;

	const cycleAnterior = +cycle - 1;

	const { data: historyData, isLoading: isLoadingHistorycycle } =
		useCollaborationConsensuHistory({
			page: keysPage.page,
			salesChannelId,
			familyId,
			cycleId,
			companyAreaId,
			salesZoneId,
		});

	const [cycles, setCycles] = useState<Row[]>([]);

	const [optionsFilterCycles, setOptionsFilterCycles] = useState<number[]>(
		[],
	);
	const [selectedFilterCycle, setSelectedFilterCycle] = useState<number[]>(
		[],
	);

	const columnFirtDataConsensoCadastrar = useMemo(
		() => ({
			Header: 'Ciclo',
			accessor: 'ciclo',
			disableSortBy: true,
			type: 'text',
		}),
		[],
	);

	useEffect(() => {
		if (!isLoadingFilters && !!historyData?.length) {
			const newOptionsFilterCycles: number[] = [];
			const newCycles: any = [];
			historyData.forEach((valuesCycle) => {
				const rowCycle: RowHover = {
					media: 0,
					total: 0,
					type: 'text-hover',
				};
				let total = 0;
				if (valuesCycle && valuesCycle[keysPage.page]) {
					if (valuesCycle.ciclo !== cycleAnterior) {
						newOptionsFilterCycles.push(valuesCycle.ciclo);
					}
					valuesCycle[keysPage.page].forEach((colabOrConsensus) => {
						const [year, month] =
							colabOrConsensus.mes_ano.split('-');
						total += colabOrConsensus[`${keysPage.page}`];
						const key = `${month}/${year.slice(-2)}`;
						rowCycle[key] = numberToLocaleString({
							value: colabOrConsensus[`${keysPage.page}`],
							maximumFractionDigits:
								configsCompany?.precisao_decimal,
						});
					});
					if (keysPage.page === 'consenso') {
						rowCycle.ciclo = valuesCycle.ciclo;
					} else {
						const pastConfig =
							valuesCycle.configuracaoColaboracao[0] || {};

						const currentConfig = collaborationConfig;

						const newMessageForCycleCollab: string =
							getNewMessageForCollabConfig(
								pastConfig,
								currentConfig,
							);
						rowCycle.ciclo = !newMessageForCycleCollab
							? valuesCycle.ciclo
							: {
									value: valuesCycle.ciclo,
									message: newMessageForCycleCollab,
									typeHover: 'message',
							  };
					}
					rowCycle.total = numberToLocaleString({
						value: total,
						maximumFractionDigits: configsCompany?.precisao_decimal,
					});
					rowCycle.media = numberToLocaleString({
						value: total / cycleDuration,
						maximumFractionDigits: configsCompany?.precisao_decimal,
					});
					newCycles.push(rowCycle);
				}
			});
			setOptionsFilterCycles(newOptionsFilterCycles);
			setCycles(newCycles);
		} else if (!isLoadingFilters && !historyData?.length) {
			setCycles([]);
		}
	}, [
		cycleAnterior,
		cycleDuration,
		historyData,
		isLoadingFilters,
		keysPage.page,
		collaborationConfig,
	]);

	const qtdOption = 5;

	const startMonth =
		+cycle - qtdOption < 1 ? +cycle - qtdOption + 12 : +cycle - qtdOption;

	const startYear = +cycle - qtdOption < 1 ? +year - 1 : +year;

	const collumsDataMonths = generateRowsMonths({
		startMonth,
		qtdMounth: cycleDuration + qtdOption - 1,
		abbreviatedMonth: true,
		addYear: true,
		startYear,
		acessorIncludeYaer: true,
	});

	const columnsEndCycle = useMemo(
		() => [
			{
				Header: 'MÉDIA',
				accessor: 'media',
				type: 'text',
			},
			{
				Header: 'TOTAL',
				accessor: 'total',
				type: 'text',
			},
		],
		[],
	);

	const filterOptionsValuesSelected = useCallback(() => {
		if (!optionsFilterCycles.length) return [];

		if (!!optionsFilterCycles.length && !!selectedFilterCycle.length) {
			const commonValues = optionsFilterCycles.filter((value) =>
				selectedFilterCycle.includes(value),
			);

			return commonValues.map((value) => ({
				label: value,
				value: value,
			}));
		}
	}, [selectedFilterCycle, optionsFilterCycles]);

	const renderTablecycle = useCallback(() => {
		if (isLoadingFilters || isLoadingHistorycycle)
			return <LoadingSpinner />;
		if (!!cycles?.length)
			return (
				<StatisticTable
					title={{
						icon: IconRotateClockwise2,
						name: 'Ciclo',
					}}
					SectionButtons={
						<>
							<MultiSelect
								onChange={(newValue) => {
									setSelectedFilterCycle(
										newValue.map((v) => Number(v.label)),
									);
								}}
								options={optionsFilterCycles.map((item) => ({
									label: item,
									value: item,
								}))}
								size={'medium'}
								placeholder='Filtrar por ciclos'
								value={filterOptionsValuesSelected()}
								notHideSelectedOptions
								selectAllTitle='Todos os ciclos'
							/>
						</>
					}
					conditionalFilter={({ row }) => {
						if (
							keysPage.page === 'consenso' ||
							!row.ciclo.message
						) {
							return (
								row.ciclo === cycleAnterior ||
								selectedFilterCycle.includes(Number(row.ciclo))
							);
						}
						return (
							row.ciclo.value === cycleAnterior ||
							selectedFilterCycle.includes(
								Number(row.ciclo.value),
							)
						);
					}}
					columnsData={{
						header: [columnFirtDataConsensoCadastrar],
						body: collumsDataMonths,
						footer: columnsEndCycle,
					}}
					tableData={cycles}
					applyLocaleString
				/>
			);

		return (
			<InformationNotFound
				icon={IconRotateClockwise2}
				message={'Nenhum ciclo foi encontrado.'}
				name={'Ciclo'}
			/>
		);
	}, [
		cycleAnterior,
		cycles,
		collumsDataMonths,
		columnFirtDataConsensoCadastrar,
		columnsEndCycle,
		isLoadingFilters,
		isLoadingHistorycycle,
		optionsFilterCycles,
		selectedFilterCycle,
	]);

	return renderTablecycle();
}

export const getNewMessageForCollabConfig = (
	past: ConfigCollaboration,
	current: ConfigCollaboration,
): string => {
	const conditionMapping: {
		condition: boolean;
		message: string;
	}[] = [
		{
			condition:
				past.agrupar_canal_venda !== current.agrupar_canal_venda &&
				past.agrupar_zona_venda === current.agrupar_zona_venda,
			message:
				'Valor total na família de todos canais e zonas de venda agrupados.',
		},
		{
			condition:
				past.agrupar_canal_venda === current.agrupar_canal_venda &&
				past.agrupar_zona_venda !== current.agrupar_zona_venda,
			message:
				'Valor do total na família e canal de venda para todas as zonas.',
		},
	];

	const matchedCondition = conditionMapping.find((item) => item.condition);
	return matchedCondition ? matchedCondition.message : '';
};
