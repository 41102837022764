import { Flex } from '@chakra-ui/react';
import { useContextColaboracaoConsenso } from '../context';
import SectionCiclo from './SectionCiclo';
import SectionEstatistica from './SectionEstatistica';
import SectionHistorico from './SectionHistorico';
import OpportunitySection from './OpportunitySection';
export const MoreSection = () => {
	const sections = [
		{
			id: 'Histórico',
			component: <SectionHistorico />,
		},
		{
			id: 'Estatística',
			component: <SectionEstatistica />,
		},
		{
			id: 'Ciclos',
			component: <SectionCiclo />,
		},
		{
			id: 'Oportunidades',
			component: <OpportunitySection />,
		},
	];
	const { showSections, familyId } = useContextColaboracaoConsenso();
	return (
		<>
			{familyId ? (
				sections.map((section) => {
					return (
						<Flex
							id={section.id}
							key={section.id}
						>
							{showSections[section.id] && section.component}
						</Flex>
					);
				})
			) : (
				<></>
			)}
		</>
	);
};
