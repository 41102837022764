import { Box, ButtonGroup, Flex, Spinner, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import {
	FilterBody,
	ModulesType,
	SelectedFilterBody,
} from '../models/TableFilterModels';
import {
	clearLocalStorageFilter,
	getLocalStorageFilter,
	setLocalStorageFilter,
} from '../utils/localStorageFilterManager';
import { SelectedFilterItem } from './SelectedFilterItem';

interface SelectedFiltersProps {
	filtersMap: FilterBody[];
	selectedItems: string[];
	handleFilterToggle: (itemId: string) => void;
	setShouldClearFilterForm: React.Dispatch<React.SetStateAction<boolean>>;
	shouldClearFilterForm: boolean;
	filters?: SelectedFilterBody[];
	setFilters: React.Dispatch<React.SetStateAction<SelectedFilterBody[]>>;
	module: ModulesType;
	setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
	getFromExternalAPI?: boolean;
	searchParams?: Object;
}

type RenderSelectedFilterItemParams = {
	itemId: string;
	filtersMap: FilterBody[];
	handleRemoveItem: (itemId: string) => void;
	setForm: (form: SelectedFilterBody[]) => void;
	form: SelectedFilterBody[];
	applyFilters: (close: any) => void;
	module: ModulesType;
	selectedItems: string[];
	setSelectedItems: any;
};

type RenderSelectedFilterItemReturn = JSX.Element | null;

export const SelectedFilters = (props: SelectedFiltersProps) => {
	const {
		filtersMap,
		selectedItems,
		handleFilterToggle,
		setShouldClearFilterForm,
		shouldClearFilterForm,
		filters = [],
		setFilters,
		module,
		setSelectedItems,
		getFromExternalAPI = false,
		searchParams,
	} = props;

	const [form, setForm] = useState<SelectedFilterBody[]>([]);

	const filtersLocalStorage = getLocalStorageFilter(module);

	useEffect(() => {
		if (form?.length === 0 && filtersLocalStorage?.length > 0) {
			setForm(filtersLocalStorage);
			const selectedItems = filtersLocalStorage
				.map((item: SelectedFilterBody) => item.nome_coluna)
				.filter(
					(value: string, index: number, self) =>
						self.indexOf(value) === index,
				);

			setSelectedItems(selectedItems);

			if (!!filters?.length) {
				const isDifferent =
					JSON.stringify(filters) !==
					JSON.stringify(filtersLocalStorage);
				if (isDifferent) {
					setFilters(filtersLocalStorage);
				}
			} else {
				setFilters(filtersLocalStorage);
			}
		}
	}, [form]);

	// Função que remove o filtro selecionado
	const handleRemoveItem = (itemId: string): void => {
		handleFilterToggle(itemId);
		const filterItemsByItemId = (
			prevItems: SelectedFilterBody[],
			itemId: string,
		) =>
			prevItems?.filter(
				(item: SelectedFilterBody) => item.nome_coluna !== itemId,
			);

		setForm((prevForm: SelectedFilterBody[]) =>
			filterItemsByItemId(prevForm, itemId),
		);
		setFilters((prevFilters: SelectedFilterBody[]) =>
			filterItemsByItemId(prevFilters, itemId),
		);

		setLocalStorageFilter(
			module,
			filterItemsByItemId(filtersLocalStorage, itemId),
		);
	};

	// Função responsável por renderizar os itens selecionados no filtro
	const renderSelectedFilterItem = ({
		itemId,
		filtersMap,
		handleRemoveItem,
		form,
		applyFilters,
		module,
		selectedItems,
		setSelectedItems,
	}: RenderSelectedFilterItemParams): RenderSelectedFilterItemReturn => {
		const filterFind: FilterBody | undefined = filtersMap.find(
			(filter: FilterBody) => filter.nome_coluna === itemId,
		);

		if (!filterFind) {
			return null;
		}

		return (
			<SelectedFilterItem
				key={itemId}
				filter={filterFind}
				handleRemoveItem={handleRemoveItem}
				setForm={setForm}
				form={form}
				applyFilters={applyFilters}
				module={module}
				selectedItems={selectedItems}
				setSelectedItems={setSelectedItems}
				getFromExternalAPI={getFromExternalAPI}
				searchParams={searchParams}
			/>
		);
	};

	useEffect(() => {
		if (shouldClearFilterForm) {
			setForm([]);
			setFilters([]);
			setShouldClearFilterForm(false);
			clearLocalStorageFilter(module);
		}
	}, [shouldClearFilterForm]);

	const applyFilters = useCallback(
		(close) => {
			const filterItemsMatching: SelectedFilterBody[] | [] = form.filter(
				(filter: SelectedFilterBody) => {
					return filtersLocalStorage.some(
						(filterStorage: SelectedFilterBody) => {
							return (
								filter.nome_coluna ===
									filterStorage.nome_coluna &&
								filter.operacao === filterStorage.operacao
							);
						},
					);
				},
			);

			if (filtersLocalStorage.length > 0) {
				if (filterItemsMatching.length === 0) {
					if (form.length === 1) {
						setFilters(form);
						setLocalStorageFilter(module, form);
					} else {
						setFilters((prev) => [...prev, ...form]);
						setLocalStorageFilter(module, [
							...filtersLocalStorage,
							...form,
						]);
					}
				} else {
					const updateItemsOnMatching: SelectedFilterBody[] =
						form.map((filter: SelectedFilterBody) => {
							const matchingItem = filterItemsMatching.find(
								(filterStorage: SelectedFilterBody) => {
									return (
										filterStorage.nome_coluna ===
											filter.nome_coluna &&
										filterStorage.operacao ===
											filter.operacao
									);
								},
							);
							return matchingItem || filter;
						});

					setFilters(updateItemsOnMatching);
					setLocalStorageFilter(module, updateItemsOnMatching);
				}
			} else {
				setFilters(form);
				setLocalStorageFilter(module, form);
			}

			close();
		},
		[form, filtersLocalStorage, module, setFilters, setLocalStorageFilter],
	);

	const showSpinner = selectedItems.length > 0 && filtersMap.length === 0;

	return (
		<>
			{selectedItems.length > 0 && (
				<Box
					mt={4}
					w={'100%'}
				>
					<Text
						fontSize='sm'
						mb={2}
						color='easyBLUE.300'
					>
						Filtros selecionados:
					</Text>

					{showSpinner && (
						<Flex
							w={'100%'}
							alignItems={'center'}
						>
							<Text
								fontSize='sm'
								color='easyBLUE.300'
								textAlign={'center'}
							>
								Carregando filtros...
							</Text>
							<Spinner
								size='sm'
								color='easyBLUE.300'
								ml={2}
							/>
						</Flex>
					)}
					<ButtonGroup
						spacing={4}
						flexWrap={'wrap'}
					>
						{selectedItems.map((itemId) => {
							return renderSelectedFilterItem({
								itemId,
								filtersMap,
								handleRemoveItem,
								setForm,
								form,
								applyFilters,
								module,
								selectedItems,
								setSelectedItems,
							});
						})}
					</ButtonGroup>
				</Box>
			)}
		</>
	);
};
