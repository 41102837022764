import { currencyToNumber } from '../../../utils/functions/currencyToNumber';
import api from '../api';

import { resource } from 'models/resource';

export const createResource = async (body: resource) => {
	const response = await api.post('/recurso', body);
	return response.data;
};

export const editResource = async (body) => {
	const response = await api.patch('/recurso', body);
	return response.data;
};

export const getResourceById = async ({ queryKey }) => {
	const [, id] = queryKey;

	const response = await api.get(`recurso/recursoById?id=${id}`);
	return response.data;
};

export const deleteResource = async (id: number) => {
	const response = await api.delete(`recurso?id=${id}`);
	return response.data;
};

export const deleteResourceByFilters = async (filters: any) => {
	const response = await api.post('recurso/deletarPorFiltros', filters);
	return response.data;
};

export const calculateIrogMetrics = async (body) => {
	const payload = {
		...body,
		...(body?.pecasBoas
			? { pecasBoas: currencyToNumber(String(body.pecasBoas)) }
			: undefined),
		...(body?.refugos
			? { refugos: currencyToNumber(String(body.refugos)) }
			: undefined),
	};

	if (typeof body.cicloTime == 'string') {
		payload.cicloTime = +body.cicloTime.replace(',', '.');
	}

	const response = await api.post(
		'/registroIrog/calcularMetricasDoIrog',
		payload,
	);
	return response.data;
};

export const getAvailabilityMinutes = async () => {
	const response = await api.get('/diaUtil/disponibilidadeMinutos');
	return response.data;
};

export const getWorkingDays = async ({ queryKey }) => {
	const [, year, month] = queryKey;
	const response = await api.get(
		`/diaUtil/diaUtilByMesAno?ano=${year}&mes=${month}`,
	);
	return response.data;
};

export const getMemberByFilters = async (params) => {
	const response = await api.get('/membro/filter/autocomplete', {
		params: {
			...params,
		},
	});
	return response.data;
};
