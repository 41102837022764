import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	ButtonGroup,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';

import {
	defaultFormStyle,
	formFlexProps,
	rowFlexProps,
	defaultInputStyle,
	defaultModalFormStyle,
	columnFlexProps,
} from 'utils/forms/defaultsProps';
import { makeGenericFormInputStructure } from 'utils/forms/defaultInputs';
import {
	validateRequired,
	validateMaxDecimal,
	validateMinDecimal,
} from 'utils/forms/validations';
import {
	filterBrand,
	filterModel,
	filterVersion,
	renderBrand,
	renderModel,
	renderResourceGroup,
	renderVersion,
} from 'utils/forms/autocomplete';
import { currencyToNumber } from 'utils/functions/currencyToNumber';
import { numberToLocaleString } from 'utils/functions/number';

import { ButtonComponent } from 'components/buttons/ButtonComponent';
import GenericForm, { IFormRows } from 'components/forms/GenericForm';

import { useEditResource } from 'services/queryClient/wrapperHooks/useEditResource';
import { useCreateResource } from 'services/queryClient/wrapperHooks/useCreateResource';
import { useCreateResourceGroup } from 'services/queryClient/wrapperHooks/useCreateResourceGroup';
import { useCalculateIrogMetrics } from 'services/queryClient/wrapperHooks/useCalculateIrogMetrics';

interface IFormCadastrarEditarRecurso {
	initialState: any;
	resourceId?: number;
	autocompleteAdditionalData?: any;
}

const FormCadastrarEditarRecurso = ({
	initialState,
	resourceId = 0,
	autocompleteAdditionalData,
}: IFormCadastrarEditarRecurso) => {
	const history = useHistory();

	const isEditing = !!resourceId;

	const [formProps, setFormProps] = useState<any>();
	const [autocompleteData, setAutocompleteData] = useState<any>();
	const [payload, setPayload] = useState<any>();
	const [payloadCalculateIROG, setPayloadCalculateIROG] = useState<any>();

	const [dataChart, setDataChart] = useState<any[]>([]);
	const [irogDataChart, setIrogDataChart] = useState<number>(0);
	const [showCharts, setShowCharts] = useState(false);

	const [dataChartAfterCalculate, setDataChartAfterCalculate] =
		useState<any>();

	const { mutate: calculateIrogMetrics, data: dataCalculateMetrics } =
		useCalculateIrogMetrics(
			payloadCalculateIROG,
			setDataChart,
			setIrogDataChart,
			setShowCharts,
			setDataChartAfterCalculate,
		);

	const { mutate: createResourceGroup } = useCreateResourceGroup(
		undefined,
		undefined,
		undefined,
		'grupo_recurso_id',
		formProps,
		setAutocompleteData,
	);

	const { mutate: editResource } = useEditResource(payload, resourceId);
	const { mutate: createResource } = useCreateResource(payload);

	const [isMachine, setMachine] = useState<boolean>(
		initialState?.maquina ? initialState?.maquina === 'true' : false,
	);
	const [typeIROG, setTypeIROG] = useState<string>(
		initialState?.tipo_dados_irog,
	);

	const [irogManual, setIrogManual] = useState<any>({
		desempenho: currencyToNumber(initialState?.desempenho_irog) ?? 0,
		disponibilidade:
			currencyToNumber(initialState?.disponibilidade_irog) ?? 0,
		qualidade: currencyToNumber(initialState?.qualidade_irog) ?? 0,
	});

	const keyIrogIndustria = 'INDUSTRIA';
	const keyIrogManual = 'MANUAL';
	const keyIrogCalculado = 'CALCULADO';

	const {
		isOpen: isOpenModalCreate,
		onOpen: onOpenModalCreate,
		onClose: onCloseModalCreate,
	} = useDisclosure();

	const {
		isOpen: isOpenModalCalculateIROG,
		onOpen: onOpenModalCalculateIROG,
		onClose: onCloseModalCalculateIROG,
	} = useDisclosure();

	const optionsMachineType = useMemo(() => {
		return [
			<option value='true'>Sim, o recurso é máquina</option>,
			<option value='false'>Não, o recurso não é máquina</option>,
		];
	}, []);

	const optionsGargalo = useMemo(() => {
		return [
			<option value='true'>Sim, o recurso é gargalo</option>,
			<option value='false'>Não, o recurso não é gargalo</option>,
		];
	}, []);

	const optionsIROG = useMemo(() => {
		return [
			<option value={keyIrogIndustria}>
				Valores de referência da indústria
			</option>,
			<option value={keyIrogManual}>
				Entrar com valores manualmente
			</option>,
			<option value={keyIrogCalculado}>Calcular o IROG</option>,
		];
	}, []);

	const styleCard = useMemo(() => {
		return {
			backgroundColor: '#314866',
			width: '350px',
			padding: '16px',
			height: '130px',
			borderRadius: '16px',
			color: '#fff',
		};
	}, []);

	const irogChartOptions = useMemo(
		(): any => ({
			series: [
				{
					data: dataChart,
				},
			],
			options: {
				chart: {
					type: 'bar',
					height: 380,
				},
				plotOptions: {
					bar: {
						barHeight: '100%',
						distributed: true,
						horizontal: true,
						dataLabels: {
							position: 'bottom',
						},
					},
				},
				legend: {
					show: false,
				},
				colors: ['#F0243E', '#ced6e0', '#0296F2'],
				dataLabels: {
					enabled: true,
					textAnchor: 'start',
					style: {
						colors: ['#2f3542'],
					},
					formatter: function (val, opt) {
						return (
							opt.w.globals.labels[opt.dataPointIndex] +
							':  ' +
							Intl.NumberFormat('pt-BR').format(val) +
							'%'
						);
					},
					offsetX: 0,
				},
				stroke: {
					width: 1,
					colors: ['#fff'],
				},
				xaxis: {
					categories: ['Disponibilidade', 'Performance', 'Qualidade'],
				},
				yaxis: {
					labels: {
						show: false,
					},
				},
				tooltip: {
					theme: 'dark',
					x: {
						show: false,
					},
					y: {
						title: {
							formatter: function () {
								return '';
							},
						},
					},
				},
			},
		}),
		[dataChart],
	);

	const handleAddResourceGroup = useCallback(
		(_, formProperties) => {
			if (!formProps) {
				setFormProps(formProperties);
			}

			onOpenModalCreate();
		},
		[formProps, onOpenModalCreate],
	);

	const handleOnChangeMachine = useCallback(
		(event: any, _formProps: any) => {
			if (!formProps) setFormProps(_formProps);

			const { setValue } = _formProps;

			if (event?.target?.value === 'false') {
				setValue('gargalo', 'false');

				setMachine(false);
			} else {
				setMachine(true);
			}
		},
		[formProps],
	);

	const handleOnChangeIROG = useCallback(
		(event: any, _formProps: any) => {
			if (!formProps) setFormProps(_formProps);

			const newValue = event?.target?.value;

			setTypeIROG(newValue);

			if (newValue === keyIrogIndustria) {
				const { setValue, clearErrors } = _formProps;

				clearErrors([
					'disponibilidade_irog',
					'desempenho_irog',
					'qualidade_irog',
					'irog',
				]);

				setValue('disponibilidade_irog', 90);
				setValue('desempenho_irog', 70);
				setValue('qualidade_irog', 98);
				setValue('irog', 61.74);
			} else if (newValue === keyIrogCalculado) {
				onOpenModalCalculateIROG();
			}
		},
		[formProps, onOpenModalCalculateIROG],
	);

	const handleOnChangeDisponibilidade = useCallback(
		(value, _formProps) => {
			if (!formProps) setFormProps(_formProps);

			setIrogManual((prev) => ({
				...prev,
				disponibilidade: currencyToNumber(value),
			}));
		},
		[formProps],
	);

	const handleOnChangePerformance = useCallback(
		(value, _formProps) => {
			if (!formProps) setFormProps(_formProps);

			setIrogManual((prev) => ({
				...prev,
				desempenho: currencyToNumber(value),
			}));
		},
		[formProps],
	);

	const handleOnChangeQualidade = useCallback(
		(value, _formProps) => {
			if (!formProps) setFormProps(_formProps);

			setIrogManual((prev) => ({
				...prev,
				qualidade: currencyToNumber(value),
			}));
		},
		[formProps],
	);

	const renderIrogInputs = useCallback(
		(
			dataTest: string,
			name: string,
			label: string,
			labelTooltip?: string,
			applyPadding?: boolean,
			onChange?: Function,
		) => {
			const _columnFlexProps = {
				...columnFlexProps,
				marginRight: '30px',
			};

			return makeGenericFormInputStructure({
				type: 'decimal',
				name,
				label,
				showRequiredOnLabel: true,
				labelTooltip,
				inputProps: {
					decimalsLimit: 2,
					prefix: '',
					'data-test': dataTest,
					...(!!onChange ? { onChange } : undefined),
				},
				validate: {
					...validateRequired(),
					validate: (value) => validateMaxDecimal(value, 100),
				},
				disabled: () =>
					name === 'irog' ||
					typeIROG === keyIrogIndustria ||
					typeIROG === keyIrogCalculado,
				...(applyPadding ? { columnFlexProps: _columnFlexProps } : {}),
			});
		},
		[typeIROG],
	);

	useEffect(() => {
		setAutocompleteData({
			resourceGroup: autocompleteAdditionalData?.resourceGroup,
		});
	}, [autocompleteAdditionalData]);

	useEffect(() => {
		if (formProps && keyIrogManual) {
			const { setValue } = formProps;

			const percDisponibilidade =
				(currencyToNumber(irogManual?.disponibilidade) || 0) / 100;
			const percPerformance =
				(currencyToNumber(irogManual?.desempenho) || 0) / 100;
			const percQualidade =
				(currencyToNumber(irogManual?.qualidade) || 0) / 100;

			const percIrog =
				percDisponibilidade * percPerformance * percQualidade * 100;

			setValue(
				'irog',
				percIrog > 100
					? '100'
					: numberToLocaleString({
							value: percIrog,
							maximumFractionDigits: 2,
					  }),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		irogManual?.desempenho,
		irogManual?.disponibilidade,
		irogManual?.qualidade,
	]);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'maquina',
							label: 'O recurso é do tipo máquina?',
							labelTooltip:
								'Recursos podem ser pessoas ou máquinas',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'select-o_recurso_e_do_tipo_maquina-cadastrar_editar-page-recurso',
								selectOptions: optionsMachineType,
								onChange: handleOnChangeMachine,
							},
							validate: {
								...validateRequired(),
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'descricao',
							label: 'Nome do recurso',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'input-nome_do_recurso-cadastrar_editar-page-recurso',
							},
							validate: {
								...validateRequired(),
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'grupo_recurso_id',
							label: 'Grupo de recurso',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'autocomplete-grupo_de_recurso-cadastrar_editar-page-recurso',
								...defaultInputStyle,
								searchType: 'grupo_recurso',
								hasButton: true,
								renderInputLabel: renderResourceGroup,
								autocomplete: 'off',
								initialLabel: autocompleteData?.resourceGroup
									? renderResourceGroup(
											autocompleteData?.resourceGroup,
									  )
									: '',
							},
							validate: {
								...validateRequired(),
							},
							button: {
								onClick: handleAddResourceGroup,
								tooltip: 'Adicionar Grupo de Recurso',
								buttonProps: {
									'data-test':
										'button-adicionar_grupo_de_recurso-recurso',
									bg: 'easyBLUE.300',
									color: 'white',
								},
								buttonFlexProps: {
									gap: 5,
									justify: 'space-between',
								},
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'disponibilidade_minutos_dia',
							label: 'Disponibilidade em minutos',
							showRequiredOnLabel: true,
							labelTooltip:
								'Valor em minutos para 1 recurso (máquina ou pessoa) por dia.',
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
							inputProps: {
								'data-test':
									'input-disponibilidade_em_minutos-cadastrar_editar-page-recurso',
								allowNegativeValue: false,
								prefix: '',
							},
							validate: {
								...validateRequired(),
								validate: (value) =>
									validateMinDecimal(value, 0.01),
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete-with-data',
							name: 'nome_marca',
							label: 'Marca',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'autocomplete-marca-cadastrar_editar-page-recurso',
								searchType: 'marca',
								useSearchAsNewValue: true,
								initialLabel: initialState?.nome_marca || '',
								renderInputLabel: renderBrand,
								filterByData: {
									apiData:
										autocompleteAdditionalData?.brandes,
									filterData: filterBrand,
								},
								autocomplete: 'off',
							},
							validate: {
								...validateRequired(),
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete-with-data',
							name: 'nome_modelo',
							label: 'Modelo',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'autocomplete-modelo-cadastrar_editar-page-recurso',
								searchType: 'modelo',
								useSearchAsNewValue: true,
								initialLabel: initialState?.nome_modelo || '',
								renderInputLabel: renderModel,
								filterByData: {
									apiData: autocompleteAdditionalData?.models,
									filterData: filterModel,
								},
								autocomplete: 'off',
							},
							validate: {
								...validateRequired(),
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete-with-data',
							name: 'nome_versao',
							label: 'Versão',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'autocomplete-versao-cadastrar_editar-page-recurso',
								searchType: 'versao',
								useSearchAsNewValue: true,
								initialLabel: initialState?.nome_versao || '',
								renderInputLabel: renderVersion,
								filterByData: {
									apiData:
										autocompleteAdditionalData?.versions,
									filterData: filterVersion,
								},
								autocomplete: 'off',
							},
							validate: {
								...validateRequired(),
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'gargalo',
							label: 'O recurso que você quer analisar é gargalo?',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'select-o_recurso_que_voce_quer_analisar_e_gargalo-cadastrar_editar-page-recurso',
								selectOptions: optionsGargalo,
							},
							validate: {
								...validateRequired(),
							},
							disabled: () => !isMachine,
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'tipo_dados_irog',
							label: 'Como você deseja entrar com os dados de IROG do recurso?',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'select-como_voce_deseja_entrar_com_os_dados_de_IROG_do_recurso-cadastrar_editar-page-recurso',
								selectOptions: optionsIROG,
								onChange: handleOnChangeIROG,
							},
							validate: {
								...validateRequired(),
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...renderIrogInputs(
							'input-disponibilidade-cadastrar_editar-page-recurso',
							'disponibilidade_irog',
							'Disponibilidade',
							undefined,
							true,
							handleOnChangeDisponibilidade,
						),
					},
					{
						...renderIrogInputs(
							'input-performance-cadastrar_editar-page-recurso',
							'desempenho_irog',
							'Performance',
							undefined,
							true,
							handleOnChangePerformance,
						),
					},
					{
						...renderIrogInputs(
							'input-qualidade-cadastrar_editar-page-recurso',
							'qualidade_irog',
							'Qualidade',
							undefined,
							true,
							handleOnChangeQualidade,
						),
					},
					{
						...renderIrogInputs(
							'input-IROG-cadastrar_editar-page-recurso',
							'irog',
							'IROG',
							'Índice de Rendimento Operacional Global do equipamento.',
						),
					},
				],
			},
		];
	}, [
		optionsMachineType,
		handleOnChangeMachine,
		autocompleteData?.resourceGroup,
		handleAddResourceGroup,
		initialState?.nome_marca,
		initialState?.nome_modelo,
		initialState?.nome_versao,
		autocompleteAdditionalData?.brandes,
		autocompleteAdditionalData?.models,
		autocompleteAdditionalData?.versions,
		optionsGargalo,
		optionsIROG,
		handleOnChangeIROG,
		renderIrogInputs,
		handleOnChangeDisponibilidade,
		handleOnChangePerformance,
		handleOnChangeQualidade,
		isMachine,
	]);

	const renderRowsCreateResourceGroup = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome_grupo_recurso',
							label: 'Nome do Grupo',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'input-nome_do_grupo-grupo_de_recurso-modal-recurso',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
		];
	}, []);

	const handleOnCreateResourceGroup = useCallback(
		(values) => {
			createResourceGroup(values);

			onCloseModalCreate();
		},
		[createResourceGroup, onCloseModalCreate],
	);

	const renderModalCreate = useCallback(() => {
		const createInitialState = { nome_grupo_recurso: '' };
		return (
			<Modal
				isOpen={isOpenModalCreate}
				onClose={onCloseModalCreate}
				onEsc={onCloseModalCreate}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader>
						Cadastrar Grupo de Recurso
						<Text
							color='gray.500'
							fontSize='sm'
							fontWeight={200}
						>
							Preencha todos os dados para cadastrar grupo de
							recurso
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<GenericForm
							dataTestProps={{
								cancel: 'button-cancelar-modal-recurso',
								save: 'button-salvar-modal-recurso',
							}}
							initialState={createInitialState}
							formFlexProps={formFlexProps}
							rows={renderRowsCreateResourceGroup()}
							style={defaultModalFormStyle}
							onSubmit={handleOnCreateResourceGroup}
							onCancel={onCloseModalCreate}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		);
	}, [
		isOpenModalCreate,
		onCloseModalCreate,
		renderRowsCreateResourceGroup,
		handleOnCreateResourceGroup,
	]);

	const handleOnClearCalculatedIROG = useCallback(() => {
		setShowCharts(false);
		setPayloadCalculateIROG(null);
	}, []);

	const handleOnSaveCalculatedIROG = useCallback(() => {
		const { setValue, clearErrors } = formProps;

		clearErrors([
			'disponibilidade_irog',
			'desempenho_irog',
			'qualidade_irog',
			'irog',
		]);

		setValue(
			'disponibilidade_irog',
			numberToLocaleString({
				value: dataChartAfterCalculate?.disponibilidade,
				maximumFractionDigits: 2,
			}),
		);
		setValue(
			'desempenho_irog',
			numberToLocaleString({
				value: dataChartAfterCalculate?.desempenho,
				maximumFractionDigits: 2,
			}),
		);
		setValue(
			'qualidade_irog',
			numberToLocaleString({
				value: dataChartAfterCalculate?.qualidade,
				maximumFractionDigits: 2,
			}),
		);
		setValue(
			'irog',
			numberToLocaleString({
				value: dataChartAfterCalculate?.irog,
				maximumFractionDigits: 2,
			}),
		);
		setValue('tipo_dados_irog', keyIrogManual);

		handleOnClearCalculatedIROG();

		setTypeIROG(keyIrogManual);

		onCloseModalCalculateIROG();
	}, [
		dataChartAfterCalculate?.desempenho,
		dataChartAfterCalculate?.disponibilidade,
		dataChartAfterCalculate?.irog,
		dataChartAfterCalculate?.qualidade,
		formProps,
		onCloseModalCalculateIROG,
		handleOnClearCalculatedIROG,
	]);

	const handleOnCalculateIROG = useCallback(
		(values) => {
			const { getValues } = formProps;

			setPayloadCalculateIROG({
				...values,
				gargalo: getValues()?.gargalo === 'true',
			});

			calculateIrogMetrics();
		},
		[calculateIrogMetrics, formProps],
	);

	const renderRowsModalCalculateIROG = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'paradasProgramadas',
							label: 'Paradas Programadas',
							labelTooltip:
								'Tempo que é dedicado a eventos programados tais como: refeições, manutenções planejadas, reuniões ou treinamentos.',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								decimalsLimit: 2,
								prefix: '',
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'number',
							name: 'paradasNaoProgramadas',
							label: 'Paradas Não Programadas',
							labelTooltip:
								'Minutos gastos em eventos não programados tais como: quebra de máquina, falta de matéria-prima, falta de energia...',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								decimalsLimit: 2,
								prefix: '',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'number',
							name: 'cicloTime',
							label: 'Tempo padrão de 1 item',
							labelTooltip:
								'É definido pela engenharia de produção como o tempo necessário para que um recurso execute uma operação no processo na fabricação de um produto.',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								decimalsLimit: 2,
								prefix: '',
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'pecasBoas',
							label: 'Itens Produzidos',
							labelTooltip:
								'Refere-se a quantidade de itens fabricados no recurso considerando o tempo em minutos no turno (exemplo, turno de  480 minutos).',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								decimalsLimit: 2,
								prefix: '',
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'refugos',
							label: 'Refugos',
							labelTooltip:
								'Refere-se a quantidade de itens não aprovados que foram fabricados na máquina que esta sendo monitorada.',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								decimalsLimit: 2,
								prefix: '',
							},
						}),
					},
				],
			},
		];
	}, []);

	const renderChart = useCallback(() => {
		if (!showCharts || !dataCalculateMetrics) return null;

		return (
			<>
				<Divider />

				<Flex
					mt={'44px'}
					mb={'22px'}
					gap={3}
				>
					<Text
						w={'35%'}
						color={'#2B3674'}
						fontSize={32}
						fontWeight={700}
					>
						Resultado do IROG
					</Text>

					<Text
						w={'60%'}
						fontWeight={700}
					>
						<Text>
							{irogDataChart >= 85
								? 'O IROG médio mundial é de 85%. Você está acima da média. Ótimo trabalho!'
								: 'O IROG médio mundial é de 85%. Você está abaixo da média. Continue melhorando seus indicadores diariamente!'}
						</Text>
					</Text>
				</Flex>

				<Flex
					h={200}
					alignItems={'center'}
				>
					<Flex w={'35%'}>
						<div style={styleCard}>
							<Text>Resultado:</Text>
							<Text
								fontSize={26}
								fontWeight={700}
							>
								{`IROG ${Intl.NumberFormat('pt-BR').format(
									irogDataChart,
								)}%`}{' '}
							</Text>
						</div>
					</Flex>
					<Flex w={'60%'}>
						<div
							id='chart'
							style={{
								width: '100%',
							}}
						>
							<ReactApexChart
								options={irogChartOptions.options}
								series={irogChartOptions.series}
								type='bar'
								height={200}
							/>
						</div>
					</Flex>
				</Flex>

				<Flex
					justifyContent='end'
					my={'44px'}
				>
					<ButtonGroup spacing={'24px'}>
						<ButtonComponent
							type='ghost'
							title='Calcular novamente'
							onClick={handleOnClearCalculatedIROG}
						/>
						<ButtonComponent
							type='primary'
							title='Salvar'
							onClick={handleOnSaveCalculatedIROG}
						/>
					</ButtonGroup>
				</Flex>
			</>
		);
	}, [
		dataCalculateMetrics,
		handleOnClearCalculatedIROG,
		handleOnSaveCalculatedIROG,
		irogChartOptions.options,
		irogChartOptions.series,
		irogDataChart,
		showCharts,
		styleCard,
	]);

	const onCloseModalIrogWithouSaveData = useCallback(() => {
		handleOnClearCalculatedIROG();
		onCloseModalCalculateIROG();
	}, [handleOnClearCalculatedIROG, onCloseModalCalculateIROG]);

	const renderModalCalculateIROG = useCallback(() => {
		const calculateInitialState = {
			cicloTime: '',
			paradasNaoProgramadas: '',
			paradasProgramadas: '',
			pecasBoas: '',
			refugos: '',
		};

		const { getValues } = formProps || {};

		let isGargalo;

		if (!!getValues) isGargalo = getValues('gargalo') === 'true';
		else isGargalo = initialState?.gargalo === 'true';

		return (
			<Modal
				isOpen={isOpenModalCalculateIROG}
				onClose={onCloseModalCalculateIROG}
				onEsc={onCloseModalCalculateIROG}
				size={'6xl'}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader>
						Calcular IROG
						<Text
							color='gray.500'
							fontSize='sm'
							fontWeight={200}
						>
							Obs.: O tempo disponível considerado é de{' '}
							{isGargalo
								? autocompleteAdditionalData?.availableMinutes
										?.disponibilidade_minutos_gargalo
								: autocompleteAdditionalData?.availableMinutes
										?.disponibilidade_minutos}{' '}
							minutos visto que o recurso{' '}
							{isGargalo ? 'é' : 'não é'} gargalo.
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<GenericForm
							initialState={calculateInitialState}
							formFlexProps={formFlexProps}
							rows={renderRowsModalCalculateIROG()}
							style={defaultModalFormStyle}
							onSubmit={handleOnCalculateIROG}
							onCancel={onCloseModalIrogWithouSaveData}
							labelSave='Calcular resultados'
						/>

						{renderChart()}
					</ModalBody>
				</ModalContent>
			</Modal>
		);
	}, [
		autocompleteAdditionalData?.availableMinutes?.disponibilidade_minutos,
		autocompleteAdditionalData?.availableMinutes
			?.disponibilidade_minutos_gargalo,
		formProps,
		handleOnCalculateIROG,
		initialState?.gargalo,
		isOpenModalCalculateIROG,
		onCloseModalCalculateIROG,
		onCloseModalIrogWithouSaveData,
		renderChart,
		renderRowsModalCalculateIROG,
	]);

	const onSubmit = useCallback(
		(values) => {
			function getNameById(propName: string, id: number) {
				let dataList = [];

				switch (propName) {
					case 'nome_marca':
						dataList = autocompleteAdditionalData?.brandes;
						break;
					case 'nome_modelo':
						dataList = autocompleteAdditionalData?.models;
						break;
					case 'nome_versao':
						dataList = autocompleteAdditionalData?.versions;
						break;
				}

				const dataIndex = dataList?.findIndex(
					(el: any) => el?.id === id,
				);

				if (dataIndex >= 0) return dataList[dataIndex][propName];

				return null;
			}

			setPayload({
				...values,
				gargalo: values?.gargalo === 'true',
				maquina: values?.maquina === 'true',
				disponibilidade_minutos_dia: currencyToNumber(
					values?.disponibilidade_minutos_dia,
				),
				desempenho_irog: currencyToNumber(values?.desempenho_irog),
				disponibilidade_irog: currencyToNumber(
					values?.disponibilidade_irog,
				),
				irog: currencyToNumber(values?.irog),
				qualidade_irog: currencyToNumber(values?.qualidade_irog),
				/**
				 * Necessário validar se form recebeu do autocomplete um nome de marca ou ID da marca
				 */
				nome_marca:
					typeof values?.nome_marca === 'string'
						? values?.nome_marca
						: getNameById('nome_marca', Number(values?.nome_marca)),
				nome_modelo:
					typeof values?.nome_modelo === 'string'
						? values?.nome_modelo
						: getNameById(
								'nome_modelo',
								Number(values?.nome_modelo),
						  ),
				nome_versao:
					typeof values?.nome_versao === 'string'
						? values?.nome_versao
						: getNameById(
								'nome_versao',
								Number(values?.nome_versao),
						  ),
			});

			if (isEditing) editResource();
			else createResource();
		},
		[
			autocompleteAdditionalData?.brandes,
			autocompleteAdditionalData?.models,
			autocompleteAdditionalData?.versions,
			createResource,
			editResource,
			isEditing,
		],
	);

	const goToResources = useCallback(() => {
		history.goBack();
	}, [history]);

	function renderForm() {
		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-recurso',
						save: 'button-salvar-page-recurso',
					}}
					initialState={initialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToResources}
				/>

				{renderModalCreate()}

				{renderModalCalculateIROG()}
			</>
		);
	}

	return renderForm();
};

export default FormCadastrarEditarRecurso;
