import {
	Input,
	Textarea,
	Switch,
	Select as ChakraSelect,
	Tooltip,
	Flex,
	InputGroup,
	InputRightAddon,
	InputLeftAddon,
	Checkbox,
	Box,
	Icon,
} from '@chakra-ui/react';

import InputTimeMask from './time/InputTimeMask';
import { defaultTooltipProps } from '../../utils/forms/defaultsProps';
import GenericAutocomplete from '../autocomplete/GenericAutocomplete';
import MoneyField from './MoneyField';
import DecimalField from './DecimalField';
import GenericAutocompleteWithData from '../autocomplete/GenericAutocompleteWithData';
import ProductQuantityField from './ProductQuantityField';
import moment from 'moment';
import Select from 'react-select';
import GenericAutocompleteInfiniteScroll from 'components/autocomplete/GenericAutocompleteInfiniteScroll';

export type InputType =
	| 'text'
	| 'number'
	| 'time'
	| 'date'
	| 'month'
	| 'timemask'
	| 'textarea'
	| 'select'
	| 'react-select'
	| 'switch'
	| 'checkbox'
	| 'autocomplete'
	| 'autocomplete-with-data'
	| 'autocomplete-infinite-scroll'
	| 'multiselect'
	| 'money'
	| 'input-with-addon'
	| 'decimal'
	| 'product-quantity'
	| 'email';

interface IGenericInput {
	type: InputType;
	inputProps?: {
		[key: string]: any;
	};
	tooltip?: string;
	tooltipProps?: any;
}

const GenericInput = ({
	type,
	inputProps,
	tooltip,
	tooltipProps,
}: IGenericInput) => {
	const {
		name,
		value,
		onChange,
		disabled,
		hidden,
		isChecked,
		showWarning,
		...others
	} = inputProps || {};
	const _tooltipProps = tooltipProps || defaultTooltipProps;

	function renderAutoComplete() {
		const { searchType, ...othersAutocompleteProps } = others;

		const autocompleteProps = {
			name,
			value,
			onChange,
			disabled,
			hidden,
			...othersAutocompleteProps,
		};
		return (
			<GenericAutocomplete
				searchType={searchType}
				inputProps={autocompleteProps}
			/>
		);
	}

	function renderAutoCompleteWithData() {
		const { searchType, ...othersAutocompleteProps } = others;

		const autocompleteProps = {
			name,
			value,
			onChange,
			disabled,
			hidden,
			...othersAutocompleteProps,
		};
		return (
			<GenericAutocompleteWithData
				searchType={searchType}
				inputProps={autocompleteProps}
			/>
		);
	}

	function renderAutoCompleteInfiniteScroll() {
		const { searchType, ...othersAutocompleteProps } = others;

		const autocompleteProps: any = {
			name,
			value,
			onChange,
			disabled,
			hidden,
			...othersAutocompleteProps,
		};

		return (
			<GenericAutocompleteInfiniteScroll
				searchType={searchType}
				inputProps={autocompleteProps}
			/>
		);
	}

	function renderTimeMask() {
		return (
			<InputTimeMask
				name={name}
				value={value}
				{...others}
			/>
		);
	}

	function renderMoneyField() {
		return (
			<MoneyField
				name={name}
				value={value}
				onChange={onChange}
				disabled={disabled}
				{...others}
			/>
		);
	}

	function renderProductQuanityField() {
		return (
			<ProductQuantityField
				name={name}
				defaultValue={value}
				value={value}
				onChange={onChange}
				disabled={disabled}
				{...others}
			/>
		);
	}
	function renderDecimalField() {
		return (
			<DecimalField
				name={name}
				defaultValue={value}
				onChange={onChange}
				disabled={disabled}
				{...others}
			/>
		);
	}

	function renderSelect() {
		const { selectOptions, ...othersSelectProps } = others;

		if (showWarning?.condition) {
			return (
				<>
					<ChakraSelect
						name={name}
						value={value}
						onChange={onChange}
						disabled={disabled}
						hidden={hidden}
						borderColor='orange.300'
						boxShadow={`0 0 0 1px orange`}
						{...othersSelectProps}
					>
						{selectOptions}
					</ChakraSelect>
					<Flex
						alignItems={'center'}
						color='orange.300'
						marginTop={2}
						fontSize='sm'
					>
						{showWarning?.message}
					</Flex>
				</>
			);
		}

		return (
			<ChakraSelect
				name={name}
				value={value}
				onChange={onChange}
				disabled={disabled}
				hidden={hidden}
				{...othersSelectProps}
			>
				{selectOptions}
			</ChakraSelect>
		);
	}

	function renderSwitch() {
		return (
			<Switch
				name={name}
				value={value}
				isChecked={value}
				onChange={onChange}
				disabled={disabled}
				hidden={hidden}
				{...others}
			/>
		);
	}

	function renderTextArea() {
		return (
			<Textarea
				name={name}
				value={value}
				onChange={onChange}
				disabled={disabled}
				hidden={hidden}
				{...others}
			/>
		);
	}

	function renderInputWithAddon() {
		const {
			addonInputType,
			addonPosition,
			addonContent,
			...othersInputWithAddonProps
		} = others;

		return (
			<InputGroup>
				{addonPosition === 'left' && (
					<InputLeftAddon
						p={0}
						borderRadius={10}
						border={'trasparent'}
						h={10}
					>
						{addonContent}
					</InputLeftAddon>
				)}

				<Input
					type={addonInputType}
					name={name}
					value={value}
					onChange={onChange}
					disabled={disabled}
					hidden={hidden}
					{...othersInputWithAddonProps}
				/>

				{addonPosition === 'right' && (
					<InputRightAddon
						p={0}
						borderRadius={10}
						border={'trasparent'}
						h={10}
					>
						{addonContent}
					</InputRightAddon>
				)}
			</InputGroup>
		);
	}

	function renderCheckbox() {
		return (
			<Checkbox
				name={name}
				value={value}
				onChange={onChange}
				disabled={disabled}
				hidden={hidden}
				{...others}
			/>
		);
	}

	function renderReactSelect() {
		return (
			<Select
				name={name}
				value={value}
				onChange={onChange}
				isClearable
				isSearchable
				{...others}
			/>
		);
	}

	function renderDefault() {
		const dateTypes = ['date', 'time', 'month', 'datetime-local', 'week'];

		const dateRestrictions = dateTypes.includes(type)
			? {
					max: moment().add(2, 'year').format('YYYY-MM-DD'),
			  }
			: undefined;
		const { icon } = others;
		const input = (
			<Input
				type={type}
				name={name}
				value={value}
				onChange={onChange}
				disabled={disabled}
				hidden={hidden}
				{...dateRestrictions}
				{...others}
			/>
		);
		return icon ? (
			<Box
				display={'flex'}
				alignItems={'center'}
			>
				<Input
					{...input.props}
					paddingRight={'30px'}
				/>
				<Box
					position={'absolute'}
					right={'2px'}
					height={'100%'}
					display={'flex'}
					alignItems={'center'}
					padding={'0 5px'}
				>
					{icon}
				</Box>
			</Box>
		) : (
			input
		);
	}

	function getInput() {
		switch (type) {
			case 'autocomplete':
				return renderAutoComplete();
			case 'autocomplete-with-data':
				return renderAutoCompleteWithData();
			case 'autocomplete-infinite-scroll':
				return renderAutoCompleteInfiniteScroll();
			case 'timemask':
				return renderTimeMask();
			case 'money':
				return renderMoneyField();
			case 'decimal':
				return renderDecimalField();
			case 'product-quantity':
				return renderProductQuanityField();
			case 'select':
				return renderSelect();
			case 'react-select':
				return renderReactSelect();
			case 'switch':
				return renderSwitch();
			case 'textarea':
				return renderTextArea();
			case 'checkbox':
				return renderCheckbox();
			case 'input-with-addon':
				return renderInputWithAddon();
			default:
				return renderDefault();
		}
	}

	return tooltip ? (
		<Tooltip
			label={tooltip}
			{..._tooltipProps}
		>
			<span>{getInput()}</span>
		</Tooltip>
	) : (
		getInput()
	);
};

export default GenericInput;
