import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Flex,
	Button,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { LoadingSpinner } from 'components/loading/Loading';
interface StylesTypes {
	color?: string;
	colorArrow?: string;
	bg?: string;
	size?: string;
	notLoading?: boolean;
	messageLoading?: string;
	emptyColor?: string;
	disable?: boolean;
}

const valuesSpecial: Record<string, StylesTypes> = {
	aiPrediction: {
		color: 'white',
		bg: 'linear-gradient(180deg, rgba(240,36,62,1) 0%, rgba(176,26,46,1) 100%)',
		messageLoading: 'Processamento IA em andamento...',
		emptyColor: 'easyRED.400',
		size: 'md',
	},
	manual: {
		notLoading: true,
	},
};
const specialType: Record<string, StylesTypes> = {
	exceptionEdit: {
		color: 'easyRED.300',
		colorArrow: 'easyBLUE.300',
	},
	exception: {
		disable: true,
	},
};

type SelectComponentType = 'exceptionEdit' | 'exception' | 'common';
interface SelectComponentProps {
	options: { value: string; label: string }[];
	value: string;
	onChange: (value: string) => void;
	isLoading: boolean;
	type?: SelectComponentType;
}
const SelectComponent = ({
	options,
	value,
	onChange,
	isLoading,
	type,
}: SelectComponentProps) => {
	const special = specialType[type || ''] || valuesSpecial[value];

	const currentValue =
		options?.find((option) => option.value === value)?.label ||
		'Não especificado';

	return (
		<Menu>
			<MenuButton
				data-test='select-colab_consenso'
				as={Button}
				bg={special?.bg}
				color={special?.color}
				border='1px solid rgb(226, 232, 240)'
				textAlign='left'
				width='12rem'
				height='56px'
				disabled={special?.disable}
				rightIcon={
					!special?.notLoading && isLoading ? (
						<Flex>
							<LoadingSpinner
								message={false}
								speed={'1.80s'}
								label={
									special?.messageLoading || 'Carregando...'
								}
								emptyColor={special?.emptyColor}
								size={special?.size || 'sm'}
								color={special?.color}
							/>
							<ChevronDownIcon />
						</Flex>
					) : (
						<ChevronDownIcon color={special?.colorArrow} />
					)
				}
				_hover={{
					background: special?.bg,
				}}
				_active={{ background: special?.bg }}
			>
				{currentValue}
			</MenuButton>
			<MenuList>
				{options.map((option) => (
					<MenuItem
						key={option.value}
						value={option.value}
						onClick={() => onChange(option.value)}
					>
						{option.label}
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
};

export default SelectComponent;
