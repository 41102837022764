// estrutura básica
import {
	ButtonGroup,
	Flex,
	FormControl,
	FormLabel,
	Select,
	Tooltip,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Box, Text } from '@chakra-ui/react';
import CircularProgress from 'components/charts/CircularProgress';

// Custom components
import { useQueryClient } from '@tanstack/react-query';
import Card from 'components/card/Card.js';
import { useGetProjectionFilters } from 'services/queryClient/wrapperHooks/projection/useGetProjectionFilters';
import { useCreateProjection } from 'services/queryClient/wrapperHooks/projection/useCreateProjection';
import { nameMonths } from 'utils/consts/months';
import { useGetHolidays } from 'services/queryClient/wrapperHooks/projection/useGetHolidays';
import { useGetProjectionByDate } from 'services/queryClient/wrapperHooks/projection/useGetProjectionByDate';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { ScrollBar } from 'components/scroolbar/Scrollbar';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

const Styles = styled.div`
	width: 100%;

	table {
		border-collapse: collapse;
		text-align: center;
		vertical-align: middle;
		width: 100%;
		border-collapse: collapse;
		border-radius: 6px;
		overflow: hidden;
	}
`;

export default function CadenciaVendas() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	useEffect(() => {
		const helpCadastrarEstoque: SidebarHelp = {
			title: 'Cadência de Vendas',
			firstParagraph: `A projeção orienta a Cadência de Vendas do mês selecionado. Entre com a sua projeção ao longo dos dias do mês. Observação: não é permitido atingir uma projeção maior que 100%, portanto cuide para distribuir sua cadência ao longo dos dias de forma adequada.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		};
		setDataSidebar(helpCadastrarEstoque);
	}, []);
	const toast = useToast();
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const easyBLUE = useColorModeValue('easyBLUE.300', 'easyBLUE.200');
	const easyRED = useColorModeValue('easyRED.300', 'white');

	const [dias, setDias]: any = useState([]);

	const [totalCadencia, setTotalCadencia] = useState(0);

	const queryClient = useQueryClient();

	const anoAtual = new Date().getFullYear();
	const mesAtual = new Date().getMonth() + 1;

	const [mes, setMes] = useState(mesAtual);
	const [ano, setAno] = useState(anoAtual);

	const [hollydays, setHollydays] = useState<Record<number, string>>({});

	// use useGetProjectionFilters to get anos
	const { data: filters } = useGetProjectionFilters(ano);
	const { data: dataHollydays } = useGetHolidays(mes, ano);

	const { data } = useGetProjectionByDate(mes, ano);

	const changeMes = (e) => {
		setMes(e.target.value);
	};
	const changeAno = (e) => {
		setAno(e.target.value);
	};
	useEffect(() => {
		if (dataHollydays) {
			const newHollydays = {};
			dataHollydays.map(({ date, description }) => {
				const dia = date.substring(8, 10);
				newHollydays[dia] = description;
			});
			setHollydays({ ...newHollydays });
		}
	}, [dataHollydays]);

	const temCasasDecimais = (valor: number): boolean => {
		return valor !== Math.floor(valor);
	};

	useEffect(() => {
		if (data) {
			let dias: any[] = [];
			let quantidadeDias = new Date(ano, mes, 0).getDate();
			for (let i = 1; i <= quantidadeDias; i++) {
				let cadencia = data.filter((item) => {
					if (item.data.split('-')[2] === i) {
						return item.cadencia_venda_dia;
					} else {
						return 0;
					}
				});
				dias.push({
					dia: i,
					cadencia_venda_dia: cadencia[0]
						? cadencia[0].cadencia_venda_dia
						: 0,
				});
			}

			setDias(dias);
			// reduce para somar todos os valores de cadencia_venda_dia
			let totalCadencia = dias.reduce((acc, item) => {
				return acc + item.cadencia_venda_dia;
			}, 0);

			let formattedTotal;
			if (temCasasDecimais(totalCadencia)) {
				formattedTotal = totalCadencia.toFixed(2);
			} else {
				formattedTotal = Math.floor(totalCadencia);
			}

			setTotalCadencia(formattedTotal);
		}
	}, [data]);
	const changeCadencia = (e, index) => {
		const novosDias = [...dias];
		const inputValue = e.target.value;

		const soma =
			totalCadencia +
			Number(inputValue) -
			Number(dias[index].cadencia_venda_dia);

		// se soma total de cadencias for maior que 100, não deixa salvar e mostra toast warning

		if (soma > 100) {
			toast({
				title: 'Erro ao receber valor!',
				description:
					'Cadência de vendas não pode ser maior que 100%! Por favor, distribua os valores ao longo do mês para totalizar 100%!',
				position: 'bottom-left',
				status: 'warning',
				duration: 8000,
				isClosable: true,
			});
		} else {
			novosDias[index].cadencia_venda_dia = inputValue;
			setDias(novosDias);
			somaCadencia();
		}
	};

	const body = {
		ano: ano,
		mes: mes,
		cadencias_venda: dias,
	};

	const { mutate, isLoading: isLoadingMutate } = useCreateProjection(body);

	const salvar = () => {
		mutate();
	};

	const cancelar = () => {
		queryClient.invalidateQueries(['projectionByDate']);
	};

	// somar todos os cadencias_venda_dia
	const somaCadencia = () => {
		let soma = 0;
		dias.forEach((dia) => {
			soma += Number(dia.cadencia_venda_dia);
		});

		let newtotalCadencia;
		if (temCasasDecimais(soma)) {
			newtotalCadencia = +soma.toFixed(2);
		} else {
			newtotalCadencia = Math.floor(soma);
		}
		setTotalCadencia(newtotalCadencia);
	};

	const isWeekendOrHollyday = (
		day,
		month,
		year,
	): { color: string; message?: string } => {
		const date = new Date(year, month - 1, day);
		if (hollydays[day]) {
			return {
				message: hollydays[day],
				color: easyRED,
			};
		}
		if (date.getDay() === 0) {
			return {
				message: 'Domingo',
				color: easyRED,
			};
		}
		if (date.getDay() === 6) {
			return {
				message: 'Sábado',
				color: easyBLUE,
			};
		}
		return {
			color: '#000',
		};
	};

	return (
		<Flex
			flexDirection={'column'}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
		>
			<Flex
				justifyContent='space-between'
				mt={'24px'}
				wrap='wrap'
				gap={4}
			>
				<Flex>
					<FormControl
						mr='5px'
						mb='20px'
					>
						<FormLabel mb='0'>Ano</FormLabel>
						<Select
							data-test='select-ano-page-cadencia_de_vendas'
							placeholder='Selecione'
							name={'cod_produto'}
							h='44px'
							borderRadius={10}
							onChange={(e) => changeAno(e)}
							value={ano}
						>
							{filters?.anos.map((ano, index) => (
								<option
									key={index + 1}
									value={ano.ano}
								>
									{ano.ano}
								</option>
							))}
						</Select>
					</FormControl>

					<FormControl
						mr='5px'
						mb='20px'
					>
						<FormLabel mb='0'>Mês</FormLabel>
						<Select
							data-test='select-mes-page-cadencia_de_vendas'
							placeholder='Selecione'
							name={'mes'}
							h='44px'
							borderRadius={10}
							onChange={(e) => changeMes(e)}
							value={mes}
						>
							{filters?.meses.map((mes, index) => (
								<option
									key={index + 1}
									value={mes.mes}
								>
									{nameMonths[mes.mes - 1]}
								</option>
							))}
						</Select>
					</FormControl>
				</Flex>
			</Flex>

			<Styles>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '80% 20%',
						gap: 10,
					}}
				>
					<Card
						p='13px'
						align='center'
						direction='column'
						w='100%'
					>
						<div>
							<div
								style={{
									display: 'grid',
									gridTemplateColumns: '200px 1fr',
								}}
							>
								<div>
									<div
										style={{
											display: 'grid',
											gridTemplateRows: '1fr 1.5fr',
										}}
									>
										<div
											style={{
												backgroundColor: '#e0e5f2',
												paddingTop: '10px',
												textAlign: 'center',
												height: '40px',
												borderRight:
													'1px solid #d5dae6',
											}}
										>
											ANO {ano} - DIAS
										</div>
										<div
											style={{
												backgroundColor: '#f9fbfe',
												paddingTop: '20px',
												textAlign: 'center',
												borderRight:
													'1px solid #d5dae6',
											}}
										>
											Projeção (%)
										</div>
									</div>
								</div>
								<ScrollBar color='secondary'>
									<div>
										<div
											style={{
												display: 'grid',
												gridTemplateRows: '1fr 1.5fr',
											}}
										>
											<div
												style={{
													backgroundColor: '#e0e5f2',
													textAlign: 'center',
													height: '40px',
													display: 'grid',
													gridTemplateColumns: `repeat(${dias.length}, 55px)`,
												}}
											>
												{dias.map((dia, index) => {
													const isWeekend =
														isWeekendOrHollyday(
															dia.dia,
															mes,
															ano,
														);
													if (isWeekend.message) {
														return (
															<Tooltip
																label={
																	isWeekend.message
																}
																placement='top'
																bg='gray.200'
																color={
																	'blue.800'
																}
																hasArrow
																arrowSize={15}
															>
																<Text
																	key={index}
																	color={
																		isWeekend.color
																	}
																	style={{
																		paddingTop:
																			'10px',
																		borderRight:
																			'1px solid #d5dae6',
																	}}
																>
																	{dia.dia < 9
																		? '0' +
																		  dia.dia
																		: dia.dia}
																</Text>
															</Tooltip>
														);
													}
													return (
														<div
															key={index}
															style={{
																paddingTop:
																	'10px',
																borderRight:
																	'1px solid #d5dae6',
																color: isWeekend.color,
															}}
														>
															{dia.dia < 9
																? '0' + dia.dia
																: dia.dia}
														</div>
													);
												})}
											</div>
											<div
												style={{
													backgroundColor: '#f9fbfe',
													display: 'grid',
													textAlign: 'center',
													gridTemplateColumns: `repeat(${dias.length}, 55px)`,
												}}
											>
												{!isLoadingMutate &&
													dias.map((dia, index) => (
														<div
															key={index}
															style={{
																backgroundColor:
																	'#f9fbfe',
																textAlign:
																	'center',
																borderRight:
																	'1px solid #d5dae6',
																paddingTop:
																	'10px',
																paddingLeft:
																	'10px',
																paddingRight:
																	'10px',
															}}
														>
															<input
																type='number'
																placeholder='%'
																style={{
																	width: '100%',
																	textAlign:
																		'center',
																	height: '40px',
																	marginRight:
																		'5px',
																	border: '1px solid #d5dae6',
																	borderRadius:
																		'10px',
																}}
																value={
																	dia.cadencia_venda_dia
																}
																onChange={(e) =>
																	changeCadencia(
																		e,
																		index,
																	)
																}
															/>
														</div>
													))}
											</div>
										</div>
									</div>
								</ScrollBar>
							</div>

							<Flex
								style={{ backgroundColor: '#567eb3' }}
								h='50px'
								marginTop='20px'
							>
								<Flex
									marginLeft='auto'
									paddingRight={'20px'}
									paddingTop='5px'
								>
									<ButtonGroup spacing={'10px'}>
										<ButtonComponent
											data-test='button-cancelar-page-cadencia_de_vendas'
											type={'ghost'}
											title={'Cancelar'}
											onClick={() => cancelar()}
											textColor={'white'}
										/>
										<ButtonComponent
											data-test='button-salvar-page-cadencia_de_vendas'
											type={'primary'}
											title={'Salvar'}
											onClick={() => salvar()}
										/>
									</ButtonGroup>
								</Flex>
							</Flex>
						</div>
					</Card>
					<div>
						<Card
							p='13px'
							align='center'
							direction='column'
							w='100%'
						>
							<Text
								color={textColor}
								fontSize='2xl'
								fontWeight='700'
								mb='0px'
								mx='auto'
								textAlign={'center'}
							>
								Total da Projeção Mensal
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize='sm'
								fontWeight='500'
								maxW='350px'
								mx='auto'
								mb='10px'
							>
								Confira o mês
							</Text>

							<Box
								w='120px'
								mx='auto'
								mb='10px'
								mt='0px'
							>
								<CircularProgress
									percentage={totalCadencia}
									color={
										totalCadencia > 60
											? '#02b574'
											: '#f16264'
									}
								/>
							</Box>
						</Card>
					</div>
				</div>
			</Styles>
		</Flex>
	);
}
