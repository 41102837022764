import {
	Flex,
	FormControl,
	FormLabel,
	Tabs,
	TabList,
	Tab,
	TabPanel,
	TabPanels,
	Tooltip,
	useDisclosure,
	Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import SalesCadence from './components/SalesCadence';
import SalesDashboardTable from './components/SalesDashboardTable';
import MiniStatisticsCards from './components/MiniStatisticsCards';
import Speedometer from 'components/chart/Speedometer';
import { speedometerChartOptions } from './variables/charts';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
	useGetSalesDashboardAta,
	useGetSalesDashboardCadencia,
	useGetSalesDashboardEstatisticas,
} from 'services/queryClient/wrapperHooks/dashboardVendas/useGetSalesDashboard';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import GenericInput from '../../../../../components/inputs/GenericInput';
import { IconFilter } from '@tabler/icons';
import { defaultTooltipProps } from '../../../../../utils/forms/defaultsProps';
import AtaVendasModal from '../../../../../components/modal/AtaVendasModal';
import SalesByFamilyDashboardTable from './components/SalesByFamilyDashboardTable';
import { LoadingSpinner } from 'components/loading/Loading';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

interface FilterInput {
	startDate: string;
	endDate: string;
}

const DashboardVendas = () => {
	const { setters } = useContext(SidebarHelpContext);

	const startMonth = moment().startOf('month');
	const currentDate = moment().startOf('day');

	const { setDataSidebar } = setters;

	const helpCadastrarEstoque: SidebarHelp = useMemo(
		() => ({
			title: 'Dashboard de Vendas',
			firstParagraph: `Este dashboard permite o acompanhamento da Cadência de Vendas semanal sempre levando em consideração a demanda (plano restrito), meta de vendas e histórico. Use os filtros de data para selecionar os dados desejados.`,
			secondParagraph: `Para analisar as vendas por canais de venda utilize como auxiliar a tabela, nela é possível comparar sua meta, carteira boa, faturado e forecast.`,
			thirdParagraph: `O gráfico de cadência de vendas se refere aos dias do mês selecionado no filtro, sendo possível comparar sua cadência de vendas com o andamento do mês atual, mês passado e o histórico.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);
	useEffect(() => {
		setDataSidebar(helpCadastrarEstoque);
	}, [helpCadastrarEstoque, setDataSidebar]);

	const [filterInput, setFilterInput] = useState<FilterInput>({
		startDate: startMonth.format('YYYY-MM-DD'),
		endDate: currentDate.format('YYYY-MM-DD'),
	});

	const [filterRangeDates, setFilterRangeDates] = useState<any>({
		startDate: startMonth.format('YYYY-MM-DD'),
		endDate: currentDate.format('YYYY-MM-DD'),
	});

	const [paginationCanalVenda, setPaginationCanalVenda] = useState<any>({
		dashboardType: 'canal_venda',
		page: 0,
		limit: 3,
	});

	const [paginationFamiliaReceita, setPaginationFamiliaReceita] =
		useState<any>({
			dashboardType: 'familia_receita_bruta',
			page: 0,
			limit: 3,
		});

	const [paginationFamiliaQuantidade, setPaginationFamiliaQuantidade] =
		useState<any>({
			dashboardType: 'familia_quantidade',
			page: 0,
			limit: 3,
		});

	const [dataCanalVenda, setDataCanalVenda] = useState<any>([]);
	const [dataFamiliaReceita, setDataFamiliaReceita] = useState<any>([]);
	const [dataFamiliaQuantidade, setDataFamiliaQuantidade] = useState<any>([]);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const {
		data: DataSalesDashboardAta,
		refetch: RefetchDataSalesDashboardAta,
	} = useGetSalesDashboardAta(filterInput.startDate, filterInput.endDate);

	const { data: DataSalesDashboardCadencia } = useGetSalesDashboardCadencia(
		filterInput.startDate,
		filterInput.endDate,
	);

	const {
		data: DataSalesDashboardEstatisticasCanalVenda,
		isLoading: isLoadingSalesDashboardEstatisticasCanalVenda,
		mutate: RefetchDataSalesDashboardEstatisticasCanalVenda,
	} = useGetSalesDashboardEstatisticas(
		'estatistica',
		filterInput.startDate,
		filterInput.endDate,
		paginationCanalVenda.dashboardType,
		paginationCanalVenda.page,
		paginationCanalVenda.limit,
	);

	const {
		data: DataSalesDashboardEstatisticasCanalVendaTotal,
		isLoading: isLoadingDataSalesDashboardEstatisticasCanalVendaTotal,
		mutate: RefetchDataSalesDashboardEstatisticasCanalVendaTotal,
	} = useGetSalesDashboardEstatisticas(
		'estatistica/total',
		filterInput.startDate,
		filterInput.endDate,
		paginationCanalVenda.dashboardType,
	);

	/**
	 * Familia Receita
	 */
	const {
		data: DataSalesDashboardEstatisticasFamiliaReceita,
		isLoading: isLoadingSalesDashboardEstatisticasFamiliaReceita,
		mutate: RefetchDataSalesDashboardEstatisticasFamiliaReceita,
	} = useGetSalesDashboardEstatisticas(
		'estatistica',
		filterInput.startDate,
		filterInput.endDate,
		paginationFamiliaReceita.dashboardType,
		paginationFamiliaReceita.page,
		paginationFamiliaReceita.limit,
	);

	const {
		data: DataSalesDashboardEstatisticasFamiliaReceitaTotal,
		mutate: RefetchDataSalesDashboardEstatisticasFamiliaReceitaTotal,
	} = useGetSalesDashboardEstatisticas(
		'estatistica/total',
		filterInput.startDate,
		filterInput.endDate,
		paginationFamiliaReceita.dashboardType,
	);

	/**
	 * Familia Quantidade
	 */
	const {
		data: DataSalesDashboardEstatisticasFamiliaQuantidade,
		isLoading: isLoadingSalesDashboardEstatisticasFamiliaQuantidade,
		mutate: RefetchDataSalesDashboardEstatisticasFamiliaQuantidade,
	} = useGetSalesDashboardEstatisticas(
		'estatistica',
		filterInput.startDate,
		filterInput.endDate,
		paginationFamiliaQuantidade.dashboardType,
		paginationFamiliaQuantidade.page,
		paginationFamiliaQuantidade.limit,
	);

	const {
		data: DataSalesDashboardEstatisticasFamiliaQuantidadeTotal,
		mutate: RefetchDataSalesDashboardEstatisticasFamiliaQuantidadeTotal,
	} = useGetSalesDashboardEstatisticas(
		'estatistica/total',
		filterInput.startDate,
		filterInput.endDate,
		paginationFamiliaQuantidade.dashboardType,
	);

	const [faturadoBoa, setFaturadoBoa] = useState<number>(0);
	const [percentToMeta, setPercentToMeta] = useState<number>(0);

	const getTableData = useCallback(() => {
		RefetchDataSalesDashboardEstatisticasCanalVenda?.();
		RefetchDataSalesDashboardEstatisticasCanalVendaTotal?.();
		RefetchDataSalesDashboardEstatisticasFamiliaReceita?.();
		RefetchDataSalesDashboardEstatisticasFamiliaReceitaTotal?.();
		RefetchDataSalesDashboardEstatisticasFamiliaQuantidade?.();
		RefetchDataSalesDashboardEstatisticasFamiliaQuantidadeTotal?.();
	}, [
		RefetchDataSalesDashboardEstatisticasCanalVenda,
		RefetchDataSalesDashboardEstatisticasCanalVendaTotal,
		RefetchDataSalesDashboardEstatisticasFamiliaQuantidade,
		RefetchDataSalesDashboardEstatisticasFamiliaQuantidadeTotal,
		RefetchDataSalesDashboardEstatisticasFamiliaReceita,
		RefetchDataSalesDashboardEstatisticasFamiliaReceitaTotal,
	]);

	const arraySort = useCallback((a: any, b: any, keyForName: string) => {
		function hasValue(value) {
			return value !== undefined && value !== null && value !== '';
		}

		if (hasValue(a?.meta) || hasValue(b?.meta)) {
			if (a?.meta > b?.meta) return -1;
		}

		return a[keyForName];
	}, []);

	/**
	 * Atualizando array canal de vendas
	 */
	useEffect(() => {
		if (
			!isLoadingSalesDashboardEstatisticasCanalVenda &&
			!!DataSalesDashboardEstatisticasCanalVenda?.estatisticaVendasByCanal
				?.length
		)
			setDataCanalVenda((prev) =>
				[
					...prev,
					...DataSalesDashboardEstatisticasCanalVenda?.estatisticaVendasByCanal,
				]
					?.filter(
						(el, index, self) =>
							index === self?.findIndex((t) => t?.id === el?.id),
					)
					?.sort((a, b) => arraySort(a, b, 'nome_canal_venda')),
			);
	}, [
		DataSalesDashboardEstatisticasCanalVenda?.estatisticaVendasByCanal,
		arraySort,
		isLoadingSalesDashboardEstatisticasCanalVenda,
	]);

	/**
	 * Atualizando array família receita
	 */
	useEffect(() => {
		if (
			!isLoadingSalesDashboardEstatisticasFamiliaReceita &&
			!!DataSalesDashboardEstatisticasFamiliaReceita
				?.estatisticaVendasByFamilia?.length
		)
			setDataFamiliaReceita((prev) =>
				[
					...prev,
					...DataSalesDashboardEstatisticasFamiliaReceita?.estatisticaVendasByFamilia,
				]
					?.filter(
						(el, index, self) =>
							index === self?.findIndex((t) => t?.id === el?.id),
					)
					?.sort((a, b) => arraySort(a, b, 'nome_familia')),
			);
	}, [
		DataSalesDashboardEstatisticasFamiliaReceita?.estatisticaVendasByFamilia,
		arraySort,
		isLoadingSalesDashboardEstatisticasFamiliaReceita,
	]);

	/**
	 * Atualizando array família quantidade
	 */
	useEffect(() => {
		if (
			!isLoadingSalesDashboardEstatisticasFamiliaQuantidade &&
			!!DataSalesDashboardEstatisticasFamiliaQuantidade
				?.estatisticaVendasByFamilia?.length
		)
			setDataFamiliaQuantidade((prev) =>
				[
					...prev,
					...DataSalesDashboardEstatisticasFamiliaQuantidade?.estatisticaVendasByFamilia,
				]
					?.filter(
						(el, index, self) =>
							index === self?.findIndex((t) => t?.id === el?.id),
					)
					?.sort((a, b) => arraySort(a, b, 'nome_familia')),
			);
	}, [
		DataSalesDashboardEstatisticasFamiliaQuantidade?.estatisticaVendasByFamilia,
		arraySort,
		isLoadingSalesDashboardEstatisticasFamiliaQuantidade,
	]);

	useEffect(() => {
		if (
			!!DataSalesDashboardEstatisticasCanalVendaTotal &&
			filterInput?.startDate &&
			filterInput?.endDate
		) {
			const { faturadoMaisCarteiraBoa, meta } =
				DataSalesDashboardEstatisticasCanalVendaTotal || {};

			const calculationFaturadoBoa: number = faturadoMaisCarteiraBoa;
			const calculationPercentToMeta: number =
				meta === 0
					? 0.5
					: (Number(calculationFaturadoBoa) * 100) / meta;

			setFaturadoBoa(calculationFaturadoBoa);
			if (calculationPercentToMeta > 100) {
				setPercentToMeta(100);
			} else if (
				calculationPercentToMeta > 0 &&
				calculationPercentToMeta < 1 &&
				calculationPercentToMeta !== 0.5
			) {
				setPercentToMeta(1);
			} else {
				setPercentToMeta(calculationPercentToMeta);
			}
		} else {
			setFaturadoBoa(0);
			setPercentToMeta(0);
		}
	}, [
		DataSalesDashboardEstatisticasCanalVendaTotal,
		filterInput?.startDate,
		filterInput?.endDate,
	]);

	useEffect(
		() => {
			getTableData?.();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	/**
	 * verificando se existem mais canal de vendas
	 */
	useEffect(() => {
		if (
			!isLoadingSalesDashboardEstatisticasCanalVenda &&
			DataSalesDashboardEstatisticasCanalVenda?.estatisticaVendasByCanal
				?.length === paginationCanalVenda?.limit
		) {
			setPaginationCanalVenda((prev) => ({
				...prev,
				page: prev.page + 1,
			}));

			setTimeout(
				() => RefetchDataSalesDashboardEstatisticasCanalVenda?.(),
				500,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		DataSalesDashboardEstatisticasCanalVenda?.estatisticaVendasByCanal
			?.length,
		isLoadingSalesDashboardEstatisticasCanalVenda,
	]);

	/**
	 * verificando se existem mais famílias por receita
	 */
	useEffect(() => {
		if (
			!isLoadingSalesDashboardEstatisticasFamiliaReceita &&
			DataSalesDashboardEstatisticasFamiliaReceita
				?.estatisticaVendasByFamilia?.length ===
				paginationFamiliaReceita?.limit
		) {
			setPaginationFamiliaReceita((prev) => ({
				...prev,
				page: prev.page + 1,
			}));

			setTimeout(
				() => RefetchDataSalesDashboardEstatisticasFamiliaReceita?.(),
				500,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		DataSalesDashboardEstatisticasFamiliaReceita?.estatisticaVendasByFamilia
			?.length,
		isLoadingSalesDashboardEstatisticasFamiliaReceita,
	]);

	/**
	 * verificando se existem mais famílias por quantidade
	 */
	useEffect(() => {
		if (
			!isLoadingSalesDashboardEstatisticasFamiliaQuantidade &&
			DataSalesDashboardEstatisticasFamiliaQuantidade
				?.estatisticaVendasByFamilia?.length ===
				paginationFamiliaQuantidade?.limit
		) {
			setPaginationFamiliaQuantidade((prev) => ({
				...prev,
				page: prev.page + 1,
			}));

			setTimeout(
				() =>
					RefetchDataSalesDashboardEstatisticasFamiliaQuantidade?.(),
				500,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		DataSalesDashboardEstatisticasFamiliaQuantidade
			?.estatisticaVendasByFamilia?.length,
		isLoadingSalesDashboardEstatisticasFamiliaQuantidade,
	]);

	const renderInputDate = (name, inputName) => {
		function handleOnChangeDate(e) {
			const value = e?.target?.value;

			const startDate = moment(value, 'YYYY-MM-DD')
				.startOf('month')
				.format('YYYY-MM-DD');

			setFilterRangeDates({
				startDate,
				endDate: value,
			});
		}

		return (
			<GenericInput
				type='date'
				inputProps={{
					name,
					borderRadius: 10,
					value: filterRangeDates[inputName],
					onChange: (e) => {
						const value = e?.target?.value;

						if (value) handleOnChangeDate(e);
					},
					'data-test': 'input-data-page-dashboard_de_vendas',
				}}
			/>
		);
	};

	function resetPagination() {
		setPaginationCanalVenda((prev) => ({ ...prev, page: 0 }));
		setPaginationFamiliaReceita((prev) => ({ ...prev, page: 0 }));
		setPaginationFamiliaQuantidade((prev) => ({ ...prev, page: 0 }));

		setTimeout(() => getTableData?.(), 500);
	}

	function applyFilter() {
		setDataCanalVenda([]);
		setDataFamiliaReceita([]);
		setDataFamiliaQuantidade([]);

		setTimeout(() => resetPagination(), 300);

		setTimeout(
			() =>
				setFilterInput({
					...filterInput,
					endDate: filterRangeDates?.endDate,
					startDate: filterRangeDates?.startDate,
				}),
			750,
		);
	}

	const defaultStyleProps = useMemo(
		() => ({
			_selected: {
				color: '#0296f2',
				borderBottom: '1px solid #0296f2',
			},
			_hover: {
				backgroundColor: '#e1e8ef',
			},
			_focus: {
				outline: '0 !important',
			},
			color: 'easyBLUE.300',
		}),
		[],
	);

	const dataMiniStatisticsMemoized = useMemo(() => {
		return {
			...DataSalesDashboardEstatisticasCanalVendaTotal,
			cadenciaVendasEstatisticas: DataSalesDashboardCadencia,
		};
	}, [
		DataSalesDashboardCadencia,
		DataSalesDashboardEstatisticasCanalVendaTotal,
	]);

	const dataSalesChannelMemoized = useMemo(() => {
		return {
			estatisticaVendasByCanal: dataCanalVenda,
			estatisticaVendasTotal:
				DataSalesDashboardEstatisticasCanalVendaTotal,
		};
	}, [dataCanalVenda, DataSalesDashboardEstatisticasCanalVendaTotal]);

	const dataRecipePerFamilyMemoized = useMemo(() => {
		return {
			estatisticaVendasByFamiliaReceita: dataFamiliaReceita,
			estatisticaVendasByFamiliaReceitaTotal:
				DataSalesDashboardEstatisticasFamiliaReceitaTotal,
		};
	}, [dataFamiliaReceita, DataSalesDashboardEstatisticasFamiliaReceitaTotal]);

	const dataQuantityPerFamilyMemoized = useMemo(() => {
		return {
			estatisticaVendasByFamiliaQuantidade: dataFamiliaQuantidade,
			estatisticaVendasByFamiliaQuantidadeTotal:
				DataSalesDashboardEstatisticasFamiliaQuantidadeTotal,
		};
	}, [
		dataFamiliaQuantidade,
		DataSalesDashboardEstatisticasFamiliaQuantidadeTotal,
	]);

	const renderTabSalesChannel = useCallback(() => {
		if (
			!isLoadingSalesDashboardEstatisticasCanalVenda &&
			!dataSalesChannelMemoized?.estatisticaVendasByCanal?.length
		)
			return (
				<Flex
					w='100%'
					justifyContent='center'
					alignItems='center'
				>
					<Text color='easyBlue.300'>
						Não existem dados! Verifique se existe ao menos um canal
						de vendas com orçamento cadastrado ou que esteja
						vinculado a uma hierarquia comercial.
					</Text>
				</Flex>
			);

		if (
			!dataSalesChannelMemoized?.estatisticaVendasByCanal?.length ||
			!dataSalesChannelMemoized?.estatisticaVendasTotal
		)
			return <LoadingSpinner message='Carregando canais de vendas...' />;

		return (
			<SalesDashboardTable
				dataSalesDashboard={dataSalesChannelMemoized}
				date={moment(filterInput?.endDate).toDate()}
			/>
		);
	}, [
		isLoadingSalesDashboardEstatisticasCanalVenda,
		dataSalesChannelMemoized,
		filterInput?.endDate,
	]);

	const renderTabFamilyRevenue = useCallback(() => {
		if (
			!dataRecipePerFamilyMemoized?.estatisticaVendasByFamiliaReceita
				?.length ||
			!dataRecipePerFamilyMemoized?.estatisticaVendasByFamiliaReceitaTotal
		)
			return <LoadingSpinner message='Carregando familias...' />;

		return (
			<SalesByFamilyDashboardTable
				dataSalesDashboard={dataRecipePerFamilyMemoized}
				rowsKey={'estatisticaVendasByFamiliaReceita'}
				totalKey={'estatisticaVendasByFamiliaReceitaTotal'}
				date={moment(filterInput?.endDate).toDate()}
				type='receita_bruta'
			/>
		);
	}, [dataRecipePerFamilyMemoized, filterInput?.endDate]);

	const renderTabFamilyQuantity = useCallback(() => {
		if (
			!dataQuantityPerFamilyMemoized?.estatisticaVendasByFamiliaQuantidade
				?.length ||
			!dataQuantityPerFamilyMemoized?.estatisticaVendasByFamiliaQuantidadeTotal
		)
			return <LoadingSpinner message='Carregando familias...' />;

		return (
			<SalesByFamilyDashboardTable
				dataSalesDashboard={dataQuantityPerFamilyMemoized}
				rowsKey={'estatisticaVendasByFamiliaQuantidade'}
				totalKey={'estatisticaVendasByFamiliaQuantidadeTotal'}
				date={moment(filterInput?.endDate).toDate()}
				type='quantidade'
			/>
		);
	}, [dataQuantityPerFamilyMemoized, filterInput?.endDate]);

	const isLoadingData =
		isLoadingSalesDashboardEstatisticasFamiliaQuantidade ||
		DataSalesDashboardEstatisticasFamiliaQuantidade
			?.estatisticaVendasByFamilia?.length ===
			paginationFamiliaQuantidade?.limit ||
		isLoadingSalesDashboardEstatisticasCanalVenda ||
		DataSalesDashboardEstatisticasCanalVenda?.estatisticaVendasByCanal
			?.length === paginationCanalVenda?.limit ||
		isLoadingSalesDashboardEstatisticasFamiliaReceita ||
		DataSalesDashboardEstatisticasFamiliaReceita?.estatisticaVendasByFamilia
			?.length === paginationFamiliaReceita?.limit;

	return (
		<Flex
			direction={'column'}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
		>
			<Card
				width={{ base: '100%', md: '100%', xl: '100%' }}
				marginBottom={3.5}
			>
				<Flex>
					<Flex w='25%'>
						<FormControl mr={5}>
							<FormLabel
								fontSize={'sm'}
								fontWeight={500}
								color={'easyBLUE.300'}
							>
								Data
							</FormLabel>
							{renderInputDate('data_fim', 'endDate')}
						</FormControl>
						<Tooltip
							label={
								isLoadingData
									? 'Aguarde o carregamento de todos os dados para aplicar o filtro novamente.'
									: 'Filtrar'
							}
							{...defaultTooltipProps}
						>
							<span>
								<ButtonComponent
									data-test='button-filtrar-dashboard_de_vendas'
									type='icon'
									icon={<IconFilter />}
									onClick={applyFilter}
									mt='30px'
									disabled={isLoadingData}
								/>
							</span>
						</Tooltip>
					</Flex>
					<Flex
						paddingY={4}
						w='75%'
						display='flex'
						justifyContent='end'
						alignItems='end'
					>
						<ButtonComponent
							data-test='button-ata-dashboard_de_vendas'
							type='secondary'
							title='ATA'
							onClick={onOpen}
							marginTop='15px'
						/>
						{/* Modal de Ata */}
						{isOpen && (
							<AtaVendasModal
								isOpen={isOpen}
								onClose={onClose}
								provider={'DASHBOARD_VENDA'}
								data={DataSalesDashboardAta}
								endDate={filterInput?.endDate}
								refetchData={RefetchDataSalesDashboardAta}
							/>
						)}
					</Flex>
				</Flex>
			</Card>
			<Flex>
				{/* Cards de estatísticas */}
				<MiniStatisticsCards
					data={dataMiniStatisticsMemoized}
					month={moment(filterInput?.endDate).format('M')}
					day={moment(filterInput?.endDate).format('D')}
				/>
				<Card
					marginLeft={3}
					width={{ base: '100%', md: '50%', xl: '50%' }}
				>
					{/* Velocímetro */}
					{isLoadingDataSalesDashboardEstatisticasCanalVendaTotal ? (
						<LoadingSpinner mt='25%' />
					) : (
						<Speedometer
							speedometerData={[faturadoBoa]}
							speedometerOptions={speedometerChartOptions(
								null,
								percentToMeta,
								filterInput?.startDate && filterInput?.endDate,
								false,
							)}
						/>
					)}
				</Card>
			</Flex>
			<Card marginY={3.5}>
				<Tabs
					variant='line'
					size='sm'
					align='start'
					w='100%'
				>
					<TabList>
						<Tab
							panelId='canal'
							{...defaultStyleProps}
						>
							Canais
						</Tab>
						<Tab
							panelId='faturado_familia'
							{...defaultStyleProps}
						>
							Famílias R$
						</Tab>
						<Tab
							panelId='quantidade_familia'
							{...defaultStyleProps}
						>
							Famílias QTD
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>{renderTabSalesChannel()}</TabPanel>
						<TabPanel>{renderTabFamilyRevenue()}</TabPanel>
						<TabPanel>{renderTabFamilyQuantity()}</TabPanel>
					</TabPanels>
				</Tabs>
			</Card>
			{/* Gráfico de Cadência de Vendas */}
			<SalesCadence dataSalesDashboard={DataSalesDashboardCadencia} />
		</Flex>
	);
};

export default DashboardVendas;
