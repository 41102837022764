import { useQuery } from '@tanstack/react-query';
import { checkExistsUnrestrictedPlan } from 'services/api/requests/unrestrictedPlan';

export const useExistsUnrestrictedPlan = (cycleId?: number | string) => {
	return useQuery(
		['unrestrictedPlan-exists-byId', cycleId],
		checkExistsUnrestrictedPlan,
		{
			refetchOnWindowFocus: true,
			enabled: !!cycleId,
		},
	);
};
