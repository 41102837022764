import { monthsUpperCase } from 'utils/consts/months';
import { numberToLocaleString } from './number';

export const numberAsCurrencyOrPercentage = (
	value: number,
	type: 'money' | 'percent' | 'quantity',
	maximumFractionDigits: number = 0,
) => {
	if (type === 'quantity')
		return numberToLocaleString({
			value: value || 0,
			maximumFractionDigits,
		});

	if (type === 'percent') return `${value?.toFixed(2) || 0}%`;

	const intValue = Math.trunc(value);

	return intValue?.toLocaleString('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
};

export const tranformInputDashboardHover = (
	value: number,
	date: Date,
	type: 'money' | 'percent' | 'quantity',
	maximumFractionDigits: number = 0,
	onHoverCallback?: any,
) => {
	return {
		month: monthsUpperCase[date.getMonth()] + '/' + date.getFullYear(),
		value: numberAsCurrencyOrPercentage(value, type, maximumFractionDigits),
		clientes: [],
		renderAsHover: true,
		onHoverCallback,
	};
};

export const checkCanUpdateState = (
	allClients,
	updateState,
	column,
	entityId,
	requestParamName,
) => {
	const keys = Object.keys(allClients?.[column] || {})?.map((key) =>
		Number(key),
	);

	if (keys.includes(entityId)) return;
	else updateState(column, requestParamName, entityId);
};

export const buildCellHover = (
	updateState,
	allClients,
	isLoadingClients,
	value,
	date,
	type,
	column,
	entityId,
	entityName,
	decimalPrecision = 0,
) => {
	return {
		...tranformInputDashboardHover(
			value,
			date,
			type,
			decimalPrecision,
			() =>
				checkCanUpdateState(
					allClients,
					updateState,
					column,
					entityId,
					entityName,
				),
		),
		clientes: !!entityId ? allClients?.[column]?.[entityId] || [] : [],
		isLoading:
			isLoadingClients &&
			!!entityId &&
			!allClients?.[column]?.[entityId]?.length,
	};
};
