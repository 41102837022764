import { Flex, Icon } from '@chakra-ui/react';
import { IconCheck } from '@tabler/icons';
import GenericInput from 'components/inputs/GenericInput';
import SelectComponent from 'components/select/SelectComponent';
import { useGlobalContext } from 'contexts/GlobalContext';
import { globalStyles } from 'theme/styles';
import { CellText } from './CellText';
import { ColumnData, RowInput } from './StatisticTable';

interface ComponetsRowsInputProps {
	column: ColumnData;
	row: RowInput;
	cell: string | number;
	[key: string]: any;
}

export const CellInput = ({
	cell,
	column,
	row,
	useInputMoney,
}: ComponetsRowsInputProps): JSX.Element => {
	const { configsCompany } = useGlobalContext();
	if (column.Header === 'PREENCHIMENTO') {
		const { options, onChangeSelect, value } = row.preenchimento;

		return (
			<Flex
				width='100%'
				paddingX='4px'
				position={'relative'}
			>
				<SelectComponent
					options={options}
					isLoading={row.preenchimento.isLoading}
					value={value}
					onChange={(newValue) => onChangeSelect(newValue)}
					type={column.inputType as any}
				/>
			</Flex>
		);
	}

	if (!isNaN(+column.accessor)) {
		const valueInput = cell || cell === 0 ? cell : '';
		const styleType =
			(valueInput !== '' ? column.inputType : 'excecaoBlank') ||
			'default';

		const disabled =
			column.inputType && column.inputType !== 'exceptionEdit';
		const placeholder = column.inputType ? '0' : '';

		const defaultStyle = getDefaultStyle(cell, row, styleType);

		return (
			<Flex
				width='100%'
				height='100%'
				marginX='4px'
				alignItems='center'
				flexGrow={1}
			>
				{useInputMoney ? (
					<>
						<Flex
							position={'relative'}
							h={'100%'}
							alignItems={'center'}
						>
							<GenericInput
								type='product-quantity'
								inputProps={{
									maskConfig: {
										prefix: '',
										decimalLimit:
											configsCompany.precisao_decimal,
									},
									onChange: (value) => {
										row?.preenchimento?.onChange(
											column.accessor,
											value,
										);
									},
									style: defaultStyle,
									value: valueInput,
									className: defaultStyle.className,
									disabled,
									placeholder,
									'data-test': row.dataTest
										? row.dataTest + '-' + column.accessor
										: undefined,
								}}
							/>
							{styleType === 'exception' && (
								<Icon
									right={'0'}
									m={'10px'}
									h={'30px'}
									w={'30px'}
									as={IconCheck}
									color={globalStyles.colors.green[200]}
									position={'absolute'}
									alignSelf={'center'}
									backgroundColor={'white'}
								/>
							)}
						</Flex>
					</>
				) : (
					<GenericInput type='text' />
				)}
			</Flex>
		);
	}
	return (
		<CellText
			cell={cell}
			column={column}
			row={row}
		/>
	);
};
interface StyleInput {
	border: string;
	color: string;
	borderRadius: string;
	className: string;
	padding: string;
}
type StyleInputType =
	| 'exception'
	| 'exceptionEdit'
	| 'default'
	| 'excecaoBlank';
const styleInput: Record<StyleInputType, StyleInput> = {
	exception: {
		border: '1px solid ' + globalStyles.colors.green[200],
		color: globalStyles.colors.green[200],
		borderRadius: '16px',
		className: 'inputDecimalException exception',
		padding: '0 1rem',
	},
	exceptionEdit: {
		border: '1px solid ' + globalStyles.colors.blue[500],
		color: globalStyles.colors.blue[500],
		borderRadius: '16px',
		className: 'inputDecimalException exceptionEdit',
		padding: '0 1rem',
	},
	excecaoBlank: {
		border: '1px solid #E2E8F0',
		color: '#4A5568',
		borderRadius: '16px',
		className: 'inputDecimalException exceptionBlank',
		padding: '0 1rem',
	},
	default: {
		border: '1px solid #E2E8F0',
		color: '#4A5568',
		borderRadius: '2xl',
		className: 'inputDecimal',
		padding: '0 1rem',
	},
};

const getDefaultStyle = (cell, row, inputType?: StyleInputType) => {
	const style: StyleInput =
		inputType && styleInput[inputType]
			? styleInput[inputType]
			: styleInput.default;
	return {
		htmlSize: 4,
		paddingX: '8px',
		minW: '80px',
		className: style.className,
		isInvalid: true,
		height: '100%',
		placeholder: '0',
		borderRadius: style.borderRadius,
		value: cell || cell === 0 ? cell : '',
		width: '100%',
		border: style.border,
		padding: style.padding,
		fontSize: '1rem',
		color: style.color,
		outline: 'none',
		transition: 'all 0.2s',
		errorBorderColor: row?.errorConditional(cell as number | '')
			? 'gray.500'
			: 'red.300',
	};
};
