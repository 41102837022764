import { useQuery } from '@tanstack/react-query';
import { SelectedFilterBody } from '../../../../components/filter/models/TableFilterModels';
import { fetchDataByFiltersAgGrid } from '../../../api/requests/agGrid';
import { PAGINATION_SIZE_LIST } from '../../../../utils/consts/pagination';
import { useToast } from '@chakra-ui/react';
import { QueryTypeModule } from './useGetCheckTableDataWithFilters';

export interface IPaginationData {
	module: QueryTypeModule;
	page?: number;
	size?: number;
	filters?: SelectedFilterBody[] | any[];
	preFilteredRows?: any;
	order: string[][];
	[key: string]: any;
}
export interface RequestCheckTableBody<BodyType> {
	paginacao?: {
		page: number | undefined;
		size: number;
		order: string[][];
	};
	filtros: any[] | SelectedFilterBody[] | undefined;
	edited?: BodyType[];
	[key: string]: any;
}

export const useGetDataGridTableWithFilters = <T extends unknown, BodyType>(
	{ module, page, size, order, filters, edited, ...other }: IPaginationData,
	onSuccess: VoidFunction,
) => {
	const toast = useToast();

	return useQuery(
		[
			`${module}-list`,
			page,
			size,
			JSON.stringify(filters),
			JSON.stringify(other),
			JSON.stringify(order),
		],
		() => {
			const requestBody: RequestCheckTableBody<BodyType> = {
				paginacao: {
					page,
					size: size || PAGINATION_SIZE_LIST[0],
					order,
				},
				filtros: filters,
				edited,
				...other,
			};

			return fetchDataByFiltersAgGrid<T, BodyType>(module, requestBody);
		},

		{
			onError: (e) => {
				toast({
					title: 'Erro ao buscar dados!',
					description:
						'Ocorreu um erro ao buscar os dados para listagem.',
					status: 'error',
					duration: 8000,
					position: 'bottom-left',
					isClosable: true,
				});
			},
			refetchOnWindowFocus: false,
			retry: false,
			enabled: !!module,
			onSuccess,
		},
	);
};

export interface IPaginationTotal<T, BodyType> {
	module: QueryTypeModule;
	filters?: SelectedFilterBody[] | any[];
	edited?: BodyType[];
	[key: string]: any;
}
