import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { editParameterizationCompany } from 'services/api/requests/companyConfigs/parameterizationCompany';
import { useGlobalContext } from 'contexts/GlobalContext';

export const useEditParameterizationCompany = () => {
	const queryClient = useQueryClient();
	const toast = useToast();
	const { refreshPermissions } = useGlobalContext();

	return useMutation(editParameterizationCompany, {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['parameterization-company-list']);
			refreshPermissions();
			toast({
				title: 'Atualizado com sucesso!',
				description: (
					<span>
						Configurações atualizadas com sucesso.
						<br />
						CNAE primário: {sucess.cnae_primario}.
						<br />
						Tamanho do ciclo: {sucess.tamanho_ciclo} meses.
						<br />
						Tempo do histórico de vendas:{' '}
						{sucess.tamanho_rateio_ticket} meses.
					</span>
				),
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: () => {
			toast({
				title: 'Erro ao atualizar Configurações!',
				description:
					'Verifique se o CNAE primário é válido e se os campos tamanho do ciclo e tempo do histórico de vendas estão preenchidos.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
