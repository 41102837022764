import { useQuery } from '@tanstack/react-query';
import { getAverageTicketByFamily } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
interface IUseAverageTicketByFamily {
	familyId: string;
	salesChannelId?: string;
}
export const useAverageTicketByFamily = ({
	familyId,
	salesChannelId = '',
}: IUseAverageTicketByFamily) => {
	return useQuery(
		['average-ticket-by-family', salesChannelId, familyId],
		getAverageTicketByFamily,
		{
			refetchOnWindowFocus: false,
		},
	);
};
