import Table, { Row, RowInput } from 'components/dataTables/Table';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { generateRowsMonths } from 'components/dataTables/statisticTable/generateRowsMonths';
import { monthNumberToName } from 'utils/consts/months';

export default function WorkingDaysSection({
	defaultValues,
	setEdit,
	setCloseSave,
	year,
	dataWorkingDays,
	worrkingDays,
	setWorrkingDays,
	tamanhoCiclo,
}) {
	const dataEdit = dataWorkingDays;

	useEffect(() => {
		if (dataEdit) setEdit(dataEdit);
	}, [dataEdit, setEdit]);

	const editSend = (key, value, row) => {
		dataEdit[key][row] = Number(value);
	};

	const columnFirtDataWorrkingDayCadastrar = {
		Header: 'Ano',
		accessor: 'titleColumn',
		type: 'text',
	};

	const onChange = (key: string, value: string, row: string) => {
		const num = Number.isNaN(Number(value.replace(/[^0-9]/g, '')))
			? worrkingDays[value]
			: Number(value.replace(/[^0-9]/g, ''));
		editSend(key, num, row);
		setWorrkingDays({ ...worrkingDays, [key]: value === '' ? '' : num });

		setCloseSave(!(JSON.stringify(dataEdit) === defaultValues));
	};

	const errorConditional = (value: number | string) => {
		return value !== 0 && value !== '';
	};

	const [valueSelect, setValueSelect] = useState<string>('Manual');

	const feriados: Row = {
		type: 'text',
		titleColumn: 'Feriados',
		1: dataWorkingDays[1].feriados,
		2: dataWorkingDays[2].feriados,
		3: dataWorkingDays[3].feriados,
		4: dataWorkingDays[4].feriados,
		5: dataWorkingDays[5].feriados,
		6: dataWorkingDays[6].feriados,
		7: dataWorkingDays[7].feriados,
		8: dataWorkingDays[8].feriados,
		9: dataWorkingDays[9].feriados,
		10: dataWorkingDays[10].feriados,
		11: dataWorkingDays[11].feriados,
		12: dataWorkingDays[12].feriados,
	};

	const diasUteis: RowInput = {
		type: 'input',
		...worrkingDays,
		1: dataWorkingDays[1].dias_uteis,
		2: dataWorkingDays[2].dias_uteis,
		3: dataWorkingDays[3].dias_uteis,
		4: dataWorkingDays[4].dias_uteis,
		5: dataWorkingDays[5].dias_uteis,
		6: dataWorkingDays[6].dias_uteis,
		7: dataWorkingDays[7].dias_uteis,
		8: dataWorkingDays[8].dias_uteis,
		9: dataWorkingDays[9].dias_uteis,
		10: dataWorkingDays[10].dias_uteis,
		11: dataWorkingDays[11].dias_uteis,
		12: dataWorkingDays[12].dias_uteis,
		preenchimento: {
			title: 'Dias Úteis',
			key: 'dias_uteis',
			selectValues: ['Manual'],
			onChange: onChange,
			onChangeSelect: (value: string) => setValueSelect(value),
			value: valueSelect,
		},
		errorConditional: errorConditional,
	};

	const colaboradores: RowInput = {
		type: 'input',
		...worrkingDays,
		1: dataWorkingDays[1].colaboradores,
		2: dataWorkingDays[2].colaboradores,
		3: dataWorkingDays[3].colaboradores,
		4: dataWorkingDays[4].colaboradores,
		5: dataWorkingDays[5].colaboradores,
		6: dataWorkingDays[6].colaboradores,
		7: dataWorkingDays[7].colaboradores,
		8: dataWorkingDays[8].colaboradores,
		9: dataWorkingDays[9].colaboradores,
		10: dataWorkingDays[10].colaboradores,
		11: dataWorkingDays[11].colaboradores,
		12: dataWorkingDays[12].colaboradores,
		preenchimento: {
			title: 'Colaboradores',
			key: 'colaboradores',
			selectValues: ['Manual'],
			onChange: onChange,
			onChangeSelect: (value: string) => setValueSelect(value),
			value: valueSelect,
		},
		errorConditional: errorConditional,
	};

	const formatDate = (date) => {
		const mes = moment(date).month() + 1;
		return monthNumberToName[mes];
	};

	const mes: Row = {
		type: 'text',
		titleColumn: 'Mês',
		1: formatDate(dataWorkingDays[1].mes_ano),
		2: formatDate(dataWorkingDays[2].mes_ano),
		3: formatDate(dataWorkingDays[3].mes_ano),
		4: formatDate(dataWorkingDays[4].mes_ano),
		5: formatDate(dataWorkingDays[5].mes_ano),
		6: formatDate(dataWorkingDays[6].mes_ano),
		7: formatDate(dataWorkingDays[7].mes_ano),
		8: formatDate(dataWorkingDays[8].mes_ano),
		9: formatDate(dataWorkingDays[9].mes_ano),
		10: formatDate(dataWorkingDays[10].mes_ano),
		11: formatDate(dataWorkingDays[11].mes_ano),
		12: formatDate(dataWorkingDays[12].mes_ano),
	};

	const collumnsMounths = generateRowsMonths({
		startMonth: 0,
		qtdMounth: tamanhoCiclo,
		abbreviatedMonth: true,
		addYear: true,
		startYear: year,
	});
	const columnsData = [
		columnFirtDataWorrkingDayCadastrar,
		...collumnsMounths,
	];

	return (
		<>
			<Table
				name={'Dias Úteis'}
				year={year}
				columnsData={columnsData}
				tableData={[mes, feriados, diasUteis, colaboradores]}
			/>
		</>
	);
}
