export const backgroundColors = {
	total: '#E9EDF7',
	media: '#F4F7FE',
	fatMeta: '#F4F7FE',
	fatBoaMeta: '#F4F7FE',
	fcstMeta: '#F4F7FE',
	fatBoaFcst: '#F4F7FE',
	crescimento: '#E0E5F2',
	fatHistorico: '#F4F7FE',
	fatRestrito: '#F4F7FE',
	fatBoaHistorico: '#F4F7FE',
	fatBoaRestrito: '#F4F7FE',
};
