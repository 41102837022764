import { useMutation, useQuery } from '@tanstack/react-query';
import {
	getHierarchyById,
	getHierarchyByIdAsMutate,
} from 'services/api/requests/commercial';

export const useGetHierarchyWithId = (id: number) => {
	return useQuery(['hierarchyWithId', id], getHierarchyById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
		retry: false,
	});
};

export const useGetHierarchyWithIdAsMutate = (id: number) => {
	return useMutation(() => getHierarchyByIdAsMutate(id), {
		onSuccess: (success) => {},
		onError: (error: any, variables, context) => {
			console.log(error);
		},
	});
};
