import { useEffect, useState } from 'react';
import {
	Box,
	ButtonGroup,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { ButtonComponent } from '../buttons/ButtonComponent';
import { IconRefreshAlert } from '@tabler/icons';
import { useGetApportionInconsistencies } from 'services/queryClient/wrapperHooks/apportionment/useGetApportionInconsistencies';
import { useUpdateApportionInconsistencies } from 'services/queryClient/wrapperHooks/apportionment/useUpdateApportionInconsistencies';
import { useGlobalContext } from 'contexts/GlobalContext';
import ModalInconsistencyUpdateTable from './RateioInconsistencyUpdateTable';

interface Cycle {
	ano: number;
	ciclo: number;
	id: number;
}

interface RateioInconsistencyUpdateModalProps {
	cycle: Cycle;
	onSuccessCallback?: any;
}

const styles = {
	modalContent: {
		borderRadius: '3xl',
	},
	icon: {
		color: 'easyRED.300',
		boxSize: 14,
	},
	headerText: {
		fontWeight: 700,
		color: 'easyBLUE.300',
		fontSize: '2xl',
	},
	bodyText: {
		color: 'secondaryGray.500',
		fontWeight: 400,
		fontSize: 'md',
	},
	modal: {
		size: '3xl',
		top: '10vh',
	},
};

const RateioInconsistencyUpdateModal = (
	props: RateioInconsistencyUpdateModalProps,
) => {
	const { cycle, onSuccessCallback = () => {} } = props;
	const { pathCompanyName } = useGlobalContext();

	const { data: apportionInconsistencies, refetch } =
		useGetApportionInconsistencies(cycle?.id);

	const { mutateAsync } = useUpdateApportionInconsistencies(cycle?.id);
	const { isOpen, onClose, onOpen } = useDisclosure();
	useEffect(() => {
		if (
			apportionInconsistencies?.quantityProductsChangedFamilies ||
			apportionInconsistencies?.quantityProductsUnallocated
		) {
			onOpen();
		} else {
			onClose();
		}
	}, [apportionInconsistencies, onClose, onOpen]);
	useEffect(() => {
		const yearCurrente = new Date().getFullYear();
		const cicloCurrente = new Date().getMonth() + 1;
		if (
			cycle &&
			cycle.ano === yearCurrente &&
			cycle.ciclo === cicloCurrente
		) {
			refetch();
		} else {
			onClose();
		}
	}, [cycle, onClose, refetch]);

	const handleOnConfirm = async () => {
		await mutateAsync(cycle?.id);
		onSuccessCallback();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			{...styles.modal}
		>
			<ModalOverlay />
			<ModalContent {...styles.modalContent}>
				<ModalHeader>
					<Icon
						as={IconRefreshAlert}
						{...styles.icon}
					/>
					<Text {...styles.headerText}>
						{'Notamos que a hierarquia de produtos mudou'}
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box mb={6}>{generateBodyText(cycle?.ciclo)}</Box>
					{apportionInconsistencies && (
						<ModalInconsistencyUpdateTable
							productsChangedFamilies={
								apportionInconsistencies.productsChangedFamilies
							}
							productsUnallocated={
								apportionInconsistencies.productsUnallocated
							}
							pathCompanyName={pathCompanyName}
						/>
					)}
					<Box
						as='hr'
						my={4}
					/>
					<Box mb={4}>
						<Text
							fontWeight='bold'
							mb={4}
						>
							Confirma a atualização da família desses produtos no
							rateio do ciclo {cycle?.ciclo ?? 'selecionado'}?
						</Text>
					</Box>
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							data-test='button-cancelar-modal-rateio'
							type='ghost'
							title='Cancelar'
							onClick={onClose}
						/>
						<ButtonComponent
							data-test='button-confirmar-modal-rateio'
							type='secondary'
							title='Atualizar'
							onClick={handleOnConfirm}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
export const generateBodyText = (ciclo?: number) => {
	return (
		<Text {...styles.bodyText}>
			<b>
				{`Você deseja atualizar o rateio e o plano irrestrito do ciclo ${
					ciclo ?? 'selecionado'
				} para refletir essas mudanças?`}
			</b>
			<br />
			<Box
				borderWidth='1px'
				borderRadius='lg'
				borderColor='easyRED.300'
				p={4}
				mt={4}
			>
				<Text {...styles.bodyText}>
					<b>{'Importante:'}</b> <br />
					{
						'• Se optar por atualizar, os valores de rateio desses produtos serão transferidos automaticamente para a nova família.'
					}
					<br />
					{'• O plano restrito'} <b>{'não'}</b>{' '}
					{
						'será atualizado automaticamente. Por favor, atualize o plano restrito manualmente.'
					}
				</Text>
			</Box>
		</Text>
	);
};
export default RateioInconsistencyUpdateModal;
