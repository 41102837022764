import { useColorModeValue } from '@chakra-ui/system';
import { ColumnData } from '../../../Table';
import { CellClientInputHover } from './CellClientInputHover';
import { CellMessageHover } from './CellMessageHover';
import { CellText } from '../../CellText';
import { RowHover, ValueHover } from '../../StatisticTable';
interface ComponetsRowsHoverProps {
	cell: ValueHover;
	row: RowHover;
	column: ColumnData;
}
export const CellInputHover = (p: ComponetsRowsHoverProps): JSX.Element => {
	const { cell, column, row } = p;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	if (cell?.typeHover === 'client') {
		return (
			<CellClientInputHover
				cell={cell}
				textColor={textColor}
			/>
		);
	}
	if (cell?.typeHover === 'message') {
		return (
			<CellMessageHover
				cell={cell}
				row={row}
				column={column}
			/>
		);
	}

	return (
		<CellText
			cell={cell}
			row={row}
			column={column}
		/>
	);
};
