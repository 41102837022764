import {
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
} from '@chakra-ui/react';
import { IconDownload } from '@tabler/icons';
import { useContextColaboracaoConsenso } from '../../context';
import ButtonExportGenericCSV from 'components/buttons/ButtonExportGenericCSV';
import { useExportRestrictedPlan } from 'services/queryClient/wrapperHooks/capacidade/restrictedPlan/useExportRestrictedPlan';
import { useExportCollaborationConsensus } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useExportCollaborationConsensus';
import { RestrictedPlanMessages } from 'pages/admin/capacidade/dashboardCapacidade/restrictedPlan/variables/restrictedPlanMessages';
import { ConsensusMessages } from 'pages/admin/demanda/consenso/variables/consensusMessages';
import { ColabMessages } from 'pages/admin/demanda/templateCadastrarColabCons/variables/colabMessages';
export default function ExportCSVButton() {
	const {
		keysPage,
		cycleId = '',
		companyAreaId,
		cycle,
	} = useContextColaboracaoConsenso();
	const filters = {
		ano: cycle?.ano?.toString(),
		ciclo: cycle?.ciclo?.toString(),
	};
	return (
		<>
			{keysPage.page === 'consenso' ? (
				<Menu>
					<MenuButton
						as={IconButton}
						aria-label='Export options'
						data-test='button-exportar'
						icon={<IconDownload />}
						variant='solid'
						color='easyBLUE.300'
						borderRadius={'15px'}
						border={'1px solid'}
						borderColor={'easyBrandMenu.200'}
					/>
					<MenuList>
						<ButtonExportGenericCSV
							cycleId={cycleId}
							errorDescription={
								ConsensusMessages.MSG_CONSENSUS_REQUIRED_TO_EXPORT_UNRESTRICTED_PLAN
							}
							module='plano-irrestrito'
							useExport={useExportCollaborationConsensus}
							Component={({ onClick }) => (
								<MenuItem
									data-test='button-exportar-plano_irrestrito'
									onClick={onClick}
									as='button'
								>
									{' '}
									{
										ConsensusMessages.MSG_EXPORT_UNRESTRICTED_PLAN_TO_CSV
									}
								</MenuItem>
							)}
							args={[companyAreaId, keysPage.page]}
							filters={filters}
						/>
						<ButtonExportGenericCSV
							module='plano-restrito'
							cycleId={cycleId}
							errorDescription={
								RestrictedPlanMessages.MSG_WHITOUT_RESTRICTED_PLAN
							}
							useExport={useExportRestrictedPlan}
							Component={({ onClick }) => (
								<MenuItem
									data-test='button-exportar-plano_restrito'
									onClick={onClick}
								>
									{
										RestrictedPlanMessages.MSG_EXPORT_RESTRICTED_PLAN_TO_CSV
									}
								</MenuItem>
							)}
							filters={filters}
						/>
					</MenuList>
				</Menu>
			) : (
				<ButtonExportGenericCSV
					module='colaboracao'
					cycleId={cycleId}
					errorDescription={
						ColabMessages.MSG_REQUIRED_COLAB_OF_ONE_FAMILY_TO_EXPORT_CSV
					}
					useExport={useExportCollaborationConsensus}
					args={[companyAreaId, keysPage.page]}
					filters={filters}
				/>
			)}
		</>
	);
}
