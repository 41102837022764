import { useQuery } from '@tanstack/react-query';
import { getExceptionByFamily } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
interface IExceptionsByFamilyList {
	page: string;
	cycleId?: string;
	salesChannelId?: string;
	familyId?: string;
	companyAreaId?: string;
}
export const useExceptionsByFamilyList = ({
	page,
	cycleId = '',
	familyId = '',
	salesChannelId = '',
	companyAreaId = '',
}: IExceptionsByFamilyList) => {
	return useQuery(
		[
			'collaboration-consensus-exception-by-family',
			page,
			cycleId,
			familyId,
			salesChannelId,
			companyAreaId,
		],
		getExceptionByFamily,
		{
			refetchOnWindowFocus: false,
		},
	);
};
