import { Flex } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';

import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useBrandList } from '../../../../../services/queryClient/wrapperHooks/useBrandList';
import { useModelList } from '../../../../../services/queryClient/wrapperHooks/useModelList';
import { useVersionList } from '../../../../../services/queryClient/wrapperHooks/useVersionList';
import { useAvailabilityMinutes } from '../../../../../services/queryClient/wrapperHooks/useAvailabilityMinutes';
import { LoadingSpinner } from '../../../../../components/loading/Loading';
import Card from '../../../../../components/card/Card';
import FormCadastrarEditarRecurso from 'components/forms/capacity/resources/FormCadastrarEditarRecurso';

import { URL_CT360, URL_FALE_CONOSCO } from '../../../../../constants/global';

export default function CadastrarRecurso() {
	const [autocompleteAdditionalData, setAutocompleteAditionalData] =
		useState<any>();
	const [formInitialState, setFormInitialState] = useState<any>({
		id: undefined,
		maquina: 'true',
		descricao: '',
		grupo_recurso_id: '',
		disponibilidade_minutos_dia: '',
		nome_marca: '',
		nome_modelo: '',
		nome_versao: '',
		gargalo: 'false',
		tipo_dados_irog: 'MANUAL',
		disponibilidade_irog: '',
		desempenho_irog: '',
		qualidade_irog: '',
		irog: '',
	});

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpRecursos: SidebarHelp = useMemo(
		() => ({
			title: 'Cadastrar Recursos',
			firstParagraph:
				'Para cadastrar um novo recurso informe o nome do Recurso, Grupo do Recurso (possível cadastrar novo grupo no botão Adicionar), Marca, Modelo, e Versão. Informe também se este recurso é Gargalo (recursos mais lentos) e seu IROG.',
			secondParagraph:
				'O IROG pode ser informado manualmente, ou pode ser seguido os valores padrões da indústria brasileira, ou ainda calcular o IROG do recurso através da calculadora.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	const { data: BrandData, isLoading: isLoadingBrandData } = useBrandList();
	const { data: ModelData, isLoading: isLoadingModelData } = useModelList();
	const { data: VersionData, isLoading: isLoadingVersionData } =
		useVersionList();
	const {
		data: AvailabilityMinutesData,
		isLoading: isLoadingAvailabilityMinutesData,
	} = useAvailabilityMinutes();

	useEffect(() => {
		setDataSidebar(helpRecursos);
	}, [helpRecursos, setDataSidebar]);

	useEffect(() => {
		setAutocompleteAditionalData({
			brandes: BrandData,
			models: ModelData,
			versions: VersionData,
			availableMinutes: AvailabilityMinutesData,
		});
	}, [AvailabilityMinutesData, BrandData, ModelData, VersionData]);

	return isLoadingAvailabilityMinutesData ||
		isLoadingBrandData ||
		isLoadingModelData ||
		isLoadingVersionData ||
		!formInitialState ? (
		<LoadingSpinner />
	) : (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarRecurso
					initialState={formInitialState}
					autocompleteAdditionalData={autocompleteAdditionalData}
				/>
			</Card>
		</Flex>
	);
}
