import {
	Flex,
	FormLabel,
	Heading,
	SimpleGrid,
	useColorModeValue,
} from '@chakra-ui/react';
import { getStylesConfig } from 'components/multiSelect/stylesConfig';
import Select from 'react-select';
import { useContextColaboracaoConsenso } from '../../context';
import InputsMainFilter from './InputsMainFilter';
export default function MainFilter() {
	const {
		setValuesFilter,
		onChangeShowSections,
		showSections,
		filters,
		keysPage,
	} = useContextColaboracaoConsenso();
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const options: string[] = [
		'Histórico',
		'Estatística',
		'Ciclos',
		'Oportunidades',
	];

	return (
		<>
			<SimpleGrid
				columns={{ sm: 1, md: 1, lg: 1, xl: 3 }}
				gap='20px'
				mb='20px'
			>
				<Flex>
					<Heading
						color={textColor}
						fontSize='xl'
						fontWeight='700'
						lineHeight='100%'
					></Heading>
				</Flex>
			</SimpleGrid>
			<Flex
				flexWrap={'wrap'}
				gap={0}
				mx={5}
			>
				<InputsMainFilter
					filters={filters}
					setFilters={setValuesFilter}
					page={keysPage?.page || 'not_found'}
				/>
				<Flex flexDirection={'column'}>
					<FormLabel
						fontSize={'sm'}
						fontWeight={500}
					>
						{'Exibir'}
					</FormLabel>
					<Select
						id='select-exibir'
						value={Object.keys(showSections)
							.filter((v) => showSections[v])
							.map((value) => ({ value, label: value }))}
						placeholder={'Exibir'}
						closeMenuOnSelect={false}
						styles={getStylesConfig('medium')}
						isMulti
						options={options.map((value) => ({
							value,
							label: value,
						}))}
						onChange={onChangeShowSections as any}
					/>
				</Flex>
			</Flex>
		</>
	);
}
