import {
	Box,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { IconClipboardCheck } from '@tabler/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import GenericTreeViewInfiniteScroll, {
	ITreeViewProps,
} from 'components/treeViewInfinityScroll/GenericTreeViewInfiniteScroll';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import ButtonExportGenericCSV from 'components/buttons/ButtonExportGenericCSV';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { useExportUnrestrictedPlan } from 'services/queryClient/wrapperHooks/demand/unrestrictedPlan/useExportUnrestrictedPlan';
import { useCycleList } from 'services/queryClient/wrapperHooks/useCycleList';
import {
	getFamiliesUnrestrictedPlanByCycle,
	getProductsUnrestrictedPlanByCycle,
	getSalesChannelUnrestrictedPlanByCycle,
} from 'services/api/requests/unrestrictedPlan';
import { useGlobalContext } from 'contexts/GlobalContext';
import {
	ajustColumnMonthsFromCicle,
	pushFooterOnFormFromCycleAndStaticData,
	buildHeaderFromCycle,
	getColumnWidthFromCycle,
	renderFamily,
	renderSalesChannel,
} from 'utils/functions/treeview';
import { numberToLocaleString } from '../../../utils/functions/number';
import TagTypeException from './TagTypeException';
import { ConsensusMessages } from 'pages/admin/demanda/consenso/variables/consensusMessages';

const UnrestrictedPlanModal = ({ isOpen, onClose, cycleId }) => {
	const { configsCompany } = useGlobalContext();

	const [currentCycle, setCurrentCycle] = useState<any>();

	const [hasConsensus, setHasConsensus] = useState(false);

	const { data: CycleData } = useCycleList();

	const { ...formProps } = useForm({});

	const columWidth = useMemo(
		() => getColumnWidthFromCycle(currentCycle),
		[currentCycle],
	);

	const formatValue = useCallback(
		(value) => {
			return numberToLocaleString({
				value: Number(value),
				maximumFractionDigits: configsCompany?.precisao_decimal,
			});
		},
		[configsCompany?.precisao_decimal],
	);

	const buildHeader = useCallback(() => {
		return buildHeaderFromCycle({
			cycle: currentCycle,
			iconSpaceWidth: '80px',
		});
	}, [currentCycle]);

	const buildFooter = useCallback(
		({ data }) => {
			return pushFooterOnFormFromCycleAndStaticData(
				currentCycle,
				data?.props,
				'80px',
			);
		},
		[currentCycle],
	);

	const buildRow = useCallback(
		({ row, columWidth }) => {
			const {
				codigo_produto,
				nome_produto,
				consenso,
				totais,
				tipo_excecao,
			} = row;

			const descricao = `${codigo_produto} - ${nome_produto}`;

			const descricaoTooltip = (
				<TooltipStandard label={descricao}>
					<Text color='easyBLUE.300'>
						{descricao} <TagTypeException tag={tipo_excecao} />
					</Text>
				</TooltipStandard>
			);

			const formattedArrayData = ajustColumnMonthsFromCicle({
				arrayData: totais,
				keyColumnValue: 'consenso',
				cycleStartDate: currentCycle?.data_inicio,
				cycleLength: currentCycle?.duracao,
			});

			const values = formattedArrayData?.map((el) => ({
				consenso: formatValue(el?.consenso),
				mes_ano: moment(el?.mes_ano),
			}));

			values?.push({
				consenso: formatValue(consenso),
				mes_ano: null,
			});

			const columns = values?.map((el) => {
				return (
					<Flex
						w={`${columWidth}%`}
						display='flex'
						justifyContent='center'
						alignItems='center'
					>
						<Text>{el?.consenso}</Text>
					</Flex>
				);
			});

			return (
				<Flex
					display='flex'
					flexDirection='row'
					gap={3}
					minH='40px'
				>
					<Flex
						w='15%'
						display='flex'
						alignItems='center'
					>
						{descricaoTooltip}
					</Flex>
					{columns}
				</Flex>
			);
		},
		[currentCycle, formatValue],
	);

	const renderProductRow = useCallback(
		({ row }) => {
			if (!row?.nome_produto) return 'Não informado';

			return (
				<Flex
					w='100%'
					display='flex'
					flexDirection='column'
				>
					{buildRow({
						row,
						columWidth,
					})}
				</Flex>
			);
		},
		[buildRow, columWidth],
	);

	const formParams = useMemo(
		() => ({
			formProps,
			manageData: [
				{
					deeper: 1,
					getSharedDataChildren: ({ data, parentId }) => {
						if (!data || !parentId) return {};

						const parent = data?.pages?.[0]?.rows?.find(
							(el) => el?.id === parentId,
						);
						const formattedArrayData = ajustColumnMonthsFromCicle({
							arrayData: parent?.totais,
							keyColumnValue: 'total_consenso',
							cycleStartDate: currentCycle?.data_inicio,
							cycleLength: currentCycle?.duracao,
						});
						return {
							props: {
								...parent,
								totais: formattedArrayData?.map((el) => ({
									mes_ano: moment(el?.mes_ano),
									total_mes: formatValue(el?.total_consenso),
								})),
								total_geral: formatValue(
									parent?.total_consenso,
								),
							},
						};
					},
				},
				{
					deeper: 2,
					renderHeader: buildHeader,
					renderFooter: buildFooter,
				},
			],
		}),
		[buildFooter, buildHeader, formatValue, currentCycle],
	);

	const treeview: ITreeViewProps = useMemo(
		() => ({
			deeper: 0,
			parentFilterParams: { ciclo_id: cycleId },
			row: {
				filterKeyName: 'canal_venda_id',
				renderRow: renderSalesChannel,
				infinityQueriesFunctions: [
					{
						getData: (params) => {
							return getSalesChannelUnrestrictedPlanByCycle({
								...params,
							});
						},
						appendData: ({ data }) => {
							const result = data?.pages?.reduce?.(
								(acc, page) => [...acc, ...(page?.rows || [])],
								[],
							);

							setHasConsensus(!!result?.length);

							return result;
						},
						queryKeyName: 'unresctricted-plan-sales-channel',
						getNextPage: (lastPage, allPages, limit) => {
							return lastPage?.rows?.length === limit
								? allPages?.length
								: undefined;
						},
					},
				],
			},
			children: [
				{
					deeper: 1,
					row: {
						filterKeyName: 'familia_id',
						renderRow: renderFamily,
						infinityQueriesFunctions: [
							{
								getData: (params) => {
									return getFamiliesUnrestrictedPlanByCycle({
										...params,
									});
								},
								appendData: ({ data }) => {
									return data?.pages?.reduce?.(
										(acc, page) => [
											...acc,
											...(page?.rows || []),
										],
										[],
									);
								},
								queryKeyName:
									'unresctricted-plan-sales-channel-family',
								getNextPage: (lastPage, allPages, limit) => {
									return lastPage?.rows?.length === limit
										? allPages?.length
										: undefined;
								},
							},
						],
					},
					children: [
						{
							deeper: 2,
							hasHeader: true,
							hasFooter: true,
							row: {
								renderRow: renderProductRow,
								infinityQueriesFunctions: [
									{
										getData: (params) => {
											return getProductsUnrestrictedPlanByCycle(
												{
													...params,
												},
											);
										},
										appendData: ({ data }) => {
											return data?.pages?.reduce?.(
												(acc, page) => [
													...acc,
													...(page?.rows || []),
												],
												[],
											);
										},
										queryKeyName:
											'unresctricted-plan-sales-channel-family-product',
										getNextPage: (
											lastPage,
											allPages,
											limit,
										) => {
											return lastPage?.rows?.length ===
												limit
												? allPages?.length
												: undefined;
										},
									},
								],
							},
						},
					],
				},
			],
		}),
		[cycleId, renderProductRow],
	);

	useEffect(() => {
		if (CycleData && cycleId) {
			setCurrentCycle(CycleData?.find((c) => c?.id === Number(cycleId)));
		}
	}, [CycleData, cycleId]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size='full'
			>
				<ModalOverlay />
				<ModalContent borderRadius={20}>
					<ModalHeader
						display='flex'
						alignItems={'end'}
						justifyContent={'space-between'}
						tabIndex={-1}
					>
						<Box>
							<Icon
								as={IconClipboardCheck}
								boxSize={12}
								color={'#25364D'}
							/>
							<Flex
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								<Text
									fontSize='2xl'
									fontWeight={700}
									color={'#25364D'}
								>
									Plano Irrestrito
								</Text>
							</Flex>
						</Box>
						<TooltipStandard
							label={
								ConsensusMessages.MSG_EXPORT_UNRESTRICTED_PLAN_TO_CSV
							}
							shouldWrapChildren={true}
						>
							<ButtonExportGenericCSV
								errorDescription='Não há dados para exportar!'
								module='plano-irrestrito'
								useExport={useExportUnrestrictedPlan}
								isDisabled={!hasConsensus}
								cycleId={cycleId}
								filters={{
									ano: currentCycle?.ano,
									ciclo: currentCycle?.ciclo,
								}}
							/>
						</TooltipStandard>
					</ModalHeader>
					<ModalBody>
						<ModalCloseButton />
						{!hasConsensus && (
							<AlertComponent
								title='Atenção'
								description='Ainda não há consenso para este ciclo selecionado.'
								status='warning'
							/>
						)}
						<Box
							overflowX={'auto'}
							maxHeight={'90vh'}
						>
							<GenericTreeViewInfiniteScroll
								treeview={treeview}
								formParams={formParams}
							/>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default UnrestrictedPlanModal;
