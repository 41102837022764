import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	useColorModeValue,
	ButtonGroup,
} from '@chakra-ui/react';
import {
	IconAlertCircle,
	IconAlertTriangle,
	IconX,
	TablerIcon,
	IconCircleCheck,
} from '@tabler/icons';
import { useState } from 'react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { globalStyles } from 'theme/styles';
interface AlertComponentProps {
	title?: string;
	description?: string | JSX.Element;
	status?: 'info' | 'warning' | 'success' | 'error';
	noIcon?: boolean;
	hasCloseButton?: boolean;
	actionClose?: Function;
	mb?: string | number;
	[key: string]: any;
}
interface StatusAlert {
	icon: TablerIcon;
	color: string;
	color2: string;
}
const statusAlerts: Record<
	'info' | 'warning' | 'success' | 'error',
	StatusAlert
> = {
	info: {
		icon: IconAlertCircle,
		color: globalStyles.colors.easyBLUE['300'],
		color2: globalStyles.colors.easyBLUE['300'],
	},
	warning: {
		icon: IconAlertTriangle,
		color: globalStyles.colors.orange['500'],
		color2: globalStyles.colors.orange['700'],
	},
	success: {
		icon: IconCircleCheck,
		color: globalStyles.colors.green['500'],
		color2: globalStyles.colors.green['500'],
	},
	error: {
		icon: IconAlertCircle,
		color: globalStyles.colors.easyRED['400'],
		color2: globalStyles.colors.easyRED['400'],
	},
};
export const AlertComponent = (props: AlertComponentProps) => {
	const {
		title,
		description,
		status,
		noIcon,
		hasCloseButton,
		actionClose,
		mb = '10px',
	} = props;
	const [isOpen, setIsOpen] = useState<boolean>(
		hasCloseButton === undefined || hasCloseButton === true,
	);

	const ArqBg = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

	const actionCloseAlert = () => {
		setIsOpen(false);
		actionClose && actionClose();
	};
	const statusAlert = statusAlerts[status || 'info'];

	return isOpen ? (
		<Alert
			mb={mb}
			status={status}
			backgroundColor={status === undefined ? ArqBg : ''}
			minH={'60px'}
			borderRadius='16px'
			w={status !== undefined ? '100%' : '40%'}
			justifyContent={status !== undefined ? 'center' : ''}
		>
			{!noIcon && (
				<AlertIcon
					as={statusAlert.icon}
					color={statusAlert.color}
					boxSize={'24px'}
				/>
			)}
			<AlertTitle
				color={statusAlert.color}
				mr={'12px'}
			>
				{title}
			</AlertTitle>
			<AlertDescription color={statusAlert.color2}>
				{description}
			</AlertDescription>
			{hasCloseButton && (
				<ButtonGroup marginLeft={'auto'}>
					<ButtonComponent
						type={'icon'}
						colorType='warning'
						iconType={<IconX size='20px' />}
						size={4}
						color={statusAlert.color}
						onClick={actionCloseAlert}
					/>
				</ButtonGroup>
			)}
		</Alert>
	) : (
		<></>
	);
};
