import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';
import { updateSalesChannel } from 'services/api/requests/salesChannel';

export const useEditSalesChannel = (id, body, onClose) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(() => updateSalesChannel(id, body), {
		onSuccess: () => {
			queryClient.invalidateQueries(['canalVenda-list']);
			queryClient.invalidateQueries(['salesChannelById', id]);
			queryClient.invalidateQueries(['salesChannelWithId', id]);

			toast({
				title: 'Atualizado com sucesso!',
				description: 'Canal de venda atualizado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});

			onClose?.();
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao editar canal de venda!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
