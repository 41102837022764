import { Box, Flex } from '@chakra-ui/layout';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import { useColorModeValue } from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import CheckTable from 'components/dataTables/CheckTable';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useEditOpportunityConsensusException } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useEditOpportunityConsensusException';
import {
	documentFormatters,
	DocumentType,
} from 'utils/functions/formatDocuments';
import { columnsDataOpportunityException } from '../variables/columnsDataOpportunityException';
import { modalHeaderStyles } from '../variables/modalHeaderStyles';

interface OpportunityExceptionsTableModalProps {
	isOpenModal: boolean;
	onCloseModal: () => void;
	cycleId: number | string;
	date: string;
	familyId: number | string;
	familyName: string;
}

interface Opportunity {
	id: number;
	codigo: string;
	sequencia_codigo: number;
	data: string;
	mes_ano_ciclo: string;
	nome_cliente: string;
	cpf: string;
	cnpj: string;
	nome_familia: string;
	nome_produto: string;
	codigo_produto: string;
	quantidade: number;
	nome_membro: string;
	receita_bruta: number;
	nome_canal_venda: string;
	data_registro: string;
	tipo_documento: DocumentType;
	documento: string;
	consenso_excecao: boolean;
}

export const OpportunityExceptionsTableModal = (
	props: OpportunityExceptionsTableModalProps,
) => {
	const { isOpenModal, onCloseModal, cycleId, date, familyId, familyName } =
		props;

	const [payload, setPayload] = useState<any>({});

	const tableColumns = useMemo(() => columnsDataOpportunityException, []);

	const renderRow = useCallback((opportunity: Opportunity) => {
		return {
			id: opportunity.id,
			codigo: opportunity?.codigo,
			sequencia_codigo: opportunity?.sequencia_codigo,
			competencia: moment(opportunity.data, 'YYYY/MM/DD').format(
				'DD/MM/YYYY',
			),
			cicloAno: opportunity?.mes_ano_ciclo,
			cliente: opportunity.nome_cliente,
			cnpjCpfId: documentFormatters(
				opportunity?.tipo_documento,
				opportunity?.documento,
			),
			familia: opportunity.nome_familia ? opportunity.nome_familia : '-',
			produto: opportunity.nome_produto,
			codigoProduto: opportunity.codigo_produto,
			quantidade: opportunity.quantidade?.toLocaleString('pt-BR'),
			membro: opportunity.nome_membro,
			receitaBruta: opportunity.receita_bruta
				? opportunity.receita_bruta.toLocaleString?.('pt-BR', {
						style: 'currency',
						currency: 'BRL',
				  })
				: 'R$ 0,00',
			canalVenda: opportunity?.nome_canal_venda ?? '-',
			excecao: opportunity?.consenso_excecao ? 'SIM' : 'NÃO',
			dataRegistro: opportunity?.data_registro
				? moment
						.utc(opportunity.data_registro)
						.local()
						.format('DD/MM/YYYY HH:mm:ss')
				: '',
		};
	}, []);

	const dataEditAll = useMemo(
		() => ({
			toastSuccessTitle: 'Exceções atualizadas!',
			toastSuccessDescription:
				'Exceções da oportunidade atualizadas com sucesso.',
			toastErrorTitle: 'Erro ao atualizar exceções!',
			invalidate: 'oportunidade-list',
			filters: payload,
			modalTitleAdd: 'Criar exceções de oportunidade',
			modalTextAdd: `Deseja criar exceções para os itens selecionados da família "${familyName}" que compõem essa oportunidade? Essas exceções irão compor seu plano irrestrito.`,
			modalTitleRemove:
				' Deletar as exceções da demanda criadas com base na oportunidade?',
			modalTextRemove: `Deseja deletar as exceções para os itens selecionados da família "${familyName}" que compõem essa oportunidade? Essas exceções serão removidas do seu plano irrestrito.`,
			buttonText: 'Sim',
			menuOptions: [
				{
					label: 'Adicionar Exceções',
					value: 'add',
				},
				{
					label: 'Remover Exceções',
					value: 'remove',
				},
			],
		}),
		[payload, familyName],
	);
	const { mutate: updateOpportunityMutate } =
		useEditOpportunityConsensusException(
			dataEditAll,
			cycleId,
			date,
			familyId,
		);

	const handleUpdateOpportunityException = useCallback((filter) => {
		setPayload(filter);
		setTimeout(() => updateOpportunityMutate(), 500);
	}, []);

	const renderTable = () => {
		return (
			<CheckTable
				dataTest='button-filtrar-excecoes_das_oportunidades'
				module={'oportunidades'}
				columnsData={tableColumns}
				showGenericSearchInput={false}
				filterModule='oportunidade'
				renderRow={renderRow}
				preFilteredRows={{
					familyId,
					cycleId,
					date,
				}}
				pt={14}
				massEditingActions={handleUpdateOpportunityException}
				dataAction={dataEditAll}
				bulkRemoval={false}
			/>
		);
	};

	const bgHeader = useColorModeValue('whiteAlpha.500', 'rgba(11,20,55,0.5)');
	const FixedModalHeader = () => {
		return (
			<Box
				position='fixed'
				width='98%'
				zIndex='1'
				boxShadow={'none'}
				bg={bgHeader}
				{...modalHeaderStyles}
			>
				<ModalHeader
					textColor={'easyBLUE.300'}
					fontSize={'24px'}
					fontWeight='700'
				>
					Gerenciar Exceções das Oportunidades
				</ModalHeader>
				<ModalCloseButton />
			</Box>
		);
	};
	return (
		<Modal
			isOpen={isOpenModal}
			onClose={onCloseModal}
			size={'full'}
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<FixedModalHeader />
				<ModalBody mt={20}>
					<Flex
						direction='column'
						pt={10}
					>
						{renderTable()}
					</Flex>
				</ModalBody>
				<ModalFooter>
					<ButtonComponent
						type={'ghost'}
						title={'Retornar ao Consenso'}
						onClick={onCloseModal}
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
