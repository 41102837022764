import { useQuery } from '@tanstack/react-query';
import { exportProducts } from 'services/api/requests/product';

export const useExportProducts = () => {
	return useQuery(['export-products'], exportProducts, {
		refetchOnWindowFocus: false,
		retry: 1,
		enabled: false,
	});
};
