import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import InputSearch from '../MainFilter/InputSearch';
import {
	ChanelException,
	ExceptionProprietes,
	ProductException,
} from 'pages/admin/demanda/consenso/context';

import { defaultInputStyle } from 'utils/forms/defaultsProps';
import { IAutocomplete } from 'utils/forms/autocomplete';
import { useProductListAsMutate } from 'services/queryClient/wrapperHooks/product/useProductListAsMutate';
import { IconEdit, IconPlus, IconTrash, TablerIcon } from '@tabler/icons';
import { globalStyles } from 'theme/styles';
import { useContextColaboracaoConsenso } from '../../context';
import { UseGetSelectedFilterDemand } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/filters/useGetFilterDemand';
import { useSalesChannelListAsMutate } from 'services/queryClient/wrapperHooks/salesChannel/useSalesChannelListAsMutate';
interface BoxProps {
	exception?: ExceptionProprietes;
	onChange: (excption: ExceptionProprietes) => void;
	indexException: number;
	disabled: boolean;
}
interface ProductBoxProps extends BoxProps {
	familyId: string;
}
const ProductBox = ({
	exception,
	onChange,
	familyId,
	disabled,
	indexException,
}: ProductBoxProps) => {
	return (
		<Flex
			w={'100%'}
			flexDirection='column'
			gap={2}
			alignItems='start'
		>
			<Text
				fontSize='sm'
				fontWeight='700'
			>
				Produto (SKU){' '}
				<span style={{ color: globalStyles.colors.graphic.red }}>
					*
				</span>
			</Text>
			<InputSearch<ProductException, IAutocomplete>
				inputProps={{
					dataTest: `input_search-exception-${indexException}-produto-excecao`,
					...defaultInputStyle,
					renderInputLabel: (value) =>
						value
							? value.codigo_produto + ' - ' + value.nome_produto
							: '',
					onChange: (
						_name: string,
						value: ProductException | null,
					) => {
						if (value && exception) {
							onChange({
								...exception,
								product: value,
							});
						}
					},
					name: 'produto',
					value: exception?.product,
					disabled,
				}}
				size='large'
				useGetRequest={useProductListAsMutate}
				searchType='product'
				extraRequestData={{
					familyId,
					productId: exception?.product?.id,
					status: true,
				}}
			/>
		</Flex>
	);
};

const ChannelBox = ({
	exception,
	onChange,
	disabled,
	indexException,
}: BoxProps) => {
	const { cycleId } = useContextColaboracaoConsenso();
	return (
		<Flex
			w={'100%'}
			flexDirection='column'
			gap={2}
			alignItems='start'
		>
			<Text
				fontSize='sm'
				fontWeight='700'
			>
				Canal de Venda
				<span style={{ color: globalStyles.colors.graphic.red }}>
					*
				</span>
			</Text>
			<InputSearch<ChanelException, UseGetSelectedFilterDemand>
				inputProps={{
					name: 'canal',
					onChange: (_option, value) => {
						if (value && exception)
							onChange({
								...exception,
								salesChannel: value,
							});
					},
					value: exception?.salesChannel,
					renderInputLabel: (value) => value?.nome_canal_venda || '',
					disabled,
					dataTest: `input_search-exception-${indexException}-canal-excecao`,
				}}
				searchType={'canal_venda'}
				extraRequestData={{
					cycleId,
					column_name: 'canal_venda',
					pageName: 'consensus',
				}}
				useGetRequest={useSalesChannelListAsMutate}
			/>
		</Flex>
	);
};

interface ExceptionButonProps {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	text: string;
	Icon: TablerIcon;
	indexException: number;
	action: 'edit' | 'delete' | 'add';
}
const ExceptionButon = ({
	Icon: icon,
	onClick,
	text,
	indexException,
	action,
}: ExceptionButonProps) => {
	const { keysPage } = useContextColaboracaoConsenso();
	return (
		<Button
			height={'60px'}
			onClick={onClick}
			borderRadius={'20px'}
			border={`1px solid #E9EDF7`}
			padding={'0 18px'}
			gap='10px'
			data-test={`button-${action}-exception-${indexException}-${keysPage.page}`}
		>
			<Icon
				color='easyBLUE.300'
				as={icon}
				w='20px'
				h='20px'
			/>
			{text}
		</Button>
	);
};

interface SectionButtonsProps {
	onDelete: () => void;
	exception?: ExceptionProprietes;
	familyId: string;
	onChange: (excption: ExceptionProprietes) => void;
	onEdit: () => void;
	disabled: boolean;
	isEditing: boolean;
	exceptionExists?: boolean;
	indexException: number;
}

const SectionButtons: React.FC<SectionButtonsProps> = ({
	onDelete,
	familyId,
	exception,
	onChange,
	disabled,
	onEdit,
	isEditing,
	exceptionExists,
	indexException,
}) => {
	const { filters } = useContextColaboracaoConsenso();
	return (
		<Flex
			w={'100%'}
			flexDirection='column'
		>
			<Flex
				marginTop={2}
				gap={4}
				justifyContent={'space-between'}
				alignItems={'end'}
				marginBottom={'20px'}
			>
				<Flex gap={4}>
					{!filters?.canal_venda?.value && (
						<ChannelBox
							exception={exception}
							onChange={onChange}
							disabled={disabled || !isEditing}
							indexException={indexException}
						/>
					)}
					<ProductBox
						exception={exception}
						onChange={onChange}
						familyId={familyId}
						disabled={disabled || !isEditing}
						indexException={indexException}
					/>
				</Flex>
				<Flex gap={2}>
					{exceptionExists && !isEditing && (
						<ExceptionButon
							Icon={IconEdit}
							onClick={() => onEdit()}
							text={'Editar'}
							indexException={indexException}
							action={'edit'}
						/>
					)}
					{!exceptionExists && !isEditing && (
						<ExceptionButon
							Icon={IconPlus}
							onClick={() => onEdit()}
							text={'Adicionar Exceção'}
							indexException={indexException}
							action={'add'}
						/>
					)}
					{(exceptionExists || isEditing) && (
						<ExceptionButon
							Icon={IconTrash}
							onClick={onDelete}
							text={'Excluir'}
							action={'delete'}
							indexException={indexException}
						/>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};
export default SectionButtons;
