import { IManualPaging } from 'components/dataTables/ManualPaging';
import { SelectedFilterBody } from 'components/filter/models/TableFilterModels';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
	useGetAccuracyData,
	useGetAccuracyDataCommonData,
} from 'services/queryClient/wrapperHooks/useGetAccuracyData';
import { useGetAccuracyFilters } from 'services/queryClient/wrapperHooks/useGetAccuracyFilters';
import { useGetTotalProductWithoutFamily } from 'services/queryClient/wrapperHooks/useGetTotalProductWithoutFamily';
import { useSalesChannelList } from 'services/queryClient/wrapperHooks/useSalesChannelList';
import { PAGINATION_SIZE_LIST } from 'utils/consts/pagination';

interface IAcuracidadeFilters {
	intervalo?: string;
	tipo_previsao?: string;
	canal_venda?: string;
	tipo_indicador?: string;
}

interface IAcuracidadeContextData {
	areaColaboracao: string | undefined;
	setAreaColaboracao: React.Dispatch<
		React.SetStateAction<string | undefined>
	>;
	selectedTab: string;
	setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
	lastSelectedDate: string;
	setLastSelectedDate: React.Dispatch<React.SetStateAction<string>>;
	pagination: IManualPaging;
	setPagination: React.Dispatch<React.SetStateAction<IManualPaging>>;
	tableFilters: SelectedFilterBody[] | [];
	setTableFilters: React.Dispatch<
		React.SetStateAction<SelectedFilterBody[] | []>
	>;
	filters: IAcuracidadeFilters;
	setFilters: React.Dispatch<React.SetStateAction<IAcuracidadeFilters>>;
	filteredDates: any;
	setFilteredDates: React.Dispatch<React.SetStateAction<any>>;
	canShowAddtionalInfo: boolean;
	setCanShowAddtionalInfo: React.Dispatch<React.SetStateAction<boolean>>;
	salesChannelData: any;
	isLoadingSalesChannelData: boolean;
	accuracyFilters: any;
	isLoadingAccuracyFilters: boolean;
	mutateAccuracyFilters: any;
	accuracyDataControl: any;
	isLoadingAccuracyDataControl: boolean;
	mutateAccuracyDataControl: any;
	accuracyDataGraphicalAnalysis: any;
	isLoadingAccuracyDataGraphicalAnalysis: boolean;
	mutateAccuracyDataGraphicalAnalysis: any;
	accuracyDataTable: any;
	isLoadingAccuracyDataTable: boolean;
	mutateAccuracyDataTable: any;
	getInitialPrevisionType: (param: string) => string;
	totalProductsWithoutFamily: any;
}

const AcuracidadeContext = createContext<IAcuracidadeContextData>(
	{} as IAcuracidadeContextData,
);

export default function AcuracidadeProvider({ children }) {
	const currentDate = moment();

	const [areaColaboracao, setAreaColaboracao] = useState<
		string | undefined
	>();

	function getInitialPrevisionType(param) {
		switch (param) {
			case 'COMERCIAL':
				return 'colab_comercial';
			case 'PLANEJAMENTO':
				return 'colab_planejamento';
			case 'MARKETING':
				return 'colab_marketing';
			default:
				return 'consenso';
		}
	}

	const [filters, setFilters] = useState<IAcuracidadeFilters>({
		intervalo: currentDate.startOf('day').format('YYYY-MM'),
		tipo_previsao: getInitialPrevisionType(areaColaboracao),
		canal_venda: '',
		tipo_indicador: 'acuracidade_comercial',
	});

	const [filteredDates, setFilteredDates] = useState<any>({
		data_inicio: currentDate.startOf('month').format('YYYY-MM-DD'),
		data_fim: currentDate.endOf('month').format('YYYY-MM-DD'),
	});

	const [selectedTab, setSelectedTab] = useState<string>(
		'controle_acuracidade',
	);

	const [lastSelectedDate, setLastSelectedDate] = useState<string>(
		currentDate.startOf('day').format('YYYY-MM'),
	);

	const [canShowAddtionalInfo, setCanShowAddtionalInfo] = useState<boolean>(
		!filters?.canal_venda,
	);

	const [pagination, setPagination] = useState<IManualPaging>({
		page: 0,
		size: PAGINATION_SIZE_LIST[0],
		totalPages: 1,
	});

	const [tableFilters, setTableFilters] = useState<SelectedFilterBody[] | []>(
		[],
	);

	const { data: salesChannelData, isLoading: isLoadingSalesChannelData } =
		useSalesChannelList();

	const {
		data: accuracyFilters,
		isLoading: isLoadingAccuracyFilters,
		mutate: mutateAccuracyFilters,
	} = useGetAccuracyFilters(filters?.intervalo);

	const isFiltersLoaded = !!accuracyFilters && !isLoadingAccuracyFilters;

	const {
		data: accuracyDataControl,
		isLoading: isLoadingAccuracyDataControl,
		mutate: mutateAccuracyDataControl,
	} = useGetAccuracyDataCommonData(
		'control',
		filters?.intervalo,
		filters?.tipo_previsao,
		!!filters?.canal_venda ? filters?.canal_venda : undefined,
		filters?.tipo_indicador,
		pagination?.page,
		pagination?.size,
	);

	const {
		data: accuracyDataGraphicalAnalysis,
		isLoading: isLoadingAccuracyDataGraphicalAnalysis,
		mutate: mutateAccuracyDataGraphicalAnalysis,
	} = useGetAccuracyDataCommonData(
		'graphical_analysis',
		filters?.intervalo,
		filters?.tipo_previsao,
		!!filters?.canal_venda ? filters?.canal_venda : undefined,
		filters?.tipo_indicador,
		pagination?.page,
		pagination?.size,
	);

	const {
		data: accuracyDataTable,
		isLoading: isLoadingAccuracyDataTable,
		mutate: mutateAccuracyDataTable,
	} = useGetAccuracyData({
		additional_filters: tableFilters,
		interval: filters?.intervalo,
		demand_type: filters?.tipo_previsao,
		sales_channel: filters?.canal_venda,
		indicator: filters?.tipo_indicador,
		page_number: pagination?.page,
		page_size: pagination?.size,
	});

	useEffect(() => {
		if (isFiltersLoaded) {
			mutateAccuracyDataControl();
			mutateAccuracyDataGraphicalAnalysis();
			mutateAccuracyDataTable();
		}
	}, [
		isFiltersLoaded,
		mutateAccuracyDataControl,
		mutateAccuracyDataGraphicalAnalysis,
		mutateAccuracyDataTable,
	]);

	const { data: totalProductsWithoutFamily } =
		useGetTotalProductWithoutFamily(
			filteredDates?.data_inicio,
			filteredDates?.data_fim,
		);

	useEffect(() => {
		if (accuracyDataTable) {
			setPagination((prev) => ({
				...prev,
				totalPages: Math.ceil(
					(accuracyDataTable?.qtd_total_familias || 0) /
						pagination?.size,
				),
			}));
		}
	}, [accuracyDataTable, pagination?.size]);

	return (
		<AcuracidadeContext.Provider
			value={{
				areaColaboracao,
				setAreaColaboracao,
				selectedTab,
				setSelectedTab,
				lastSelectedDate,
				setLastSelectedDate,
				pagination,
				setPagination,
				tableFilters,
				setTableFilters,
				filters,
				setFilters,
				filteredDates,
				setFilteredDates,
				canShowAddtionalInfo,
				setCanShowAddtionalInfo,
				salesChannelData,
				isLoadingSalesChannelData,
				accuracyFilters,
				isLoadingAccuracyFilters,
				mutateAccuracyFilters,
				accuracyDataControl,
				isLoadingAccuracyDataControl,
				mutateAccuracyDataControl,
				accuracyDataGraphicalAnalysis,
				isLoadingAccuracyDataGraphicalAnalysis,
				mutateAccuracyDataGraphicalAnalysis,
				accuracyDataTable,
				isLoadingAccuracyDataTable,
				mutateAccuracyDataTable,
				getInitialPrevisionType,
				totalProductsWithoutFamily,
			}}
		>
			{children}
		</AcuracidadeContext.Provider>
	);
}

export const useAcuracidade = () => {
	return useContext(AcuracidadeContext);
};
