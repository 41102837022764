import { useQuery } from '@tanstack/react-query';
import { exportCollaborationConsensus } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
export const useExportCollaborationConsensus = (
	cicloId: string,
	areaEmpresaId: string,
	page: string,
) => {
	return useQuery(
		['export-collaboration-consensus', page, cicloId, areaEmpresaId],
		exportCollaborationConsensus,
		{
			refetchOnWindowFocus: false,
			retry: 1,
			enabled: false,
		},
	);
};
