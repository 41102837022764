import React, { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import Table, { generateRowsMonths, Row } from 'components/dataTables/Table';
import Multiselect from 'components/multiSelect/Multiselect';
import moment from 'moment';
import { monthNumberToName } from 'utils/consts/months';
import { useQuery } from '@tanstack/react-query';
import { getWorkingDaysPast } from '../../../../../../services/api/requests/capacity/workingDays/workingDays';

type DataItem = any;

interface IWorkingDaysModalProps {
	isOpen: boolean;
	onClose: () => void;
	tamanhoCiclo: number;
}

export const WorkingDaysModal: React.FC<IWorkingDaysModalProps> = ({
	isOpen,
	onClose,
	tamanhoCiclo,
}) => {
	const modalTitle = 'Registro de Dias Úteis';

	const columnStructure = {
		Header: 'Ano',
		accessor: 'titleColumn',
		type: 'text',
	};

	const { data: dataWorkingDays, isFetched } = useQuery<DataItem[]>(
		['working-days-list-past'],
		getWorkingDaysPast,
		{ refetchOnWindowFocus: false },
	);

	const [data, setData] = useState<DataItem[]>([]);
	const [dataView, setDataView] = useState<DataItem[]>([]);

	useEffect(() => {
		if (dataWorkingDays && Array.isArray(dataWorkingDays)) {
			const sorted = dataWorkingDays.sort(
				(a, b) => Number(a.ano) - Number(b.ano),
			);
			setData(sorted);
			setDataView(sorted);
		}
	}, [dataWorkingDays]);

	const yearOptions: any[] = Array.from(
		new Set(data.map((item) => Number(item.ano))),
	)
		.sort((a, b) => b - a)
		.map((year) => ({ value: year, label: String(year) }));

	const [selectedYears, setSelectedYears] = useState<any[]>([]);

	const onChangeYear = (selectedOptions: any): void => {
		setSelectedYears(selectedOptions);
		const filterYears: number[] = selectedOptions.map(
			(opt: any) => opt.value,
		);
		if (data.length > 0) {
			const filtered = data.filter((item) =>
				filterYears.includes(Number(item.ano)),
			);
			setDataView(filtered);
		}
	};

	const groupedData = (dataView || []).reduce(
		(acc: Record<string, DataItem[]>, curr: DataItem) => {
			const key = curr.ano;
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(curr);
			return acc;
		},
		{} as Record<string, DataItem[]>,
	);

	const sortedYears = Object.keys(groupedData)
		.map((key) => {
			const sortedMonthsData = groupedData[key].sort(
				(a, b) =>
					moment(a.mes_ano, 'YYYY-MM-DD').month() -
					moment(b.mes_ano, 'YYYY-MM-DD').month(),
			);
			return {
				ano: Number(key),
				data: sortedMonthsData,
			};
		})
		.sort((a, b) => b.ano - a.ano);

	const formatDate = (date: string): string => {
		const monthNum = moment(date, 'YYYY-MM-DD').month() + 1;
		return monthNumberToName[monthNum] || '';
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size='6xl'
			isCentered
			scrollBehavior='inside'
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
				minH='300px'
			>
				<ModalCloseButton />
				<ModalHeader
					color='gray.700'
					mb={2}
				>
					{modalTitle}
				</ModalHeader>
				<ModalBody>
					{isFetched && (
						<>
							<Flex
								mb='8'
								w='fit-content'
							>
								<FormControl
									mr='5px'
									className='filter-bar'
								>
									<FormLabel>Ano</FormLabel>
									<Multiselect
										id='select-exibir-ano-registro-dias-uteis'
										value={selectedYears}
										onChange={onChangeYear}
										defaultValue={[]}
										options={yearOptions}
										size='medium'
										placeholder='Selecione'
										selectAllTitle='Todos os Anos'
										notHideSelectedOptions
									/>
								</FormControl>
							</Flex>
							<Accordion
								defaultIndex={[0]}
								allowMultiple
							>
								{sortedYears.map((yearData) => {
									const mesRow: Row = {
										type: 'text',
										titleColumn: 'Mês',
									};
									const feriadosRow: Row = {
										type: 'text',
										titleColumn: 'Feriados',
									};
									const diasUteisRow: Row = {
										type: 'text',
										titleColumn: 'Dias Úteis',
									};
									const colaboradoresRow: Row = {
										type: 'text',
										titleColumn: 'Colaboradores',
									};

									for (let m = 1; m <= 12; m++) {
										mesRow[m] = yearData.data[0][m]
											? formatDate(
													yearData.data[0][m].mes_ano,
											  )
											: '';
										feriadosRow[m] = yearData.data[0][m]
											? yearData.data[0][m].feriados
											: 0;
										diasUteisRow[m] = yearData.data[0][m]
											? yearData.data[0][m].dias_uteis
											: 0;
										colaboradoresRow[m] = yearData.data[0][
											m
										]
											? yearData.data[0][m].colaboradores
											: 0;
									}

									const columnsMonths = generateRowsMonths(
										String(yearData.ano),
										12,
										tamanhoCiclo,
									);
									const columnsData = [
										columnStructure,
										...columnsMonths,
									];

									return (
										<AccordionItem key={yearData.ano}>
											<h2>
												<AccordionButton>
													<Box
														flex='1'
														textAlign='left'
													>
														{yearData.ano}
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>
												<Table
													name='Colaboração'
													columnsData={columnsData}
													tableData={[
														mesRow,
														feriadosRow,
														diasUteisRow,
														colaboradoresRow,
													]}
												/>
											</AccordionPanel>
										</AccordionItem>
									);
								})}
							</Accordion>
						</>
					)}
				</ModalBody>
				<ModalFooter />
			</ModalContent>
		</Modal>
	);
};
