/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState } from 'react';
import './GenericAutocomplete.css';
import { useGetCustomerByNameOrCnpjAsMutate } from '../../services/queryClient/wrapperHooks/useGetCustomerByNameOrCnpjAsMutate';
import GenericInput from '../inputs/GenericInput';
import { useFamilyListWithoutProductsAsMutate } from '../../services/queryClient/wrapperHooks/useFamilyListAsMutate';
import { useProductListAsMutate } from '../../services/queryClient/wrapperHooks/product/useProductListAsMutate';
import { useSubFamilyListAsMutate } from 'services/queryClient/wrapperHooks/useSubFamilyListAsMutate';
import { useResourceGroupListAsMutate } from '../../services/queryClient/wrapperHooks/useResourceListAsMutate';
import { useGetCustomerToCollaborationConsensusByNameOrCnpjAsMutate } from '../../services/queryClient/wrapperHooks/demanda/colabAndConsensus/useGetCustomerToCollaborationConsensusByNameOrCnpjAsMutate';
import { useProductModelListAsMutate } from 'services/queryClient/wrapperHooks/product/useProductModelListAsMutate';
import { useGetFilterDemand } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/filters/useGetFilterDemand';
import { useMemberGroupListAsMutate } from 'services/queryClient/wrapperHooks/useMemberListAsMutate';

interface IGenericAutocomplete {
	searchType: string;
	inputProps?: {
		ref?: any;
		value?: any;
		initialLabel?: string;
		onChange?: (value) => void;
		renderInputLabel?: (value: any) => string;
		extraRequestData?: any;
		clearInput?: () => boolean;
		hasButton?: boolean;
		getIcon?: () => JSX.Element;
		initialValue?: any;
		[key: string]: any;
	};
}

function GetRequestProps(
	limit = 5,
	search = '',
	searchType: string,
	extraRequestData: any = {},
) {
	const { ...clientDemandMutateProps } =
		useGetCustomerToCollaborationConsensusByNameOrCnpjAsMutate({
			busca: search,
			limit,
			...extraRequestData,
		});
	const { ...filterDemandMutateProps } = useGetFilterDemand({
		...extraRequestData,
		search,
		size: limit,
	});

	const { ...clientMutateProps } = useGetCustomerByNameOrCnpjAsMutate({
		busca: search,
		limit,
	});

	const { ...familyMutateProps } = useFamilyListWithoutProductsAsMutate({
		busca: search,
		limit,
	});

	const { ...subFamilyMutateProps } = useSubFamilyListAsMutate({
		busca: search,
		limit,
		...extraRequestData,
	});

	const { ...productModelMutateProps } = useProductModelListAsMutate({
		busca: search,
		limit,
		...extraRequestData,
	});

	const { ...productMutateProps } = useProductListAsMutate({
		status: true,
		search,
		limit,
		...extraRequestData,
	});

	const { ...resourceMutateProps } = useResourceGroupListAsMutate({
		status: true,
		busca: search,
		limit,
	});

	const { ...memberMutateProps } = useMemberGroupListAsMutate({
		busca: search,
		limit,
		...extraRequestData,
	});

	switch (searchType) {
		case 'cliente-demanda':
			return clientDemandMutateProps;
		case 'filtro-demanda':
			return filterDemandMutateProps;
		case 'cliente':
			return clientMutateProps;
		case 'familia':
			return familyMutateProps;
		case 'sub_familia':
			return subFamilyMutateProps;
		case 'modelo_produto':
			return productModelMutateProps;
		case 'produto':
			return productMutateProps;
		case 'grupo_recurso':
			return resourceMutateProps;
		case 'membro':
			return memberMutateProps;
		default:
			throw new Error('Mutate para autocomplete não implementado!');
	}
}
const GenericAutocomplete = ({
	searchType,
	inputProps,
}: IGenericAutocomplete) => {
	const {
		ref,
		value,
		initialLabel,
		onChange,
		renderInputLabel = (value) => 'Não implementado',
		extraRequestData,
		clearInput = () => {},
		hasButton = false,
		getIcon,
		initialValue,
		...othersInputProps
	}: any = inputProps;

	const [search, setSearch] = useState<string>('');
	const [limit, setLimit] = useState<number>(5);
	const [options, setOptions] = useState<any[]>([]);

	const [isAddMoreResult, setAddMoreResult] = useState<boolean>(false);
	const [canRequestData, setCanRequestData] = useState<boolean>(false);
	const [canShowOptions, setCanShowOptions] = useState<boolean>(false);

	const timer = useRef<any>(null);

	const {
		data,
		mutate,
		isIdle: isFistDefinition,
	} = GetRequestProps(limit, search, searchType, extraRequestData);

	function addMoreResult() {
		setAddMoreResult(true);
		setLimit((prev) => prev + 5);
	}

	function handleOnChange(e: any) {
		if (onChange) onChange(null);

		setCanRequestData(true);
		setSearch(e?.target?.value);
	}

	function handleOnClick() {
		setCanShowOptions(true);
	}

	function handleOnMouseLeave() {
		setCanShowOptions(false);
	}

	function handleOnSelect(data, textValue) {
		setCanRequestData(false);
		setCanShowOptions(false);
		if (onChange) onChange(data);

		setSearch(textValue);
	}

	function renderOptions() {
		function getOptionStyle(): React.CSSProperties {
			return getIcon
				? {
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						alignItems: 'center',
				  }
				: {};
		}

		return options?.length ? (
			<>
				{options?.map((d) => {
					const textValue = renderInputLabel(d);
					return (
						<div
							key={d?.id}
							className='option-item'
							onClick={() => handleOnSelect(d, textValue)}
							onKeyDown={() => {}}
							style={getOptionStyle()}
						>
							{getIcon ? (
								<>
									<div
										style={{
											flexGrow: 1,
										}}
									>
										{textValue}
									</div>
									<div>{getIcon(d)}</div>
								</>
							) : (
								textValue
							)}
						</div>
					);
				})}
			</>
		) : null;
	}

	function renderMoreOptions() {
		return searchType === 'cliente-demanda' ||
			options?.length < data?.count ? (
			<div
				key='action-add'
				className='option-add'
				onClick={() => addMoreResult()}
				onKeyDown={() => {}}
			>
				Mais resultados
			</div>
		) : null;
	}

	useEffect(() => {
		if (canRequestData) {
			clearTimeout(timer?.current);

			setLimit(5);

			timer.current = setTimeout(() => {
				mutate();
			}, 250);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		if (data?.rows?.length) {
			setOptions(data?.rows);
		} else {
			setOptions([]);
		}
	}, [data]);

	useEffect(() => {
		if (isAddMoreResult) {
			setAddMoreResult(false);
			mutate();
		}
	}, [isAddMoreResult, limit, mutate]);

	useEffect(() => {
		setSearch(initialLabel);
	}, [initialLabel]);

	useEffect(() => {
		if (clearInput()) {
			if (onChange) onChange(null);
			setSearch('');
			setOptions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clearInput]);

	function renderContent() {
		if (!canShowOptions) return null;

		if (options?.length)
			return (
				<div
					className={
						hasButton
							? 'option-container-with-button'
							: 'option-container'
					}
					onMouseLeave={handleOnMouseLeave}
				>
					{renderOptions()}
					{renderMoreOptions()}
				</div>
			);

		if (!isFistDefinition)
			return (
				<div
					className={
						hasButton
							? 'option-container-with-button'
							: 'option-container'
					}
					onMouseLeave={handleOnMouseLeave}
				>
					<div
						key='no-options'
						className='no-options'
					>
						Nenhum registro encontrado!
					</div>
				</div>
			);
	}

	function render() {
		const genericInputProps = {
			placeholder: 'Digite para pesquisar',
			...othersInputProps,
			onClick: handleOnClick,
			onChange: handleOnChange,
			value: search,
			icon: value && getIcon ? getIcon(value) : null,
		};

		return (
			<div
				style={{ width: '100%', position: 'relative' }}
				ref={ref}
			>
				<GenericInput
					type='text'
					inputProps={genericInputProps}
				/>

				{renderContent()}
			</div>
		);
	}

	return render();
};

export default GenericAutocomplete;
