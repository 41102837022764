import {
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';
import ApexChart, { formatDataMount } from 'components/chart/Chart';
import { useState } from 'react';
import styled from 'styled-components';

interface PropsModalEntenderEstatistica {
	onClose: () => void;
	showModal: boolean;
}
type Information = {
	key: string;
	text: string;
	title: string;
	haveLimit?: boolean;
	values:
		| Array<{
				mounth: string;
				value: number;
				limitMin: number;
				limitMax: number;
		  }>
		| Array<{
				mounth: string;
				value: number;
		  }>;
};
type Infomations = Information[];

const informations: Infomations = [
	{
		key: 'Média Móvel',
		text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. In expedita assumenda atque minus vero magnam molestiae similique, laborum ratione obcaecati, cumque ducimus dignissimos aliquam nihil fugit? Illum sit laboriosam placeat.',
		title: 'Média Móvel',
		haveLimit: true,
		values: [
			{
				mounth: '01/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
			{
				mounth: '02/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
			{
				mounth: '03/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
			{
				mounth: '04/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
			{
				mounth: '05/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
			{
				mounth: '06/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
		],
	},
	{
		key: 'Desvio Padrão',
		text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. In expedita assumenda atque minus vero magnam molestiae similique, laborum ratione obcaecati, cumque ducimus dignissimos aliquam nihil fugit? Illum sit laboriosam placeat.',
		title: 'Desvio Padrão',
		values: [
			{
				mounth: '01/2023',
				value: 1000,
			},
			{
				mounth: '02/2023',
				value: 1000,
			},
			{
				mounth: '03/2023',
				value: 1000,
			},
			{
				mounth: '04/2023',
				value: 1000,
			},
			{
				mounth: '05/2023',
				value: 1000,
			},
			{
				mounth: '06/2023',
				value: 1000,
			},
		],
	},
	{
		key: 'Mínima',
		text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. In expedita assumenda atque minus vero magnam molestiae similique, laborum ratione obcaecati, cumque ducimus dignissimos aliquam nihil fugit? Illum sit laboriosam placeat.',
		title: 'Mínima',
		values: [
			{
				mounth: '01/2023',
				value: 1000,
			},
			{
				mounth: '02/2023',
				value: 1000,
			},
			{
				mounth: '03/2023',
				value: 1000,
			},
			{
				mounth: '04/2023',
				value: 1000,
			},
			{
				mounth: '05/2023',
				value: 1000,
			},
			{
				mounth: '06/2023',
				value: 1000,
			},
		],
	},
	{
		key: 'Máxima',
		text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. In expedita assumenda atque minus vero magnam molestiae similique, laborum ratione obcaecati, cumque ducimus dignissimos aliquam nihil fugit? Illum sit laboriosam placeat.',
		title: 'Máxima',
		values: [
			{
				mounth: '01/2023',
				value: 1000,
			},
			{
				mounth: '02/2023',
				value: 1000,
			},
			{
				mounth: '03/2023',
				value: 1000,
			},
			{
				mounth: '04/2023',
				value: 1000,
			},
			{
				mounth: '05/2023',
				value: 1000,
			},
			{
				mounth: '06/2023',
				value: 1000,
			},
		],
	},
	{
		key: 'Tendência Linear',
		text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. In expedita assumenda atque minus vero magnam molestiae similique, laborum ratione obcaecati, cumque ducimus dignissimos aliquam nihil fugit? Illum sit laboriosam placeat.',
		title: 'Tendência Linear',
		values: [
			{
				mounth: '01/2023',
				value: 1000,
			},
			{
				mounth: '02/2023',
				value: 1000,
			},
			{
				mounth: '03/2023',
				value: 1000,
			},
			{
				mounth: '04/2023',
				value: 1000,
			},
			{
				mounth: '05/2023',
				value: 1000,
			},
			{
				mounth: '06/2023',
				value: 1000,
			},
		],
	},
	{
		key: 'Tendência Exponencial',
		text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. In expedita assumenda atque minus vero magnam molestiae similique, laborum ratione obcaecati, cumque ducimus dignissimos aliquam nihil fugit? Illum sit laboriosam placeat.',
		title: 'Sazonalidade',
		haveLimit: true,
		values: [
			{
				mounth: '01/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
			{
				mounth: '02/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
			{
				mounth: '03/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
			{
				mounth: '04/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
			{
				mounth: '05/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
			{
				mounth: '06/2023',
				limitMax: 2023,
				limitMin: 244,
				value: 1000,
			},
		],
	},
];
const Point = styled.span<{
	check: boolean;
}>`
	background: ${(p) => (p.check ? '#314866' : '#E0E5F2')};
	border-radius: 50%;
	width: 8px;
	height: 8px;
	cursor: pointer;
`;
export default function ModalEntenderEstatistica({
	onClose,
	showModal,
}: PropsModalEntenderEstatistica) {
	const [tabIndex, setTabIndex] = useState(0);
	const plusOne = () => {
		const newIndex = tabIndex + 1 >= informations.length ? 0 : tabIndex + 1;
		setTabIndex(newIndex);
	};
	const lessOne = () => {
		const newIndex =
			tabIndex - 1 < 0 ? informations.length - 1 : tabIndex - 1;
		setTabIndex(newIndex);
	};
	return (
		<Modal
			onClose={onClose}
			size={'xl'}
			isOpen={showModal}
		>
			<ModalOverlay />
			<ModalContent
				maxH='800px'
				maxW='900px'
			>
				<ModalHeader
					fontSize={'24px'}
					color={'easyBLUE.300'}
				>
					{' '}
					Entender estatística
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody display={'flex'}>
					<Tabs
						index={tabIndex}
						onChange={(index) => setTabIndex(index)}
					>
						<Flex
							bg='secondaryGray.300'
							padding={'40px'}
							flexDirection='column'
							alignItems={'center'}
							justifyContent={'center'}
						>
							<Flex
								flexGrow={1}
								alignItems={'center'}
							>
								<Flex>
									<Button
										onClick={lessOne}
										bgColor={'easyBLUE.300'}
										padding={'8px'}
										type='button'
									>
										<Icon
											as={IconChevronLeft}
											width={6}
											height={12}
											color={'white'}
										/>
									</Button>
								</Flex>
								<TabPanels>
									{informations.map((infor, index) => {
										return (
											<TabPanel key={infor.key}>
												<Flex
													flexDirection={'column'}
													gap={'15px'}
												>
													<Text
														fontWeight={'700'}
														fontSize={'24px'}
														color={'#2B3674'}
													>
														{infor.title}
													</Text>
													<Text
														lineHeight={'26px'}
														fontWeight={'400'}
														fontSize={'16px'}
														color={'#8F9BBA'}
													>
														{infor.text}
													</Text>
													<ApexChart
														haveLimit={Boolean(
															infor.haveLimit,
														)}
														valuesChart={infor.values.map(
															(value: {
																mounth: string;
																value: number;
																limitMin?: number;
																limitMax?: number;
															}) =>
																formatDataMount(
																	{
																		mounth: value.mounth,
																		value: value.value,
																		section:
																			'estatistica',
																		limitMax:
																			value.limitMax,
																		limitMin:
																			value.limitMin,
																	},
																),
														)}
													/>
												</Flex>
											</TabPanel>
										);
									})}
								</TabPanels>
								<Flex>
									<Button
										onClick={plusOne}
										bgColor={'easyBLUE.300'}
										padding={'8px'}
										type='button'
									>
										<Icon
											as={IconChevronRight}
											width={6}
											height={12}
											color={'white'}
										/>
									</Button>
								</Flex>
							</Flex>
							<Flex
								gap={'20px'}
								flexGrow={1}
							>
								{informations.map((infor, index) => {
									return (
										<Point
											key={infor.key}
											onClick={() => setTabIndex(index)}
											check={index === tabIndex}
										/>
									);
								})}
							</Flex>
						</Flex>
					</Tabs>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
