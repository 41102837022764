import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import {
	createException,
	ICreateOrEditException,
} from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useCreateException = (page: string) => {
	const toast = useToast();

	return useMutation(
		(b: ICreateOrEditException) => createException(page, b),
		{
			onSuccess: () => {
				toast({
					title: 'Exceção cadastrada com sucesso!',
					status: 'success',
					duration: 8000,
					isClosable: true,
					position: 'bottom-left',
				});
			},
			onError: (error: any) => {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao criar exceção!',
				);
				toastErrors?.forEach((element) => toast(element));
			},
		},
	);
};
