import { useToast } from '@chakra-ui/toast';
import { useQuery } from '@tanstack/react-query';
import { getWorkingDays } from 'services/api/requests/resources';

export const useGetWorkingDays = (year, month, shouldFetch) => {
	const toast = useToast();
	return useQuery(['work-shift', year, month], getWorkingDays, {
		onSuccess: (data) => {
			if (!data) {
				toast({
					title: 'Data inválida!',
					description:
						'Não existem registros de dias úteis para a data selecionada!',
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			}
		},
		refetchOnWindowFocus: false,
		enabled: shouldFetch,
	});
};
