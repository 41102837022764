import { useMutation } from '@tanstack/react-query';
import { getAccuracyFilters } from '../../api/requests/accuracy';
import { useToast } from '@chakra-ui/react';

export const useGetAccuracyFilters = (interval) => {
	const toast = useToast();

	return useMutation(() => getAccuracyFilters(interval), {
		onSuccess: (data) => data,
		onError: () => {
			toast({
				title: 'Error ao carregar os filtros de Acuracidade',
				description:
					'Ocorreu um erro ao processar sua solicitação. Contate o seu mentor ou envie um e-mail para: contato@easy360.ind.br.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
