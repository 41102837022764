export const AccuracyMessages = {
	MSG_STOCK_REDUCTION:
		'Vendeu acima do valor que foi previsto! Logo, você teve uma redução de estoque!',
	MSG_STOCK_INCREASE:
		'Vendeu abaixo do valor que foi previsto! Logo, você teve um aumento de estoque!',
	MSG_NO_STOCK_REDUCTION_OR_INCREASE_FOR_COLLABORATION:
		'As colaborações da demanda não possuem aumento e redução de estoque.',
	MSG_SALES_CHANNEL_NOT_INCLUDED_IN_ALLOCATION:
		'Canal de venda selecionado não participa do rateio do ciclo/ano utilizado para esta previsão.',
	MSG_ERROR_NO_DATA_FOR_SELECTED_FORECAST:
		'Não existe dados para o mês/ano e tipo de previsão selecionados!',
	MSG_MAPE_DEFINITION:
		'O MAPE avalia a precisão de uma previsão comparando os valores previstos com os valores reais, expressando o erro como uma porcentagem da demanda real. A Easy360 analisa a acuracidade do MAPE, a qual varia de 0% a 100%. Quanto mais próximo de 100% estiver o indicador, melhor será a acuracidade. É calculado somando os desvios entre realizado vs previsto de todos os produtos e dividido pelo realizado total, considerando apenas os volumes de produtos.',
	MSG_WMAPE_DEFINITION:
		'O WMAPE é uma variação do MAPE, atribuindo pesos de acordo com os erros de previsão dos produtos. A Easy360 analisa a acuracidade do WMAPE, a qual varia de 0% a 100%. Quanto mais próximo de 100% estiver o indicador, melhor será a acuracidade. É calculado somando os desvios entre realizado vs previsto de todos os produtos e dividido pelo realizado total, considerando o peso de cada produto.',
	MSG_SALES_ACCURACY_DEFINITION:
		'Corresponde à soma das variações entre o realizado vs previsto dos produtos, multiplicado pelo preço médio do canal de	venda selecionado. Este indicador tem como finalidade mostrar o impacto que os erros das previsões de vendas têm na receita final.',
	MSG_OPERATIONAL_ACCURACY_DEFINITION:
		'Corresponde à soma das variações entre o realizado vs previsto dos produtos, multiplicado pelo custo do produto. Este indicador tem como finalidade mostrar o impacto que os erros das previsões de vendas têm no custo operacional final.',
	MSG_REVENUE_FORECAST_DEFINITION:
		'A receita bruta prevista da família é calculada somando as receitas brutas previstas de seus produtos.',
	MSG_WITHOUT_COST: 'Não existe custo cadastrado.',
	MSG_WITHOUT_TICKET: 'Não existe preço médio cadastrado.',
	MSG_NO_COST_FOR_OPERATIONAL_ERROR:
		'Erro operacional requer dado de custo cadastrado.',
	MSG_NO_TICKET_FOR_COMMERCIAL_ERROR:
		'Erro comercial requer dado de preço médio cadastrado.',
} as const;

export type AccuracyMessageType =
	(typeof AccuracyMessages)[keyof typeof AccuracyMessages];
