import { Tag } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';

const tagsTagException: Record<string, { label: string; color: string }> = {
	opportunity_exception: {
		label: 'Exceção da Oportunidade',
		color: globalStyles.colors.green[200],
	},
	common_exception: {
		label: 'Exceção Comum',
		color: globalStyles.colors.green[200],
	},
};
interface TagTypeExceptionProps {
	tag: string;
}
const TagTypeException = ({ tag }: TagTypeExceptionProps) => {
	const tagData = tagsTagException[tag];
	if (!tagData) return <></>;
	return (
		<Tag
			size='sm'
			color={tagData.color}
			backgroundColor={tagData.color + '1A'}
			overflow='hidden'
		>
			{tagData.label}
		</Tag>
	);
};
export default TagTypeException;
