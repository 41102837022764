export const columnsEndCadastrar = [
	{
		Header: 'MÉDIA',
		accessor: 'media',
		type: 'text',
	},
	{
		Header: 'TOTAL',
		accessor: 'total',
		type: 'text',
	},
];
