import { ListItem, OrderedList, Text, UnorderedList } from '@chakra-ui/react';
import { AccuracyMessages } from '../variables/accuracyMessages';

export const renderHelpContent = () => {
	return (
		<>
			<Text
				color='easyBLUE.300'
				pt={3}
			>
				Os indicadores de acuracidade são:
			</Text>

			<OrderedList
				pt={3}
				color='easyBLUE.300'
			>
				<ListItem>
					<b>MAPE (Erro Percentual Absoluto Médio):</b>
					<OrderedList style={{ listStyleType: 'lower-alpha' }}>
						<ListItem>
							O que é: O MAPE é um indicador que mede o erro médio
							percentual entre o realizado e o previsto.
						</ListItem>
						<ListItem>
							Como interpretar: A Easy360 avalia a acuracidade do
							MAPE, a qual varia de 0% a 100%. Quanto mais próximo
							de 100% estiver o indicador, melhor será a
							acuracidade. É calculado somando os desvios entre
							realizado vs previsto de todos os produtos e
							dividido pelo realizado total.
						</ListItem>
					</OrderedList>
				</ListItem>

				<ListItem>
					<b>WMAPE (Erro Percentual Absoluto Médio Ponderado):</b>
					<OrderedList style={{ listStyleType: 'lower-alpha' }}>
						<ListItem>
							O que é: O WMAPE é uma variação do MAPE, atribuindo
							pesos de acordo com os erros de previsão dos
							produtos.
						</ListItem>
						<ListItem>
							Como interpretar: A Easy360 avalia a acuracidade do
							wMAPE, a qual varia de 0% a 100%. Quanto mais
							próximo de 100% estiver o indicador, melhor será a
							acuracidade. É calculado somando os desvios entre
							realizado vs previsto de todos os produtos e
							dividido pelo realizado total, considerando o peso
							de cada produto.
						</ListItem>
					</OrderedList>
				</ListItem>

				<ListItem>
					<b>Acuracidade Comercial:</b>
					<OrderedList style={{ listStyleType: 'lower-alpha' }}>
						<ListItem>
							O que é: Corresponde à soma das variações entre o
							realizado vs previsto dos produtos, multiplicado
							pelo preço médio do canal de venda selecionado.
						</ListItem>
						<ListItem>
							Como interpretar: Este indicador tem como finalidade
							mostrar o impacto que os erros das previsões de
							vendas têm na receita final.
						</ListItem>
					</OrderedList>
				</ListItem>

				<ListItem>
					<b>Acuracidade Operacional:</b>
					<OrderedList style={{ listStyleType: 'lower-alpha' }}>
						<ListItem>
							O que é: Corresponde à soma das variações entre o
							realizado vs previsto dos produtos, multiplicado
							pelo custo do produto.
						</ListItem>
						<ListItem>
							Como interpretar: Este indicador tem como finalidade
							mostrar o impacto que os erros das previsões de
							vendas têm no custo operacional final.
						</ListItem>
					</OrderedList>
				</ListItem>
			</OrderedList>

			<Text
				pt={3}
				color='easyBLUE.300'
			>
				Além dos indicadores, temos a orientação de aumento e redução de
				estoque da previsão da demanda:
			</Text>

			<UnorderedList
				pt={3}
				color='easyBLUE.300'
			>
				<ListItem>
					Aumento de Estoque: {AccuracyMessages.MSG_STOCK_INCREASE}
				</ListItem>
				<ListItem>
					Redução de Estoque: {AccuracyMessages.MSG_STOCK_REDUCTION}
				</ListItem>
			</UnorderedList>
		</>
	);
};
