import { useQuery } from '@tanstack/react-query';
import { getAverageTicketByProduct } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
interface IUseAverageTicketByProduct {
	productId?: string | number;
	salesChannelId?: string | number;
}
export const useAverageTicketByProduct = ({
	productId = '',
	salesChannelId = '',
}: IUseAverageTicketByProduct) => {
	return useQuery(
		[
			'average-ticket-by-product',
			String(salesChannelId),
			String(productId),
		],
		getAverageTicketByProduct,
		{
			refetchOnWindowFocus: false,
		},
	);
};
