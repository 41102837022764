import { QueryFunctionContext } from '@tanstack/react-query';
import api from '../api';

export const getMarkets = async () => {
	const response = await api.get('/hierarquiaComercial');
	return response.data;
};

export const getFilterCommercialHierarchy = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, canal_venda_id] = queryKey;
	if (canal_venda_id) {
		const response = await api.get(
			`/hierarquiaComercial/filtrosHierarquiaComercial?canal_venda_id=${canal_venda_id}`,
		);
		return response.data;
	}
	const response = await api.get(
		'/hierarquiaComercial/filtrosHierarquiaComercial',
	);
	return response.data;
};

export const createMarket = async (body) => {
	const response = await api.post('/hierarquiaComercial', body);
	return response.data;
};

export const deleteHierarchy = async (id: number) => {
	const response = await api.delete(`/hierarquiaComercial?id=${id}`);
	return response.data;
};

export const getMarketById = async ({ queryKey }) => {
	const [, id] = queryKey;

	const response = await api.get('mercado/mercadoById', {
		params: { id },
	});
	return response.data;
};

export const updateMarket = async (id: number, body) => {
	const response = await api.patch('mercado', body, {
		params: { id },
	});
	return response.data;
};

export const deleteMarketByFilters = async (filters: any) => {
	const response = await api.post('mercado/deletarPorFiltros', filters);
	return response.data;
};

export const deleteMarket = async (id: number) => {
	const response = await api.delete('mercado', { params: { id } });
	return response.data;
};
