import { useToast } from '@chakra-ui/react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createResourceGroup } from 'services/api/requests/resource-group';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';

export const useCreateResourceGroup = (
	form?,
	setForm?,
	setGrupoRecursoId?,
	inputName?: string,
	formProps?,
	setAutocompleteData?,
) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(createResourceGroup, {
		onSuccess: (success) => {
			queryClient.invalidateQueries(['resource-group-list']);

			toast({
				title: 'Criado com sucesso!',
				description: 'Grupo de recurso criado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});

			if (form && setForm) {
				setForm({
					...form,
					grupo_recurso: success.id,
					disponibilidade_minutos: success.disp_minuto_turno,
					grupo_recurso_id: success.id,
				});
				setGrupoRecursoId(success.id);
			}

			if (inputName && formProps) {
				const { setValue, clearErrors } = formProps;

				setValue(inputName, String(success.id));
				clearErrors('grupo_recurso_id');

				if (setAutocompleteData)
					setAutocompleteData((prev) => ({
						...prev,
						resourceGroup: success,
					}));
			}
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao criar grupo de recurso!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
