import api from '../api';

export const getPortfolioById = async ({ queryKey }) => {
	const [_key, id] = queryKey;
	const url = `/carteiraPedido/carteiraPedidoById?carteira_pedido_id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const createPortfolio = async (body) => {
	const url = '/carteiraPedido';
	const response = await api.post(url, body);
	return response.data;
};

export const editPortfolio = async (body, id: number) => {
	const url = `/carteiraPedido?carteira_pedido_id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const deletePortfolio = async (id: number) => {
	const url = `/carteiraPedido?carteira_pedido_id=${id}`;
	const response = await api.delete(url);
	return response.data;
};

export const deletePortfolioByFilters = async (filters: any) => {
	const response = await api.post(
		'carteiraPedido/deletarPorFiltros',
		filters,
	);
	return response.data;
};
