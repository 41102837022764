import { useToast } from '@chakra-ui/react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createCollaboration } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { getKeysValuesSelectedFilterDemandFamily } from './filters/useGetFilterDemandFamily';
import { getKeysValuesSelectedFilters } from './filters/useGetSelectedFilter';
import { getKeysValuesStatusDemand } from './filters/useGetStatusDemand';

export const useCreateCollaboration = () => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(createCollaboration, {
		onSuccess: async (_, variables) => {
			const {
				area_empresa_id,
				canal_venda_id,
				ciclo_id,
				excecao,
				familia_id,
				zona_venda_id,
			} = variables;
			if (!excecao) {
				queryClient.invalidateQueries(
					getKeysValuesSelectedFilters({
						pageName: 'colaboracao',
						cycleId: ciclo_id,
						familyId: familia_id,
						companyAreaId: area_empresa_id,
						salesChannelId: canal_venda_id,
						salesZoneId: zona_venda_id,
					}),
				);
				queryClient.invalidateQueries([
					'sum-for-sales-channel',
					`${ciclo_id}`,
					`${area_empresa_id}`,
					`${familia_id}`,
					`${canal_venda_id}`,
				]);

				queryClient.invalidateQueries(
					getKeysValuesSelectedFilterDemandFamily({
						cycleId: ciclo_id,
						pageName: 'colaboracao',
						companyAreaId: area_empresa_id,
						salesChannelId: canal_venda_id,
						salesZoneId: zona_venda_id,
					}),
				);
				queryClient.invalidateQueries(
					getKeysValuesStatusDemand({
						pageName: 'colaboracao',
						cycleId: ciclo_id,
						companyAreaId: area_empresa_id,
					}),
				);
				toast({
					title: 'Colaboração cadastrada com sucesso',
					status: 'success',
					duration: 9000,
					isClosable: true,
					position: 'bottom-left',
				});
			}
		},
		onError: () => {
			toast({
				title: 'Erro ao cadastrar colaboração',
				status: 'error',
				duration: 9000,
				isClosable: true,
				position: 'bottom-left',
			});
		},
	});
};
