import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { editHierarchy } from 'services/api/requests/commercial';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useEditHierarchy = (body, id: number) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => editHierarchy(body, id), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['hierarquiaComercial-list']);
			queryClient.invalidateQueries(['hierarchyById', id]);
			queryClient.invalidateQueries(['hierarchyWithId', id]);

			history.goBack();
			toast({
				title: 'Atualizado com sucesso!',
				description: 'Hierarquia atualizada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			const erro = error.response.data[0].message;

			if (erro === 'O membro já pertence a este canal!') {
				toast({
					title: 'Erro ao atualizar Hierarquia!',
					description:
						'Este membro já pertence a uma zona neste canal de venda. Por favor, selecione outro membro.',
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao criar Hierarquia Comercial!',
					'Verifique se os campos estão preenchidos corretamente. Os campos Canal de Venda, Zona de Venda e Membro são obrigatórios.',
				);
				toastErrors?.forEach((element) => toast(element));
			}
		},
	});
};
