import { ModulesType, SelectedFilterBody } from '../models/TableFilterModels';

const PREFIX: string = 'filters-';

export const setLocalStorageFilter = (
	module: ModulesType,
	newSet: any,
): void => {
	if (newSet) {
		window.localStorage.setItem(PREFIX + module, JSON.stringify(newSet));
	}
};

export const getLocalStorageFilter = (
	module: ModulesType,
): SelectedFilterBody[] | [] => {
	const localStorageFilter: string | null = window.localStorage.getItem(
		PREFIX + module,
	);
	if (localStorageFilter) {
		return JSON.parse(localStorageFilter);
	}

	return [];
};

export const clearLocalStorageFilter = (module: ModulesType): void => {
	localStorage.removeItem(PREFIX + module);
};

export const clearLocalStorageFilters = () => {
	const modules: ModulesType[] = [
		'venda',
		'oportunidade',
		'carteiraPedido',
		'horaHora',
		'forecastDemanda',
		'estoque',
		'orcamento',
		'hierarquia',
		'acuracidade',
		'membro',
		'clienteRelacionamento',
		'planoRestrito',
		'colaboracao',
		'consenso',
	];
	modules.forEach((module) => {
		clearLocalStorageFilter(module);
	});
};
