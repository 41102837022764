import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';
// ■ SelectColumnFilter
import { SelectColumnFilter } from 'components/dataTables/FilterTypes/SelectColumnFilter';

export const columnsDataStopTypology = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Nome da Parada',
		accessor: 'tipologiaParada',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Programada',
		accessor: 'programada',
		helper: true,
		type: 'badge-yes-no',
		badgeTrueText: 'SIM',
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: 'Tempo de Parada',
		accessor: 'tempoDeParada',
		type: 'text',
		helper: true,
		helperText:
			'Tempo de duração padrão da parada programada. Ex: HH:mm:ss',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
	},
];

export const inputsStopTypology = [
	{
		Header: 'Nome da Parada',
		accessor: 'tipologiaParada',
		type: 'text',
	},
	{
		Header: 'Tipo',
		accessor: 'programada',
		type: 'toggle',
		hasDependency: true,
		dependencyHeader: 'Tempo de Parada',
		dependencyAccessor: 'tempoDeParada',
	},
];
