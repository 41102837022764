import { useToast } from '@chakra-ui/react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createConsensus } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { getKeysValuesSelectedFilterDemandFamily } from './filters/useGetFilterDemandFamily';
import { getKeysValuesStatusDemand } from './filters/useGetStatusDemand';

export const useCreateConsensus = () => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(createConsensus, {
		onSuccess: (_, variables) => {
			const { ciclo_id, familia_id } = variables;
			queryClient.invalidateQueries([
				'collaboration-consensus-exception-by-family',
				'consenso',
				ciclo_id,
				familia_id,
				'',
			]);
			queryClient.invalidateQueries(
				getKeysValuesSelectedFilterDemandFamily({
					cycleId: ciclo_id,
					pageName: 'consenso',
				}),
			);
			queryClient.invalidateQueries(
				getKeysValuesStatusDemand({
					pageName: 'consenso',
					cycleId: ciclo_id,
				}),
			);
			toast({
				title: 'Consenso cadastrado com sucesso',
				status: 'success',
				duration: 9000,
				isClosable: true,
				position: 'bottom-left',
			});
		},
		onError: () => {
			toast({
				title: 'Erro ao cadastrar Consenso',
				status: 'error',
				duration: 9000,
				isClosable: true,
				position: 'bottom-left',
			});
		},
	});
};
