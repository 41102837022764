import { Button, ButtonGroup, IconButton } from '@chakra-ui/react';
import { IconX } from '@tabler/icons';
import { PopoverFilter } from 'components/popover/PopoverFilter';
import { styleSelectedFilterButton } from '../constants/StylesFilterButtons';
import {
	FilterBody,
	ModulesType,
	SelectedFilterBody,
} from '../models/TableFilterModels';

interface SelectedFilterItemProps {
	filter: FilterBody;
	handleRemoveItem: (itemId: string) => void;
	setForm: React.Dispatch<React.SetStateAction<SelectedFilterBody[]>>;
	form: SelectedFilterBody[];
	applyFilters: (close: any) => void;
	module: ModulesType;
	selectedItems: string[];
	setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
	getFromExternalAPI?: boolean;
	searchParams?: Object;
}

export const SelectedFilterItem = ({
	filter,
	handleRemoveItem,
	setForm,
	form,
	applyFilters,
	module,
	selectedItems,
	setSelectedItems,
	getFromExternalAPI = false,
	searchParams,
}: SelectedFilterItemProps) => {
	const { nome_coluna, nome_coluna_dicionario, tipo_coluna } = filter;

	const renderFilterButton = (): string => {
		if (tipo_coluna !== 'string') {
			return nome_coluna_dicionario;
		}

		const selectedFilter = form?.filter(
			(item) => item.nome_coluna === nome_coluna,
		);

		const valores: any =
			selectedFilter?.length > 0 ? selectedFilter[0].valores : [];

		if (valores.length === 0) {
			return nome_coluna_dicionario;
		}

		if (valores.length <= 2) {
			const values = valores?.join(', ');
			return `${nome_coluna_dicionario}: ${values}`;
		} else {
			return `${nome_coluna_dicionario}: ${valores.length} itens selecionados`;
		}
	};

	return (
		<PopoverFilter
			filter={filter}
			setForm={setForm}
			form={form}
			applyFilters={applyFilters}
			module={module}
			selectedItems={selectedItems}
			setSelectedItems={setSelectedItems}
			getFromExternalAPI={getFromExternalAPI}
			searchParams={searchParams}
		>
			<ButtonGroup
				isAttached
				mb={2}
				variant='outline'
				color={'easyBLUE.300'}
			>
				<Button {...styleSelectedFilterButton}>
					{renderFilterButton()}
				</Button>
				<IconButton
					data-test='button-remover_filtro'
					aria-label='Remover filtro'
					icon={
						<IconX
							width={'16px'}
							height={'16px'}
						/>
					}
					onClick={() => handleRemoveItem(nome_coluna)}
					{...styleSelectedFilterButton}
				/>
			</ButtonGroup>
		</PopoverFilter>
	);
};
