// Chakra imports
import {
	Box,
	ButtonGroup,
	CircularProgress,
	Flex,
	FormControl,
	FormLabel,
	Icon,
	Input,
	Text,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { IconCaretDown, IconCaretRight } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import TreeView from 'components/treeView/TreeView';
import { useForm } from 'services/hooks/useForm';
import { useGetProductionTarget } from 'services/queryClient/wrapperHooks/useGetProductionTarget';

import { useEditProductionTarget } from 'services/queryClient/wrapperHooks/useEditProductionTarget';
import { RouterPrompt } from 'components/modal/RouterPrompt';
import { ModalSave } from './variables/ModalSave';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import GenericInput from 'components/inputs/GenericInput';
import moment from 'moment';
import { LoadingSpinner } from 'components/loading/Loading';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function CadastroMetas() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpMetas: SidebarHelp = useMemo(
		() => ({
			title: 'Metas',
			firstParagraph:
				'Nesta tela é possível cadastrar e visualizar Metas de Produção dos grupos de recursos da sua indústria. A meta ideal, global, para a eficiência dos recursos é de 85%. Porém, a eficiência dos recursos pode variar de acordo com o mix de produtos, número de setups realizados, entre outros. Por isso, é importante levar em consideração todas as situações de cada grupo de recurso para definir sua meta.',
			secondParagraph:
				'Para cadastrar uma meta, nos filtros selecione o Ano e Mês em que deseja entrar com a nova meta, em seguida clique em cima do nome do Grupo de Recurso e entre com os valores de disponibilidade, performance e qualidade. IROG = disponibilidade x performance x qualidade. Ao clicar em “Salvar”, será permitido salvar a meta criada para o ano inteiro ou apenas para o mês selecionado.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpMetas);
	}, [helpMetas, setDataSidebar]);

	const { form, onChange, setForm } = useForm({});

	const {
		isOpen: isOpenConfirmSave,
		onOpen: onOpenConfirmSave,
		onClose: onCloseConfirmSave,
	} = useDisclosure();
	const {
		isOpen: isOpenModalSaveYear,
		onOpen: onOpenModalSaveYear,
		onClose: onCloseModalSaveYear,
	} = useDisclosure();
	const [closeSave, setCloseSave] = useState(false);
	const [saveYear, setSaveYear] = useState();

	const [items, setItems] = useState<any>({
		root: {
			index: 'root',
			children: [],
			data: 'Root item',
			hasChildren: true,
			indexComponent: 0,
		},
	});

	const date = new Date();
	const currentYear = date.getFullYear();
	const currentMonth = date.getMonth() + 1;

	const [filterProductTarget, setFilterProductTarget] = useState({
		year: currentYear,
		month: currentMonth,
	});

	const {
		mutate: getProductionTargetMutate,
		data: ProductionTargetData,
		isLoading: isLoadingProductionTarget,
		isError: isErrorProductionTarget,
	} = useGetProductionTarget(
		filterProductTarget.year,
		filterProductTarget.month,
	);

	useEffect(() => {
		// event.preventDefault();
		getProductionTargetMutate();
	}, []);

	// const { data: ProductionTargetData, isLoading: ProductionTargetIsLoading, error: ProductionTargetIsError, status } = useGetProductionTarget(filterProductTarget.year, filterProductTarget.month)

	useEffect(() => {
		if (ProductionTargetData) {
			const newItems: any = {
				root: {
					index: 'root',
					children: [],
					data: 'Root item',
					hasChildren: true,
					indexComponent: 0,
				},
			};

			ProductionTargetData?.forEach((item: any, index: number) => {
				const keyChannel = item.nome_grupo_recurso;
				newItems.root.children.push(keyChannel);
				newItems[keyChannel] = {
					id: item.id_grupo,
					index: keyChannel,
					children: [],
					data: item.nome_grupo_recurso,
					hasChildren: true,
					indexComponent: 1,
					isOpen: false,
					idElement: item.id_grupo,
					original: item,
				};

				item.recursos.forEach((subItem: any, subIndex: number) => {
					const keyFamily = `${keyChannel}-${subItem.descricao_recurso}`;
					newItems[keyChannel].children.push(keyFamily);
					newItems[keyFamily] = {
						id: subItem.recurso_id,
						index: keyFamily,
						children: [],
						data: subItem.descricao_recurso,
						hasChildren: true,
						indexComponent: 2,
						isOpen: false,
						idElement: item.id_grupo,
						isChildrenOfChannel: keyChannel,
						isChildrenOfFamily: subItem.descricao_recurso,
						original: subItem,
					};
				});
			});

			setItems(newItems);
		}
	}, [ProductionTargetData]);

	const [objToUpdate, setObjToUpdate] = useState<any>([]);

	useEffect(() => {
		if (ProductionTargetData) {
			const newObj = {};

			ProductionTargetData?.forEach((item: any, index: number) => {
				const keyChannel = item.nome_grupo_recurso;
				newObj[keyChannel] = {};

				item.recursos.forEach((subItem: any, subIndex: number) => {
					const keyFamily = subItem.descricao_recurso;
					setObjToUpdate((old) => [...old, subItem]);
					newObj[keyChannel][keyFamily] = {
						DISPONIBILIDADE: subItem.disponibilidade_meta,
						QUALIDADE: subItem.qualidade_meta,
						PERFORMANCE: subItem.performance_meta,
						IROG: `${subItem.irog_meta}%`,
						id: subItem.id,
						recurso_id: subItem.recurso_id,
						empresa_id: 1,
						save_year: saveYear,
						mes_ano: subItem.mes_ano,
					};
				});
			});

			setForm(newObj);
		}
	}, [ProductionTargetData, saveYear, setForm]);

	const columnsProductTarget = [
		{ text: 'DISPONIBILIDADE' },
		{ text: 'PERFORMANCE' },
		{ text: 'QUALIDADE' },
		{ text: 'IROG' },
	];

	const [focus, setFocus] = useState({
		ProductTargetId: '',
		column: '',
	});

	const [stateForBody, setStateForBody] = useState<any>([]);

	const { mutate } = useEditProductionTarget(
		stateForBody,
		setCloseSave,
		getProductionTargetMutate,
	);

	const updateData = () => {
		setCloseSave(false);
		// event.preventDefault();
		onCloseModalSaveYear();
		mutate();
	};

	const updateProductionTarget = useCallback(
		(event, isChildrenOfChannel?, item?) => {
			event.preventDefault();
			setCloseSave(true);

			const { name, value, checked } = event.target;

			const family = {
				...form[isChildrenOfChannel],
				[item]: { ...form[isChildrenOfChannel][item], [name]: value },
			};

			const qualidade = Number(family[item]['QUALIDADE']) / 100;
			const performance = Number(family[item]['PERFORMANCE']) / 100;
			const diponibilidade =
				Number(family[item]['DISPONIBILIDADE']) / 100;

			family[item]['IROG'] = (
				diponibilidade *
				performance *
				qualidade *
				100
			).toFixed(2);

			/**
			 * Necessário filtrar para evitar que os recursos sejam duplicados. Ocorre que a função está sendo
			 * chamada duas vezes quando o usuário altera o valor do input.
			 */
			const createObjectToUpdate = objToUpdate
				.map((recurso, index) => {
					if (recurso.recurso_id === family[item].recurso_id) {
						return (objToUpdate[index] = {
							disponibilidade_meta: Number(
								family[item]['DISPONIBILIDADE'],
							),
							performance_meta: Number(
								family[item]['PERFORMANCE'],
							),
							qualidade_meta: Number(family[item]['QUALIDADE']),
							irog_meta: Number(family[item]['IROG']),
							mes_ano: family[item]['mes_ano'],
							updateYear: family[item]['save_year'],
							id: family[item]['id'],
							empresa_id: family[item]['empresa_id'],
							recurso_id: family[item]['recurso_id'],
						});
					} else {
						return recurso;
					}
				})
				.filter(
					(value, index, array) =>
						array.findIndex(
							(el) => el?.recurso_id === value?.recurso_id,
						) === index,
				);

			setStateForBody(createObjectToUpdate);
		},
		[form, objToUpdate],
	);

	useEffect(() => {
		stateForBody?.forEach((element) => {
			return (element.updateYear = saveYear);
		});
	}, [saveYear, stateForBody]);

	const inputProducts = (column, item, indexItem, isChildrenOfChannel) => {
		return (
			<Flex
				h={12}
				alignItems={'center'}
				key={column.id}
				borderBottom={item !== 'Total' ? '1px solid' : 'null'}
				borderColor={item !== 'Total' ? borderColor : 'null'}
			>
				<FormControl
					key={column.id}
					display={'flex'}
					justifyContent={'center'}
				>
					<Input
						w={20}
						// Input renderiza a página sempre que digita um valor, para isso provisioriamente utilizamos autoFocus para que o input fique sempre em foco
						autoFocus={
							focus.ProductTargetId === item &&
							focus.column === column.text
						}
						px={4}
						type='number'
						border={'1px solid'}
						borderColor={'secondaryGray.100'}
						borderRadius={'16px'}
						value={form[isChildrenOfChannel][item][column.text]}
						onChange={(e) => {
							setFocus({
								ProductTargetId: item,
								column: column.text,
							});
							onChange(
								e,
								'meta-producao',
								column.text,
								indexItem,
								isChildrenOfChannel,
								'',
								item,
							);
							updateProductionTarget(
								e,
								isChildrenOfChannel,
								item,
							);
						}}
						name={column.text}
					/>
				</FormControl>
			</Flex>
		);
	};

	const columnInputs = (
		item: string,
		indexItem: string,
		isChildrenOfChannel,
		isChildrenOfFamily,
	) =>
		columnsProductTarget.map((column: any, index: number) => {
			switch (index) {
				case 3: // Renderiza coluna IROG
					return (
						<Flex
							h={12}
							alignItems={'center'}
							borderBottom={'1px solid'}
							borderColor={borderColor}
						>
							<FormControl
								key={column.id}
								mx={3}
							>
								<Input
									px={0}
									type='string'
									value={
										form[isChildrenOfChannel][item][
											column.text
										]
									}
									border={'none'}
									color={
										item !== 'Total'
											? 'secondaryGray.500'
											: 'easyBLUE.300'
									}
									fontWeight={500}
									fontSize={'14px'}
									textAlign={'center'}
									isReadOnly={true}
									borderRadius={'16px'}
								/>
							</FormControl>
						</Flex>
					);
				default:
					return inputProducts(
						column,
						item,
						indexItem,
						isChildrenOfChannel,
					);
			}
		});

	const displaycolumnsAbbreviated = (
		isChildrenOfChannel,
		isChildrenOfFamily,
	) =>
		columnsProductTarget.map((column: any, index) => {
			if (index === 3) {
				return (
					<Flex
						h={12}
						alignItems={'center'}
						justifyContent={'center'}
						borderBottom={'1px solid'}
						borderColor={borderColor}
					>
						<Text
							key={column.id}
							mx={6}
							fontSize={'sm'}
							color={'secondaryGray.500'}
							textAlign={'center'}
							fontWeight={700}
						>
							{column.text.toUpperCase()}
						</Text>
					</Flex>
				);
			}
			return (
				<Flex
					h={12}
					alignItems={'center'}
					justifyContent={'center'}
					borderBottom={'1px solid'}
					borderColor={borderColor}
				>
					<Text
						key={column.id}
						mx={6}
						fontSize={'sm'}
						textAlign={'center'}
						fontWeight={700}
						// color={((form[column.abbreviated].Total) >= 99 && (form[column.abbreviated].Total) <= 101) ? "green.500" : "secondaryGray.500"}
					>
						{column.text}
					</Text>
				</Flex>
			);
		});

	const onChangeFilter = (valueYear, valueMonth) => {
		setFilterProductTarget({ year: valueYear, month: valueMonth });
		getProductionTargetMutate();
	};

	const setOpen = (id: string) => {
		setItems({
			...items,
			[id]: { ...items[id], isOpen: !items[id].isOpen },
		});
	};

	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const renderYears = useMemo(() => {
		let initialYear = moment().subtract(1, 'years').year();
		const finalYear = moment().add(4, 'years').year();

		const years = [] as any;

		do {
			years.push(
				<option
					key={initialYear}
					value={initialYear}
					selected={filterProductTarget?.year === initialYear}
				>
					{initialYear}
				</option>,
			);

			initialYear++;
		} while (initialYear <= finalYear);

		return years;
	}, [filterProductTarget?.year]);

	const renderMonths = useMemo(() => {
		const months = [
			{ value: 1, label: 'Janeiro' },
			{ value: 2, label: 'Fevereiro' },
			{ value: 3, label: 'Março' },
			{ value: 4, label: 'Abril' },
			{ value: 5, label: 'Maio' },
			{ value: 6, label: 'Junho' },
			{ value: 7, label: 'Julho' },
			{ value: 8, label: 'Agosto' },
			{ value: 9, label: 'Setembro' },
			{ value: 10, label: 'Outubro' },
			{ value: 11, label: 'Novembro' },
			{ value: 12, label: 'Dezembro' },
		];

		return months.map((month) => (
			<option
				key={month.value}
				value={month.value}
				selected={filterProductTarget?.month === month.value}
			>
				{month.label}
			</option>
		));
	}, [filterProductTarget?.month]);

	return (
		<Flex
			direction={{ base: 'column', xl: 'row' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
		>
			<Card pt={10}>
				<Box mb={5}>
					<Box mb={14}>
						<Flex gap={4}>
							<FormControl
								mr='5px'
								mb='20px'
							>
								<FormLabel mb='0'>Ano</FormLabel>
								<GenericInput
									type='select'
									inputProps={{
										onChange: (e) => {
											onChangeFilter(
												e.target.value,
												filterProductTarget.month,
											);
										},
										h: '44px',
										borderRadius: 10,
										selectOptions: renderYears,
									}}
								/>
							</FormControl>

							<FormControl
								mr='5px'
								mb='20px'
							>
								<FormLabel mb='0'>Mês</FormLabel>
								<GenericInput
									type='select'
									inputProps={{
										onChange: (e) => {
											onChangeFilter(
												filterProductTarget.year,
												e.target.value,
											);
										},
										h: '44px',
										borderRadius: 10,
										selectOptions: renderMonths,
									}}
								/>
							</FormControl>
						</Flex>
					</Box>
					{isLoadingProductionTarget && <LoadingSpinner />}
					{isErrorProductionTarget && (
						<h1>Ocorreu um erro, tente novamente!</h1>
					)}
					{ProductionTargetData && (
						<TreeView
							ids={[]}
							id='root'
							Containers={[
								({ data, children }) => (
									<Box>
										<>{children}</>
									</Box>
								),
								({
									data,
									id,
									children,
									isChildrenOfChannel,
									isChildrenOfFamily,
								}) => (
									<Box
										display={'grid'}
										gridTemplateColumns={`15rem repeat(4, 1fr)`}
										gridRowGap={0.5}
										alignItems={'center'}
									>
										<Flex
											onClick={() => setOpen(id)}
											// paddingLeft={24}
											fontSize={'16px'}
											fontWeight={400}
											color={'easyBLUE.300'}
											h={12}
											alignItems={'center'}
											borderBottom={'1px solid'}
											borderColor={borderColor}
										>
											<Flex mr={3.5}>
												{items[id].isOpen ? (
													<Icon
														as={IconCaretDown}
														boxSize={5}
														color={
															'secondaryGray.600'
														}
													/>
												) : (
													<Icon
														as={IconCaretRight}
														boxSize={5}
														color={
															'secondaryGray.600'
														}
													/>
												)}
											</Flex>
											<Text>{data}</Text>
										</Flex>
										{displaycolumnsAbbreviated(
											isChildrenOfChannel,
											isChildrenOfFamily,
										)}

										{items[id].isOpen && children}
									</Box>
								),

								({
									data,
									id,
									children,
									index,
									idElement,
									isChildrenOfChannel,
									isChildrenOfFamily,
								}) => (
									<>
										<Box
											alignItems={'center'}
											borderBottom={
												data !== 'Total'
													? '1px solid'
													: 'null'
											}
											borderColor={
												data !== 'Total'
													? borderColor
													: 'null'
											}
										>
											<Flex
												onClick={() => setOpen(id)}
												paddingLeft={15}
												fontSize={'16px'}
												fontWeight={400}
												color={'easyBLUE.300'}
												h={12}
												alignItems={'center'}
												bg={
													data === 'Total'
														? 'secondaryGray.300'
														: 'white'
												}
											>
												<Text>{data}</Text>
											</Flex>
										</Box>

										{columnInputs(
											data,
											index,
											isChildrenOfChannel,
											isChildrenOfFamily,
										)}
									</>
								),
							]}
							items={items}
							deeper={0}
						/>
					)}
				</Box>
				<Flex
					flexDirection={'row'}
					justifyContent={'flex-end'}
					mt={'20px'}
					me={'20px'}
				>
					<ButtonGroup spacing={'24px'}>
						<ButtonComponent
							type={'ghost'}
							title={'Cancelar'}
						/>
						<ButtonComponent
							data-test='button-salvar'
							type={'primary'}
							title={'Salvar'}
							onClick={onOpenModalSaveYear}
						/>
					</ButtonGroup>
				</Flex>
			</Card>

			<RouterPrompt
				isOpen={closeSave}
				saveChanges={updateData}
				onCloseConfirmSave={onCloseConfirmSave}
			></RouterPrompt>
			<ModalSave
				isOpen={isOpenModalSaveYear}
				saveChanges={updateData}
				onClose={onCloseModalSaveYear}
				setSaveYear={setSaveYear}
			/>
		</Flex>
	);
}
