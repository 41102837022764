import { createContext, useContext, useEffect, useState } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import { useQuery } from 'services/hooks/useQuery';
import { useAverageTicketByFamily } from 'services/queryClient/wrapperHooks/demanda/averageTicket/useAverageTicketByFamily';
import { useGetStatusDemand } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/filters/useGetStatusDemand';
import { useGetSelectedFilters } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/filters/useGetSelectedFilter';
import {
	SelectedFiltersOutput,
	StatusDemand,
} from 'services/api/requests/colabAndConsensus/colabAndConsensusFilters';
import { useGetCycle } from 'services/queryClient/wrapperHooks/cycle/useGetCycle';
import { Cycle } from 'models/cycle.model';
import { useGetCollaborationConfig } from 'services/queryClient/wrapperHooks/demanda/colabAndConsensus/useGetCollaborationConfig';
import { useStatistic } from 'services/queryClient/wrapperHooks/statistic/useStatistic';

export type KeyFilter =
	| 'ciclo'
	| 'familia'
	| 'canal_venda'
	| 'zona_venda'
	| 'cliente';

const ContextColaboracaoConsenso = createContext<IContext>({} as IContext);

export type KeysPage = {
	page: string;
	singular: string;
	plural: string;
	textSingular: string;
	textPlural: string;
	conclueded: string;
};
export type Page = 'colaboracao' | 'consenso';
interface PropsProviderColaboracaoConsenso {
	page: Page;
	children: JSX.Element;
}

export interface PropsUpdateBySelectColabConsen {
	setTotal: (value: React.SetStateAction<number>) => void;
	setValues: any;
	setMedia?: (value: React.SetStateAction<number>) => void;
}
export interface ConfidenceInterval {
	max: number;
	min: number;
}
export type FilterOption = {
	label: string;
	value: string | number;
	check?: boolean;
	manual_filling?: boolean;
	has_zona_venda?: boolean;
};
export type ConfigName = 'agrupar_canal_venda' | 'agrupar_zona_venda';
export interface Filter {
	show?: boolean;
	grouping?: boolean;
	disabled?: boolean;
	value: FilterOption;
	key: string;
	label: string;
	configName?: ConfigName;
	options?: FilterOption[];
	disableGrouping?: boolean;
	total?: number;
}

export type Filters = Record<string, Filter>;
export type ValuesFilterContext = Partial<
	Record<KeyFilter, FilterOption | undefined>
>;
export type ValuesFilter = Record<string, FilterOption | undefined>;
export interface IContext {
	isLoadingFilters: boolean;
	averageTicket: number;
	status?: StatusDemand;

	filters: Partial<Record<KeyFilter, Filter>>;
	setValuesFilter: React.Dispatch<React.SetStateAction<ValuesFilterContext>>;
	valuesFilter: ValuesFilterContext;
	showSections: {
		[key: string]: boolean;
	};
	onChangeShowSections: (
		newValue: OnChangeValue<string, true>,
		actionMeta: ActionMeta<any>,
	) => void;

	urlInitialCycleId: string;
	cycleId: string;
	cycle: Cycle;
	familyId: string;
	salesChannelId: string;
	companyAreaId?: string;
	clientId: string;
	collaborationConfig?: any;

	setFamilyId: React.Dispatch<React.SetStateAction<string>>;

	keysPage: KeysPage;
	confidenceInterval: ConfidenceInterval;
	companyArea: any;
	salesZoneId: string;

	familys?: FilterOption[];
	companyAreas?: FilterOption[];
}
export interface CompanyArea {
	id: number;
	nome_area_empresa: string;
	[rest: string]: any;
}
const keysPages: Record<Page, KeysPage> = {
	colaboracao: {
		page: 'colaboracao',
		singular: 'colaboração',
		plural: 'colaborações',
		textSingular: 'Colaboração',
		textPlural: 'Colaborações',
		conclueded: 'concluída',
	},
	consenso: {
		page: 'consenso',
		singular: 'consenso',
		plural: 'consensos',
		textSingular: 'Consenso',
		textPlural: 'Consensos',
		conclueded: 'concluído',
	},
};

export default function ProviderColaboracaoConsenso({
	children,
	page,
}: PropsProviderColaboracaoConsenso) {
	const query = useQuery();
	const keysPage = keysPages[page];

	const [averageTicket, setAverageTicket] = useState<number>(0);
	const [familyId, setFamilyId] = useState<string>('');

	const [confidenceInterval, setConfidenceInterval] =
		useState<ConfidenceInterval>({
			max: 999999999999999,
			min: 0,
		});

	const urlInitialCycleId = query.get('cicloId') || '';
	const companyAreaId = query.get('areaEmpresaId') || '';
	const companyArea = query.get('colaborador');

	const initialFilter: ValuesFilter = {
		canal_venda: undefined,
		ciclo: undefined,
		cliente: {
			label: 'Selecione',
			value: '',
		},
	};

	const [valuesFilter, setValuesFilter] =
		useState<ValuesFilter>(initialFilter);

	const [filters, setFilters] = useState<SelectedFiltersOutput>({});

	const [showSections, setShowSections] = useState<{
		[key: string]: boolean;
	}>({});

	const cycleId = String(valuesFilter.ciclo?.value ?? '');
	const salesChannelId = String(valuesFilter.canal_venda?.value ?? '');
	const salesZoneId = String(valuesFilter.zona_venda?.value ?? '');
	const clientId = String(valuesFilter.cliente?.value ?? '');

	const { data: cycle } = useGetCycle(cycleId);

	const { data: status } = useGetStatusDemand({
		pageName: keysPage.page,
		cycleId,
		companyAreaId,
	});
	const onSuccessSelectedFilters = (
		data: Partial<Record<KeyFilter, Filter>>,
	) => {
		setValuesFilter((prev) => {
			const newValues = { ...prev };
			for (const key in data) {
				newValues[key] = data[key].value;
			}
			return newValues;
		});
		setFilters(data);
		setFamilyId(String(data.familia?.value?.value ?? ''));
	};
	const { isLoading: isLoadingFilters } = useGetSelectedFilters(
		{
			pageName: keysPage.page,
			cycleId: cycleId || urlInitialCycleId,
			familyId,
			companyAreaId,
			salesChannelId,
			salesZoneId,
		},
		onSuccessSelectedFilters,
	);

	const { data: averageTicketData } = useAverageTicketByFamily({
		familyId,
		salesChannelId,
	});

	useEffect(() => {
		if (averageTicketData) {
			setAverageTicket(averageTicketData?.ticket_medio || 0);
		}
	}, [averageTicketData]);

	const onChangeShowSections = (
		newValue: OnChangeValue<string, true>,
		actionMeta: ActionMeta<any>,
	) => {
		switch (actionMeta.action) {
			case 'select-option':
				setChangeScrool(actionMeta.option.value);
				setShowSections({
					...showSections,
					[actionMeta.option.value]: true,
				});
				break;

			case 'remove-value':
				setShowSections({
					...showSections,
					[actionMeta.removedValue.value]: false,
				});
				setChangeScrool('');
				break;
			case 'clear':
				setShowSections({});
				break;
		}
	};
	const [changeScrool, setChangeScrool] = useState<string>();

	useEffect(() => {
		if (changeScrool) {
			const yOffset = -140;
			const element = window.document.getElementById(changeScrool);
			const y =
				(element?.getBoundingClientRect().top || 0) +
				window.pageYOffset +
				yOffset;

			window.scrollTo({ top: y, behavior: 'smooth' });
		}
	}, [changeScrool]);

	const {
		data: statisticsData,
		isError,
		isFetched,
	} = useStatistic({
		familyId,
		cycleId,
		salesChannelId,
		salesZoneId,
		andStatisticInterval: { desvioPadraoSigma: 3 },
	});

	useEffect(() => {
		if (statisticsData && statisticsData.length > 0) {
			const { limite_inferior_quantidade, limite_superior_quantidade } =
				statisticsData[0];
			setConfidenceInterval({
				max: limite_superior_quantidade,
				min: limite_inferior_quantidade,
			});
		}
		if (isFetched && isError) {
			setConfidenceInterval({
				max: 999999999999999,
				min: 0,
			});
		}
	}, [statisticsData, isError, isFetched]);

	const { data: collaborationConfig } = useGetCollaborationConfig({
		companyAreaId,
		cycleId,
	});
	return (
		<ContextColaboracaoConsenso.Provider
			value={{
				setValuesFilter,
				isLoadingFilters,
				averageTicket,
				onChangeShowSections,
				showSections,
				filters,
				valuesFilter,
				familyId,
				setFamilyId,
				urlInitialCycleId,
				cycleId,
				cycle: cycle || {
					ano: 0,
					ciclo: 0,
					duracao: 6,
					id: 0,
				},
				status,
				companyAreaId,
				clientId,
				keysPage,
				confidenceInterval,
				companyArea,
				salesChannelId,
				salesZoneId,
				collaborationConfig,
			}}
		>
			{!!cycleId && children}
		</ContextColaboracaoConsenso.Provider>
	);
}

export const useContextColaboracaoConsenso = () => {
	return useContext(ContextColaboracaoConsenso);
};
