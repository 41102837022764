import { FilterBody } from 'components/filter/models/TableFilterModels';
import api from 'services/api/api';
import { QueryTypeModule } from 'services/queryClient/wrapperHooks/genericTables/useGetCheckTableDataWithFilters';
import { RequestCheckTableBody } from 'services/queryClient/wrapperHooks/genericTables/useGetDataGridTableWithFilters';
interface FetchDataByFiltersOutput<T> {
	dados: Array<T>;
	total?: Partial<T>;
	filterMap: FilterBody[];
	dependencias: string[];
	total_linhas: number;
}
export const fetchDataByFiltersAgGrid = async <T, BodyType>(
	module: QueryTypeModule,
	body: RequestCheckTableBody<BodyType>,
): Promise<FetchDataByFiltersOutput<T>> => {
	const url = `/${module}/view`;
	const urlTotal = `/${module}/view/total`;
	const [dataRows, totalRows] = await Promise.all([
		api.post(url, body).then((res) => res.data),
		api.post(urlTotal, body).then((res) => res.data),
	]);
	return {
		...dataRows,
		total: totalRows,
	};
};
