import './styles.css';
import {
	Checkbox,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Table,
	Tbody,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTimeFormContext } from 'contexts/TimeFormContext';
import {
	FilterBody,
	SelectedFilterBody,
} from 'components/filter/models/TableFilterModels';
import { useGetHourlyWithFilters } from 'services/queryClient/wrapperHooks/lancamentoHoraHora/useGetHourlyWithFilters';
import ManualPaging, {
	IManualPaging,
} from 'components/dataTables/ManualPaging';
import { PAGINATION_SIZE_LIST } from 'utils/consts/pagination';
import { ItemLancamento } from './ItemLancamento';
import { TableFilter } from '../../../../../../components/filter/TableFilter';
import CheckTableActions from '../../../../../../components/dataTables/CheckTableActions';
import { useDeleteModalByFilters } from '../../../../../../services/queryClient/wrapperHooks/useDeleteModalByFilters';
import { LoadingSpinner } from '../../../../../../components/loading/Loading';
import { DeleteModal } from '../../../../../../components/modal/DeleteModal';
import { requestParseTime } from 'components/filter/utils/requestParseTime';

export interface THead {
	title: string;
	colSpan?: number;
	alignRight?: boolean;
}

export type OpenDeleteModal = (type: keyof DataDeleteType, id: number) => void;

interface DataDelete {
	provider: string;
	idDelete: number;
	toastSuccessDescription: string;
	toastSuccessTitle: string;
	toastProcessingTitle: string;
	toastProcessingDescription: string;
	toastErrorTitle: string;
	invalidate: string;
	title: string;
	text: string | string[];
}

interface DataDeleteType {
	time: DataDelete;
	break: DataDelete;
}

export const VisualizarLancamentoModal = (props) => {
	const { isOpen, onClose, setHasBaseData } = props;

	const {
		isOpen: isOpenDelete,
		onOpen: onOpenDelete,
		onClose: onCloseDelete,
	} = useDisclosure();

	const {
		setters: { setEditTimeForm },
	} = useTimeFormContext();

	const [pagination, setPagination] = useState<IManualPaging>({
		page: 0,
		size: PAGINATION_SIZE_LIST[0],
		totalPages: 1,
	});
	const [filterMap, setFilterMap] = useState<FilterBody[] | []>([]);
	const [filters, setFilters] = useState<SelectedFilterBody[] | []>([]);
	const [filteredTable, setFilteredTable] = useState<boolean>(false);

	const [rowsData, setRowsData] = useState<any>([]);

	const [checkboxRows, setCheckboxRows] = useState<any[]>([]);

	const [payload, setPayload] = useState<any>({});

	const { data: DataHourly, isLoading } = useGetHourlyWithFilters({
		page: pagination.page,
		size: pagination.size,
		filters: requestParseTime(filters),
	});

	useEffect(() => {
		if (DataHourly) {
			setHasBaseData(!!DataHourly?.dados?.length);
		}
	}, [DataHourly, setHasBaseData]);

	const theadLancamento = useMemo(
		() => [
			{
				title: '',
				key: 'checkbox',
			},
			{
				title: 'Recurso',
				key: 'nome_recurso',
				colSpan: 2,
			},
			{
				title: 'N° Ordem',
				key: 'numero_ordem',
			},
			{
				title: 'Data',
				key: 'data',
			},
			{
				title: 'Turno',
				key: 'turno',
			},
			{
				title: 'H. Inicial',
				key: 'hora_inicial',
			},
			{
				title: 'H. Final',
				key: 'hora_final',
			},
			{
				title: 'T. de Produção',
				key: 'tempo_producao',
			},
			{
				title: 'Produto',
				key: 'produto',
			},
			{
				title: 'Pç. Boas',
				key: 'pecas_boas',
			},
			{
				title: 'Refugo',
				key: 'refugo',
			},
			{
				title: 'Atividade',
				key: 'atividades',
			},
			{
				title: 'Paradas',
				key: 'possui_paradas',
				alignRight: true,
			},
		],
		[],
	);

	const [dataDelete, setDataDelete] = useState<DataDelete>({} as DataDelete);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estes lançamentos diários da sua base de cadastros. Excluir estes lançamentos diários resultará na remoção dos seguintes itens vinculados a ele:',
			'Registros do IROG',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'hourly',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription:
				'Os lançamentos diários foram excluídos com sucesso',
			toastErrorTitle: 'Erro ao excluir lançamento diário!',
			toastProcessingTitle: 'Processando solicitação...',
			toastProcessingDescription:
				'Processando a solicitação, aguarde o e-mail de confirmação.',
			invalidate: 'hourly-list',
			filters: payload,
		}),
		[payload],
	);

	const { mutate: deleteWithFilters } =
		useDeleteModalByFilters(dataDeleteAll);

	const deleteByFilters = useCallback((filter) => {
		setPayload({
			...filter,
			filtros: requestParseTime(filter?.filtros),
		});

		setTimeout(() => deleteWithFilters(), 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const editTimeForm = (lancamento) => {
		setEditTimeForm(lancamento);
		onClose();
	};

	const openDeleteModal = useCallback(
		(type: any, id: number) => {
			const data = {
				time: {
					provider: type,
					idDelete: id,
					toastSuccessDescription: '',
					toastSuccessTitle:
						'Lançamento Diário deletado com sucesso!',
					toastErrorTitle: 'Erro ao deletar o Lançamento Diário',
					invalidate: 'hourly-list',
					title: 'Deseja realmente excluir este Lançamento?',
					text: [
						'Você está prestes a excluir este lançamento diário da sua base de cadastros. Excluir este lançamento diário resultará na remoção dos seguintes itens vinculados a ele:',
						'Registros do IROG',
						'Você tem certeza que deseja excluir?',
					],
				},
				break: {
					provider: type,
					idDelete: id,
					toastSuccessDescription: '',
					toastSuccessTitle: 'Parada deletada com sucesso!',
					toastErrorTitle: 'Erro ao deletar a Parada',
					invalidate: 'hourly-list',
					title: 'Deseja realmente excluir esta Parada?',
					text: 'Você está prestes a excluir esta Parada da sua base de cadastros. Você tem certeza que deseja excluir?',
				},
			} as DataDeleteType;

			setDataDelete(data[type]);
			onOpenDelete();
		},
		[onOpenDelete],
	);

	const closeDeleteModal = useCallback(() => {
		setDataDelete({} as DataDelete);
		onCloseDelete();
	}, [onCloseDelete]);

	const isCheckedAll = useCallback(() => {
		return !!checkboxRows?.length
			? checkboxRows.every((el) => el?.checked)
			: false;
	}, [checkboxRows]);

	const isIntederminate = useCallback(() => {
		return !!checkboxRows?.length
			? checkboxRows.some((el) => el?.checked) && !isCheckedAll()
			: false;
	}, [checkboxRows, isCheckedAll]);

	const getSelectedIds = useCallback(() => {
		return checkboxRows
			?.filter((el) => el?.checked)
			?.map((el) => el?.dataId);
	}, [checkboxRows]);

	function handleCheckAll(e) {
		setCheckboxRows([
			...checkboxRows?.map((el) => ({
				...el,
				checked: e?.target?.checked,
			})),
		]);
	}

	function updateFilters(newFilters) {
		setFilters(newFilters);
		setFilteredTable(true);
	}

	function renderFilters() {
		return (
			<Flex mb={'20px'}>
				<TableFilter
					dataTest='button-filtrar-lancamento_diario'
					filtersMap={filterMap}
					setFilters={updateFilters}
					module='horaHora'
				/>
			</Flex>
		);
	}

	function renderOptions() {
		return !!rowsData?.length ? (
			<CheckTableActions
				filterModule='horaHora'
				filters={filters}
				deleteMessage={deleteMessage}
				deleteCallback={deleteByFilters}
				allSelectedItens={isCheckedAll()}
				getSelectedIds={getSelectedIds}
				initialPageSize={pagination?.size}
				pageSize={pagination?.size}
				totalRegisters={DataHourly?.total_linhas}
				hasSelectedItem={isIntederminate()}
			/>
		) : null;
	}

	function renderTable() {
		return (
			<Table
				className='fold-table'
				whiteSpace='nowrap'
				variant='unstyled'
			>
				<Thead>
					<Tr
						borderBottomWidth={1}
						borderBottomColor={'secondaryGray.200'}
						color='secondaryGray.600'
					>
						<Th>
							<Checkbox
								key='all'
								isChecked={isCheckedAll()}
								isIndeterminate={isIntederminate()}
								onChange={handleCheckAll}
							/>
						</Th>
						{theadLancamento.map((th: any) => (
							<Th
								key={th.key}
								colSpan={th?.colSpan}
								textAlign={th.alignRight ? 'right' : 'unset'}
							>
								{th.title}
							</Th>
						))}
						<Th />
					</Tr>
				</Thead>
				<Tbody>
					{rowsData?.map((time, index) => (
						<ItemLancamento
							key={time.id}
							rowIndex={index}
							checkboxRows={checkboxRows}
							setSelectedCheckboxRows={setCheckboxRows}
							lancamento={time}
							openDeleteModal={openDeleteModal}
							editTimeForm={editTimeForm}
						/>
					))}
				</Tbody>
			</Table>
		);
	}

	function renderPagination() {
		return (
			<ManualPaging
				pagination={pagination}
				setPagination={setPagination}
			/>
		);
	}

	useEffect(() => {
		if (filteredTable) {
			setFilteredTable(false);
			setPagination((prev) => ({
				...prev,
				page: 0,
			}));
		}
	}, [filteredTable]);

	useEffect(() => {
		if (!isLoading && !!DataHourly?.dados?.length) {
			setRowsData(DataHourly?.dados);

			const allCheckBox = DataHourly?.dados?.map((d, index) => ({
				index,
				dataId: d?.id,
				checked: false,
			}));

			setCheckboxRows(allCheckBox);

			const totalPages = Math.ceil(
				DataHourly?.total_linhas / pagination.size,
			);

			setPagination((prev) => ({
				...prev,
				totalPages,
			}));
			setFilterMap(DataHourly?.filterMap);
		} else {
			setRowsData([]);
		}
	}, [
		DataHourly?.dados,
		DataHourly?.filterMap,
		DataHourly?.total_linhas,
		isLoading,
		pagination.size,
	]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			onEsc={onClose}
			size='full'
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
				minHeight='406px'
			>
				<ModalCloseButton m={'40px 40px 0 0'} />
				<ModalHeader
					display='flex'
					flexDirection='column'
					gap={4}
				>
					<Heading
						color='gray.700'
						mb={4}
						size='md'
					>
						Lançamentos Diários
					</Heading>
				</ModalHeader>

				<ModalBody
					maxWidth='100%'
					overflowX={'auto'}
				>
					{isLoading ? (
						<LoadingSpinner mt='50vh' />
					) : (
						<Flex direction='column'>
							{renderFilters()}
							{renderOptions()}
							<div style={{ height: '65vh' }}>
								{renderTable()}
							</div>
						</Flex>
					)}
				</ModalBody>
				<ModalFooter>
					{!isLoading && (
						<Flex
							justifyContent='center'
							w='100%'
						>
							{renderPagination()}
						</Flex>
					)}
				</ModalFooter>
			</ModalContent>

			{isOpenDelete && (
				<DeleteModal
					isOpen={isOpenDelete}
					dataDelete={dataDelete}
					idDelete={dataDelete.idDelete}
					onClose={closeDeleteModal}
				/>
			)}
		</Modal>
	);
};
