import { Flex, Tooltip } from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { KeysPage } from '../../context';

interface FinishButtonProps {
	keysPage: KeysPage;
	finish: (p: {
		ciclo_id: string | number;
		area_empresa_id?: string | number;
	}) => void;
	cycleId: string | number;
	companyAreaId?: string;
}
export const FinishButton = ({
	keysPage,
	companyAreaId,
	cycleId,
	finish,
}: FinishButtonProps) => {
	return (
		<Flex
			justifyContent={'end'}
			marginTop='20px'
			flexDirection={'column'}
			alignItems='end'
		>
			<Tooltip
				label={`Adicionar valor 0 para as famílias sem preenchimento e finalizar ${keysPage.singular}`}
				placement='top'
				bg='gray.200'
				color='blue.800'
			>
				<span>
					<ButtonComponent
						data-test={`button-finalizar_${keysPage.page}-page-main-${keysPage.page}`}
						title={`Finalizar ${
							keysPage.textSingular
								.substring(0, 1)
								.toUpperCase() +
							keysPage.textSingular.substring(1)
						}`}
						type='primary'
						onClick={() =>
							finish({
								ciclo_id: cycleId,
								area_empresa_id: companyAreaId,
							})
						}
					/>
				</span>
			</Tooltip>
		</Flex>
	);
};
