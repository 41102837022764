import { useQuery } from '@tanstack/react-query';
import { exportApportionment } from 'services/api/requests/apportionment';

export const useExportApportionment = (cicloId: string) => {
	return useQuery(['export-apportionment', cicloId], exportApportionment, {
		refetchOnWindowFocus: false,
		retry: 1,
		enabled: false,
	});
};
