import {
	Box,
	ButtonGroup,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { IconRefreshAlert } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useRecalculateRestrictedPlan } from 'services/queryClient/wrapperHooks/capacidade/restrictedPlan/useRecalculateRestrictedPlan';

interface IRecalculateRestrictedPlanModalProps {
	isOpen: boolean;
	onClose: () => void;
	cycleId: number | string;
}

export const RecalculateRestrictedPlanModal = (
	props: IRecalculateRestrictedPlanModalProps,
) => {
	const { isOpen, onClose, cycleId } = props;

	const { mutateAsync } = useRecalculateRestrictedPlan();

	const handleRecalculate = async () => {
		await mutateAsync(+cycleId);
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'2xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent borderRadius={'3xl'}>
				<ModalHeader>
					<Icon
						as={IconRefreshAlert}
						color={'easyRED.300'}
						boxSize={14}
					/>
					<Text
						fontWeight={700}
						color={'easyBLUE.300'}
						fontSize={'2xl'}
					>
						Recalcular Plano Restrito
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box mb={4}>
						<Text
							color={'secondaryGray.500'}
							fontWeight={400}
							fontSize={'md'}
							pb={4}
						>
							Deseja realmente recalcular o plano restrito
							seguindo a saída do consenso da demanda, ou seja,
							plano irrestrito?
							<Text
								as={'span'}
								fontWeight={'bold'}
							>
								{' '}
								Note que ao recalcular você perderá as edições
								de plano restrito realizadas neste ciclo,{' '}
							</Text>
							retornando ao padrão mais recente do plano
							irrestrito deste ciclo &#40;
							<Text
								as={'span'}
								fontWeight={'bold'}
							>
								Observação
							</Text>
							: essa ação de recalcular é útil para cenários em
							que você editou o consenso, e consequentemente gerou
							um novo plano irrestrito. Ao prosseguir com o
							recálculo, você reflete as alterações do plano
							irrestrito para o restrito!&#41;. Caso deseje
							prosseguir, clique no botão Confirmar.
						</Text>
					</Box>
				</ModalBody>

				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							type={'ghost'}
							title={'Cancelar'}
							onClick={onClose}
						/>
						<ButtonComponent
							type={'secondary'}
							title={'Confirmar'}
							onClick={handleRecalculate}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
