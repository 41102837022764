import api from '../api';

export const getTicketMedio = async () => {
	const response = await api.get('/ticketMedio');
	return response.data;
};

export const getPrecoMedio = async () => {
	const response = await api.get('/ticketMedio');
	return response.data;
};

export const getTicketMedioById = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/ticketMedio/ticketMedioById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

// adequação do nome da requisição de consulta
export const getAverageTicketById = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/ticketMedio/ticketMedioById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const editTicketMedio = async (body, id: number) => {
	const url = `/ticketMedio?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

// adequação do nome da requisição de edição
export const editAverageTicket = async (body, id: number) => {
	const url = `/ticketMedio?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const calculateTicketMedio = async (intervaloTempo: number | string) => {
	let url = `/ticketMedio/calcularTicketMedio?`;

	if (intervaloTempo) {
		url += `&intervaloTempo=${+intervaloTempo}&historicoCompleto=false`;
	} else {
		url += `&historicoCompleto=true`;
	}

	const response = await api.get(url);
	return response.data;
};

export const createAverageTicket = async (body: any) => {
	let url = `/ticketMedio`;

	const response = await api.post(url, body);
	return response.data;
};
