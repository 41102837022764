import {
	Box,
	ButtonGroup,
	FormControl,
	FormLabel,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Text,
} from '@chakra-ui/react';
import { IconRefreshAlert } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { FilterInputState } from 'pages/admin/demanda/rateio/rateio';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { OptionProps } from 'react-select';
import {
	RateioTypeCalculation,
	useCalculateApportionment,
} from 'services/queryClient/wrapperHooks/apportionment/useCalculateApportionment';
import { useGetCompanyParameterization } from 'services/queryClient/wrapperHooks/companyConfigs/useGetCompanyParameterization';

interface InputOption {
	value: number;
	children: number;
}

type InputItem = ReactElement<OptionProps<InputOption>>;

interface RecalculateApportionmentModalProps {
	isOpen: boolean;
	onClose: () => void;
	filterInput: FilterInputState;
	yearFilter: InputItem[];
	setFilterInput: Dispatch<SetStateAction<FilterInputState>>;
	cycleFilter: InputItem[];
	onClosePrevious?: () => void;
	onSuccessCallback?: any;
}

export const RecalculateApportionmentModal = (
	props: RecalculateApportionmentModalProps,
) => {
	const {
		isOpen,
		onClose,
		filterInput,
		yearFilter,
		setFilterInput,
		cycleFilter,
		onClosePrevious = () => {},
		onSuccessCallback = () => {},
	} = props;

	const [timeInterval, setTimeInterval] = useState<string | number>('');

	const { refetch, isFetching } = useCalculateApportionment(
		+filterInput.cycleId,
		timeInterval,
		RateioTypeCalculation.HISTORICO_COMPLETO,
	);
	const { data: ParameterizationData } =
		useGetCompanyParameterization(setTimeInterval);

	const handleRecalculate = async (): Promise<void> => {
		await refetch();
		onSuccessCallback();
		onClose();
		onClosePrevious();
	};

	const timeIntervalOptions: {
		value: string | number;
		label: string;
	}[] = [
		{ value: '3', label: '3 meses' },
		{ value: '6', label: '6 meses' },
		{ value: '9', label: '9 meses' },
		{ value: '12', label: '12 meses' },
		{ value: '', label: 'Histórico Completo' },
	];

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'2xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent borderRadius={'3xl'}>
				<ModalHeader>
					<Icon
						as={IconRefreshAlert}
						color={'easyRED.300'}
						boxSize={14}
					/>
					<Text
						fontWeight={700}
						color={'easyBLUE.300'}
						fontSize={'2xl'}
					>
						Recacular Rateio com base no Histórico de Vendas
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box mb={6}>
						<Text
							color={'secondaryGray.500'}
							fontWeight={400}
							fontSize={'md'}
						>
							Deseja realmente recalcular o rateio seguindo a
							distribuição automática da easy360?
							<Text
								as={'span'}
								fontWeight={'bold'}
							>
								{' '}
								Note que ao recalcular você perderá as edições
								de rateio realizadas neste ciclo,{' '}
							</Text>
							retornando ao padrão da easy360. Caso deseja
							prosseguir, selecione o ciclo a ser recalculado:
						</Text>
					</Box>
					<FormControl>
						<FormLabel color={'easyBLUE.300'}>Ano</FormLabel>
						<Select
							data-test='select-ano-modal-rateio'
							borderRadius={'16px'}
							name={'year'}
							onChange={(e) =>
								setFilterInput({
									...filterInput,
									year: e.target.value,
								})
							}
							value={filterInput.year}
							color={'easyBLUE.300'}
						>
							<option
								value=''
								disabled
							>
								Selecione um Ano
							</option>
							<option value=''></option>
							{yearFilter}
						</Select>
					</FormControl>
					<FormControl mt={2}>
						<FormLabel color={'easyBLUE.300'}>Ciclo</FormLabel>
						<Select
							data-test='select-ciclo-modal-rateio'
							borderRadius={'12px'}
							name={'cycleId'}
							borderColor={'secondaryGray.100'}
							border={'1px solid'}
							onChange={(e) =>
								setFilterInput({
									...filterInput,
									cycleId: Number(e.target.value),
								})
							}
							value={filterInput.cycleId}
							color={'easyBLUE.300'}
						>
							<option
								value=''
								disabled
							>
								Selecione um Ciclo
							</option>
							<option value=''></option>
							{cycleFilter}
						</Select>
					</FormControl>
					<FormControl mt={2}>
						<FormLabel color={'easyBLUE.300'}>
							Intervalo de Tempo
						</FormLabel>
						<Select
							data-test='select-intervalo_de_tempo-modal-rateio'
							borderRadius={'12px'}
							name={'timeInterval'}
							borderColor={'secondaryGray.100'}
							border={'1px solid'}
							onChange={(e) => setTimeInterval(e.target.value)}
							value={timeInterval}
						>
							<option disabled>
								Selecione um intervalo de tempo
							</option>
							{timeIntervalOptions.map((option) => (
								<option
									key={option.value}
									value={option.value}
								>
									{option.label}{' '}
									{ParameterizationData?.tamanho_rateio_ticket &&
										+ParameterizationData.tamanho_rateio_ticket ===
											+option.value &&
										' (Padrão)'}
								</option>
							))}
						</Select>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							data-test={
								'button-cancelar-recacular_rateio_com_base_no_historico_de_vendas-modal-rateio'
							}
							type={'ghost'}
							title={'Cancelar'}
							onClick={onClose}
						/>
						<ButtonComponent
							data-test={
								'button-confirmar-recacular_rateio_com_base_no_historico_de_vendas-modal-rateio'
							}
							type={'secondary'}
							isLoading={isFetching}
							loadingText='Recalculando'
							title={'Confirmar'}
							onClick={isFetching ? undefined : handleRecalculate}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
