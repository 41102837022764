import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';
import { SelectColumnFilter } from 'components/dataTables/FilterTypes/SelectColumnFilter';

export const columnsDataClientesRelacionamento = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Razão Social',
		accessor: 'razaoSocial',
		type: 'relation',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'CNPJ/CPF/ID Estrangeiro',
		accessor: 'cnpjCpfId',
		type: 'relation',
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		// ■ exibir o nome da empresa, não o ID
		Header: 'Relacionamento',
		accessor: 'clienteRelacionado',
		type: 'relation',
		createable: true,
		dependesOn: 'relacionado',
		dependesOnTwo: 'status',
		filter: 'includesIgnoreCase',
	},
	{
		accessor: 'relacionado',
		filter: false,
	},
	{
		Header: 'Status',
		accessor: 'status',
		type: 'badge',
		badgeTrueText: 'Ativo',
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: 'Data Registro',
		accessor: 'dataRegistro',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
	},
];
