import { useQuery } from '@tanstack/react-query';
import { getStatusDemand } from 'services/api/requests/colabAndConsensus/colabAndConsensusFilters';

interface UseGetStatus {
	pageName: string;
	cycleId?: number | string;
	companyAreaId?: number | string;
}
const keys = ['pageName', 'cycleId', 'companyAreaId'];
export const getKeysValuesStatusDemand = (query: UseGetStatus) => {
	const values = keys.map((key) => (query[key] ? String(query[key]) : ''));
	return ['status', ...values];
};

export const useGetStatusDemand = (query: UseGetStatus) => {
	return useQuery(getKeysValuesStatusDemand(query), getStatusDemand, {
		refetchOnWindowFocus: false,
	});
};
