import {
	ColumnData,
	RowHover,
	RowNew,
} from 'components/dataTables/statisticTable/StatisticTable';
import { Row, RowInput } from 'components/dataTables/Table';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
	columnFirstDataDashVenda,
	columnsBodyDashVenda,
	columnsEndDashVenda,
} from '../variables/columnsData';
import {
	Button,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { IconChecks } from '@tabler/icons';
import {
	numberAsCurrencyOrPercentage,
	buildCellHover,
} from 'utils/functions/salesDashboard';
import { useGetSalesDashboardClients } from 'services/queryClient/wrapperHooks/dashboardVendas/useGetSalesDashboard';

import TableFixedHeader from 'components/dataTables/TableFixedHeader';

interface SalesDashboardTableProps {
	[key: string]: any;
	dataSalesDashboard: any;
	date: Date;
}

type DadoByCanaisGroupByClientes = {
	receita_bruta: number;
	razao_social: string;
	cliente_relacionado_id: number;
};

export type DataSalesChanelBd = {
	id?: number;
	nome_canal_venda: string;
	faturadoAnoAnterior: number;
	faturadoMesAnterior: number;
	faturadoMesAtual: number;
	carteiraBoa: number;
	carteiraRuim: number;
	oportunidade: number;
	meta: number;
	calculoForecast: number;
	calculoForecastOportunidade: number;
	faturadoMaisCarteiraBoa: number;
	faturadoMaisCarteiraPedido: number;
	faturadoAnoAnteriorClientes: DadoByCanaisGroupByClientes[];
	faturadoMesAnteriorClientes: DadoByCanaisGroupByClientes[];
	faturadoMesAtualClientes: DadoByCanaisGroupByClientes[];
	carteiraPedidoClientes: DadoByCanaisGroupByClientes[];
	faturadoPorMetaPercentual: number;
	faturadoMaisBoaPorMetaPercentual: number;
	forecastPorMetaPercentual: number;
	faturadoMaisBoaPorForecastPercentual: number;
	forecastOportunidadePorMetaPercentual: number;
	faturadoMaisBoaPorForecastOportunidadePercentual: number;
	planoRestrito: number;
	planoRestritoSql: number;
	carteiraBoaClientes: DadoByCanaisGroupByClientes[];
	carteiraRuimClientes: DadoByCanaisGroupByClientes[];
	forecast: number;
	calculoForecastPorMetaPercentual: number;
	calculoFaturadoMaisBoaPorForecastPercentual: number;
};

export type SalesDataTableHoverClients = {
	month: string;
	value: string;
	clientes: Array<{
		nome: string;
		quantidade: string;
	}>;
	renderAsHover?: boolean;
	onHoverCallback?: any;
};

export type SalesDataTable = {
	canalVenda: string;
	fatBoa: string;
	forecast: string;
	meta: string;
	boa: string | SalesDataTableHoverClients;
	oportunidade: string | SalesDataTableHoverClients;
	faturado: string | SalesDataTableHoverClients;
	historico: string | SalesDataTableHoverClients;
	mesAnterior: string | SalesDataTableHoverClients;
	fatMeta: string;
	fatBoaMeta: string;
	fatBoaFcst: string;
	fcstMeta: string;
	ruim: string | SalesDataTableHoverClients;
	fatBoaRuim: string;
	planoRestrito: string;
	type: 'text-hover';
};

const SalesDashboardTable = ({
	dataSalesDashboard,
	date,
}: SalesDashboardTableProps) => {
	const [tableData, setTableData] = useState<
		(Row | RowInput | RowHover | RowNew)[]
	>([]);

	const [columnsBodySales, setColumnsBodySales] =
		useState<Array<ColumnData>>(columnsBodyDashVenda);

	const keySalesDashboardLocalStorage = 'salesDashboardTableDataType';

	const keyFatBoaOportunidade = 'FAT_BOA_OPORTUNIDADE';
	const keyFatBoaForecast = 'FAT_BOA_FORECAST';
	const keyForecast = 'FORECAST';

	const lastOption = window.localStorage.getItem(
		keySalesDashboardLocalStorage,
	);

	const [tableDataType, setTableDataType] = useState<any>(
		lastOption || keyFatBoaForecast,
	);

	const [searchClients, setSearchClients] = useState<any>({
		column: undefined,
		quantity_clients: 5,
		sales_channel_id: undefined,
		family_id: undefined,
	});

	const [allClients, setAllClients] = useState<any>({});

	const timer = useRef<any>(null);

	const { data: clientsData, isLoading: isLoadingClients } =
		useGetSalesDashboardClients(
			date.toISOString().slice(0, 8) + '01',
			date.toISOString().slice(0, 10),
			searchClients.column,
			searchClients.quantity_clients,
			searchClients.sales_channel_id,
			searchClients.family_id,
		);

	const renderForecastMenu = useCallback(() => {
		const checked = <IconChecks color='green' />;

		return (
			<Menu>
				<MenuButton
					as={Button}
					rightIcon={<ChevronDownIcon />}
					bg='none'
					borderRadius='none'
					size='small'
					fontWeight='100'
				>
					<Text
						fontSize='0.75rem !important'
						fontWeight={500}
						color='secondaryGRAY.500'
					>
						FORECAST
					</Text>
				</MenuButton>
				<MenuList>
					<MenuItem
						onClick={() => {
							setTableDataType(keyFatBoaOportunidade);

							window.localStorage.setItem(
								keySalesDashboardLocalStorage,
								keyFatBoaOportunidade,
							);
						}}
					>
						<Text
							fontSize='small'
							fontWeight={500}
							color='secondaryGRAY.500'
						>
							Cálculo base em Oportunidade (FAT+BOA+OPORTUNIDADES)
						</Text>
						{tableDataType === keyFatBoaOportunidade
							? checked
							: null}
					</MenuItem>
					<MenuItem
						onClick={() => {
							setTableDataType(keyFatBoaForecast);

							window.localStorage.setItem(
								keySalesDashboardLocalStorage,
								keyFatBoaForecast,
							);
						}}
					>
						<Text
							fontSize='small'
							fontWeight={500}
							color='secondaryGRAY.500'
						>
							Cálculo base em Forecast (FAT+BOA+FORECAST)
						</Text>
						{tableDataType === keyFatBoaForecast ? checked : null}
					</MenuItem>
					<MenuItem
						onClick={() => {
							setTableDataType(keyForecast);

							window.localStorage.setItem(
								keySalesDashboardLocalStorage,
								keyForecast,
							);
						}}
					>
						<Text
							fontSize='small'
							fontWeight={500}
							color='secondaryGRAY.500'
						>
							Cálculo base em somente Forecast (FORECAST)
						</Text>
						{tableDataType === keyForecast ? checked : null}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	}, [tableDataType]);

	useEffect(() => {
		const newColumns: Array<ColumnData> = [...columnsBodySales];

		const header = {
			Header: renderForecastMenu(),
			accessor: 'forecast',
			type: 'jsx',
			disableSortBy: true,
			filter: false,
		};

		newColumns.splice(4, 1, header);

		setColumnsBodySales(newColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [renderForecastMenu]);

	const updateState = useCallback((column, requestParamName, entityId) => {
		clearTimeout(timer?.current);

		timer.current = setTimeout(() => {
			setSearchClients((prevState) => ({
				...prevState,
				column,
				[requestParamName]: entityId,
			}));
		}, 500);
	}, []);

	const transformData = useCallback(
		(data: DataSalesChanelBd, isTotal = false): SalesDataTable => {
			const {
				id,
				nome_canal_venda,
				carteiraBoa,
				carteiraRuim,
				faturadoAnoAnterior,
				faturadoMaisCarteiraBoa,
				faturadoMaisCarteiraPedido,
				faturadoMesAnterior,
				faturadoMesAtual,
				calculoForecast,
				calculoForecastOportunidade,
				meta,
				forecastPorMetaPercentual,
				faturadoPorMetaPercentual,
				faturadoMaisBoaPorMetaPercentual,
				forecastOportunidadePorMetaPercentual,
				faturadoMaisBoaPorForecastPercentual,
				faturadoMaisBoaPorForecastOportunidadePercentual,
				oportunidade,
				planoRestrito,
				forecast,
				calculoForecastPorMetaPercentual,
				calculoFaturadoMaisBoaPorForecastPercentual,
			} = data;

			const previousYear = new Date(
				date.getFullYear() - 1,
				date.getMonth(),
			);
			const previousMonth = new Date(
				date.getFullYear(),
				date.getMonth() - 1,
			);

			const displayedBoa = isTotal
				? numberAsCurrencyOrPercentage(carteiraBoa, 'money')
				: buildCellHover(
						updateState,
						allClients,
						isLoadingClients,
						carteiraBoa,
						date,
						'money',
						'carteira_boa',
						id,
						'sales_channel_id',
				  );

			const displayedRuim = isTotal
				? numberAsCurrencyOrPercentage(carteiraRuim, 'money')
				: buildCellHover(
						updateState,
						allClients,
						isLoadingClients,
						carteiraRuim,
						date,
						'money',
						'carteira_ruim',
						id,
						'sales_channel_id',
				  );

			const displayedFaturado = isTotal
				? numberAsCurrencyOrPercentage(faturadoMesAtual, 'money')
				: buildCellHover(
						updateState,
						allClients,
						isLoadingClients,
						faturadoMesAtual,
						date,
						'money',
						'faturado',
						id,
						'sales_channel_id',
				  );

			const displayedHistorico = isTotal
				? numberAsCurrencyOrPercentage(faturadoAnoAnterior, 'money')
				: buildCellHover(
						updateState,
						allClients,
						isLoadingClients,
						faturadoAnoAnterior,
						previousYear,
						'money',
						'historico',
						id,
						'sales_channel_id',
				  );

			const displayedMesAnterior = isTotal
				? numberAsCurrencyOrPercentage(faturadoMesAnterior, 'money')
				: buildCellHover(
						updateState,
						allClients,
						isLoadingClients,
						faturadoMesAnterior,
						previousMonth,
						'money',
						'mes_anterior',
						id,
						'sales_channel_id',
				  );

			const displayedOportunidade = isTotal
				? numberAsCurrencyOrPercentage(oportunidade, 'money')
				: buildCellHover(
						updateState,
						allClients,
						isLoadingClients,
						oportunidade,
						date,
						'money',
						'oportunidade',
						id,
						'sales_channel_id',
				  );

			const getForecastMetaPercentual = () => {
				switch (tableDataType) {
					case keyFatBoaOportunidade:
						return forecastOportunidadePorMetaPercentual;
					case keyFatBoaForecast:
						return forecastPorMetaPercentual;
					case keyForecast:
						return calculoForecastPorMetaPercentual;
					default:
						return 0;
				}
			};

			const getFaturadoBoaForecastPercentual = () => {
				switch (tableDataType) {
					case keyFatBoaOportunidade:
						return faturadoMaisBoaPorForecastOportunidadePercentual;
					case keyFatBoaForecast:
						return faturadoMaisBoaPorForecastPercentual;
					case keyForecast:
						return calculoFaturadoMaisBoaPorForecastPercentual;
					default:
						return 0;
				}
			};

			const getForecast = () => {
				switch (tableDataType) {
					case keyFatBoaOportunidade:
						return calculoForecastOportunidade;
					case keyFatBoaForecast:
						return calculoForecast;
					case keyForecast:
						return forecast;
					default:
						return 0;
				}
			};

			return {
				canalVenda: isTotal ? 'Total' : nome_canal_venda,
				fatBoa: numberAsCurrencyOrPercentage(
					faturadoMaisCarteiraBoa,
					'money',
				),
				forecast: numberAsCurrencyOrPercentage(getForecast(), 'money'),
				meta: numberAsCurrencyOrPercentage(meta, 'money'),
				planoRestrito: numberAsCurrencyOrPercentage(
					planoRestrito,
					'money',
				),
				boa: displayedBoa,
				oportunidade: displayedOportunidade,
				faturado: displayedFaturado,
				historico: displayedHistorico,
				mesAnterior: displayedMesAnterior,
				fatMeta: numberAsCurrencyOrPercentage(
					faturadoPorMetaPercentual,
					'percent',
				),
				fatBoaMeta: numberAsCurrencyOrPercentage(
					faturadoMaisBoaPorMetaPercentual,
					'percent',
				),
				fatBoaFcst: numberAsCurrencyOrPercentage(
					getFaturadoBoaForecastPercentual(),
					'percent',
				),
				fcstMeta: numberAsCurrencyOrPercentage(
					getForecastMetaPercentual(),
					'percent',
				),
				ruim: displayedRuim,
				fatBoaRuim: numberAsCurrencyOrPercentage(
					faturadoMaisCarteiraPedido,
					'money',
				),
				type: 'text-hover',
			};
		},
		[date, updateState, allClients, isLoadingClients, tableDataType],
	);

	useEffect(() => {
		if (dataSalesDashboard) {
			const channelDatatable =
				dataSalesDashboard?.estatisticaVendasByCanal?.map((data) =>
					transformData(data),
				);
			const totalDataTable = transformData(
				dataSalesDashboard?.estatisticaVendasTotal,
				true,
			);

			setTableData([...channelDatatable, totalDataTable]);
		}
	}, [dataSalesDashboard, date, tableDataType, transformData]);

	useEffect(() => {
		const newColumns: Array<ColumnData> = [...columnsBodySales];

		const header = {
			Header: renderForecastMenu(),
			accessor: 'forecast',
			type: 'jsx',
			disableSortBy: true,
			filter: false,
		};

		newColumns.splice(4, 1, header);

		setColumnsBodySales(newColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [renderForecastMenu]);

	useEffect(() => {
		if (!!clientsData?.length) {
			const clients = clientsData?.map((client: any) => {
				const { razao_social, receita_bruta } = client;

				return {
					nome: razao_social,
					quantidade: numberAsCurrencyOrPercentage(
						receita_bruta,
						'money',
					),
				};
			});

			setAllClients((prevState: any) => ({
				...prevState,
				[searchClients.column]: {
					...prevState[searchClients.column],
					[searchClients.sales_channel_id]: clients,
				},
			}));
		} else {
			setAllClients((prevState: any) => ({
				...prevState,
				[searchClients.column]: {
					...prevState[searchClients.column],
					[searchClients.sales_channel_id]: [],
				},
			}));
		}
	}, [clientsData, searchClients.sales_channel_id, searchClients.column]);

	return (
		<>
			<TableFixedHeader
				columns={[
					...columnFirstDataDashVenda,
					...columnsBodySales,
					...columnsEndDashVenda,
				]}
				data={tableData as any[]}
				dataTest='table-dashboard_de_vendas'
				hasTotalRows={true}
			/>
		</>
	);
};

export default SalesDashboardTable;
