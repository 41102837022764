import {
	Box,
	ButtonGroup,
	FormControl,
	FormLabel,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Text,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { IconRefreshAlert } from '@tabler/icons';
import { useCalculateTicketMedio } from 'services/queryClient/wrapperHooks/demanda/averageTicket/useCalculateTicketMedio';
import { useState } from 'react';
import { useGetCompanyParameterization } from 'services/queryClient/wrapperHooks/companyConfigs/useGetCompanyParameterization';
import { CompanyParameterization } from 'services/api/requests/company';

const generateOptions = (
	value: number,
	parameterization?: CompanyParameterization,
) => {
	return (
		<option value={value}>
			{value} {value === 1 ? 'mês' : 'meses'}
			{parameterization?.tamanho_rateio_ticket === value
				? '(Padrão)'
				: ''}
		</option>
	);
};
export const RecalculatePrecoMedioModal = (props) => {
	const { isOpen, onClose } = props;

	const [intervaloTempo, setIntervaloTempo] = useState<string | number>('');

	const { mutate: RecalculateTicketMedio } =
		useCalculateTicketMedio(intervaloTempo);
	const { data: parameterization } =
		useGetCompanyParameterization(setIntervaloTempo);

	const handleActionButtonRecalculate = () => {
		RecalculateTicketMedio();
		onClose();
	};

	const [monthsOptions] = useState([1, 2, 3, 4, 5, 6, 9, 12]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'2xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent borderRadius={'3xl'}>
				<ModalHeader>
					<Icon
						as={IconRefreshAlert}
						color={'easyRED.300'}
						boxSize={14}
					/>
					<Text
						fontWeight={700}
						color={'easyBLUE.300'}
						fontSize={'2xl'}
					>
						Recalcular Preço Médio
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box mb={6}>
						<Text
							color={'secondaryGray.500'}
							fontWeight={400}
							fontSize={'md'}
						>
							Deseja realmente recalcular o preço médio? Caso
							deseje prosseguir, selecione o intervalo de tempo do
							histórico de vendas que deseja considerar no
							recálculo do preço médio:
						</Text>
					</Box>
					<FormControl>
						<FormLabel color={'easyBLUE.300'}>
							<Text pb={2.5}>Intervalo de Tempo</Text>
							<Select
								data-test='select-intervalo_de_tempo-modal-preco_medio'
								onChange={(e) =>
									setIntervaloTempo(e.target.value)
								}
								value={intervaloTempo}
							>
								<option disabled>
									Selecione um intervalo de tempo
								</option>
								{monthsOptions.map((month) => {
									return generateOptions(
										month,
										parameterization,
									);
								})}
								<option value=''>Histórico Completo</option>
							</Select>
						</FormLabel>
					</FormControl>
				</ModalBody>

				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							data-test={'button-cancelar-modal-preco_medio'}
							type={'ghost'}
							title={'Cancelar'}
							onClick={onClose}
						/>
						<ButtonComponent
							data-test={'button-confirmar-modal-preco_medio'}
							type={'secondary'}
							title={'Confirmar'}
							onClick={handleActionButtonRecalculate}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
