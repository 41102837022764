import { useMutation } from '@tanstack/react-query';
import { getCustomerToCollaborationConsensusByNameOrCnpjWithLimit } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { IAutocomplete } from '../../../../../utils/forms/autocomplete';
export const useGetCustomerToCollaborationConsensusByNameOrCnpjAsMutate = ({
	search,
	limit,
	orderBy,
	sort,
	...extraRequestData
}: IAutocomplete) => {
	const _order = orderBy || 'razao_social';
	const _sort = sort || 'asc';
	return useMutation(() =>
		getCustomerToCollaborationConsensusByNameOrCnpjWithLimit(
			search,
			limit,
			_order,
			_sort,
			extraRequestData,
		),
	);
};
