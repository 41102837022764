import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { editPortfolio } from 'services/api/requests/portfolio';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useEditPortfolio = (body, id: number, module?: string) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();
	return useMutation(() => editPortfolio(body, id), {
		onSuccess: () => {
			queryClient.invalidateQueries(['carteiraPedido-list']);
			queryClient.invalidateQueries(['portfolioWithId', id]);
			if (!module) {
				history.goBack();
			}
			toast({
				title: 'Atualizada com sucesso!',
				description: 'Carteira de pedido atualizada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao editar Carteira de Pedido!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
