import { useMemo, useCallback, useState, useEffect, useRef } from 'react';

import {
	ButtonGroup,
	Select as ChakraSelect,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Text,
	Textarea,
	useDisclosure,
	useToast,
	FormErrorMessage,
	Icon,
	Tooltip,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Stack,
	RadioGroup,
	Radio,
} from '@chakra-ui/react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { IconTrash } from '@tabler/icons';
import moment from 'moment';

import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import InputFlexibleTimeMask from 'components/inputs/time/InputFlexibleTimeMask';
import ProductQuantityField from 'components/inputs/ProductQuantityField';
import { InputFormLabel } from 'components/fields/InputFormLabel';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import GenericInput from 'components/inputs/GenericInput';
import { ProductionItineraryHeader } from './ProductionItineraryHeader';

import { currencyToNumber } from 'utils/functions/currencyToNumber';
import { useDownloadTemplateProductionRoadmap } from 'services/queryClient/wrapperHooks/uploads/useDownloadTemplateProductionRoadmap';
import { useUploadProductionRoadmap } from 'services/queryClient/wrapperHooks/uploads/useUploadProductionRoadmap';
import { useExistsRoadmapProduction } from 'services/queryClient/wrapperHooks/useExistsRoadmapProduction';
import { useCreateProductionRoadmap } from 'services/queryClient/wrapperHooks/useCreateProductionRoadmap';
import { getActivities } from 'services/api/requests/roadmap';

import { ActivityModal } from 'pages/admin/cadastros/capacidade/roteiros/modal/ActivityModal';
import { columnsDataCreateRoteiro } from 'pages/admin/cadastros/capacidade/roteiros/variables/columnsData';
import {
	TIME_MESSAGE,
	PRODUCT_FILTER_MESSAGE,
	REQUIRED_FIELD_MESSAGE,
} from './itineraryMessages';

type TIPO_FILTRO_ROTEIRO =
	| 'TODOS'
	| 'PRODUTOS_COM_ROTEIRO'
	| 'PRODUTOS_SEM_ROTEIRO';

const ProductionItineraryForm = () => {
	const {
		isOpen: isOpenActivityModal,
		onOpen: onOpenActivityModal,
		onClose: onCloseActivityModal,
	} = useDisclosure();

	const {
		isOpen: isOpenRemoveModal,
		onOpen: onOpenRemoveModal,
		onClose: onCloseRemoveModal,
	} = useDisclosure();

	const {
		isOpen: isOpenImportModal,
		onOpen: onOpenImportModal,
		onClose: onCloseImportModal,
	} = useDisclosure();

	const toast = useToast();

	const [successImport, setSuccessImport] = useState(false);

	const [payload, setPayload] = useState<any>();

	const selectedProductRef = useRef<any>(null);
	const reusedProductRef = useRef<any>(null);

	const [hasBaseData, setHasBaseData] = useState(false);

	const [tipoFiltro, setTipoFiltro] = useState<TIPO_FILTRO_ROTEIRO>('TODOS');

	const { mutate } = useCreateProductionRoadmap(payload);

	const { data: dataExistsRoadmapProduction } = useExistsRoadmapProduction();

	const { data: dataActivitiesList } = useQuery(
		['activities-list'],
		getActivities,
		{
			refetchOnWindowFocus: false,
		},
	);

	const {
		handleSubmit,
		register,
		control,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'atividades',
	});

	const dataActivitiesListOptions = useMemo(
		() =>
			dataActivitiesList?.map((data) => (
				<option
					key={data?.id}
					value={data?.id}
				>{`${data?.descricao} - ${data?.grupoRecurso?.nome_grupo_recurso}`}</option>
			)),
		[dataActivitiesList],
	);

	const initialStateActivity = useMemo(
		() => ({
			ordem_roteiro: null,
			id_atividade: null,
			quantidade: null,
			tempo_maquina: '00:00:00',
			tempo_mao_obra: '00:00:00',
			borderColor: 'blue.500',
		}),
		[],
	);

	const getValueInputTime = useCallback((value) => {
		if (!value) return '';

		if (typeof value === 'number') {
			const duration = moment.duration(value, 'seconds');
			const hours = Math.floor(duration.asHours())
				.toString()
				.padStart(2, '0');
			const minutes = duration.minutes().toString().padStart(2, '0');
			const seconds = duration.seconds().toString().padStart(2, '0');

			return `${hours}:${minutes}:${seconds}`; // Retorna no formato flexível
		}

		const regex = /^(\d{2,6}):([0-5][0-9]):([0-5][0-9])$/;
		if (regex.test(value)) return value;

		return '';
	}, []);

	const mapActivities = useCallback(
		(activities) =>
			activities
				?.map?.((a) => ({
					key: a?.id,
					ordem_roteiro: a?.ordem_roteiro,
					id_atividade: a?.id,
					quantidade: Number(a?.quantidade_produto),
					tempo_maquina: getValueInputTime(a?.tempo_maquina),
					tempo_mao_obra: getValueInputTime(a?.tempo_mao_obra),
				}))
				?.sort((a, b) => {
					if (a?.ordem_roteiro > b?.ordem_roteiro) return 1;
					else if (a?.ordem_roteiro < b?.ordem_roteiro) return -1;
					return 0;
				}) || [],
		[getValueInputTime],
	);

	const handleOnChangeSelectProduct = useCallback(
		(e) => {
			const product = e?.data;

			reusedProductRef.current?.clearValue?.();

			if (product?.atividades?.length) {
				reset((formData) => {
					return {
						...formData,
						produto: product,
						observacao: product?.ata || '',
						atividades: mapActivities(product?.atividades),
					};
				});
			} else {
				reset((formData) => {
					return {
						...formData,
						produto: product,
						observacao: null,
						atividades: [],
					};
				});
			}
		},
		[mapActivities, reset],
	);

	const handleOnReuseScript = useCallback(
		(e) => {
			const reusedProduct = e?.data;

			if (reusedProduct?.atividades?.length) {
				reset((formData) => {
					return {
						...formData,
						atividades: mapActivities(reusedProduct?.atividades),
					};
				});
			} else {
				reset((formData) => {
					return {
						...formData,
						atividades: [],
					};
				});
			}
		},
		[mapActivities, reset],
	);

	const renderInputTimeMask = useCallback(
		(dataTest, item, index, fieldName) => {
			const isInvalid =
				(errors?.atividades as any)?.length &&
				errors?.atividades?.[index];
			return (
				<FormControl isInvalid={isInvalid}>
					<Controller
						name={`atividades.${index}.${fieldName}`}
						control={control}
						rules={{
							required: {
								value: true,
								message: REQUIRED_FIELD_MESSAGE,
							},
							pattern: {
								value: /^(\d{1,6}):([0-5][0-9]):([0-5][0-9])$/,
								message: TIME_MESSAGE,
							},
						}}
						render={({ field }) => (
							<InputFlexibleTimeMask
								data-test={dataTest}
								name={`atividades.${index}.${fieldName}`}
								value={field.value}
								onChange={field.onChange}
								borderRadius={10}
								placeholder='HH:MM:SS'
								title={TIME_MESSAGE}
							/>
						)}
					/>

					<FormErrorMessage>
						{isInvalid &&
							errors?.atividades?.[index]?.[fieldName]?.message}
					</FormErrorMessage>
				</FormControl>
			);
		},
		[control, errors?.atividades],
	);

	const renderInputNumber = useCallback(
		(dataTest, item, index, fieldName, placeholder) => {
			const isInvalid =
				(errors?.atividades as any)?.length &&
				errors?.atividades?.[index];
			return (
				<FormControl isInvalid={isInvalid}>
					<Input
						data-test={dataTest}
						key={`${item.id}-${fieldName}`}
						type='number'
						placeholder={placeholder}
						borderRadius={10}
						{...register(`atividades.${index}.${fieldName}`, {
							required: REQUIRED_FIELD_MESSAGE,
							min: {
								value: 1,
								message: 'O valor deve maior ou igual a 1.',
							},
						})}
					/>

					<FormErrorMessage>
						{isInvalid &&
							errors?.atividades?.[index]?.[fieldName]?.message}
					</FormErrorMessage>
				</FormControl>
			);
		},
		[errors?.atividades, register],
	);

	const renderInputCurrency = useCallback(
		(dataTest, item, index, fieldName, placeholder) => {
			const isInvalid =
				(errors?.atividades as any)?.length &&
				errors?.atividades?.[index];
			return (
				<FormControl isInvalid={isInvalid}>
					<Controller
						key={`${item.id}-${fieldName}`}
						name={`atividades.${index}.${fieldName}`}
						control={control}
						rules={{
							required: REQUIRED_FIELD_MESSAGE,
							min: {
								value: 0.001,
								message: 'O valor deve maior do que 0.',
							},
						}}
						render={({ field }) => {
							return (
								<ProductQuantityField
									dataTest={dataTest}
									name={`atividades.${index}.quantidade`}
									value={field.value}
									onChange={field.onChange}
									placeholder={placeholder}
									style={{
										color: 'easyBLUE.300',
										fontWeight: 500,
										isReadOnly: true,
										borderRadius: 10,
										height: '40px',
									}}
								/>
							);
						}}
					/>

					<FormErrorMessage>
						{isInvalid &&
							errors?.atividades?.[index]?.[fieldName]?.message}
					</FormErrorMessage>
				</FormControl>
			);
		},
		[errors?.atividades, control],
	);

	const sendRequestData = useCallback(() => {
		setTimeout(() => {
			mutate();
		}, 500);

		if (isOpenRemoveModal) onCloseRemoveModal();

		setTimeout(() => {
			reset({
				produto: null,
				observacao: null,
				atividades: [],
			});

			selectedProductRef.current?.clearValue?.();
			reusedProductRef.current?.clearValue?.();
		}, 1000);
	}, [isOpenRemoveModal, mutate, onCloseRemoveModal, reset]);

	function renderFilters() {
		return (
			<Flex>
				<RadioGroup
					name='tipoFiltro'
					onChange={(e) => {
						selectedProductRef.current?.clearValue?.();
						reusedProductRef.current?.clearValue?.();

						setTipoFiltro(e as TIPO_FILTRO_ROTEIRO);
					}}
					value={tipoFiltro}
				>
					<Stack direction='row'>
						<Radio
							data-test='radio-com_roteiro-page-roteiro'
							size='sm'
							value='PRODUTOS_COM_ROTEIRO'
						>
							Com roteiro
						</Radio>
						<Radio
							data-test='radio-sem_roteiro-page-roteiro'
							size='sm'
							value='PRODUTOS_SEM_ROTEIRO'
						>
							Sem roteiro
						</Radio>
						<Radio
							data-test='radio-todos-page-roteiro'
							size='sm'
							value='TODOS'
						>
							Todos
						</Radio>
					</Stack>
				</RadioGroup>
			</Flex>
		);
	}

	function renderAutocompleteInput({
		ref,
		key,
		name,
		isRequired,
		onChange,
		dataTest,
		extraRequestData,
	}: {
		ref?: any;
		key: string;
		name: string;
		isRequired: boolean;
		onChange: (e: any) => void;
		dataTest: string;
		extraRequestData?: any;
	}) {
		const requiredValidation = isRequired
			? {
					required: {
						value: true,
						message: REQUIRED_FIELD_MESSAGE,
					},
			  }
			: {};

		return (
			<Controller
				key={key}
				name={name}
				control={control}
				rules={requiredValidation}
				render={({ field }) => (
					<GenericInput
						type='autocomplete-infinite-scroll'
						inputProps={{
							id: dataTest,
							ref,
							searchType: 'roteiro-producao',
							extraRequestData,
							onChange: (e) => {
								field.onChange(e);
								onChange(e);
							},
							buildOptions: (item) => ({
								value: item.id,
								label: `${item?.codigo_produto} - ${item?.nome_produto}`,
								data: item,
							}),
						}}
					/>
				)}
			/>
		);
	}

	function renderMainForm() {
		return (
			<Flex
				flexDirection={'row'}
				gap={4}
			>
				<Flex
					flexDirection={'column'}
					w={'50%'}
					mb='20px'
				>
					<FormControl
						mb='20px'
						isInvalid={errors?.produto as any}
					>
						<Flex gap={2}>
							<Flex w='50%'>
								<InputFormLabel
									htmlFor='produto'
									label='Produto'
									labelTooltip={PRODUCT_FILTER_MESSAGE}
									placement={'right'}
								/>
								<Text
									color='red.500'
									fontSize='sm'
									fontWeight='bold'
									ml={'-2'}
								>
									*
								</Text>
							</Flex>
							<Flex
								w='50%'
								display='flex'
								justifyContent='flex-end'
							>
								{renderFilters()}
							</Flex>
						</Flex>
						{renderAutocompleteInput({
							ref: selectedProductRef,
							key: 'produto',
							name: 'produto',
							isRequired: true,
							onChange: handleOnChangeSelectProduct,
							dataTest:
								'autocomplete_infinite_scroll-produto-cadastrar_editar-page-roteiro',
							extraRequestData: {
								apenas_produtos_com_roteiro:
									tipoFiltro !== 'TODOS'
										? tipoFiltro === 'PRODUTOS_COM_ROTEIRO'
										: undefined,
							},
						})}
						<FormErrorMessage>
							{errors?.produto?.message}
						</FormErrorMessage>
					</FormControl>

					<FormControl
						mr='5px'
						mb='20px'
					>
						<FormLabel mb='0'>Reutilizar Roteiro</FormLabel>
						{renderAutocompleteInput({
							ref: reusedProductRef,
							key: 'reutilizar_roteiro',
							name: 'reutilizar_roteiro',
							isRequired: false,
							onChange: handleOnReuseScript,
							dataTest:
								'autocomplete_infinite_scroll-reutilizar_roteiro-cadastrar_editar-page-roteiro',
							extraRequestData: {
								apenas_produtos_com_roteiro: true,
							},
						})}
					</FormControl>
				</Flex>

				<Flex w={'50%'}>
					<FormControl
						mr='5px'
						mb='20px'
					>
						<FormLabel
							mb='0'
							htmlFor='observacao'
						>
							Observações
						</FormLabel>
						<Textarea
							data-test='text_area-observacoes-cadastrar_editar-page-roteiro'
							h={'75%'}
							rows={5}
							placeholder={'....'}
							borderRadius={10}
							maxLength={10000} // tamanho da coluna no DB
							{...register('observacao', {})}
						/>
					</FormControl>
				</Flex>
			</Flex>
		);
	}

	function renderHeaderFormList() {
		return <ProductionItineraryHeader />;
	}

	function renderRowFormList() {
		return (
			<Flex
				gap={2}
				direction='column'
			>
				{fields.map((item, index) => (
					<Flex
						direction='row'
						gap={5}
						key={item?.id}
					>
						<Flex w='10%'>
							{renderInputNumber(
								'input-ordem_do_roteiro-incluir_atividade-additional-roteiro',
								item,
								index,
								'ordem_roteiro',
								'Ordem de roteiro',
							)}
						</Flex>

						<Flex w='40%'>
							<FormControl
								isInvalid={
									(errors?.atividades as any)?.length &&
									errors?.atividades?.[index]
								}
							>
								<ChakraSelect
									data-test='select-atividade_-_grupo_de_recurso-incluir_atividade-additional-roteiro'
									key={`${item.id}-id_atividade`}
									placeholder='Selecione'
									borderRadius={10}
									{...register(
										`atividades.${index}.id_atividade`,
										{
											required: REQUIRED_FIELD_MESSAGE,
										},
									)}
								>
									{dataActivitiesListOptions}
								</ChakraSelect>

								<FormErrorMessage>
									{(errors?.atividades as any)?.length &&
										errors?.atividades?.[index]
											?.id_atividade?.message}
								</FormErrorMessage>
							</FormControl>
						</Flex>

						<Flex w='10%'>
							{renderInputCurrency(
								'input-quantidade-incluir_atividade-additional-roteiro',
								item,
								index,
								'quantidade',
								'Quantidade',
							)}
						</Flex>

						<Flex w='12.5%'>
							{renderInputTimeMask(
								'input-tempo_de_maquina-incluir_atividade-additional-roteiro',
								item,
								index,
								'tempo_maquina',
							)}
						</Flex>

						<Flex w='12.5%'>
							{renderInputTimeMask(
								'input-tempo_do_colaborador-incluir_atividade-additional-roteiro',
								item,
								index,
								'tempo_mao_obra',
							)}
						</Flex>

						<Flex
							w='5%'
							alignItems='center'
						>
							<FormControl key={`${item.id}-remove`}>
								<Tooltip
									label='Remover atividade'
									placement='top'
								>
									<span>
										<Icon
											data-test='link-excluir'
											color='secondaryGray.500'
											as={IconTrash}
											w='20px'
											h='20px'
											cursor='pointer'
											onClick={() => remove(index)}
										/>
									</span>
								</Tooltip>
							</FormControl>
						</Flex>
					</Flex>
				))}
			</Flex>
		);
	}

	function renderFormList() {
		if (!fields?.length) return null;

		return (
			<>
				{renderHeaderFormList()}
				{renderRowFormList()}
			</>
		);
	}

	function renderFormButtons() {
		return (
			<ButtonGroup
				display={'flex'}
				justifyContent={'space-between'}
				mt={'44px'}
			>
				<ButtonComponent
					data-test='button-incluir_atividade-roteiro'
					type={'primary'}
					title={'Incluir Atividade'}
					onClick={() => append({ ...initialStateActivity })}
				/>
				<ButtonComponent
					data-test='button-salvar-page-roteiro'
					type={'primary'}
					action='submit'
					title={'Salvar'}
					isDisabled={isSubmitting}
				/>
			</ButtonGroup>
		);
	}

	function renderImportButton() {
		return (
			<Flex
				gap={4}
				justifyContent={'end'}
				mb={2}
			>
				<ButtonGroup
					spacing={5}
					mb={fields?.length ? 2 : 0}
				>
					<ButtonComponent
						data-test='button-importar-roteiro'
						type={'ghost'}
						title={'Importar Roteiros'}
						onClick={onOpenImportModal}
					/>

					<FileUploadModal
						isOpen={isOpenImportModal}
						onClose={onCloseImportModal}
						headerText={'Importar Roteiros'}
						module={'roteiros'}
						useDownloadTemplate={
							useDownloadTemplateProductionRoadmap
						}
						stringInvalidQuery='production-roadmap-products-list'
						useUpload={useUploadProductionRoadmap}
						columnsData={columnsDataCreateRoteiro}
						setSucessImport={setSuccessImport}
						hasBaseData={hasBaseData}
					/>
					<ButtonComponent
						data-test='button-cadastrar-atividade'
						type={'primary'}
						title={'Cadastrar Atividade'}
						onClick={onOpenActivityModal}
					/>
				</ButtonGroup>
			</Flex>
		);
	}

	function onSubmit(values) {
		const mappedActivities = values?.atividades?.map((a) => ({
			id_atividade: Number(a?.id_atividade),
			ordem_roteiro: Number(a?.ordem_roteiro),
			quantidade: currencyToNumber(a?.quantidade),
			tempo_maquina: moment.duration(a?.tempo_maquina).asSeconds(),
			tempo_mao_obra: moment.duration(a?.tempo_mao_obra).asSeconds(),
		}));

		const duplicatesOrder = mappedActivities
			.map((a) => a?.ordem_roteiro)
			.filter((ordem, index, array) => array.indexOf(ordem) !== index);

		if (duplicatesOrder?.length) {
			toast({
				title: 'Atenção!',
				description: 'Ordem do roteiro duplicada!',
				position: 'bottom-left',
				status: 'warning',
				duration: 8000,
				isClosable: true,
			});
		} else {
			const body = {
				id_produto: values?.produto?.id,
				observacao: values?.observacao,
				atividades: mappedActivities,
			};

			setPayload(body);

			if (!body?.atividades?.length) onOpenRemoveModal();
			else sendRequestData();
		}
	}

	useEffect(() => {
		setHasBaseData(!!dataExistsRoadmapProduction?.id || false);
	}, [dataExistsRoadmapProduction?.id]);

	useEffect(() => {
		if (successImport) {
			setSuccessImport(false);
			handleOnChangeSelectProduct(null);
			selectedProductRef.current?.clearValue?.();
		}
	}, [successImport, handleOnChangeSelectProduct]);

	return (
		<Card className='form-cadastro'>
			<form onSubmit={handleSubmit(onSubmit)}>
				{renderMainForm()}

				{renderImportButton()}

				{renderFormList()}

				{renderFormButtons()}
			</form>

			<ActivityModal
				isOpenActivity={isOpenActivityModal}
				onCloseActivity={onCloseActivityModal}
			/>

			<ModalExcluirRoteiro
				isOpen={isOpenRemoveModal}
				onClose={onCloseRemoveModal}
				handleConfirm={sendRequestData}
			/>
		</Card>
	);
};

const ModalExcluirRoteiro = ({ isOpen, onClose, handleConfirm = () => {} }) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			onEsc={onClose}
			size='xl'
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalHeader>
					<IconTrash
						size={48}
						color='#f0243e'
					/>
					<Text
						fontWeight={700}
						color={'easyBLUE.300'}
						fontSize={'2xl'}
					>
						Roteiro sem atividades!
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text
						color='gray.500'
						fontSize='sm'
					>
						<p style={{ textAlign: 'justify' }}>
							Você está prestes a excluir este roteiro da sua base
							de cadastros. Excluir este roteiro resultará na
							remoção dos seguintes itens vinculados a ele:
						</p>
						<br />
						<p style={{ textAlign: 'justify', paddingLeft: 25 }}>
							<ul>
								<li>Registros do Irog</li>
								<li>Lançamento Diário de Produção</li>
							</ul>
						</p>
						<br />
						<p style={{ textAlign: 'justify' }}>
							Você tem certeza que deseja excluir?
						</p>
					</Text>
				</ModalBody>

				<ModalFooter>
					<Flex gap={1}>
						<ButtonComponent
							data-test='button-cancelar-modal-roteiro'
							type={'ghost'}
							title={'Cancelar'}
							onClick={onClose}
						/>
						<ButtonComponent
							data-test='button-confirmar-modal-roteiro'
							type={'secondary'}
							title={'Confirmar'}
							onClick={handleConfirm}
						/>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ProductionItineraryForm;
