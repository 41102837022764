// Chakra Imports
import {
	Box,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Flex,
	Icon,
	Link,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { IconArrowLeft } from '@tabler/icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import AdminNavbarLinks from './NavbarLinksAdmin';

export default function AdminNavbar(props) {
	const {
		secondary,
		message,
		brandText,
		brandAlternativeText,
		goBack,
		sidebarState,
		sidebarHelpState,
		setSidebarHelpState,
		activeRoute,
		width,
	} = props;
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});
	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let mainText = useColorModeValue('navy.700', 'white');
	let secondaryText = useColorModeValue('gray.700', 'white');
	let navbarPosition = 'fixed';
	let navbarFilter = 'none';
	let navbarBackdrop = 'blur(20px)';
	let navbarShadow = 'none';
	let navbarBg = useColorModeValue('whiteAlpha.500', 'rgba(11,20,55,0.5)');
	let navbarBorder = 'transparent';
	let secondaryMargin = '0px';
	let paddingX = '15px';
	let gap = '0px';
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	const activeHref = () => {
		let urlHash = window.location.hash;
		return urlHash;
	};

	const activeHrefParent = () => {
		return activeRoute?.layout + activeRoute?.parent?.path;
	};

	return (
		<Box
			position={
				sidebarHelpState === 'closed' ? navbarPosition : 'absolute'
			}
			boxShadow={navbarShadow}
			bg={navbarBg}
			borderColor={navbarBorder}
			filter={navbarFilter}
			backdropFilter={navbarBackdrop}
			backgroundPosition='center'
			backgroundSize='cover'
			borderRadius='16px'
			borderWidth='1.5px'
			borderStyle='solid'
			transitionDelay='0s, 0s, 0s, 0s'
			transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
			transition-property='box-shadow, background-color, filter, border'
			transitionTimingFunction='linear, linear, linear, linear'
			alignItems={{ xl: 'center' }}
			display={secondary ? 'block' : 'flex'}
			minH='75px'
			justifyContent={{ xl: 'center' }}
			lineHeight='25.6px'
			mx='auto'
			mt={secondaryMargin}
			pb='8px'
			right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
			px={{
				sm: paddingX,
				md: '10px',
			}}
			ps={{
				xl: '12px',
			}}
			pt='8px'
			top={{ base: '12px', md: '16px', xl: '18px' }}
			w={
				width
					? width
					: sidebarState === 'opened'
					? {
							sm: 'calc(100vw - 365px)',
							xl: 'calc(100vw - 365px)',
					  }
					: {
							sm: 'calc(100vw - 155px)',
							xl: `calc(100vw - ${
								sidebarState === undefined ? '0' : '155px'
							})`,
					  }
			}
			zIndex='2'
		>
			<Flex
				w='100%'
				flexDirection={{
					sm: 'column',
					md: 'row',
				}}
				alignItems={{ xl: 'center' }}
				mb={gap}
			>
				<Box mb={{ sm: '8px', md: '0px' }}>
					<Breadcrumb>
						<BreadcrumbItem
							color={secondaryText}
							fontSize='sm'
							mb='5px'
						>
							<BreadcrumbLink
								href='/'
								color={secondaryText}
							>
								Easy360
							</BreadcrumbLink>
						</BreadcrumbItem>
						{activeRoute?.notShowItemMenu &&
							activeRoute?.parent && (
								<BreadcrumbItem
									color={secondaryText}
									fontSize='sm'
								>
									<BreadcrumbLink
										href={activeHrefParent()}
										color={secondaryText}
									>
										{activeRoute?.parent?.name}
									</BreadcrumbLink>
								</BreadcrumbItem>
							)}
						<BreadcrumbItem
							color={secondaryText}
							fontSize='sm'
						>
							{/* BreadcrumbLink, color={'red'} */}
							<BreadcrumbLink
								href={activeHref()}
								color={secondaryText}
							>
								{brandAlternativeText
									? brandAlternativeText
									: brandText}
							</BreadcrumbLink>
						</BreadcrumbItem>
					</Breadcrumb>
					{/* Here we create navbar brand, based on route name */}
					<Flex
						gap={'10px'}
						alignItems={'center'}
					>
						{goBack && (
							<Link
								color={mainText}
								to={'./'}
								href={goBack}
								bg='inherit'
								borderRadius='inherit'
								fontWeight='bold'
								fontSize='34px'
								_hover={{ color: { mainText } }}
								_active={{
									bg: 'inherit',
									transform: 'none',
									borderColor: 'transparent',
								}}
								_focus={{
									boxShadow: 'none',
								}}
							>
								<Icon
									color={'#A3AED0'}
									w={'24px'}
									h='24px'
									as={IconArrowLeft}
								/>
							</Link>
						)}
						{/* Link, color={'red'} */}
						<Link
							color={mainText}
							href={activeHref()}
							bg='inherit'
							borderRadius='inherit'
							fontWeight='bold'
							fontSize='34px'
							_hover={{ color: { mainText } }}
							_active={{
								bg: 'inherit',
								transform: 'none',
								borderColor: 'transparent',
							}}
							_focus={{
								boxShadow: 'none',
							}}
						>
							{brandAlternativeText
								? brandAlternativeText
								: brandText}
						</Link>
					</Flex>
				</Box>
				<Box
					ms='auto'
					w={{ sm: '100%', md: 'unset' }}
				>
					<AdminNavbarLinks
						onOpen={props.onOpen}
						logoText={props.logoText}
						secondary={props.secondary}
						fixed={props.fixed}
						scrolled={scrolled}
						sidebarHelpState={sidebarHelpState}
						setSidebarHelpState={setSidebarHelpState}
					/>
				</Box>
			</Flex>
			{secondary ? <Text color='white'>{message}</Text> : null}
		</Box>
	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	brandAlternativeText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func,
};
